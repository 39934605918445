import { Layout, Space } from "antd";

import SafeStats from "./safe-stats";
import SafeDocumentsList from "./safe-documents-list";

export default function SafeListV3() {
  return (
    <Layout style={{ margin: "12px 30px 12px 30px" }}>
      <Space size="middle" direction="vertical">
        <SafeStats />
        <SafeDocumentsList />
      </Space>
    </Layout>
  );
}
