import "./App.css";
import * as Sentry from "@sentry/react";
import Router, { InvalidPathRoute } from "./routes";
import "@cloudscape-design/global-styles/index.css";

function App() {
  return (
    <Sentry.ErrorBoundary fallback={<InvalidPathRoute />}>
      <Router />
    </Sentry.ErrorBoundary>
  );
}

export default App;
