import { Reducer } from "redux";
import { UIActionTypes, UIState } from "./types";

export const initialState: UIState = {
  modalName: "",
  is_loading: false,
  permissions: [],
  is_owner: false,
};

const reducer: Reducer<UIState> = (state = initialState, action: any) => {
  switch (action.type) {
    case UIActionTypes.SET_ROLE_PERMISSIONS: {
      return {
        ...state,
        permissions: [...action.payload.permissions],
        is_owner: action.payload.is_owner,
      };
    }
    case UIActionTypes.ADD_SPINNER: {
      if (!state.is_loading) return { ...state, is_loading: true };
      else return state;
    }
    case UIActionTypes.REMOVE_SPINNER: {
      if (state.is_loading) return { ...state, is_loading: false };
      else return state;
    }
    case UIActionTypes.SET_MODAL_NAME: {
      return { ...state, modalName: action.payload };
    }
    case UIActionTypes.ADD_FLASHBAR_ITEM: {
      return {
        ...state,
      };
    }
    case UIActionTypes.REMOVE_FLASHBAR_ITEM: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
};

export { reducer as uiReducer };
