import {
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Typography,
  Space,
  Collapse,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ToastContext } from "../context/toast/incredable-toast";
import IncredableContent from "../components/incredable-content";
import RHFTextField from "../components/RHF/RHFTextField";
import IncredableButton from "../components/button";
import { SpinnerContext } from "../context/spinner/spinner";
import {
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationMutation,
} from "../redux/api/organization/organization";
import { AddressType } from "../redux/api/provider/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";

type IFormInput = {
  name: string;
  phone: string;
  organizationAddress: AddressType;
};

export default function OrgBasicDetailsEdit() {
  const methods = useForm<IFormInput>();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const {
    data: organization,
    isFetching,
    isLoading,
  } = useGetOrganizationDetailsQuery();

  const [updateOrganization] = useUpdateOrganizationMutation();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("organization_settings.edit") ?? false),
  );

  useEffect(() => {
    if (organization) {
      methods.setValue("name", organization.name);
      methods.setValue("phone", organization.phone);
      methods.setValue("organizationAddress", organization.organizationAddress);
    }
  }, [organization]);

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editorganization"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: IFormInput) => {
            if (!!organization?.id) {
              spinnerContext?.showSpinner();
              await updateOrganization({
                id: organization?.id,
                name: data.name,
                phone: data.phone,
                organizationAddress: {
                  addressLane1: data.organizationAddress.addressLane1,
                  addressLane2: data.organizationAddress.addressLane2,
                  city: data.organizationAddress.city,
                  state: data.organizationAddress.state,
                  zipcode: data.organizationAddress.zipcode,
                },
              });
              spinnerContext?.hidespinner();
              toastContext?.openInfoNotification({
                message: `organization saved successfully`,
                placement: "topRight",
              });
              navigate(-1);
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Basic Details
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton disabled={!hasPermission} type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="facilities" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                            readonly={!hasPermission}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Phone Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `phone`,
                            }}
                          />
                        </Col>
                      </Row>

                      <Collapse
                        bordered={false}
                        defaultActiveKey={[]}
                        style={{ background: "none" }}
                      >
                        <Collapse.Panel
                          header={
                            <div style={{ padding: 0 }}>
                              Organization Address
                            </div>
                          }
                          key="1"
                          style={{ padding: 0 }}
                        >
                          <Divider></Divider>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col span={12}>
                              <RHFTextField
                                formItemProps={{
                                  label: "Address Lane 1",
                                }}
                                inputProps={{}}
                                controllerProps={{
                                  name: `organizationAddress.addressLane1`,
                                }}
                              />
                            </Col>
                            <Col span={12}>
                              <RHFTextField
                                formItemProps={{
                                  label: "Address Lane 2",
                                }}
                                inputProps={{}}
                                controllerProps={{
                                  name: `organizationAddress.addressLane2`,
                                }}
                              />
                            </Col>
                            <Col span={12}>
                              <RHFTextField
                                formItemProps={{
                                  label: "City",
                                }}
                                inputProps={{}}
                                controllerProps={{
                                  name: `organizationAddress.city`,
                                }}
                              />
                            </Col>
                            <Col span={12}>
                              <RHFTextField
                                formItemProps={{
                                  label: "State",
                                }}
                                inputProps={{}}
                                controllerProps={{
                                  name: `organizationAddress.state`,
                                }}
                              />
                            </Col>
                            <Col span={12}>
                              <RHFTextField
                                formItemProps={{
                                  label: "Zip Code",
                                }}
                                inputProps={{}}
                                controllerProps={{
                                  name: `organizationAddress.zipcode`,
                                }}
                              />
                            </Col>
                          </Row>
                        </Collapse.Panel>
                      </Collapse>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
