import { filter } from "lodash";
import { Facility } from "../facility/types";
import { MemberType } from "../manageadmin/types";
import { Provider } from "../provider/types";

export type Permission = {
  id: string;
  key: string;
};

export type PermissionsGroupType = {
  [key: string]: Permission[];
};

export type RolePermissions = {
  id: string;
  name: string;
  default: boolean;
  permissions: string[];
};

export type Role = {
  id: string;
  name: string;
  description: string;
  default: boolean;
  admins: ReadonlyArray<MemberType>;
  facilities: ReadonlyArray<Facility>;
  providers: ReadonlyArray<Provider>;
  permissions: ReadonlyArray<Permission>;
};

export type RoleMetaData = {
  id: string;
  name: string;
  description: string;
  default: boolean;
  permissions: ReadonlyArray<Permission>;
};

export type RoleRequest = {
  name: string;
  description: string;
};

export const findNextPermission = () => {};
export const findLowerPermissionKeys = (
  permissions: Permission[],
  permission_key: string
) => {
  let group_name = permission_key.split(".")[0];

  let filtered_permission_keys = filter(permissions, (permission) =>
    permission.key.includes(group_name)
  ).map((permission) => permission.key);

  let index = filtered_permission_keys.findIndex(
    (key) => key === permission_key
  );
  if (index !== -1) return filtered_permission_keys.slice(0, index + 1);
  return [];
};

export const findHigherPermissionKey = (
  permissions: Permission[],
  permission_key: string
) => {
  let group_name = permission_key.split(".")[0];

  let filtered_permission_keys = filter(permissions, (permission) =>
    permission.key.includes(group_name)
  ).map((permission) => permission.key);

  let index = filtered_permission_keys.findIndex(
    (key) => key === permission_key
  );
  if (index !== -1) {
    return filtered_permission_keys.at(index + 1);
  }
  return undefined;
};

export const isPermissionCheckBoxDisabled = ({
  role_permissions,
  permissions,
  permission_key,
}: {
  role_permissions: string[];
  permissions: Permission[];
  permission_key: string;
}) => {
  let higherPermissionKey = findHigherPermissionKey(
    permissions,
    permission_key
  );
  if (higherPermissionKey) {
    return role_permissions.includes(higherPermissionKey);
  }
  return false;
};
