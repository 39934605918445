import { Col, Space, Form, Flex, Divider, Row, Modal } from "antd";
import React, { useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import IncredableButton from "../../../components/button";
import RHFTextArea from "../../../components/RHF/RHFTextArea";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { useGetAllCategoryQuery } from "../../../redux/api/category/category";
import { useGetBasicAllProvidersQuery } from "../../../redux/api/provider/provider";
import {
  DocumentRequest,
  DocumentRequestResponse,
} from "../../../redux/api/documentrequest/types";
import { useResendDocumentShareREquestMutation } from "../../../redux/api/documentrequest/documentrequest";

type FormInputType = DocumentRequest;

interface EditDocumentRequestModalProps {
  editDocumentRequest: boolean;
  handleCloseEditDocumentRequest: () => void;
  document: DocumentRequestResponse | undefined;
}

const EditDocumentRequestList: React.FC<EditDocumentRequestModalProps> = ({
  editDocumentRequest,
  handleCloseEditDocumentRequest,
  document,
}) => {
  const methods = useForm<DocumentRequest>();

  const spinnerContext = React.useContext(SpinnerContext);
  const toastContext = React.useContext(ToastContext);

  const [resendDocumentShareRequest] = useResendDocumentShareREquestMutation();

  const { data: categories } = useGetAllCategoryQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();

  useEffect(() => {
    if (document) {
      methods.setValue("title", document?.title);
      methods.setValue("categoryId", document?.category?.id);
      methods.setValue("providerId", document?.provider?.id);
      methods.setValue("description", document?.description);
    }
  }, [document]);

  return (
    <Modal
      width={1000}
      open={editDocumentRequest}
      onCancel={handleCloseEditDocumentRequest}
      title="Resend Document Share Request"
      footer={false}
    >
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          name="addDocumentRequest"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (document) {
              spinnerContext?.showSpinner();
              handleCloseEditDocumentRequest();
              await resendDocumentShareRequest({
                ...data,
                id: document?.id,
              });
              spinnerContext?.showSpinner();
              toastContext?.openSuccessNotification({
                message: "Resend Document Request Updated successfully",
                placement: "topRight",
              });
            }
          })}
          autoComplete="off"
        >
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Title",
                }}
                inputProps={{}}
                controllerProps={{
                  name: `title`,
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Category" }}
                controllerProps={{
                  name: `categoryId`,
                }}
                disabled={true}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Search to Select",
                  options: categories?.map((facility) => {
                    return {
                      label: facility?.name,
                      value: facility?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Provider" }}
                controllerProps={{ name: "providerId" }}
                disabled={true}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Search to Select",
                  options: providers?.map((provider) => ({
                    label: `${provider.firstName} ${provider.lastName}`,
                    value: provider.id,
                  })),
                }}
              />
            </Col>
            <Col span={24}>
              <RHFTextArea
                formItemProps={{
                  label: "Description",
                }}
                textAreaProps={{}}
                controllerProps={{
                  name: `description`,
                }}
              />
            </Col>
          </Row>
          <Flex gap="small" wrap justify="end">
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  handleCloseEditDocumentRequest();
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Resend
              </IncredableButton>
            </Space>
          </Flex>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default EditDocumentRequestList;
