import { useParams } from "react-router-dom";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import {
  useDeleteCredentialTemplateFormsMutation,
  useGetAllCredentialTemplateFormsQuery,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { Button, ConfigProvider, Space, Typography } from "antd";
import { MinusOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import AddFormModal from "../modals/addform-modal";
import { Header, Table } from "@cloudscape-design/components";

export default function FormsListV2() {
  const { templateId } = useParams();
  const {
    data: digitalforms = [],
    refetch,
    isFetching,
    isLoading,
  } = useGetAllCredentialTemplateFormsQuery(
    templateId ? { credentialTemplateId: templateId } : skipToken
  );

  const [addFormModalVisible, setAddFormModalVisible] =
    useState<boolean>(false);
  const [deleteCredentialTemplateForms] =
    useDeleteCredentialTemplateFormsMutation();

  const [selectedRows, setSelectedRows] = useState<DigitalForm[]>([]);

  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            const temp = selectedRows ? [...selectedRows] : [];
            const selectedItemIndex = temp.findIndex(
              (record) => record?.id + "" === "" + selectedItem?.id
            );
            if (selectedItemIndex !== -1) {
              temp?.splice(selectedItemIndex, 1);
              setSelectedRows(temp);
            } else {
              setSelectedRows([...temp, selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => <span>{item?.name}</span>,
          },
          {
            id: "type",
            header: <div>Type</div>,
            cell: (item) => <span>{item?.digitalFormType?.type}</span>,
          },
        ]}
        items={!!digitalforms ? [...digitalforms] : []}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Button
                    disabled={selectedRows?.length === 0}
                    onClick={() => {
                      if (!selectedRows || !templateId) return;
                      const formsId = selectedRows?.map(
                        (form) => form?.id + ""
                      );
                      deleteCredentialTemplateForms({
                        credentialTemplateId: templateId,
                        forms: formsId,
                      });
                      setSelectedRows([]);
                    }}
                    icon={<MinusOutlined />}
                  >
                    Remove
                  </Button>

                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      onClick={() => {
                        setAddFormModalVisible(true);
                      }}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add Forms
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={4} style={{ marginTop: "0" }}>
                Forms{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${digitalforms?.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
      />
      <AddFormModal
        closeModal={() => {
          setAddFormModalVisible(false);
        }}
        visible={addFormModalVisible}
      />
    </>
  );
}
