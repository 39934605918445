import {
    Box,
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    Pagination,
    SpaceBetween,
    Table,
    TableProps,
} from "@cloudscape-design/components";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Payers } from "../../../redux/api/payers/types";
import { useUpdateAssociatedPayerMutation } from "../../../redux/api/payergroup/payergroup";
import usePayerFilter from "../../../table-filters/payer-filters";
import { Button, Modal, Space } from "antd";

export default function AssociatedPayersModals({
    isAssociatedPayersModalOpen,
    setAssociatedPayersModalOpen,
    associatedPayers,
    setAssociatedPayers,
}: {
    isAssociatedPayersModalOpen: boolean;
    setAssociatedPayersModalOpen: Dispatch<SetStateAction<boolean>>;
    associatedPayers: Payers[];
    setAssociatedPayers: Dispatch<SetStateAction<Payers[]>>;
}) {
    const { payergroupId } = useParams();
    const [updateAssociatedPayer] = useUpdateAssociatedPayerMutation();

    const { TableFilters, filteredPayers, UseQueryHookResult } = usePayerFilter();

    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences<any>
    >({
        pageSize: 10,
        wrapLines: true,
    });

    const { items, collectionProps, paginationProps } = useCollection(
        filteredPayers,
        {
            pagination: {},
        }
    );

    const [selectedPayers, setSelectedPayers] = useState<Payers[]>([]);

    const tableProps: TableProps<Payers> = useMemo(() => {
        return {
            items,
            ...collectionProps,
            pagination: <Pagination {...paginationProps} />,
            preferences: (
                <CollectionPreferences
                    onConfirm={({ detail }) => {
                        if (!!detail) setPreferences(detail);
                    }}
                    preferences={preferences}
                    pageSizePreference={{
                        options: [
                            { value: 10, label: "10 items" },
                            { value: 30, label: "30 items" },
                            { value: 50, label: "50 items" },
                        ],
                    }}
                />
            ),
            filter: TableFilters,
            variant: "borderless",
            loading: UseQueryHookResult?.isLoading || UseQueryHookResult?.isFetching,
            loadingText: "Loading...",
            selectionType: "multi",
            resizableColumns: false,
            stickyHeader: true,
            onSelectionChange: (selectionChangeDetail) => {
                setSelectedPayers(selectionChangeDetail.detail.selectedItems);
            },
            onRowClick: ({ detail }) => {
                const temp = selectedPayers;
                if (
                    !detail.item ||
                    !!associatedPayers
                        ?.map((associatedPayer) => associatedPayer?.id + "")
                        ?.includes(detail.item.id + "")
                )
                    return;
                if (temp?.includes(detail.item))
                    setSelectedPayers(temp.filter((item) => item !== detail.item));
                else setSelectedPayers([detail.item, ...temp]);
            },
            selectedItems: selectedPayers,
            trackBy: (item) => "" + item.id,
            columnDisplay: [
                { id: "name", visible: true },
                { id: "payerId", visible: true },
                { id: "website", visible: true },
                { id: "state", visible: true },
            ],
            isItemDisabled: (item) => {
                return associatedPayers
                    ?.map((associatedPayer) => associatedPayer?.id + "")
                    ?.includes(item?.id + "");
            },
            columnDefinitions: [
                {
                    id: "id",
                    header: <div>ID</div>,
                    cell: (item) => <span>{item.id}</span>,
                    visibility: false,
                },
                {
                    id: "name",
                    header: <div>Name</div>,
                    cell: (item) => <span>{item?.name}</span>,
                    sortingField: "name",
                },
                {
                    id: "payerId",
                    header: <div>Payer ID</div>,
                    cell: (item) => <div>{item?.payerId ?? "-"}</div>,
                },
                {
                    id: "website",
                    header: "Website",
                    cell: (item) => <div>{item.website || "-"}</div>,
                    sortingField: "website",
                    isRowHeader: true,
                },
                {
                    id: "state",
                    header: <div>State</div>,
                    cell: (item) => <div> {item?.payerAddress?.state ?? "-"} </div>,
                },
            ],
        };
    }, [items, selectedPayers]);
    return (
        <Modal
            visible={isAssociatedPayersModalOpen}
            title={<Header counter={`(${filteredPayers.length})`}>Payers</Header>}
            onCancel={() => {
                setAssociatedPayersModalOpen(false);
            }}
            footer={
                <Space direction="horizontal">
                    <Button
                        onClick={() => {
                            setSelectedPayers([]);
                            setAssociatedPayersModalOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={async () => {
                            try {
                                if (!!selectedPayers && !!payergroupId) {
                                    const payersGroupsToUpdate =
                                        selectedPayers?.map(
                                            (selectedPayersItem) => selectedPayersItem?.id + "",
                                        ) || [];

                                    await updateAssociatedPayer({
                                        payerGroupID: payergroupId,
                                        payersToUpdate: payersGroupsToUpdate,
                                    });

                                    setAssociatedPayers([
                                        ...associatedPayers,
                                        ...selectedPayers,
                                    ]);
                                    setSelectedPayers([]);
                                    setAssociatedPayersModalOpen(false);
                                }
                            } catch (error) {
                                console.error("Error updating admin payerGroups:", error);
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Space>
            }
            width={800}
            style={{ maxHeight: "90vh" }}
            styles={{
                body: { overflowY: "auto", maxHeight: "80vh" },
            }}
        >
            <div>
                <Table {...tableProps} />
            </div>
        </Modal>
    );
}