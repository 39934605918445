import queryString from "query-string";
import { api } from "..";
import { PaginationSignatureRequest, SignatureRequestType, eSignatureResponse } from "./types";

const SignatureRequestAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSignatureRequests: build.query<
      PaginationSignatureRequest,
      {
        pageNumber: number;
        pageSize: number;
        providerId?: string;
        signatureRequestsStatus?: string;
        digitalFormId?:string,
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        providerId,
        signatureRequestsStatus,
        digitalFormId,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            providerId,
            signatureRequestsStatus,
            digitalFormId,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `signature/all?${requestParams}`;
      },
      providesTags: ["SignatureRequest"],
    }),
    getSignatureRequest: build.query<eSignatureResponse, { id: string }>({
      query: ({ id }) => `/signature/${id}`,
      providesTags: ["SignatureRequest"],
    }),
    voidSignature: build.mutation<void, { signatureId: string }>({
      query({ signatureId }) {
        return {
          url: `signature/${signatureId}/void`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "SignatureRequest" }],
    }),
    resendSignature: build.mutation<void, { signatureId: string }>({
      query({ signatureId }) {
        return {
          url: `signature/${signatureId}/request`,
          method: "POST",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "SignatureRequest" }],
    }),
    addDigitalformSignatureRequest: build.mutation<
      eSignatureResponse,
      { formId: string } & SignatureRequestType
    >({
      query: ({ formId, ...body }) => {
        return {
          url: `digitalform/${formId}/requestsignature`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SignatureRequest"],
    }),
    addCredentialPackageSignatureRequest: build.mutation<
      eSignatureResponse,
      { packageId: string } & SignatureRequestType
    >({
      query: ({ packageId, ...body }) => {
        return {
          url: `credentialing/packages/${packageId}/requestsignature`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["SignatureRequest"],
    }),
  }),
});

export const {
  useLazyGetAllSignatureRequestsQuery,
  useGetAllSignatureRequestsQuery,
  useResendSignatureMutation,
  useVoidSignatureMutation,
  useGetSignatureRequestQuery,
  useAddDigitalformSignatureRequestMutation,
  useAddCredentialPackageSignatureRequestMutation,
} = SignatureRequestAPI;
