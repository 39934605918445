import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Row,
  Space,
  Typography,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UpdateMemberTypes } from "../../redux/api/manageadmin/types";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import IncredableButton from "../../components/button";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useGetAllRoleQuery } from "../../redux/api/role/role";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  useDeleteAdminFacilityMutation,
  useGetMemberQuery,
  useUpdateAdminFacilityMutation,
  useUpdateMemberRoleMutation,
} from "../../redux/api/manageadmin/manageadmin";
import { skipToken } from "@reduxjs/toolkit/query";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";
import {
  Table as CloudScapeTable,
  Pagination,
  Box,
} from "@cloudscape-design/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { Facility } from "../../redux/api/facility/types";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
type FormInputType = UpdateMemberTypes;
export default function EditActiveMember() {
  const { data: organization, isLoading: isOrgLoading } =
    useGetOrganizationDetailsQuery();
  const [updateAdminFacility] = useUpdateAdminFacilityMutation();
  const { data: facilites = [] } = useGetFacilitiesQuery();
  const [rightSelectedFacilites, setRightSelectedFacilites] =
    useState<Facility[]>();
  const [leftselectedFacilites, setLeftSelectedFacilites] =
    useState<Facility[]>();
  const [unAssociatedFacilities, setUnAssociatedFacilites] = useState<
    Facility[]
  >([]);
  const [associatedFacilities, setAssociatedFacilites] = useState<Facility[]>(
    []
  );
  const navigate = useNavigate();
  const { activeMemberId } = useParams();
  const methods = useForm<FormInputType>();
  const [updateMemberRole] = useUpdateMemberRoleMutation();
  const { data: roles, isLoading, isFetching } = useGetAllRoleQuery();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [leftSearchText, setLeftSearchText] = useState("");
  const [rightSearchText, setRightSearchText] = useState("");

  const filteredLeftItems = unAssociatedFacilities?.filter((item) =>
    item.name.toLowerCase().includes(leftSearchText.toLowerCase())
  );

  const filteredRightItems = associatedFacilities?.filter((item) =>
    item.name.toLowerCase().includes(rightSearchText.toLowerCase())
  );

  const { data: member } = useGetMemberQuery(
    !!activeMemberId ? { activeId: activeMemberId } : skipToken
  );
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false)
  );
  const {
    items: leftItems,
    collectionProps,
    paginationProps: leftPaginationProps,
  } = useCollection(unAssociatedFacilities, {
    pagination: { pageSize: 10 },
  });
  const [deleteAdminFacility] = useDeleteAdminFacilityMutation();
  useEffect(() => {
    setUnAssociatedFacilites(
      facilites?.filter(
        (f) =>
          !associatedFacilities?.map((af) => af?.id + "")?.includes(f?.id + "")
      )
    );
  }, [facilites, associatedFacilities]);
  const {
    items: rightItems,
    collectionProps: rightCollectionProps,
    paginationProps: rightPaginationProps,
  } = useCollection(associatedFacilities ?? [], {
    pagination: { pageSize: 10 },
  });
  useEffect(() => {}, []);
  useEffect(() => {
    if (member) {
      methods.setValue("firstName", member?.firstName ?? "");
      methods.setValue("lastName", member?.lastName ?? "");
      methods.setValue("email", member?.email ?? "");
      methods.setValue("role", member?.role?.id);
      setAssociatedFacilites(member?.facilities ?? []);
    }
  }, [methods, member]);
  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      breadcrumbProps={{
        items: [
          {
            title: <NavLink to="/manageAdmin">Members</NavLink>,
          },
          {
            title: "Edit",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="editMember"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (!!activeMemberId) {
              spinnerContext?.showSpinner();
              const faciltiesToUpdate =
                associatedFacilities?.map(
                  (selectedFacility) => selectedFacility?.id + ""
                ) || [];
              const faciltiesToRemove =
                rightSelectedFacilites?.map((selectedFacility) =>
                  selectedFacility?.id.toString()
                ) || [];
              const manageadminIdNum = parseInt(activeMemberId || "", 10);
              if (isNaN(manageadminIdNum)) {
                throw new Error("Invalid manageadminId");
              }

              if (faciltiesToRemove.length > 0) {
                await deleteAdminFacility({
                  manageadminId: manageadminIdNum,
                  faciltiesToRemove,
                });
              }
              await updateMemberRole({
                id: "" + activeMemberId,
                role_ids: data.role,
              });
              await updateAdminFacility({
                manageadminId: activeMemberId,
                faciltiesToupdate: faciltiesToUpdate,
              });
            }
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Facility Updated Successfully`,
              placement: "topRight",
            });
            setTimeout(() => {
              navigate(-1);
            }, 1000);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Edit Member
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton
                disabled={!hasPermission}
                type="primary"
                htmlType="submit"
              >
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="member" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            readonly
                            formItemProps={{
                              label: "First Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `firstName`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            readonly
                            formItemProps={{
                              label: "Last Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `lastName`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            readonly
                            formItemProps={{
                              label: "Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `email`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFSelect
                            formItemProps={{ label: "Role" }}
                            controllerProps={{
                              name: `role`,
                            }}
                            disabled={member?.role.default || !hasPermission}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Role",
                              options: roles?.map((role) => {
                                return {
                                  label: role?.name,
                                  value: role?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
      {organization?.settings?.enableFacilityBasedAccess && (
        <>
          <Divider orientation="left"> Facilities</Divider>
          {!member?.role.default && (
            <div style={{ paddingTop: "12px" }}>
              <Flex gap="middle">
                <CloudScapeTable
                  selectionType="multi"
                  columnDefinitions={[
                    {
                      id: "name",
                      header: "Name",
                      cell: (item) => {
                        return item.name || "-";
                      },
                      sortingField: "name",
                      isRowHeader: true,
                    },
                  ]}
                  trackBy={"id"}
                  filter={
                    <Flex justify="space-between">
                      {" "}
                      <Input
                        placeholder="Search facilities"
                        value={leftSearchText}
                        onChange={(e) => setLeftSearchText(e.target.value)}
                        style={{ width: "50%" }}
                      />
                      <Pagination {...leftPaginationProps} />
                    </Flex>
                  }
                  items={filteredLeftItems}
                  selectedItems={leftselectedFacilites}
                  onRowClick={(rowClickDetails) => {
                    const itemId = rowClickDetails.detail.item.id;
                    setLeftSelectedFacilites((prevSelected) =>
                      prevSelected?.some((selected) => selected.id === itemId)
                        ? prevSelected.filter(
                            (selected) => selected.id !== itemId
                          )
                        : [...(prevSelected || []), rowClickDetails.detail.item]
                    );
                  }}
                  {...collectionProps}
                  loadingText="Loading resources"
                  header={"Facilities"}
                />
                <Flex
                  align="center"
                  justify="center"
                  vertical={true}
                  gap="middle"
                >
                  <Button
                    icon={
                      <FontAwesomeIcon
                        icon={faArrowCircleRight}
                        style={{ fontSize: "24px", color: "#2B6BE6" }}
                      />
                    }
                    onClick={() => {
                      if (!!leftselectedFacilites)
                        setAssociatedFacilites([
                          ...(associatedFacilities ?? []),
                          ...leftselectedFacilites,
                        ]);
                    }}
                  ></Button>
                  <Button
                    icon={
                      <FontAwesomeIcon
                        icon={faArrowCircleLeft}
                        style={{ fontSize: "24px", color: "#2B6BE6" }}
                      />
                    }
                    onClick={() => {
                      if (!!rightSelectedFacilites) {
                        const temp = associatedFacilities?.filter(
                          (facility) =>
                            !rightSelectedFacilites
                              ?.map((r) => r?.id + "")
                              ?.includes(facility?.id + "")
                        );
                        setAssociatedFacilites(temp);
                      }
                    }}
                  ></Button>
                </Flex>
                <CloudScapeTable
                  selectionType="multi"
                  columnDefinitions={[
                    {
                      id: "name",
                      header: "Name",
                      cell: (item) => {
                        return item.name || "-";
                      },
                      sortingField: "name",
                      isRowHeader: true,
                    },
                  ]}
                  filter={
                    <Flex justify="space-between">
                      {" "}
                      <Input
                        placeholder="Search associated facilities"
                        value={rightSearchText}
                        onChange={(e) => setRightSearchText(e.target.value)}
                        style={{ marginBottom: "10px", width: "50%" }}
                      />
                      <Pagination {...rightPaginationProps} />
                    </Flex>
                  }
                  items={filteredRightItems}
                  {...rightCollectionProps}
                  trackBy={"id"}
                  loadingText="Loading resources"
                  selectedItems={rightSelectedFacilites}
                  onRowClick={(rowClickDetails) => {
                    const itemId = rowClickDetails.detail.item.id;
                    setRightSelectedFacilites((prevSelected) =>
                      prevSelected?.some((selected) => selected.id === itemId)
                        ? prevSelected.filter(
                            (selected) => selected.id !== itemId
                          )
                        : [...(prevSelected || []), rowClickDetails.detail.item]
                    );
                  }}
                  header={"Associated Facilities"}
                  empty={
                    <Box
                      margin={{ vertical: "xs" }}
                      textAlign="center"
                      color="inherit"
                    >
                      <b>No associated facilities</b>
                    </Box>
                  }
                />
              </Flex>
            </div>
          )}
          {!!member?.role.default && (
            <span>Account owner has access to all facilities</span>
          )}
        </>
      )}
    </IncredableContent>
  );
}
