import { Pagination, StatusIndicator, Table } from "@cloudscape-design/components";
import IncredableDateFormat from "../common/incredable-date-format";
import {
  SAFECONTENT_TABLE_DEFAULT_PREFERENCES,
  SAFECONTENT_CONTENT_DISPLAY_OPTIONS,
  TablePreferences,
} from "./table-config";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import useSafeFilter from "../table-filters/safe-filters";
import { AllDocumentResponse } from "../redux/api/document/types";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useDeleteDocumentMutation } from "../redux/api/document/document";
import { Link } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { useLocalStorage } from "../common/localStorage";
import { Header } from "@cloudscape-design/components";
import IncredableButton from "../components/button";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import { useContext, useEffect, useMemo, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";

export default function SafeDocumentsList() {
  const [selectedRows, setSelectedRows] = useState<AllDocumentResponse[]>();
  let [searchParams, setSearchParams] = useSearchParams({ providerId: "" });
  const { providerId } = useParams();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  let isProvider = location.pathname.includes("manageprovider");

  const containerStyle: React.CSSProperties = {
    position: "relative",
  };

  const selectedProviderId = useMemo(() => {
    return searchParams.get("providerId") ?? "";
  }, [searchParams]);

  const {
    TableFilters,
    filteredDocuments = [],
    documentsTotalRecords,
    UseQueryHookResult: {
      reload,
      setPageNumber,
      setPageSize,
      pageNumber,
      pageSize,
      isFetching,
      sortingDetails,
      setSortingDetails,
    },
  } = useSafeFilter({
    selectedProviderId: providerId ? providerId : selectedProviderId,
  });
  const [deleteDocument] = useDeleteDocumentMutation();

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-SafeContent-Table-Preferences",
    SAFECONTENT_TABLE_DEFAULT_PREFERENCES
  );

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  useEffect(() => {
    if (tablePreferences?.pageSize) setPageSize(tablePreferences.pageSize);
  }, [tablePreferences, setPageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredDocuments,
    {
      pagination: { pageSize },
    }
  );
  return (
    <div style={containerStyle}>
      <Table
        loading={isFetching}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            const temp = selectedRows ? [...selectedRows] : [];
            const selectedItemIndex = temp.findIndex(
              (record) => record?.id + "" === "" + selectedItem?.id
            );
            if (selectedItemIndex !== -1) {
              temp?.splice(selectedItemIndex, 1);
              setSelectedRows(temp);
            } else {
              setSelectedRows([...temp, selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "documentName",
            header: "Document Name",
            sortingField: "name",
            cell: (item) => (
              <Link
                href={!!item?.provider?.id ? `/safe/${item?.id}/edit` : "#"}
              >
                {`${item.name}`}
              </Link>
            ),
            isRowHeader: true,
          },
          {
            id: "categoryName",
            header: "Category",
            cell: (item) => item?.category?.name ?? "-",
            sortingField: "category.name",
          },
          {
            id: "provider",
            header: "Provider",
            cell: (item) =>
              `${item?.provider?.lastName || ""} ${item?.provider?.firstName || "-"
              }`,
            sortingField: "provider.lastName",
          },
          {
            id: "facility",
            header: "Facility",
            cell: (item) => item?.facility?.name ?? "-",
            sortingField: "facility.name",
          },
          {
            id: "fileName",
            header: "Attachment name",
            cell: (item) => item?.attachment?.name ?? "-",
            sortingField: "attachment.name",
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => item?.status ? <StatusIndicator
              colorOverride={item?.status === "Active" ? "green" : (item?.status === "Expired" ? "red" : "yellow")}
            >
              {item?.status}
            </StatusIndicator>
              : "-",
            sortingField: "status",
          },
          {
            id: "uploaded",
            header: "Uploaded Date",
            sortingField: "createdDate",
            cell: (item) =>
              !!item?.createdDate
                ? IncredableDateFormat(item?.createdDate)
                : "-",
          },
          {
            id: "alertDays",
            header: "Alert Days",
            sortingField: "alertDays",
            cell: (item) => item?.alertDays,
          },
          {
            id: "expiration",
            header: "Expiration Date",
            sortingField: "expirationDate",
            cell: (item) =>
              !!item?.expirationDate
                ? IncredableDateFormat(item?.expirationDate)
                : "-",
          },
          {
            id: "notes",
            header: "Notes",
            sortingField: "notes",
            cell: (item) => <span>{item.notes}</span>,
          },
        ]}
        items={items}
        pagination={
          <Pagination
            {...paginationProps}
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!tablePreferences?.pageSize && tablePreferences?.pageSize !== 0
                ? Math.ceil(documentsTotalRecords / tablePreferences?.pageSize)
                : 1
            }
          />
        }
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              if (preferences.pageSize !== tablePreferences.pageSize) {
                preferences.pageSize && setPageSize(preferences.pageSize);
              }
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={SAFECONTENT_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    icon={<ReloadOutlined />}
                    onClick={() => {
                      !!reload && reload();
                    }}
                  ></Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            if (!!selectedRows?.at(0)?.provider?.id)
                              isProvider
                                ? navigate(
                                  `document/${selectedRows?.at(0)?.id}`
                                )
                                : navigate(
                                  `/safe/${selectedRows?.at(0)?.id}/edit`
                                );
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          icon: <DeleteOutlined />,
                          disabled: !hasDeletePermission,
                          danger: true,
                          onClick: () => {
                            setDeleteModalOpen(true);
                          },
                        },
                      ],
                    }}
                  >
                    <IncredableButton>
                      Actions
                      <DownOutlined />
                    </IncredableButton>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Dropdown.Button
                      type="primary"
                      icon={<DownOutlined />}
                      menu={{
                        disabled: !hasPermission,
                        items: [
                          {
                            label: "Add Multiple Documents",
                            key: "1",
                          },
                        ],
                      }}
                      onClick={() => {
                        !!selectedProviderId && selectedProviderId !== "all"
                          ? navigate(
                            `/safe/addnew?providerId=${selectedProviderId}`
                          )
                          : isProvider
                            ? navigate("addnew")
                            : navigate("/safe/addnew");
                      }}
                    >
                      Add Document
                    </Dropdown.Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Documents{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${documentsTotalRecords})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.length === 1 && selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteDocument({
              documentId: String(selectedRows?.at(0)?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `document deleted successfully`,
              placement: "topRight",
            });
            setSelectedRows([]);
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Document"
        content={
          <Space size="middle">
            <Typography.Text>Document Name :</Typography.Text>
            <Typography.Text strong>
              {selectedRows?.at(0)?.name}
            </Typography.Text>
          </Space>
        }
        description="Are you sure you want to delete this document?"
      />
    </div>
  );
}
