import { api } from "..";
import { Dashboard } from "./types";

const dashboardAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDashboard: build.query<Dashboard, void>({
      query: () => `dashboard`,
      providesTags: ["Dashboard"],
    }),
    getDashboardPayerEnrollmentStats: build.query<any, void>({
      query: () => `dashboard/payerenrollment/stats`,
      providesTags: ["Dashboard"],
    }),
  }),
});
export const {
  useGetAllDashboardQuery,
  useGetDashboardPayerEnrollmentStatsQuery,
} = dashboardAPI;
