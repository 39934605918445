import { TypeFlags } from "typescript";
import { api } from "..";
import { AffiliationType, AffiliationTypeRequest } from "./types";

const affiliationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAffiliationType: build.query<AffiliationType[], void>({
      query: () => `affiliationtype/all`,
      providesTags: ["AffiliationType"],
    }),
    saveAffiliationType: build.mutation<void, AffiliationTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "affiliationtype",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["AffiliationType"],
    }),
    updateAffiliationType: build.mutation<
      void,
      { id: string } & Partial<AffiliationTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `affiliationtype/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "AffiliationType", id: arg.id },
        { type: "AffiliationType" },
      ],
    }),
    saveAllAffiliationType: build.mutation<void, AffiliationType[]>({
      query: (types) => {
        return {
          url: `affiliationtype`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["AffiliationType"],
    }),
    deleteAffiliationType: build.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `affiliationtype/${id}`,
          method: "DELETE",
          body: id,
        };
      },
      invalidatesTags: ["AffiliationType"],
    }),
  }),
});

export const {
  useGetAllAffiliationTypeQuery,
  useSaveAffiliationTypeMutation,
  useUpdateAffiliationTypeMutation,
  useSaveAllAffiliationTypeMutation,
  useDeleteAffiliationTypeMutation,
} = affiliationApi;
