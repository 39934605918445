import React, { useMemo } from "react";
import { Col, Row, Spin, Alert } from "antd";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import {
  useGetAllWorkflowTemplateQuery,
  useGetAllWorkflowStatusQuery,
} from "../../redux/api/workflow/workflow";
import { useNavigate } from "react-router-dom";

const StepOne: React.FC = () => {
  const {
    data: providers,
    isLoading: isLoadingProviders,
    isError: isErrorProviders,
  } = useGetBasicAllProvidersQuery();

  const navigate = useNavigate();

  const {
    data: workflowTemplates,
    isLoading: isLoadingTemplates,
    isError: isErrorTemplates,
  } = useGetAllWorkflowTemplateQuery();

  const {
    data: workflowStatuses,
    isLoading: isLoadingStatuses,
    isError: isErrorStatuses,
  } = useGetAllWorkflowStatusQuery();

  const {
    data: members,
    isLoading: isLoadingMembers,
    isError: isErrorMembers,
  } = useGetAllMembersQuery();

  const {
    data: facilities,
    isLoading: isLoadingFacilities,
    isError: isErrorFacilities,
  } = useGetFacilitiesQuery();

  const workflowStatusOptions = useMemo(
    () =>
      workflowStatuses?.map((status) => ({
        label: status.name,
        value: status.id,
      })) || [],
    [workflowStatuses]
  );

  if (
    isLoadingProviders ||
    isLoadingTemplates ||
    isLoadingStatuses ||
    isLoadingMembers ||
    isLoadingFacilities
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (
    isErrorProviders ||
    isErrorTemplates ||
    isErrorStatuses ||
    isErrorMembers ||
    isErrorFacilities
  ) {
    return <Alert message="Error fetching data" type="error" />;
  }

  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={8}>
        <RHFSelect
          formItemProps={{ label: "Provider" }}
          controllerProps={{
            name: "providerId",
          }}
          selectProps={{
            allowClear: true,
            showSearch: true,
            style: { width: "100%" },
            placeholder: "",
            options: providers?.map((provider) => ({
              label: `${provider?.firstName} ${provider?.lastName}`,
              value: provider?.id,
            })),
          }}
          rules={{ required: "This field is required" }}
        />
      </Col>
      <Col span={8}>
        <RHFSelect
          formItemProps={{ label: "Workflow Template" }}
          controllerProps={{
            name: "workflowTemplateId",
          }}
          selectProps={{
            allowClear: true,
            showSearch: true,
            style: { width: "100%" },
            placeholder: "",
            options: workflowTemplates?.map((workflowtemplate) => ({
              label: workflowtemplate?.name,
              value: workflowtemplate?.id,
            })),
          }}
        />
      </Col>
      <Col span={8}>
        <RHFTextField
          formItemProps={{
            label: "Workflow Name",
          }}
          inputProps={{}}
          controllerProps={{
            name: `name`,
          }}
          rules={{ required: "This field is required" }}
        />
      </Col>
      <Col span={8}>
        <RHFSelect
          formItemProps={{
            label: "Workflow Status",
          }}
          controllerProps={{
            name: "workflowStatusId",
            rules: { required: "Workflow Status is required" },
          }}
          selectProps={{
            allowClear: true,
            showSearch: true,
            style: { width: "100%" },
            placeholder: "Select Workflow Status",
            options: workflowStatusOptions,
            onModifyClick: () => {
              navigate("/workflowstatus");
            },
          }}
        />
      </Col>
      <Col span={8}>
        <RHFSelect
          formItemProps={{ label: "Assigned To" }}
          controllerProps={{
            name: "adminId",
          }}
          selectProps={{
            allowClear: true,
            showSearch: true,
            style: { width: "100%" },
            placeholder: "",
            options: members?.map((member) => {
              return {
                label: `${member?.firstName} ${member?.lastName}`,
                value: member?.id,
              };
            }),
          }}
        />
      </Col>
      <Col span={8}>
        <RHFSelect
          formItemProps={{ label: "Facility" }}
          controllerProps={{
            name: "facilityId",
          }}
          selectProps={{
            allowClear: true,
            showSearch: true,
            style: { width: "100%" },
            placeholder: "Search to Select",
            options: facilities?.map((facility) => {
              return {
                label: facility?.name,
                value: facility?.id,
              };
            }),
          }}
        />
      </Col>
      <Col span={24}>
        <RHFTextArea
          formItemProps={{ label: "Notes" }}
          controllerProps={{ name: "notes" }}
        />
      </Col>
    </Row>
  );
};

export default StepOne;
