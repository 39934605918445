import { CSSProperties } from "react";

export const loginContent: CSSProperties = {
  width: "80%",
  margin: "50px",
};

export const loginTitle: CSSProperties = {
  fontSize: "42px",
  fontWeight: 800,
  color: "#7c4095",
  lineHeight: "44px",
  marginBottom: "5px",
};

export const loginText: CSSProperties = {
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "28px",
  color: "#51515b",
};

export const loginButton: CSSProperties = {
  borderRadius: "6px",
  padding: "14px 18px",
  backgroundColor: "#521262",
  boxShadow: "0px 1px 2px 0px #1018280a",
  height: "52px",
  fontSize: "18px",
  fontWeight: 600,
  color: "#ffffff",
};

export const forgotPassButton: CSSProperties = {
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "24px",
  float: "right",
  marginBottom: "24px",
  padding: "0",
};
