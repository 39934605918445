import { Flex, Typography, Form, Space, Divider, Row, Col } from "antd";
import { useForm, FormProvider } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import IncredableButton from "../../../components/button";
import IncredableContent from "../../../components/incredable-content";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { useGetAllCategoryQuery } from "../../../redux/api/category/category";
import RHFSelect from "../../../components/RHF/RHFSelect";
import { useGetBasicAllProvidersQuery } from "../../../redux/api/provider/provider";
import { ShareDocumentMutation } from "../../../redux/api/documentrequest/types";
import { useAddDocumentShareRequestMutation } from "../../../redux/api/documentrequest/documentrequest";
import { useContext } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import RHFTextArea from "../../../components/RHF/RHFTextArea";

export default function AddDocumentRequest() {
  const navigate = useNavigate();
  const methods = useForm<ShareDocumentMutation>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: categories } = useGetAllCategoryQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const [addDocumentShareRequest] = useAddDocumentShareRequestMutation();

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          {
            title: <NavLink to="/requests">Requests</NavLink>,
          },
          {
            title: "Add",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          layout="vertical"
          name="addDocumentRequest"
          onFinish={methods.handleSubmit(async (data) => {
            spinnerContext?.showSpinner();
            await addDocumentShareRequest(data);
            toastContext?.openInfoNotification({
              message: `Request saved successfully`,
              placement: "topRight",
            });
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Add Document Share Request
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Title",
                }}
                inputProps={{}}
                controllerProps={{
                  name: `title`,
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Category" }}
                controllerProps={{
                  name: `categoryId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: categories?.map((facility) => {
                    return {
                      label: facility?.name,
                      value: facility?.id,
                    };
                  }),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Provider" }}
                controllerProps={{ name: "providerId" }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: providers?.map((provider) => ({
                    label: `${provider.firstName} ${provider.lastName}`,
                    value: provider.id,
                  })),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={24}>
              <RHFTextArea
                formItemProps={{
                  label: "Description",
                }}
                textAreaProps={{}}
                controllerProps={{
                  name: `description`,
                }}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
