import { useContext, useState } from "react";
import { Button, ConfigProvider, Flex, Space } from "antd";
import { Pagination, Table, Modal } from "@cloudscape-design/components";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useGetCredentialPackageQuery,
  useUpdateCredentialPackageDocumentsMutation,
} from "../../redux/api/credentialpackages/credentialpackages";
import { useGetAllProviderSafeDocumentsQuery } from "../../redux/api/provider/provider";
import { AllDocumentResponse } from "../../redux/api/document/types";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import {
  CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import { useLocalStorage } from "../../common/localStorage";

type CredentialPackageAddDocumentsModalProps = {
  closeModal: VoidFunction;
  visible: boolean;
};

export default function CredentialPackageAddDocumentsModal({
  visible,
  closeModal,
}: CredentialPackageAddDocumentsModalProps) {
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<AllDocumentResponse[]>([]);

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-CredentialPackageDocumentList-Table-Preferences",
    CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_DEFAULT_PREFERENCES
  );

  const { id: credentialPackageId } = useParams();

  const [updateCredentialPackageDocuments] =
    useUpdateCredentialPackageDocumentsMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { data: credentialPackage } = useGetCredentialPackageQuery(
    credentialPackageId ?? skipToken
  );

  const { data: documents, isLoading } = useGetAllProviderSafeDocumentsQuery(
    !!credentialPackage?.provider?.id
      ? {
        providerId: credentialPackage?.provider?.id,
      }
      : skipToken
  );

  const handleSave = async () => {
    if (!selectedRows || !credentialPackageId || selectedRows.length === 0) {
      closeModal();
      return;
    }

    try {
      spinnerContext?.showSpinner();

      await updateCredentialPackageDocuments({
        id: credentialPackageId,
        documents: selectedRows.map(String),
      });

      spinnerContext?.hidespinner();

      toastContext?.openSuccessNotification({
        message: `documents updated successfully`,
        placement: "topRight",
      });
      closeModal();
    } catch (error) {
      spinnerContext?.hidespinner();
      toastContext?.openErrorNotification({
        message: `Error updating documents:`,
        placement: "topRight",
      });
    }
  };

  const { items, collectionProps, paginationProps } = useCollection(
    documents?.documents || [],
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <Modal
      header={"Documents"}
      visible={visible}
      onDismiss={closeModal}
      size="large"
      footer={[
        <Flex justify="end">
          <Space>
            <Button
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </Button>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#2B6BE6",
                },
              }}
            >
              <Button type="primary" onClick={handleSave}>
                Save
              </Button>
            </ConfigProvider>
          </Space>
        </Flex>,
      ]}
    >
      <Table
        loading={isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail?.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (!!selectedItem) {
            const temp = !!selectedRows ? [...selectedRows] : [];
            const selectedItemIndex = temp.findIndex(
              (record) => record?.id + "" === "" + selectedItem?.id
            );
            if (selectedItemIndex !== -1) {
              temp?.splice(selectedItemIndex, 1);
              setSelectedRows(temp);
            } else {
              setSelectedRows([...temp, selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: "Id",
            cell: (item) => <span>{item?.id}</span>,
            sortingField: "id",
          },
          {
            id: "name",
            header: "Document Name",
            cell: (item) => <span>{item?.name}</span>,
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(b?.name ?? ""),
          },
          {
            id: "category",
            header: "Category",
            cell: (item) => <span>{item?.category?.name}</span>,
            sortingField: "category",
            sortingComparator: (a, b) =>
              (a?.category?.name ?? "").localeCompare(b?.category?.name ?? ""),
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            contentDisplayOptions={
              CREDENTIALPACKAGE_DOCUMENTLIST_TABLE_CONTENT_DISPLAY_OPTIONS
            }
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
          />
        }
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
        {...collectionProps}
        columnDisplay={tablePreferences.contentDisplay}
        wrapLines={tablePreferences.wrapLines}
        stripedRows={tablePreferences.stripedRows}
        contentDensity={tablePreferences.contentDensity}
        stickyColumns={tablePreferences.stickyColumns}
      />
    </Modal>
  );
}
