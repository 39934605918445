import { Col, Divider, Form, Row, Space, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import IncredableContent from "../../components/incredable-content";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { WorkFlowTaskRequest } from "../../redux/api/workflow/types";
import {
  useAddWorkflowTaskMutation,
  useGetWorkflowQuery,
} from "../../redux/api/workflow/workflow";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import IncredableButton from "../../components/button";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";

export default function AddWorkflowTasks() {
  const methods = useForm<WorkFlowTaskRequest>();
  const [showTextArea, setShowTextArea] = useState(false);
  const { workflowId } = useParams();
  const [addWorkflowTask] = useAddWorkflowTaskMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { data: taskStatuses } = useGetAllTaskStatusQuery();
  const { data: taskTypes } = useGetAllTaskTypeQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: members } = useGetAllMembersQuery();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: workflow, isLoading } = useGetWorkflowQuery(
    workflowId ? { id: workflowId } : skipToken
  );
  const [providerId, taskTypeId] = methods.watch(["providerId", "taskTypeId"]);

  const handleLabelClick = () => {
    setShowTextArea((prev) => !prev);
  };

  useEffect(() => {
    const assignedProvider = providers?.find(
      (provider) => provider?.id + "" === providerId + ""
    );
    const taskType = taskTypes?.find(
      (taskType) => taskType?.id + "" === taskTypeId + ""
    );
    const taskName =
      assignedProvider?.firstName +
      " " +
      assignedProvider?.lastName +
      "_" +
      taskType?.name;
    if (!!assignedProvider && !!taskType) methods.setValue("name", taskName);
  }, [providerId, taskTypeId]);

  useEffect(() => {
    if (!!workflowId) methods.setValue("workflowId", workflowId);
  }, []);

  const onSubmit = async (data: WorkFlowTaskRequest) => {
    if (!!workflowId) {
      try {
        spinnerContext?.showSpinner();
        await addWorkflowTask({ ...data });
        spinnerContext?.hidespinner();
        toastContext?.openSuccessNotification({
          message: `add  workflowtask successfully`,
          placement: "topRight",
          onClose: () => {
            navigate(-1);
          },
        });
      } catch (error) {
        toastContext?.openErrorNotification({
          message: "Failed to add workflow task.",
          placement: "topRight",
        });
      }
    }
  };

  return (
    <IncredableContent
      spinning={isLoading}
      breadcrumbProps={{
        items: [
          {
            title: <NavLink to="/workflows">Workflows</NavLink>,
          },
          {
            title: <NavLink to={`/workflows/${workflow?.id}`}>{workflow?.name}</NavLink>,
          },
          {
            title: "Add Task",
          },
        ],
      }}
    >
      <Space style={{ paddingBottom: "20px" }}>
        <Typography.Text
          strong
          style={{
            fontSize: "18px",
            color: "#1D1A1F",
            lineHeight: "25px",
            fontWeight: "500",
          }}
        >
          Add Workflow Task
        </Typography.Text>
      </Space>
      <FormProvider {...methods}>
        <Form layout="vertical">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Task Type",
                }}
                controllerProps={{
                  name: "taskTypeId",
                  rules: { required: "Task Type is required" },
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Task Type",
                  options: taskTypes?.map((type) => ({
                    label: type.name,
                    value: type.id,
                  })),
                  onModifyClick: () => {
                    navigate("/taskstype");
                  },
                }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Provider",
                }}
                controllerProps={{
                  name: `providerId`,
                }}
                selectProps={{
                  showSearch: true,
                  placeholder: "Select Provider",
                  options: providers?.map((provider) => ({
                    label: `${provider.firstName} ${provider.lastName}`,
                    value: provider.id,
                  })),
                }}
              />
            </Col>

            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Task Name",
                }}
                inputProps={{
                  placeholder: "Enter Task Name",
                }}
                controllerProps={{
                  name: `name`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Assigned To",
                }}
                controllerProps={{
                  name: `adminId`,
                  rules: { required: "Task Type is required" },
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Member",
                  options: members?.map((member) => ({
                    label: `${member.firstName} ${member.lastName}`,
                    value: member.id,
                  })),
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Task Status",
                }}
                controllerProps={{
                  name: `taskStatusId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Task Status",
                  options: taskStatuses?.map((status) => ({
                    label: status.name,
                    value: status.id,
                  })),
                  onModifyClick: () => {
                    navigate("/taskstatus");
                  },
                }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Facility",
                }}
                controllerProps={{
                  name: `facilityId`,
                  rules: { required: false },
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Facility",
                  options: facilities?.map((facility) => ({
                    label: facility.name,
                    value: facility.id,
                  })),
                }}
              />
            </Col>

            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Start Date",
                }}
                controllerProps={{
                  name: `startDate`,
                }}
                datePickerProps={{
                  placeholder: "Select Start Date",
                }}
              />
            </Col>

            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Due Date",
                }}
                controllerProps={{
                  name: `dueDate`,
                }}
                datePickerProps={{
                  placeholder: "Select Due Date",
                }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Completed Date",
                }}
                controllerProps={{
                  name: `completedDate`,
                }}
                datePickerProps={{
                  placeholder: "Select Completed Date",
                }}
              />
            </Col>
            <Col span={24}>
              <label
                onClick={handleLabelClick}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
              >
                {showTextArea ? <RightOutlined /> : <DownOutlined />} Notes{" "}
              </label>
              <Divider style={{ margin: "5px", padding: "0" }} />
              {showTextArea && (
                <RHFTextArea
                  formItemProps={{ label: "Notes" }}
                  controllerProps={{ name: "description" }}
                />
              )}
            </Col>
          </Row>
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
            }}
          >
            <IncredableButton
              secondary
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </IncredableButton>
            <IncredableButton
              type="primary"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Save
            </IncredableButton>
          </Space>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
