import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useMemo, useState } from "react";
import { useGetAllInvitedQuery } from "../redux/api/manageadmin/invitation";

export default function useInvitationsFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("email");

  const [emailFilterValue, setEmailFilterValue] = useState<string | undefined>(
    undefined
  );

  const {
    data: invitations = [],
    refetch,
    isLoading: invitationsLoading,
    isFetching,
  } = useGetAllInvitedQuery();

  const filteredInvitations = useMemo(() => {
    if (invitationsLoading) return [];
    return invitations.filter((invitation) => {
      let matches = true;
      if (emailFilterValue) {
        const nameWords = emailFilterValue?.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) =>
            invitation?.email?.toLowerCase().includes(word)
          );
      }
      return matches;
    });
  }, [invitations, emailFilterValue, invitationsLoading]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[{ value: "email", label: "Email" }]}
        />
        {selectedFilterKey === "email" && (
          <Input
            onChange={(event) => setEmailFilterValue(event.target.value)}
            value={emailFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Email"
            prefix={<SearchOutlined />}
          />
        )}
      </Space>
      <div></div>
    </Space>
  );

  return {
    TableFilters,
    filteredInvitations,
    UseQueryHookResult: {
      refetch,
      isLoading: invitationsLoading,
      isFetching,
    },
  };
}
