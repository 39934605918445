import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useState } from "react";
import { Location } from "../redux/api/location/types";
import { useGetAllLocationQuery } from "../redux/api/location/location";

export default function useLocationFilter(items?: Location[]) {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [lcoationNameFilterValue, setLocationNameFiltervalue] = useState<
    string | undefined
  >(undefined);

  const {
    data: locations = [],
    refetch,
    isLoading: locationLoading,
    isFetching,
  } = useGetAllLocationQuery();
  const [filteredLocation, setFilteredLocation] = useState<Location[]>(
    items ?? locations
  );

  useEffect(() => {
    setFilteredLocation(items ?? locations);
  }, [locations, , items]);

  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);

  useEffect(() => {
    let temp_filtered_location = !!items ? [...items] : [...locations];
    if (!!lcoationNameFilterValue && selectedFilterkey === "name") {
      temp_filtered_location = temp_filtered_location?.filter((group) =>
        group?.name
          ?.toLowerCase()
          .includes(lcoationNameFilterValue?.toLowerCase())
      );
    }
    setFilteredLocation(temp_filtered_location);
  }, [lcoationNameFilterValue, locations, items]);

  useEffect(() => {
    setLocationNameFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[
          { value: "name", label: "Name" },
          { value: "groupCode", label: "Group Code" },
        ]}
      />
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setLocationNameFiltervalue(event?.target?.value);
          }}
          value={lcoationNameFilterValue}
          style={{
            width: "320px",
          }}
          placeholder="Find Name"
          prefix={<SearchOutlined />}
        />
      )}
    </Space>
  );

  return {
    TableFilters,
    filteredLocation,
    UseQueryHookResult: {
      refetch,
      isLoading: locationLoading,
      isFetching,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}
