import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import {
  useGetAllTasksQuery,
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../redux/api/task";
import { useGetAllWorkflowQuery } from "../redux/api/workflow/workflow";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";

export default function useTaskFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("name");

  const [taskTypeFilterValue, setTaskTypeFilterValue] = useState<
    string | undefined
  >(undefined);

  const [facilityFilterValue, setFacilityFilterValue] = useState<
    string | undefined
  >(undefined);

  const [nameFilterValue, setNameFilterValue] = useState<string | undefined>(
    undefined
  );

  const [workflowFilterValue, setWorkflowFilterValue] = useState<
    string | undefined
  >(undefined);

  const [taskStatusFilterValue, setTaskStatusFilterValue] = useState<
    string | undefined
  >(undefined);

  const [providerFilterValue, setProviderFilterValue] = useState<
    string | undefined
  >(undefined);

  const [assignedToFilterValue, setAssignedToFilterValue] = useState<
    string | undefined
  >(undefined);

  const { data: assignedTo = [], isLoading: assignedToLoading } =
    useGetAllMembersQuery();

  const { data: taskStatus = [], isLoading: taskStatusLoading } =
    useGetAllTaskStatusQuery();

  const { data: taskTypes = [], isLoading: taskTypesLoading } =
    useGetAllTaskTypeQuery();

  const { data: facilities = [], isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();

  const { data: workflows = [], isLoading: workflowsLoading } =
    useGetAllWorkflowQuery();

  const { data: providers = [], isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();

  const {
    data: tasks = [],
    refetch,
    isLoading: tasksLoading,
    isFetching,
  } = useGetAllTasksQuery();

  const filteredTasks = useMemo(() => {
    if (
      tasksLoading ||
      taskTypesLoading ||
      facilitiesLoading ||
      workflowsLoading ||
      taskStatusLoading ||
      providersLoading ||
      assignedToLoading
    )
      return [];
    return tasks.filter((task) => {
      let matches = true;
      if (facilityFilterValue) {
        matches = matches && task?.facility?.id === facilityFilterValue;
      }
      if (taskTypeFilterValue) {
        matches = matches && task?.taskType?.id === taskTypeFilterValue;
      }
      if (nameFilterValue) {
        const nameWords = nameFilterValue?.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) => task?.name?.toLowerCase().includes(word));
      }
      if (workflowFilterValue) {
        matches = matches && task?.workflow?.id === workflowFilterValue;
      }
      if (taskStatusFilterValue) {
        matches = matches && task?.taskStatus?.id === taskStatusFilterValue;
      }
      if (providerFilterValue) {
        matches = matches && task?.provider?.id === providerFilterValue;
      }
      if (assignedToFilterValue) {
        matches = matches && task?.admin?.id === assignedToFilterValue;
      }
      return matches;
    });
  }, [
    tasks,
    facilityFilterValue,
    taskTypeFilterValue,
    nameFilterValue,
    workflowFilterValue,
    taskStatusFilterValue,
    providerFilterValue,
    assignedToFilterValue,
    tasksLoading,
    taskTypesLoading,
    facilitiesLoading,
    workflowsLoading,
    taskStatusLoading,
    providersLoading,
    assignedToLoading,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "name", label: "Name" },
            { value: "taskType", label: "Task Type" },
            { value: "status", label: "Status" },
            { value: "provider", label: "Provider" },
            { value: "facility", label: "Facility" },
            { value: "assignedTo", label: "Assigned To" },
            { value: "workflow", label: "Workflow" },
          ]}
        />
        {selectedFilterKey === "name" && (
          <Input
            onChange={(event) => setNameFilterValue(event.target.value)}
            value={nameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "facility" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={facilityFilterValue}
            placeholder="Select facility"
            onChange={(value) => setFacilityFilterValue(value)}
            style={{ width: "320px" }}
            options={facilities.map((facility) => ({
              label: facility.name,
              value: facility.id,
            }))}
          />
        )}
        {selectedFilterKey === "taskType" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={taskTypeFilterValue}
            placeholder="Select Task Type"
            onChange={(value) => setTaskTypeFilterValue(value)}
            style={{ width: "320px" }}
            options={taskTypes.map((type) => ({
              label: type.name,
              value: type.id,
            }))}
          />
        )}
        {selectedFilterKey === "workflow" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={workflowFilterValue}
            placeholder="Select Workflow"
            onChange={(value) => setWorkflowFilterValue(value)}
            style={{ width: "320px" }}
            options={workflows.map((workflow) => ({
              label: workflow.name,
              value: workflow.id,
            }))}
          />
        )}
        {selectedFilterKey === "status" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={taskStatusFilterValue}
            placeholder="Select Task Status"
            onChange={(value) => setTaskStatusFilterValue(value)}
            style={{ width: "320px" }}
            options={taskStatus.map((status) => ({
              label: status.name,
              value: status.id,
            }))}
          />
        )}
        {selectedFilterKey === "provider" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={providerFilterValue}
            placeholder="Select Provider"
            onChange={(value) => setProviderFilterValue(value)}
            style={{ width: "320px" }}
            options={providers.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              value: provider.id,
            }))}
          />
        )}
        {selectedFilterKey === "assignedTo" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={assignedToFilterValue}
            placeholder="Select Assigned To"
            onChange={(value) => setAssignedToFilterValue(value)}
            style={{ width: "320px" }}
            options={assignedTo.map((assign) => ({
              label: `${assign.firstName} ${assign.lastName}`,
              value: assign.id,
            }))}
          />
        )}
      </Space>
      <div>
        {facilityFilterValue && (
          <Tag closable onClose={() => setFacilityFilterValue(undefined)}>
            Facility:{" "}
            {
              facilities.find((facility) => facility.id === facilityFilterValue)
                ?.name
            }
          </Tag>
        )}
        {taskTypeFilterValue && (
          <Tag closable onClose={() => setTaskTypeFilterValue(undefined)}>
            Task Type:{" "}
            {taskTypes.find((type) => type.id === taskTypeFilterValue)?.name}
          </Tag>
        )}
        {workflowFilterValue && (
          <Tag closable onClose={() => setWorkflowFilterValue(undefined)}>
            Workflow:{" "}
            {
              workflows.find((workflow) => workflow.id === workflowFilterValue)
                ?.name
            }
          </Tag>
        )}
        {taskStatusFilterValue && (
          <Tag closable onClose={() => setTaskStatusFilterValue(undefined)}>
            Status:{" "}
            {
              taskStatus.find((status) => status.id === taskStatusFilterValue)
                ?.name
            }
          </Tag>
        )}
        {providerFilterValue && (
          <Tag closable onClose={() => setProviderFilterValue(undefined)}>
            Provider:{" "}
            {
              providers.find((provider) => provider.id === providerFilterValue)
                ?.firstName
            }
          </Tag>
        )}
        {assignedToFilterValue && (
          <Tag closable onClose={() => setAssignedToFilterValue(undefined)}>
            Assigned To:{" "}
            {
              assignedTo.find((assign) => assign.id === assignedToFilterValue)
                ?.firstName
            }
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredTasks,
    UseQueryHookResult: {
      refetch,
      isLoading: tasksLoading,
      isFetching,
    },
  };
}
