import React from "react";
import { Col, Row } from "antd";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";

const StepTwo: React.FC = () => {
  return (
    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
      <Col span={12}>
        <RHFDatePicker
          formItemProps={{
            label: "Start Date",
          }}
          controllerProps={{
            name: "startDate",
          }}
          datePickerProps={{
            style: { width: "100%" },
            placeholder: "Select Start Date",
          }}
        />
      </Col>
      <Col span={12}>
        <RHFDatePicker
          formItemProps={{
            label: "Due Date",
          }}
          controllerProps={{
            name: "dueDate",
          }}
          datePickerProps={{
            style: { width: "100%" },
            placeholder: "Select Due Date",
          }}
        />
      </Col>
    </Row>
  );
};

export default StepTwo;
