import { Col, Divider, Flex, Form, Row, Space, Typography } from "antd";
import { useContext, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import IncredableButton from "../../../components/button";
import IncredableContent from "../../../components/incredable-content";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import { PayerEnrollmentStatus } from "../../../redux/api/payerenrollment/types";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useUpdateEnrollmentStatusMutation,
} from "../../../redux/api/payerenrollment/payerenrollment";

type FormInputType = PayerEnrollmentStatus;

export default function EditEnrollmentStatus() {
  const navigate = useNavigate();
  const { enrollmentStatusId } = useParams();
  const methods = useForm<FormInputType>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [updateEnrollmentStatus] = useUpdateEnrollmentStatusMutation();
  const { data: enrollmentStatuses, isLoading } =
    useGetAllPayerEnrollmentStatusQuery();
  const enrollmentStatus = enrollmentStatuses?.find(
    (status) => status?.id == enrollmentStatusId
  );

  useEffect(() => {
    if (enrollmentStatus) {
      methods.setValue("name", enrollmentStatus?.name ?? "");
    }
  }, [enrollmentStatus]);

  return (
    <IncredableContent
      spinning={isLoading}
      breadcrumbProps={{
        items: [
          {
            title: (
              <NavLink to="/payerenrollmentstatus">Enrollment Status</NavLink>
            ),
          },
          {
            title: "Edit",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="editenrollmentstatus"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();
            if (!!enrollmentStatusId)
              updateEnrollmentStatus({ ...data, id: enrollmentStatusId });
            toastContext?.openInfoNotification({
              message: `Payer Enrollment Status Edited successfully`,
              placement: "topRight",
            });
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Enrollment Status
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="enrollmentstatus" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
