import queryString from "query-string";
import { api } from "..";
import { AllDocumentResponse } from "../document/types";
import { PaginationDocumentsReport, PaginationExpiredDocuments, PaginationPayerEnrollmentReport, PaginationProviderAffiliationReport, PaginationProviderReport, ProviderDocuments } from "./types";

const reportingAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDocuments: build.query<
      PaginationDocumentsReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/documents?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getPayerEnrollmentsReport: build.query<
      PaginationPayerEnrollmentReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/payerenrollment?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getProviderAffiliationReport: build.query<
      PaginationProviderAffiliationReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/providersaffiliations?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getAllExpiredDocuments: build.query<
      PaginationExpiredDocuments,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/documents/expired?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
    getAllExpiringDocuments: build.query<
      PaginationExpiredDocuments,
      {
        days?: string;
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        days,
        pageNumber,
        pageSize,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            days,
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/documents/expiring?${requestParams}`
      },
      providesTags: ["Reporting"],
    }),
    getAllProvidersReport: build.query<
      PaginationProviderReport,
      {
        pageNumber: number;
        pageSize: number;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `reporting/providers/all?${requestParams}`;
      },
      providesTags: ["Reporting"],
    }),
  }),
});
export const {
  useLazyGetProviderAffiliationReportQuery,
  useLazyGetPayerEnrollmentsReportQuery,
  useGetPayerEnrollmentsReportQuery,
  useGetProviderAffiliationReportQuery,
  useGetAllDocumentsQuery,
  useLazyGetAllDocumentsQuery,
  useLazyGetAllExpiredDocumentsQuery,
  useGetAllExpiredDocumentsQuery,
  useLazyGetAllExpiringDocumentsQuery,
  useGetAllExpiringDocumentsQuery,
  useLazyGetAllProvidersReportQuery,
  useGetAllProvidersReportQuery,
} = reportingAPI;
