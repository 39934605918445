import { useCollection } from "@cloudscape-design/collection-hooks";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    SpaceBetween,
    Table,
    TableProps,
} from "@cloudscape-design/components";
import { Payers } from "../../../redux/api/payers/types";
import usePayerFilter from "../../../table-filters/payer-filters";
import { useDeleteAssociatedPayerMutation } from "../../../redux/api/payergroup/payergroup";
import { Button } from "antd";
import { Facility } from "../../../redux/api/facility/types";
import useFacilityFilter from "../../../table-filters/facility-filters";
import { useRemoveFacilitiesFromFacilityGroupMutation } from "../../../redux/api/facilitygroup/facilityGroup";
import AssociatedFacilityModals from "./associated-facility-modal";

export default function AssoicatedFacilityList({
    associatedFacilities,
    setAssociatedFacilities,
}: {
    associatedFacilities: Facility[];
    setAssociatedFacilities: Dispatch<SetStateAction<Facility[]>>;
}) {
    const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);
    const [isAssociatedFacilitiesModalOpen, setAssociatedFacilitiesModalOpen] =
        useState<boolean>(false);
    const { facilitygroupId } = useParams();

    const { TableFilters, filteredFacilities, UseQueryHookResult } = useFacilityFilter(associatedFacilities);
    const [deleteAssociatedFacility] = useRemoveFacilitiesFromFacilityGroupMutation();
    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences<any>
    >({
        pageSize: 10,
        wrapLines: true,
    });

    const { items, collectionProps, paginationProps } = useCollection(
        filteredFacilities,
        {
            pagination: {},
        }
    );

    const tableProps: TableProps<Facility> = useMemo(() => {
        return {
            header: (
                <Header
                    counter={`(${filteredFacilities?.length})`}
                    actions={
                        <SpaceBetween size="l" direction="horizontal">
                            <Button
                                disabled={!selectedFacilities?.at(0)?.id}
                                onClick={async () => {
                                    try {
                                        if (!facilitygroupId) return;
                                        const facilityGroup =
                                            selectedFacilities?.map(
                                                (selectedFacilities) => selectedFacilities?.id + "",
                                            ) || [];

                                        await deleteAssociatedFacility({
                                            facilityGroupId: facilitygroupId,
                                            facilityId: facilityGroup,
                                        });

                                        const temp = associatedFacilities.filter(
                                            (facility) => !facilityGroup.includes(facility?.id + ""),
                                        );
                                        setAssociatedFacilities(temp);
                                        setSelectedFacilities([]);
                                    } catch (error) {
                                        console.error("Error deleting facility:", error);
                                    }
                                }}
                            >
                                Remove
                            </Button>

                            <Button
                                type="primary"
                                onClick={() => {
                                    setAssociatedFacilitiesModalOpen(true);
                                }}
                            >
                                Associate Facility
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Associated Facility
                </Header>
            ),
            items,
            ...collectionProps,
            preferences: (
                <CollectionPreferences
                    onConfirm={({ detail }) => {
                        if (!!detail) setPreferences(detail);
                    }}
                    preferences={preferences}
                    pageSizePreference={{
                        options: [
                            { value: 10, label: "10 items" },
                            { value: 30, label: "30 items" },
                            { value: 50, label: "50 items" },
                        ],
                    }}
                />
            ),
            filter: TableFilters,
            variant: "container",
            loading: UseQueryHookResult.isLoading || UseQueryHookResult.isFetching,
            loadingText: "Loading...",
            selectionType: "multi",
            resizableColumns: true,
            stickyHeader: true,
            onSelectionChange: (selectionChangeDetail) => {
                setSelectedFacilities(selectionChangeDetail.detail.selectedItems);
            },
            onRowClick: ({ detail }) => {
                const temp = selectedFacilities;
                if (!detail.item) return;
                if (temp?.includes(detail.item))
                    setSelectedFacilities(temp.filter((item) => item !== detail.item));
                else setSelectedFacilities([detail.item, ...temp]);
            },
            selectedItems: selectedFacilities,
            trackBy: (item) => "" + item.id,
            columnDisplay: [
                { id: "id", visible: true },
                { id: "name", visible: true },
                { id: "description", visible: true },
                { id: "npiNumber", visible: true },
                { id: "facilityType", visible: true },
                { id: "taxId", visible: true },
                { id: "cliaId", visible: true },
                { id: "immunizationId", visible: true },
                { id: "taxonomyCode", visible: true },
                { id: "addressLane1", visible: true },
                { id: "addressLane2", visible: true },
                { id: "city", visible: true },
                { id: "state", visible: true },
                { id: "zipcode", visible: true },
                { id: "country", visible: true },
            ],
            columnDefinitions: [
                {
                    id: "id",
                    header: <div>ID</div>,
                    cell: (item) => <span>{item.id}</span>,
                    visibility: false,
                },
                {
                    id: "name",
                    header: <div>Name</div>,
                    cell: (item) => <span>{item?.name}</span>,
                    sortingField: "name",
                },
                {
                    id: "description",
                    header: (
                        <div>Description</div>
                    ),
                    cell: (item) => <div>{item?.description ?? "-"}</div>,
                },
                {
                    id: "npiNumber",
                    header: <div>NPI Number</div>,
                    cell: (item) => <div>{item?.npiNumber ?? "-"}</div>,
                },
                {
                    id: "facilityType",
                    header: (
                        <div>Facility Type</div>
                    ),
                    cell: (item) => <div>{item?.facilityType?.name ?? "-"}</div>,
                },
                {
                    id: "taxId",
                    header: <div>Tax ID</div>,
                    cell: (item) => <div>{item?.taxId ?? "-"}</div>,
                },
                {
                    id: "cliaId",
                    header: <div>CLIA ID</div>,
                    cell: (item) => <div>{item?.cliaId ?? "-"}</div>,
                },
                {
                    id: "immunizationId",
                    header: (
                        <div>Immunization ID</div>
                    ),
                    cell: (item) => <div>{item?.immunizationId ?? "-"}</div>,
                },
                {
                    id: "taxonomyCode",
                    header: (
                        <div>Taxonomy Code</div>
                    ),
                    cell: (item) => <div>{item?.taxonomyCode ?? "-"}</div>,
                },
                {
                    id: "addressLane1",
                    header: (
                        <div>Address Lane 1</div>
                    ),
                    cell: (item) => (
                        <div>{item?.facilityAddress?.addressLane1 ?? "-"}</div>
                    ),
                },
                {
                    id: "addressLane2",
                    header: (
                        <div>Address Lane 2</div>
                    ),
                    cell: (item) => (
                        <div>{item?.facilityAddress?.addressLane2 ?? "-"}</div>
                    ),
                },
                {
                    id: "city",
                    header: <div>City</div>,
                    cell: (item) => <div>{item?.facilityAddress?.city ?? "-"}</div>,
                },
                {
                    id: "state",
                    header: <div>State</div>,
                    cell: (item) => <div>{item?.facilityAddress?.state ?? "-"}</div>,
                },
                {
                    id: "zipcode",
                    header: <div>Zipcode</div>,
                    cell: (item) => <div>{item?.facilityAddress?.zipcode ?? "-"}</div>,
                },
                {
                    id: "country",
                    header: <div>Country</div>,
                    cell: (item) => <div>{item?.facilityAddress?.country ?? "-"}</div>,
                },
            ],
        };
    }, [items, selectedFacilities]);

    return (
        <div>
            <Table {...tableProps} />
            <AssociatedFacilityModals
                associatedFacilities={associatedFacilities}
                isAssociatedFacilitiesModalOpen={isAssociatedFacilitiesModalOpen}
                setAssociatedFacilities={setAssociatedFacilities}
                setAssociatedFacilitiesModalOpen={setAssociatedFacilitiesModalOpen}
            />
        </div>
    );
}
