import { useEffect, useState } from "react";
import { DIGITALFORM_FIELDS_Type } from "./digitalform-tags-list";
import {
  PROVIDER_DIGITALFORM_FIELDS,
  STANDARD_DIGITALFORM_FIELDS,
} from "./constants";

export type ReadyFormTagsFilterType = {
  filterText: string;
  section_type: string;
};

export default function useReadyFormTagsFilter() {
  const [filter, setFilter] = useState<ReadyFormTagsFilterType>({
    filterText: "",
    section_type: "all",
  });

  const [filteredFields, setFilteredFields] = useState<
    DIGITALFORM_FIELDS_Type[]
  >([]);

  useEffect(() => {
    let temp_filtered_fields: DIGITALFORM_FIELDS_Type[] = [
      ...STANDARD_DIGITALFORM_FIELDS,
      ...PROVIDER_DIGITALFORM_FIELDS,
    ];

    if (filter.section_type === "provider") {
      temp_filtered_fields = [...PROVIDER_DIGITALFORM_FIELDS];
    }
    if (filter.section_type === "standard") {
      temp_filtered_fields = [...STANDARD_DIGITALFORM_FIELDS];
    }
    if (!!filter.filterText) {
      temp_filtered_fields = temp_filtered_fields
        .map((feild) => {
          return {
            ...feild,
            properties: feild?.properties?.filter(
              (property) =>
                property?.label
                  ?.toLowerCase()
                  ?.includes(filter.filterText?.toLowerCase()) ||
                property?.key
                  ?.toLowerCase()
                  ?.includes(filter.filterText?.toLowerCase())
            ),
          };
        })
        ?.filter((field) => field?.properties?.length > 0);
    }

    setFilteredFields(temp_filtered_fields);
  }, [filter]);

  return { filteredFields, filter, setFilter };
}
