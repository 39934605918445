import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import { ProviderRequest } from "../../redux/api/provider/types";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllLicenseTypeQuery } from "../../redux/api/licensetype/licensetype";
import { PlusOutlined } from "@ant-design/icons";
import { useAddProviderMutation } from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { NavLink, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ToastContext } from "../../context/toast/incredable-toast";
import { SpinnerContext } from "../../context/spinner/spinner";
import IncredableContent from "../../components/incredable-content";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { Facility } from "../../redux/api/facility/types";

const { useToken } = theme;

type FormInputType = { providers: ProviderRequest[] };

export default function AddProvider() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: licenseTypes = [] } = useGetAllLicenseTypeQuery();
  const { data: facilityGroups = [] } = useGetAllFacilityGroupQuery();
  const [addProvider] = useAddProviderMutation();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [filteredFacilitiesMap, setFilteredFacilitiesMap] = useState<
    Record<number, Facility[]>
  >({});

  const { watch } = methods;

  const handleFacilityGroupChange = (
    formIndex: number,
    selectedFacilityGroupId: string
  ) => {
    if (selectedFacilityGroupId) {
      const selectedFacilityGroup = facilityGroups.find(
        (fg) => fg.id + "" === selectedFacilityGroupId + ""
      );
      setFilteredFacilitiesMap((prev) => ({
        ...prev,
        [formIndex]: selectedFacilityGroup?.facilities ?? [],
      }));
    } else {
      setFilteredFacilitiesMap((prev) => ({
        ...prev,
        [formIndex]: facilities,
      }));
    }
  };

  useEffect(() => {
    const currentProviders = methods.getValues("providers") || [];

    currentProviders.forEach((provider, index) => {
      handleFacilityGroupChange(index, provider?.facilityGroupId || "");
    });

    const subscription = watch((values) => {
      values?.providers?.forEach((provider: any, index: number) =>
        handleFacilityGroupChange(index, provider?.facilityGroupId)
      );
    });

    // Cleanup subscription on unmount
    return () => subscription.unsubscribe();
  }, [watch, facilities]);

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          { title: <NavLink to={"/manageprovider"}>Providers</NavLink> },
          { title: "Add" },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addprovider"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();

            try {
              await Promise.all(
                data.providers.map((provider) => addProvider(provider).unwrap())
              );
              toastContext?.openSuccessNotification({
                message: "Provider saved successfully",
                placement: "topRight",
              });
              navigate(-1);
            } catch (error) {
              toastContext?.openErrorNotification({
                message: "Failed to save provider",
                placement: "topRight",
              });
            } finally {
              spinnerContext?.hidespinner();
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Add New Provider
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="providers" initialValue={[{}]}>
            {(fields, { add }) => (
              <>
                {fields.map(({ key, name, ...restField }, index) => (
                  <div key={key}>
                    {index > 0 && <Divider />}
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={8}>
                        <RHFTextField
                          formItemProps={{ label: "First Name" }}
                          inputProps={{}}
                          controllerProps={{
                            name: `providers[${index}].firstName`,
                          }}
                          rules={{ required: "This field is required" }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFTextField
                          formItemProps={{ label: "Last Name" }}
                          inputProps={{}}
                          controllerProps={{
                            name: `providers[${name}].lastName`,
                          }}
                          rules={{ required: "This field is required" }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFTextField
                          formItemProps={{ label: "Email" }}
                          inputProps={{}}
                          controllerProps={{
                            name: `providers[${name}].email`,
                          }}
                          rules={{ required: "This field is required" }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFTextField
                          formItemProps={{ label: "NPI Number" }}
                          inputProps={{}}
                          controllerProps={{
                            name: `providers[${name}].npiNumber`,
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Facility" }}
                          controllerProps={{
                            name: `providers[${name}].facilityId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Search to Select",
                            options: filteredFacilitiesMap[index]?.map(
                              (facility) => ({
                                label: facility?.name,
                                value: facility?.id,
                              })
                            ),
                          }}
                          rules={{ required: "This field is required" }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "Facility Group" }}
                          controllerProps={{
                            name: `providers[${name}].facilityGroupId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Search to Select",
                            options: facilityGroups.map((group) => ({
                              label: group?.name,
                              value: group?.id,
                            })),
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <RHFSelect
                          formItemProps={{ label: "License Type" }}
                          controllerProps={{
                            name: `providers[${name}].licenseTypeId`,
                          }}
                          selectProps={{
                            allowClear: true,
                            showSearch: true,
                            style: { width: "100%" },
                            placeholder: "Search to Select",
                            options: licenseTypes.map((licensetype) => ({
                              label: licensetype?.name,
                              value: licensetype?.id,
                            })),
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                ))}
                <Divider />
                <Form.Item>
                  <IncredableButton
                    onClick={() => {
                      const newIndex = fields.length;
                      add();
                      handleFacilityGroupChange(newIndex, "");
                    }}
                    style={{
                      color: token.colorPrimary,
                      borderColor: token.colorPrimary,
                    }}
                    type="dashed"
                    block
                    icon={<PlusOutlined />}
                  >
                    Add More
                  </IncredableButton>
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}