export enum UIActionTypes {
  SET_MODAL_NAME = "ui/set-modal-name",
  ADD_FLASHBAR_ITEM = "ui/add-flashbar-item",
  REMOVE_FLASHBAR_ITEM = "ui/remove-flashbar-item",
  ADD_SPINNER = "ui/add-loading-spinner",
  REMOVE_SPINNER = "ui/remove-loading-spinner",
  SET_ROLE_PERMISSIONS = "ui/add-permissions",
}
export interface UIState {
  modalName: string;
  is_loading: boolean;
  permissions: string[];
  is_owner: boolean;
}

export type NotificationProps = {
  header?: string | React.ReactNode;
  name: string;
  action?: string;
  content?: string | React.ReactNode;
  id: string;
};
