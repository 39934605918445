import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  CREDENTIALTEMPLATE_CONTENT_DISPLAY_OPTIONS,
  CREDENTIALTEMPLATE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import useCredentialPackageFilter from "../table-filters/credentialpackages-filters";
import { CredentialPackage } from "../redux/api/credentialpackages/types";
import IncredableDateFormat from "../common/incredable-date-format";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { useDeleteCredentialPackageMutation } from "../redux/api/credentialpackages/credentialpackages";

export default function CredentialPackagesList() {
  const [selectedRows, setSelectedRows] = useState<CredentialPackage[]>();

  const [deletePackage] = useDeleteCredentialPackageMutation();

  const {
    TableFilters,
    filteredCredentialPackage = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useCredentialPackageFilter();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Credential-Packages-Table-Preferences",
    CREDENTIALTEMPLATE_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredCredentialPackage,
    {
      pagination: { pageSize: tablePreferences.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>Credential Packages ID</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => (
              item?.name ? <NavLink to={`${item.id}`}>{item.name}</NavLink> : "-"
            ),
            sortingField: "name",
            sortingComparator: (a, b) =>
              (a?.name ?? "").localeCompare(
                b?.name ?? ""
              ),
          },
          {
            id: "packageStatus",
            header: <div>Package Status</div>,
            cell: (item) => <span>{item?.packageStatus?.name ?? "-"}</span>,
            sortingField: "packageStatus.name",
            sortingComparator: (a, b) =>
              (a?.packageStatus?.name ?? "").localeCompare(
                b?.packageStatus?.name ?? ""
              ),
          },
          {
            id: "credentialTemplate",
            header: <div>Credential Template</div>,
            cell: (item) => (
              <span>{item?.credentialTemplate?.name ?? "-"}</span>
            ),
            sortingField: "credentialTemplate.name",
            sortingComparator: (a, b) =>
              (a?.credentialTemplate?.name ?? "").localeCompare(
                b?.credentialTemplate?.name ?? ""
              ),
          },
          {
            id: "packageType",
            header: <div>Package Type</div>,
            cell: (item) => <span>{item?.packageType?.name ?? "-"}</span>,
            sortingField: "packageType.name",
            sortingComparator: (a, b) =>
              (a?.packageType?.name ?? "").localeCompare(
                b?.packageType?.name ?? ""
              ),
          },
          {
            id: "provider",
            header: <div>Provider</div>,
            cell: (item) => (
              <span>
                {!!item?.provider
                  ? `${item?.provider?.lastName} ${item?.provider?.firstName}`
                  : "-"}
              </span>
            ),
            sortingField: "provider.lastName",
            sortingComparator: (a, b) =>
              (a?.provider?.lastName ?? "").localeCompare(
                b?.provider?.lastName ?? ""
              ),
          },
          {
            id: "recredentialingDate",
            header: <div>Recredentialing Date</div>,
            cell: (item) => (
              item?.recredentialingDate ?
                <span> <CalendarOutlined />{" "}{IncredableDateFormat(item?.recredentialingDate)}</span>
                : "-"
            ),
            sortingField: "recredentialingDate",
          },
          {
            id: "submittedDate",
            header: <div>Submitted Date</div>,
            cell: (item) => (item?.submittedDate ?
              <span><CalendarOutlined />{" "}{IncredableDateFormat(item?.submittedDate)}</span>
              : "-"
            ),
            sortingField: "submittedDate",
          },
          {
            id: "alertDays",
            header: <div>Alert Days</div>,
            cell: (item) => (
              <span>
                {" "}
                {item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}
              </span>
            ),
            sortingField: "alertDays",
          },
          {
            id: "description",
            header: <div>Description</div>,
            cell: (item) => <span>{item?.description ?? "-"}</span>,
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={CREDENTIALTEMPLATE_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`${selectedRows?.at(0)?.id}`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => {
                            handleOpenDeleteModal();
                          },
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Start Package
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Credential Packages{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${filteredCredentialPackage.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deletePackage({
              credentialPackageId: String(selectedRows?.at(0)?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `credential package deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Credential Package"
        content={
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Package Name</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRows?.at(0)?.name}
              </Typography.Text>
            </div>
          </>
        }
        description="Are you sure you want to delete this package?"
      />
    </div>
  );
}
