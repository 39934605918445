import { useState, ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

// hooks
import useAuth from "../hooks/useAuth";
// pages
// components
import { PATH_AUTH } from "../routes/path";
import { InvalidPathRoute } from "../routes";
import { Login } from "../pages";

// ----------------------------------------------------------------------

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized } = useAuth();
  const { pathname } = useLocation();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(
    null
  );

  if (!isInitialized) {
    return <></>; // show spinner
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation === PATH_AUTH.login) {
    setRequestedLocation(null);
    return <InvalidPathRoute />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
