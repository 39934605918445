import { useState } from "react";
import { Card, Layout, Space, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import IncredableButton from "../components/button";
import ActiveList from "./active-list";
import InvitedList from "./invited-list";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";

export default function ManageAdmin() {
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState<string>("active");
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false)
  );
  const tabList = [
    {
      key: "active",
      label: <span style={{ color: "rgb(88 82 82)" }}>Active</span>,
    },
    {
      key: "invited",
      label: <span style={{ color: "rgb(88 82 82)" }}>Invited</span>,
    },
  ];
  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Card
          title={
            <Typography.Title
              style={{ paddingTop: "0", marginTop: "0" }}
              level={3}
            >
              {`Members`}
            </Typography.Title>
          }
          extra={
            <Space>
              <IncredableButton
                disabled={!hasPermission}
                type="primary"
                onClick={() => navigate("add")}
              >
                Invite Members
              </IncredableButton>
            </Space>
          }
          style={{ width: "100%" }}
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={onTabChange}
          tabProps={{
            size: "middle",
          }}
        >
          {activeTabKey === "active" ? <ActiveList /> : <InvitedList />}
        </Card>
      </Space>
    </Layout>
  );
}
