import { NotificationProps, UIActionTypes } from "./types";

export const setModalName = (name: string) => ({
  type: UIActionTypes.SET_MODAL_NAME,
  payload: name,
});

export const removeLoadingSpinner = () => ({
  type: UIActionTypes.REMOVE_SPINNER,
});

export const addLoadingSpinner = () => ({
  type: UIActionTypes.ADD_SPINNER,
});

export const notifySucess = ({ name, action, id }: NotificationProps) => {
  const content = `Successfully ${action ?? "Updated"} ${name}.`;
};

export const notifyFailed = ({ name, action, id }: NotificationProps) => {};

export const notifyInProgress = ({ name, action, id }: NotificationProps) => {
  const content = `${action ?? "Updating"} ${name}...`;
};
