import { Box, Button, Header, LineChart } from "@cloudscape-design/components";
import { WidgetConfig } from "../types";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";
import { useMemo } from "react";
import { max } from "lodash";
import IncredableDateFormat from "../../../common/incredable-date-format";

export const documentsSnapShot: WidgetConfig = {
  title: "Document Snapshot",
  description:
    "This bar graph illustrates the number of expiring documents across different months. The Y-axis represents the count of expiring documents, while the X-axis displays the months. Gain valuable insights into the document expiry patterns, helping you proactively manage renewals and compliance",
  header: MonthlyExpiringDocumentsHeader,
  content: DocumentsSnapShot,
};

function MonthlyExpiringDocumentsHeader() {
  return <Header variant="h2">{documentsSnapShot.title}</Header>;
}

function DocumentsSnapShot() {
  const { data: dashboardData, fulfilledTimeStamp } = useGetAllDashboardQuery();

  const maximumValue = useMemo(() => {
    const yValues = [
      ...(dashboardData?.documentSnapshot?.expiring ?? []),
      ...(dashboardData?.documentSnapshot?.expired ?? []),
    ];
    const maximum = max(yValues.map((item) => item.value)) ?? 10;
    return Math.ceil(maximum / 5) * 5;
  }, [fulfilledTimeStamp]);

  return (
    <LineChart
      height={200}
      series={[
        {
          title: "Expiring Documents",
          type: "line",
          data:
            dashboardData?.documentSnapshot?.expiring?.map((weeklyStat) => ({
              x:
                IncredableDateFormat("", {
                  date: weeklyStat.startDateOfWeek,
                }) ?? "",
              y: weeklyStat.value,
            })) ?? [],
        },
        {
          title: "Expired Documents",
          type: "line",
          data:
            dashboardData?.documentSnapshot?.expired?.map((weeklyStat) => ({
              x:
                IncredableDateFormat("", {
                  date: weeklyStat.startDateOfWeek,
                }) ?? "",
              y: weeklyStat.value,
            })) ?? [],
        },
      ]}
      xDomain={
        dashboardData?.documentSnapshot?.expiring?.map(
          (weeklyStat) =>
            IncredableDateFormat("", {
              date: weeklyStat.startDateOfWeek,
            }) ?? ""
        ) ?? []
      }
      yDomain={[0, maximumValue]}
      ariaLabel="Multiple data series line chart"
      xScaleType="categorical"
      xTitle="Week"
      yTitle="Count"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No data available</b>
          <Box variant="p" color="inherit">
            There is no data available
          </Box>
        </Box>
      }
      noMatch={
        <Box textAlign="center" color="inherit">
          <b>No matching data</b>
          <Box variant="p" color="inherit">
            There is no matching data to display
          </Box>
          <Button>Clear filter</Button>
        </Box>
      }
    />
  );
}
