import { Card, Col, Row, Typography } from "antd";
import useSafeFilter from "../table-filters/safe-filters";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export default function SafeStats() {
  let [searchParams] = useSearchParams({ providerId: "" });

  const selectedProviderId = useMemo(() => {
    return searchParams.get("providerId") ?? "";
  }, [searchParams]);

  const { documentStats } = useSafeFilter({
    selectedProviderId: selectedProviderId ? selectedProviderId : null,
  });
  return (
    <Row gutter={16} style={{ paddingTop: "12px" }}>
      <Col span={8}>
        <Card bordered={true}>
          <Typography.Title
            level={5}
            style={{ paddingTop: "0px", marginTop: "0px" }}
          >
            Total Documents:{" "}
            <span
              style={{
                color: "#3f8600",
                fontWeight: "400",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {documentStats?.totalDocument || 0}
            </span>{" "}
          </Typography.Title>
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={true}>
          <Typography.Title
            level={5}
            style={{ paddingTop: "0px", marginTop: "0px" }}
          >
            Expiring Documents:{" "}
            <span
              style={{
                color: "#FFBF00",
                fontWeight: "400",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {documentStats?.expiringDocument || 0}
            </span>
          </Typography.Title>
        </Card>
      </Col>
      <Col span={8}>
        <Card bordered={true}>
          <Typography.Title
            level={5}
            style={{ paddingTop: "0px", marginTop: "0px" }}
          >
            Expired Documents:{" "}
            <span
              style={{
                color: "#cf1322",
                fontWeight: "400",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              {documentStats?.expiredDocument || 0}
            </span>
          </Typography.Title>
        </Card>
      </Col>
    </Row>
  );
}
