import { useParams } from "react-router-dom";
import { useGetAllDigitalFormsQuery } from "../../../redux/api/digitalform/digitalforms";
import { useUpdateCredentialTemplateFormsMutation } from "../../../redux/api/credentialpackages/credentialpackages";
import { useState } from "react";
import { DigitalForm } from "../../../redux/api/digitalform/types";
import { Button, Col, ConfigProvider, Row } from "antd";
import { Table, Modal } from "@cloudscape-design/components";

export default function AddFormModal({
  visible,
  closeModal,
}: {
  visible: boolean;
  closeModal: VoidFunction;
}) {
  const {
    data: digitalForms = [],
    isLoading,
    isFetching,
  } = useGetAllDigitalFormsQuery();
  const { templateId } = useParams();

  const [updateCredentialTemplateForms] =
    useUpdateCredentialTemplateFormsMutation();

  const [selectedRows, setSelectedRows] = useState<DigitalForm[]>([]);

  return (
    <Modal
      header={"Add Forms"}
      onDismiss={closeModal}
      visible={visible}
      size="medium"
      footer={
        <Row justify="end" gutter={16}>
          <Col>
            <Button onClick={closeModal}>Cancel</Button>
          </Col>
          <Col>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#2B6BE6",
                },
              }}
            >
              <Button
                type="primary"
                onClick={async () => {
                  if (templateId) {
                    const formIds = selectedRows.map((form) => form.id);
                    await updateCredentialTemplateForms({
                      id: templateId,
                      forms: formIds,
                    });
                  }
                  closeModal();
                }}
              >
                Save
              </Button>
            </ConfigProvider>
          </Col>
        </Row>
      }
    >
      <Table
        loading={isLoading || isFetching}
        onSelectionChange={({ detail }) => {
          setSelectedRows([...detail.selectedItems]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            const temp = selectedRows ? [...selectedRows] : [];
            const selectedItemIndex = temp.findIndex(
              (record) => record?.id + "" === "" + selectedItem?.id
            );
            if (selectedItemIndex !== -1) {
              temp?.splice(selectedItemIndex, 1);
              setSelectedRows(temp);
            } else {
              setSelectedRows([...temp, selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => <span>{item?.name}</span>,
          },
          {
            id: "type",
            header: <div>Type</div>,
            cell: (item) => <span>{item?.digitalFormType?.type}</span>,
          },
        ]}
        items={!!digitalForms ? [...digitalForms] : []}
        loadingText="Loading resources"
        selectionType="multi"
        trackBy="id"
      />
    </Modal>
  );
}
