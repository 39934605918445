import { Anchor, Col, Row } from "antd";
import Exclusions, { NoExlusions, Overview, ProviderDocumentSummary } from "./providerDetails/overview";
import PersonalInfo from "./providerDetails/personalInfo";
import ProfessionalInfo from "./providerDetails/professionalInfo";
import IncredableContent from "../components/incredable-content";
import { SpaceBetween } from "@cloudscape-design/components";
import AffiliationsV2 from "./providerDetails/affiliations-v2";
import DEAV2 from "./providerDetails/dea-v2";
import EducationV2 from "./providerDetails/education-v2";
import LiabilityInsuranceV2 from "./providerDetails/liabilityInsurance-v2";
import LicenseV2 from "./providerDetails/license-v2";
import PeerReferenceV2 from "./providerDetails/peerreference-v2";
import SpecialtyInfoV2 from "./providerDetails/specialtyInfo-v2";
import ThirdPartyLoginV2 from "./providerDetails/thirdpartylogin-v2";
import WorkHistoryV2 from "./providerDetails/workhistory-v2";
import EnrolledPayerV2 from "./providerDetails/enrolledpayer-v2";
import SafeDocumentsList from "../safe/safe-documents-list";
import { useParams } from "react-router-dom";
import { useGetProviderQuery } from "../redux/api/provider/provider";
import { skipToken } from "@reduxjs/toolkit/query";

function AnchorLabel({ children }: { children: React.ReactNode }) {
  return (
    <span
      style={{ fontSize: "14px", fontWeight: "500", letterSpacing: ".25px" }}
    >
      {children}
    </span>
  );
}
export default function ProviderDetails({ active }: { active: string }) {
  const { providerId } = useParams();
  const {
    data: provider,
    isFetching,
    isLoading,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  return (
    <IncredableContent>
      <Row gutter={12}>
        <Col span={19}>
          <SpaceBetween size="m">
            <div id="overview">
              <Overview />
            </div>
            <div id="documentsummary">
              <ProviderDocumentSummary />
            </div>
            <div id="exclusions">
              {provider && provider?.npiNumber ? (
                <Exclusions
                  key={provider?.npiNumber}
                  npi_number={provider?.npiNumber}
                />
              ) : (
                <NoExlusions />
              )} </div>
            <div id="personalinfo">
              <PersonalInfo />
            </div>
            <div id="professionalinfo">
              <ProfessionalInfo />
            </div>
            <div id="affiliation">
              <AffiliationsV2 />
            </div>
            <div id="dea">
              <DEAV2 />
            </div>
            <div id="education">
              <EducationV2 />
            </div>
            <div id="liabilityinsurance">
              <LiabilityInsuranceV2 />
            </div>
            <div id="license">
              <LicenseV2 />
            </div>
            <div id="peerreference">
              <PeerReferenceV2 />
            </div>
            <div id="specialtyinfo">
              <SpecialtyInfoV2 />
            </div>
            <div id="thirdpartylogin">
              <ThirdPartyLoginV2 />
            </div>
            <div id="workhistory">
              <WorkHistoryV2 />
            </div>
            <div id="enrolledpayer">
              <EnrolledPayerV2 />
            </div>
            <div id="safe">
              <SafeDocumentsList />
            </div>
          </SpaceBetween>
        </Col>
        <Col span={5}>
          <Anchor
            affix={true}
            targetOffset={100}
            offsetTop={100}
            items={[
              {
                key: "overview",
                href: "#overview",
                title: <AnchorLabel>General Information</AnchorLabel>,
              },
              {
                key: "documentsummary",
                href: "#documentsummary",
                title: <AnchorLabel>Document Summary</AnchorLabel>,
              },
              {
                key: "exclusions",
                href: "#exclusions",
                title: <AnchorLabel>Exclusions</AnchorLabel>,
              },
              {
                key: "personalinfo",
                href: "#personalinfo",
                title: <AnchorLabel>Personal Info</AnchorLabel>,
              },
              {
                key: "professionalinfo",
                href: "#professionalinfo",
                title: <AnchorLabel>Professional Info</AnchorLabel>,
              },
              {
                key: "affiliations",
                href: "#affiliation",
                title: <AnchorLabel>Affiliations</AnchorLabel>,
              },
              {
                key: "dea",
                href: "#dea",
                title: <AnchorLabel>DEA</AnchorLabel>,
              },
              {
                key: "education",
                href: "#education",
                title: <AnchorLabel>Education</AnchorLabel>,
              },
              {
                key: "liabilityinsurance",
                href: "#liabilityinsurance",
                title: <AnchorLabel>Liability Insurance</AnchorLabel>,
              },
              {
                key: "license",
                href: "#license",
                title: <AnchorLabel>License</AnchorLabel>,
              },
              {
                key: "peerreference",
                href: "#peerreference",
                title: <AnchorLabel>Peer Reference</AnchorLabel>,
              },
              {
                key: "specialtyinfo",
                href: "#specialtyinfo",
                title: <AnchorLabel>Specialty Info</AnchorLabel>,
              },
              {
                key: "thirdpartylogin",
                href: "#thirdpartylogin",
                title: <AnchorLabel>Third Party Login</AnchorLabel>,
              },
              {
                key: "workhistory",
                href: "#workhistory",
                title: <AnchorLabel>Work History</AnchorLabel>,
              },
              {
                key: "enrolledpayer",
                href: "#enrolledpayer",
                title: <AnchorLabel>Enrolled Payers</AnchorLabel>,
              },
              {
                key: "safe",
                href: "#safe",
                title: <AnchorLabel>Safe</AnchorLabel>,
              },
            ]}
          />
        </Col>
      </Row>
    </IncredableContent>
  );
}
