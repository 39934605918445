import { useContext, useState } from "react";
import { Button, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocalStorage } from "../common/localStorage";
import {
  MANAGEADMINACTIVE_CONTENT_DISPLAY_OPTIONS,
  MANAGEADMINACTIVE_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./active-table-config";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import useActiveMembersFilter from "../table-filters/activemembers-filters";
import { MemberType } from "../redux/api/manageadmin/types";
import { useDeleteAdminMutation } from "../redux/api/manageadmin/manageadmin";
import IncredableDateFormat from "../common/incredable-date-format";

export default function ActiveList() {
  const [selectedRows, setSelectedRows] = useState<MemberType[]>();

  const [deleteMember] = useDeleteAdminMutation();

  const {
    TableFilters,
    filteredMember = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useActiveMembersFilter();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Active-Table-Preferences",
    MANAGEADMINACTIVE_TABLE_DEFAULT_PREFERENCES
  );
  const navigate = useNavigate();

  const { items, collectionProps, paginationProps } = useCollection(
    filteredMember,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <div style={{ padding: "0" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>Member ID</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "firstName",
            header: <div>First Name</div>,
            cell: (item) => (
              <NavLink to={`${item.id}`}>{`${item?.firstName}`}</NavLink>
            ),
          },
          {
            id: "lastName",
            header: <div>Last Name</div>,
            cell: (item) => <span>{`${item?.lastName}`}</span>,
          },
          {
            id: "email",
            header: <div>Email</div>,
            cell: (item) => <span>{`${item?.email}`}</span>,
          },
          {
            id: "role",
            header: <div>Role</div>,
            cell: (item) => <span>{`${item?.role?.name}`}</span>,
          },
          {
            id: "lastlogin",
            header: <div>Last Login</div>,
            cell: (item) => (
              <span>{IncredableDateFormat(item?.lastLogin) ?? "-"}</span>
            ),
            sortingField: "lastlogin",
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={MANAGEADMINACTIVE_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(`${selectedRows?.at(0)?.id}`);
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => {
                            handleOpenDeleteModal();
                          },
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Members{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${filteredMember.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteMember({
              adminId: String(selectedRows?.at(0)?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `member deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Member"
        content={
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Member Name</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRows?.at(0)?.firstName} {selectedRows?.at(0)?.lastName}
              </Typography.Text>
            </div>
          </>
        }
        description="Are you sure you want to delete this member?"
      />
    </div>
  );
}
