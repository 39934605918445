import queryString from "query-string";
import { api } from "..";
import {
  BasicPayer,
  PaginationPayerEnrollment,
  PayerEnrollment,
  PayerEnrollmentRequest,
  PayerEnrollmentStatus,
} from "./types";

const payerEnrollmentAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPayerEnrollments: build.query<
      PaginationPayerEnrollment,
      {
        payerId: string;
        pageNumber: number;
        pageSize: number;
        providerId?: string;
        statusId?: string;
        facilityId?: string;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        payerId,
        pageNumber,
        pageSize,
        providerId,
        statusId,
        facilityId,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            providerId,
            statusId,
            facilityId,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `payer/${payerId}/enrollments?${requestParams}`;
      },
      providesTags: ["PayerEnrollment"],
    }),
    getAllEnrollments: build.query<
      PaginationPayerEnrollment,
      {
        pageNumber: number;
        pageSize: number;
        providerId?: string;
        statusId?: string;
        facilityId?: string;
        sortBy?: string;
        direction?: string;
        payerId?: string;
        payerGroupId?: string;
        facilityGroupId?:string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        providerId,
        statusId,
        facilityId,
        sortBy,
        direction,
        payerGroupId,
        facilityGroupId,
        payerId,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            providerId,
            statusId,
            payerId,
            payerGroupId,
            facilityId,
            sortBy,
            direction,
            facilityGroupId
          },
          { skipEmptyString: true }
        );
        return `enrollments/all?${requestParams}`;
      },
      providesTags: ["PayerEnrollment"],
    }),
    getPayerEnrollment: build.query<
      PayerEnrollment,
      { payerEnrollmentId: string }
    >({
      query: ({ payerEnrollmentId }) =>
        `payer/enrollments/${payerEnrollmentId}`,
      providesTags: (result, error, { payerEnrollmentId }) => [
        { type: "PayerEnrollment", id: payerEnrollmentId },
      ],
    }),
    getAllPayerEnrollmentStatus: build.query<PayerEnrollmentStatus[], void>({
      query: () => `payer/enrollments/status/all`,
      providesTags: ["PayerEnrollment"],
    }),
    createPayerEnrollment: build.mutation<
      PayerEnrollment,
      PayerEnrollmentRequest
    >({
      query: (body) => {
        return {
          url: `payer/${body.payerId}/enroll`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["PayerEnrollment"],
    }),
    updatePayerEnrollment: build.mutation<
      PayerEnrollment,
      Pick<PayerEnrollment, "id"> & Partial<PayerEnrollmentRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `payer/enrollments/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "PayerEnrollment", id: arg.id },
        { type: "PayerEnrollment" },
      ],
    }),
    deletePayerEnrollment: build.mutation<
      void,
      {
        payerEnrollmentId: string;
      }
    >({
      query: ({ payerEnrollmentId }) => ({
        url: `payer/enrollments/${payerEnrollmentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayerEnrollment"],
    }),
    saveAllEnrollmentStatus: build.mutation<void, PayerEnrollmentStatus[]>({
      query: (types) => {
        return {
          url: `payer/enrollments/status/all`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["PayerEnrollment"],
    }),
    deleteEnrollmentStatus: build.mutation<
      void,
      {
        enrollmentStatusId: string;
      }
    >({
      query: ({ enrollmentStatusId }) => ({
        url: `payer/enrollments/status/${enrollmentStatusId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PayerEnrollment"],
    }),
    addEnrollmentStatus: build.mutation<void, PayerEnrollmentStatus>({
      query: ({ ...body }) => {
        return {
          url: "payer/enrollments/status/all",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["PayerEnrollment"],
    }),
    updateEnrollmentStatus: build.mutation<
      PayerEnrollmentStatus,
      Pick<PayerEnrollmentStatus, "id"> & Partial<PayerEnrollmentStatus>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `payer/enrollments/status/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "PayerEnrollment", id: arg.id },
        { type: "PayerEnrollment" },
      ],
    }),
    getBasicAllPayer: build.query<BasicPayer[], void>({
      query: () => `payer/basic/all`,
      providesTags: ["Provider"],
    }),
  }),
});
export const {
  useGetAllEnrollmentsQuery,
  useLazyGetAllEnrollmentsQuery,
  useLazyGetPayerEnrollmentQuery,
  useLazyGetAllPayerEnrollmentsQuery,
  useGetPayerEnrollmentQuery,
  useGetAllPayerEnrollmentStatusQuery,
  useCreatePayerEnrollmentMutation,
  useUpdatePayerEnrollmentMutation,
  useDeletePayerEnrollmentMutation,
  useSaveAllEnrollmentStatusMutation,
  useDeleteEnrollmentStatusMutation,
  useAddEnrollmentStatusMutation,
  useUpdateEnrollmentStatusMutation,
  useGetBasicAllPayerQuery,
} = payerEnrollmentAPI;
