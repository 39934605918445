import { Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import {
  useGetAllProviderEnrollmentsQuery,
  useGetBasicAllProvidersQuery,
} from "../redux/api/provider/provider";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useGetBasicAllPayerQuery,
} from "../redux/api/payerenrollment/payerenrollment";
import { skipToken } from "@reduxjs/toolkit/query";

export default function useEnrolledPayerFilters({
  providerId,
}: {
  providerId: string | undefined;
}) {
  const [selectedFilterkey, setSelectedFilterkey] = useState<string>("payer");

  const [providerFiltervalue, setProviderFiltervalue] = useState<
    string | undefined
  >(undefined);

  const [payerFilterValue, setPayerFilterValue] = useState<string | undefined>(
    undefined
  );

  const [statusFilterValue, setStatusFilterValue] = useState<
    string | undefined
  >(undefined);

  const [facilityFilterValue, setFacilityFilterValue] = useState<
    string | undefined
  >(undefined);

  const { data: facilities = [], isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();
  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: payers = [] } = useGetBasicAllPayerQuery();

  const {
    data: providerEnrollment = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllProviderEnrollmentsQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken
  );

  const filteredproviderEnrollment = useMemo(() => {
    return providerEnrollment?.filter((enrolled) => {
      let matches = true;

      if (payerFilterValue) {
        matches = matches && enrolled?.payer?.id === payerFilterValue;
      }

      if (providerFiltervalue) {
        matches = matches && enrolled?.provider?.id === providerFiltervalue;
      }

      if (statusFilterValue) {
        matches = matches && enrolled?.status?.id === statusFilterValue;
      }

      if (facilityFilterValue) {
        matches = matches && enrolled?.facility?.id === facilityFilterValue;
      }

      return matches;
    });
  }, [
    payerFilterValue,
    providerEnrollment,
    providerFiltervalue,
    statusFilterValue,
    facilityFilterValue,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterkey}
          onChange={(value) => {
            setSelectedFilterkey(value);
          }}
          style={{ width: "140px" }}
          options={[
            { value: "payer", label: "Payer" },
            { value: "provider", label: "Provider" },
            { value: "status", label: "Status" },
            { value: "facility", label: "Facility" },
          ]}
        />
        {selectedFilterkey === "payer" && (
          <Select
            key="payer-select"
            placeholder="Select Payer"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            value={payerFilterValue}
            onChange={(value) => {
              setPayerFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={payers?.map((payer) => ({
              label: `${payer.name}`,
              searchValue: payer.name,
              value: payer.id,
            }))}
          />
        )}

        {selectedFilterkey === "provider" && (
          <Select
            key="provider-select"
            placeholder="Select Provider"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            value={providerFiltervalue}
            onChange={(value) => {
              setProviderFiltervalue(value);
            }}
            style={{ width: "320px" }}
            options={providers?.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              searchValue: provider.firstName,
              value: provider.id,
            }))}
          />
        )}
        {selectedFilterkey === "status" && (
          <Select
            key="status-select"
            placeholder="Select status"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            value={statusFilterValue}
            onChange={(value) => {
              setStatusFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={status.map((status) => ({
              label: status?.name,
              searchValue: status?.name,
              value: status?.id,
            }))}
          />
        )}
        {selectedFilterkey === "facility" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            placeholder="Select facility"
            value={facilityFilterValue}
            onChange={(value) => {
              setFacilityFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={facilities?.map((facility) => ({
              label: facility?.name,
              searchValue: facility?.name,
              value: facility?.id,
            }))}
          />
        )}
      </Space>
      <div>
        {!!payerFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setPayerFilterValue(undefined);
            }}
          >
            <span>
              Payer ={" "}
              {
                payers?.find(
                  (payer) => payer?.id + "" === payerFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}

        {!!providerFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setProviderFiltervalue(undefined);
            }}
          >
            <span>
              Provider ={" "}
              {
                providers?.find(
                  (stautsOption) =>
                    stautsOption?.id + "" === providerFiltervalue + ""
                )?.firstName
              }
            </span>
          </Tag>
        )}
        {!!statusFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setStatusFilterValue(undefined);
            }}
          >
            <span>
              Status ={" "}
              {
                status?.find(
                  (stautsOption) =>
                    stautsOption?.id + "" === statusFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!facilityFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setFacilityFilterValue(undefined);
            }}
          >
            <span>
              Facility ={" "}
              {
                facilities?.find(
                  (facility) => facility?.id + "" === facilityFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredproviderEnrollment,
    UseQueryHookResult: {
      isFetching,
      refetch,
      isLoading: facilitiesLoading || isLoading,
    },
  };
}