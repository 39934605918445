import { Modal, Form, Col, Space } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import IncredableButton from "../../components/button";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { RoleRequest } from "../../redux/api/role/types";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useAddRoleMutation } from "../../redux/api/role/role";
import { useContext } from "react";

interface AddRoleModalProps {
  visible: boolean;
  onClose: () => void;
}

const AddRoleModal: React.FC<AddRoleModalProps> = ({ visible, onClose }) => {
  const methods = useForm<RoleRequest>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [addRole] = useAddRoleMutation();

  const handleSubmit = methods.handleSubmit(async (data) => {
    spinnerContext?.showSpinner();
    await addRole({ ...data });
    spinnerContext?.hidespinner();
    toastContext?.openSuccessNotification({
      message: `Role saved successfully`,
      placement: "topRight",
    });
    onClose();
  });

  return (
    <Modal visible={visible} onCancel={onClose} title="Add Role" footer={null}>
      <FormProvider {...methods}>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Col span={24}>
            <RHFTextField
              formItemProps={{
                label: "Name",
              }}
              controllerProps={{
                name: "name",
              }}
              inputProps={{}}
            />
          </Col>
          <Col span={24}>
            <RHFTextArea
              formItemProps={{ label: "Description" }}
              controllerProps={{ name: "description" }}
            />
          </Col>
          <Space style={{ width: "100%", justifyContent: "end" }}>
            <IncredableButton secondary onClick={onClose}>
              Cancel
            </IncredableButton>
            <IncredableButton type="primary" htmlType="submit">
              Save
            </IncredableButton>
          </Space>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default AddRoleModal;
