import { api } from "..";
import { Payers, PayerRequest } from "./types";

const payersAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPayer: build.query<Payers[], void>({
      query: () => `payer/all`,
      providesTags: ["Payers"],
    }),
    getPayer: build.query<Payers, { payerId: string }>({
      query: ({ payerId }) => `payer/${payerId}`,
      providesTags: (result, error, { payerId }) => [
        { type: "Payers", id: payerId },
      ],
    }),

    createPayer: build.mutation<Payers, PayerRequest>({
      query: ({ ...body }) => {
        return {
          url: `payer`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Payers"],
    }),
    updatePayer: build.mutation<
      Payers,
      Pick<Payers, "id"> & Partial<PayerRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `payer/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Payers", id: arg.id },
        { type: "Payers" },
      ],
    }),
    deletePayer: build.mutation<
      void,
      {
        payerId: string;
      }
    >({
      query: ({ payerId }) => ({
        url: `payer/${payerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payers"],
    }),

    uploadPayerAttachment: build.mutation<
      void,
      { payerId: string; file: File }
    >({
      query({ payerId, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        if (file) formData.append("file", file);

        return {
          url: `payer/${payerId}/template/upload`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Payers" }],
    }),
    updateAssociatedNetwork: build.mutation<
      void,
      { payerId: string; networks: string[] }
    >({
      query: ({ payerId, networks }) => ({
        url: `payer/${payerId}/payergroups`,
        method: "POST",
        body: networks, // Wrap faciltiesToRemove in an object
      }),
      invalidatesTags: ["Payers"],
    }),
    deleteAssociatedNetwork: build.mutation<
      void,
      { payerId: string; networks: string[] }
    >({
      query: ({ payerId, networks }) => ({
        url: `payer/${payerId}/payergroups`,
        method: "DELETE",
        body: networks, // Wrap deleteassociatedPayer in an object
      }),
      invalidatesTags: ["Payers"],
    }),
  }),
});
export const {
  useGetAllPayerQuery,
  useGetPayerQuery,
  useCreatePayerMutation,
  useUpdatePayerMutation,
  useDeletePayerMutation,
  useUploadPayerAttachmentMutation,
  useUpdateAssociatedNetworkMutation,
  useDeleteAssociatedNetworkMutation,
} = payersAPI;
