import { api } from "..";
import { FacilityGroup, FacilityGroupRequest } from "./types";

const FacilityAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllFacilityGroup: build.query<FacilityGroup[], void>({
      query: () => "/facility/facilitygroup/all",
      providesTags: ["FacilityGroup"],
    }),
    getFacilityGroup: build.query<FacilityGroup, { id: string }>({
      query: ({ id }) => `/facility/facilitygroup/${id}`,
      providesTags: (result, error, args) => [
        { type: "FacilityGroup", id: args.id },
      ],
    }),
    saveFaciltyGroup: build.mutation<
      FacilityGroup,
      { name: string; description: string }
    >({
      query: ({ name, description }) => {
        return {
          url: `/facility/facilitygroup`,
          method: "POST",
          body: {
            name: name,
            description: description,
          },
        };
      },
      invalidatesTags: ["FacilityGroup"],
    }),
    updateFacilityGroup: build.mutation<
      void,
      Pick<FacilityGroup, "id"> & Partial<FacilityGroupRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `/facility/facilitygroup/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "FacilityGroup", id: arg.id },
        { type: "FacilityGroup" },
      ],
    }),
    deleteFacilityGroup: build.mutation<FacilityGroup, { id: string }>({
      query({ id }) {
        return {
          url: `/facility/facilitygroup/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["FacilityGroup"],
    }),
    addFacilityToFacilityGroup: build.mutation<
      void,
      { facilityGroupId: string; facilityIds: string[] }
    >({
      query: ({ facilityGroupId, facilityIds }) => ({
        url: `/facility/facilitygroup/${facilityGroupId}/facility`,
        method: "POST",
        body: facilityIds, // Wrap faciltiesToUpdate in an object
      }),
      invalidatesTags: ["FacilityGroup"],
    }),
    removeFacilitiesFromFacilityGroup: build.mutation<
      void,
      { facilityGroupId: string; facilityId: string[] }
    >({
      query: ({ facilityGroupId, facilityId }) => ({
        url: `/facility/facilitygroup/${facilityGroupId}/facility`,
        method: "DELETE",
        body: facilityId, // Wrap faciltiesToRemove in an object
      }),
      invalidatesTags: ["FacilityGroup"],
    }),
    associateFacilityToFacilityGroups: build.mutation<
      void,
      { facilityId: string; facilityToPost: string[] }
    >({
      query: ({ facilityId, facilityToPost }) => ({
        url: `/facility/${facilityId}/facilitygroups`,
        method: "POST",
        body: facilityToPost, // Wrap faciltiesToUpdate in an object
      }),
      invalidatesTags: ["FacilityGroup"],
    }),
    addFacilitesToFacilityGroup: build.mutation<
      void,
      { facilityGroupId: string; facilityIds: string[] }
    >({
      query: ({ facilityGroupId, facilityIds }) => ({
        url: `/facility/facilitygroup/${facilityGroupId}`,
        method: "POST",
        body: facilityIds, // Wrap faciltiesToUpdate in an object
      }),
      invalidatesTags: ["FacilityGroup"],
    }),
  }),
});

export const {
  useGetAllFacilityGroupQuery,
  useGetFacilityGroupQuery,
  useSaveFaciltyGroupMutation,
  useUpdateFacilityGroupMutation,
  useDeleteFacilityGroupMutation,
  useAddFacilityToFacilityGroupMutation,
  useRemoveFacilitiesFromFacilityGroupMutation,
  useAssociateFacilityToFacilityGroupsMutation,
  useAddFacilitesToFacilityGroupMutation,
} = FacilityAPI;
