import { useParams } from "react-router-dom";
import { useDeleteCredentialTemplateCategoriesMutation, useGetAllCredentialTemplateCategoriesQuery, useGetAllCredentialTemplateFormsQuery } from "../../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { MinusOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import AddCategoryModal from "../modals/addcategory-modal";
import { Category } from "../../../redux/api/category/types";
import { Header, Table } from "@cloudscape-design/components";
import { Button, ConfigProvider, Space, Typography } from "antd";

export default function CategoriesListV2() {
    const { templateId } = useParams();
    const {
        data: categories = [],
        refetch,
        isFetching,
        isLoading,
    } = useGetAllCredentialTemplateCategoriesQuery(
        templateId ? { credentialTemplateId: templateId } : skipToken
    );

    const [addCategoryModalVisible, setAddCategoryModalVisible] =
        useState<boolean>(false);
    const [deleteCredentialTemplateCategory] =
        useDeleteCredentialTemplateCategoriesMutation();

    const [selectedRows, setSelectedRows] = useState<Category[]>([]);

    return (
        <>
            <Table
                loading={isFetching || isLoading}
                onSelectionChange={({ detail }) => {
                    setSelectedRows([...detail.selectedItems]);
                }}
                onRowClick={(onRowClickDetails) => {
                    const selectedItem = onRowClickDetails?.detail?.item;
                    if (selectedItem) {
                        const temp = selectedRows ? [...selectedRows] : [];
                        const selectedItemIndex = temp.findIndex(
                            (record) => record?.id + "" === "" + selectedItem?.id
                        );
                        if (selectedItemIndex !== -1) {
                            temp?.splice(selectedItemIndex, 1);
                            setSelectedRows(temp);
                        } else {
                            setSelectedRows([...temp, selectedItem]);
                        }
                    }
                }}
                selectedItems={selectedRows || []}
                columnDefinitions={[
                    {
                        id: "name",
                        header: <div>Name</div>,
                        cell: (item) => (
                            <span>{item?.name}
                            </span>
                        ),
                    },
                ]}
                items={!!categories ? [...categories] : []}
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                header={
                    <div style={{ marginTop: "8px", marginBottom: "12px" }}>
                        <Header
                            actions={
                                <Space size="middle">
                                    <Button
                                        onClick={() => {
                                            !!refetch && refetch();
                                        }}
                                    >
                                        <ReloadOutlined />
                                    </Button>
                                    <Button
                                        disabled={selectedRows?.length === 0}
                                        onClick={() => {
                                            if (!selectedRows || !templateId) return;
                                            const categoriesId = selectedRows?.map((form) => form?.id + "");
                                            deleteCredentialTemplateCategory({
                                                credentialTemplateId: templateId,
                                                categories: categoriesId,
                                            });
                                            setSelectedRows([]);
                                        }}
                                        icon={<MinusOutlined />}
                                    >
                                        Remove
                                    </Button>

                                    <ConfigProvider
                                        theme={{
                                            token: {
                                                colorPrimary: "#2B6BE6",
                                            },
                                        }}
                                    >
                                        <Button
                                            onClick={() => { setAddCategoryModalVisible(true); }}
                                            type="primary"
                                            icon={<PlusOutlined />}
                                        >
                                            Add Category
                                        </Button>
                                    </ConfigProvider>
                                </Space>
                            }
                        >
                            <Typography.Title level={4} style={{ marginTop: "0" }}>
                                Categories{" "}
                                <Typography.Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                >{`(${categories?.length})`}</Typography.Text>
                            </Typography.Title>
                        </Header>
                    </div>
                }
            />
            <AddCategoryModal
                closeModal={() => {
                    setAddCategoryModalVisible(false);
                }}
                visible={addCategoryModalVisible}
            />
        </>
    );
}
