
type LabelProp = {
  value : String;
  maskContent?: boolean;
};

export default function MaskValue({ value, maskContent = false }: LabelProp) {
  return (
    <div>
      {maskContent && !!value && typeof value === "string" ? (
        <div>{"*".repeat(value.length)}</div>
      ) : (
        <div>{value}</div>
      )}
    </div>
  );
}
