import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];

export const CONTRACT_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Contract ID", alwaysVisible: false },
  { id: "title", label: "Title", alwaysVisible: true },
  { id: "alertDays", label: "Alert Days", alwaysVisible: true },
  { id: "contractType", label: "Contract Type" },
  { id: "attachmentName", label: "Attachment Name" },
  { id: "attachmentSize", label: "Attachment Size" },
  { id: "attachmentContentType", label: "Attachment Content Type" },
  { id: "facilityName", label: "Facility Name" },
  { id: "facilityNPI", label: "Facility NPI" },
  { id: "facilityDescription", label: "Facility Description" },
  { id: "entityName", label: "Entity Name" },
  { id: "entityPhone", label: "Entity Phone" },
  { id: "entityEmail", label: "Entity Email" },
  { id: "executionDate", label: "Execution Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "lastRenewalDate", label: "Last Renewal Date" },
  { id: "description", label: "Description" },
  { id: "primaryContact", label: "Primary Contact" },
];

export const CONTRACT_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "title", visible: true },
      { id: "alertDays", visible: true },
      { id: "contractType", visible: true },
      { id: "attachmentName", visible: true },
      { id: "attachmentSize", visible: true },
      { id: "attachmentContentType", visible: true },
      { id: "facilityName", visible: true },
      { id: "facilityNPI", visible: true },
      { id: "facilityDescription", visible: true },
      { id: "entityName", visible: true },
      { id: "entityPhone", visible: true },
      { id: "entityEmail", visible: true },
      { id: "executionDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "lastRenewalDate", visible: true },
      { id: "description", visible: true },
      { id: "primaryContact", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const CONTRACTTYPE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID", alwaysVisible:false },
  { id: "type", label: "Type", alwaysVisible: true },
  { id: "description", label: "Description" },
];

export const CONTRACTTYPE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "type", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const TablePreferences = ({
  title,
  disabled,
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions,
}: {
  title?: string;
  disabled?: boolean;
  preferences: CollectionPreferencesProps.Preferences<any> | undefined;
  setPreferences: (
    preferences: CollectionPreferencesProps.Preferences<any>
  ) => void;
  pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}) => (
  <CollectionPreferences
    disabled={disabled || !contentDisplayOptions.length}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    title={title}
    pageSizePreference={{ options: pageSizeOptions }}
    wrapLinesPreference={{}}
    stripedRowsPreference={{}}
    contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
