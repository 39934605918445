import { Card, Col, Row, Typography } from "antd";
import { WidgetConfig } from "../types";
import { useNavigate } from "react-router-dom";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";

const { Title } = Typography;

export const serviceOverview: WidgetConfig = {
  title: "Service Overview",
  description: "",
  header: ServiceOverviewHeader,
  content: ServiceOverviewWidget,
};

function ServiceOverviewHeader() {
  return (
    <Title
      level={3}
      style={{
        margin: "32px 0px 24px 0px",
        fontWeight: "700",
        color: "#1D1A1F",
        fontSize: "22px",
      }}
    >
      Provider Overview
    </Title>
  );
}

function ServiceOverviewWidget() {
  const { data } = useGetAllDashboardQuery();
  const navigate = useNavigate();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => {
              navigate("/manageprovider");
            }}
          >
            <Typography.Title
              level={5}
              style={{
                paddingTop: "0px",
                marginTop: "0px",
                cursor: "pointer",
              }}
            >
              Active Providers:{" "}
              <span
                style={{
                  color: "#2B6BE6",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {data?.overview?.totalActiveProviders || 0}
              </span>
            </Typography.Title>
          </Card>
        </Col>{" "}
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => {
              navigate("/safe");
            }}
          >
            <Typography.Title
              level={5}
              style={{
                paddingTop: "0px",
                marginTop: "0px",
                cursor: "pointer",
              }}
            >
              Docs Per Provider:{" "}
              <span
                style={{
                  color: "#2B6BE6",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {data?.overview?.documentsPerProvider || 0}
              </span>
            </Typography.Title>
          </Card>
        </Col>{" "}
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => {
              navigate("/safe");
            }}
          >
            <Typography.Title
              level={5}
              style={{
                paddingTop: "0px",
                marginTop: "0px",
                cursor: "pointer",
              }}
            >
              Documents Expiring:{" "}
              <span
                style={{
                  color: "#2B6BE6",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {data?.overview?.totalExpiringDocuments || 0}
              </span>
            </Typography.Title>
          </Card>
        </Col>{" "}
        <Col xs={24} sm={12} md={6}>
          <Card
            onClick={() => {
              navigate("/safe");
            }}
          >
            <Typography.Title
              level={5}
              style={{
                paddingTop: "0px",
                marginTop: "0px",
                cursor: "pointer",
              }}
            >
              Documents Expired:{" "}
              <span
                style={{
                  color: "#2B6BE6",
                  fontSize: "24px",
                  textAlign: "center",
                }}
              >
                {data?.overview?.totalExpiredDocuments || 0}
              </span>
            </Typography.Title>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default serviceOverview;
