import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import {
  useAddProviderProfessionalinfoMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import { ProfessionalInfoRequestType } from "../../redux/api/provider/types";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import { Countries, USStatesList } from "../../common/constants";

const { useToken } = theme;

export default function EditProviderProfessionalInfo() {
  const { providerId } = useParams();
  const methods = useForm<ProfessionalInfoRequestType>();
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.professionalInfo) {
      const providerProfessionalInfo = provider?.professionalInfo;
      if (providerProfessionalInfo) {
        methods.setValue("id", providerProfessionalInfo.id);
        methods.setValue("caqh", providerProfessionalInfo.caqh);
        methods.setValue(
          "licenseTypeId",
          providerProfessionalInfo.licenseType?.id
        );
        methods.setValue(
          "primarySpecialtyId",
          providerProfessionalInfo.primarySpecialty?.id
        );
        methods.setValue("title", providerProfessionalInfo.title);
        methods.setValue("workPhone", providerProfessionalInfo.workPhone);
        methods.setValue("workPhoneAlt", providerProfessionalInfo.workPhoneAlt);
        methods.setValue(
          "identificationNumber",
          providerProfessionalInfo?.identificationNumber
        );
        methods.setValue(
          "practiceInfo.name",
          providerProfessionalInfo?.practiceInfo?.name
        );
        methods.setValue(
          "practiceInfo.yearsOfPractice",
          providerProfessionalInfo?.practiceInfo?.yearsOfPractice
        );
        methods.setValue(
          "practiceInfo.startDate",
          providerProfessionalInfo?.practiceInfo?.startDate
        );
        methods.setValue(
          "practiceInfo.endDate",
          providerProfessionalInfo?.practiceInfo?.endDate
        );
        methods.setValue(
          "practiceInfo.isCurrent",
          providerProfessionalInfo?.practiceInfo?.isCurrent
        );
        methods.setValue(
          "practiceInfo.addressLane1",
          providerProfessionalInfo?.practiceInfo?.addressLane1
        );
        methods.setValue(
          "practiceInfo.addressLane2",
          providerProfessionalInfo?.practiceInfo?.addressLane2
        );
        methods.setValue(
          "practiceInfo.city",
          providerProfessionalInfo?.practiceInfo?.city
        );
        methods.setValue(
          "practiceInfo.state",
          providerProfessionalInfo?.practiceInfo?.state
        );
        methods.setValue(
          "practiceInfo.zipcode",
          providerProfessionalInfo?.practiceInfo?.zipcode
        );
        methods.setValue(
          "practiceInfo.country",
          providerProfessionalInfo?.practiceInfo?.country
        );
        methods.setValue(
          "practiceInfo.contactName",
          providerProfessionalInfo?.practiceInfo?.contactName
        );
        methods.setValue(
          "practiceInfo.contactTitle",
          providerProfessionalInfo?.practiceInfo?.contactTitle
        );
        methods.setValue(
          "practiceInfo.contactPhone",
          providerProfessionalInfo?.practiceInfo?.contactPhone
        );
        methods.setValue(
          "practiceInfo.contactEmail",
          providerProfessionalInfo?.practiceInfo?.contactEmail
        );
        methods.setValue(
          "practiceInfo.contactFax",
          providerProfessionalInfo?.practiceInfo?.contactFax
        );
      }
    }
  }, [provider]);

  const [updateProviderProfessionalInfo] =
    useAddProviderProfessionalinfoMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderprofessionalinfo"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProfessionalInfoRequestType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderProfessionalInfo({
                  ...data,
                  providerId: providerId,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Professional Info saved successfully`,
                  placement: "topRight",
                });
              }
              navigate(-1);
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Professional Info
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="professionalInfo" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <Space>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "CAQH",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "caqh",
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Title",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "title",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Work Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "workPhone",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Work Phone Alt",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "workPhoneAlt",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Identification Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "identificationNumber",
                            }}
                          />
                        </Col>
                      </Row>

                      <Typography.Text
                        strong
                        style={{
                          fontSize: "22px",
                          color: "#1D1A1F",
                          lineHeight: "25px",
                          fontWeight: "700",
                        }}
                      >
                        Practice Info
                      </Typography.Text>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Practice Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.name",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Years Of Practice",
                            }}
                            inputProps={{
                              type: "number",
                            }}
                            controllerProps={{
                              name: "practiceInfo.yearsOfPractice",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Start Date",
                            }}
                            controllerProps={{
                              name: "practiceInfo.startDate",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "End Date",
                            }}
                            controllerProps={{
                              name: "practiceInfo.endDate",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.addressLane1",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.addressLane2",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.city",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `practiceInfo.state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `practiceInfo.country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.zipcode",
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Contact Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.contactName",
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Contact Title",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.contactTitle",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Contact Phone",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.contactPhone",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Contact Email",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.contactEmail",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Contact Fax",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "practiceInfo.contactFax",
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </Space>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
