import { Button, ConfigProvider, Space, Typography } from "antd";
import { NavLink, useNavigate } from "react-router-dom";
import IncredableDateFormat from "../common/incredable-date-format";
import { PayerEnrollment } from "../redux/api/payerenrollment/types";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useLazyGetPayerEnrollmentsReportQuery } from "../redux/api/reporting/reporting";
import { DownloadOutlined } from "@ant-design/icons";

export default function PayerEnrollmentsReport() {
  const [selectedRows, setSelectedRows] = useState<PayerEnrollment[]>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const navigate = useNavigate();
  const [trigger, { data: payerEnrollmentReportsData, isLoading, isFetching }] =
    useLazyGetPayerEnrollmentsReportQuery({});

  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  useEffect(() => {
    debouncedTrigger({
      pageNumber,
      pageSize,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [pageNumber, pageSize, sortingDetails, debouncedTrigger]);

  const csvData =
    payerEnrollmentReportsData?.payerEnrollments?.map((row) => ({
      Payer_name: row?.payer?.name || "-",
      Payer_id: row?.id || "-",
      Network: row?.facilityGroup?.name || "-",
      Network_id: row?.facilityGroup?.id || "-",
      Provider_id: row?.provider?.id || "-",
      Status: row?.status?.name || "-",
      Submitted_Date: row?.submittedDate || "-",
      Approved_Date: row?.approvedDate || "-",
      Effective_Date: row?.effectiveDate || "-",
      Recredentialing_Date: row?.recredentialingDate || "-",
      Provider_LastName: row?.provider?.lastName || "-",
      Provider_FirstName: row.provider?.firstName || "-",
      Provider_Email: row?.provider?.email || "-",
      Provider_License_Type: row?.provider?.licenseType?.name || "-",
      IdentificationNumber: row?.provider?.identificationNumber || "-",
      Provider_Facility_Name: row?.provider?.facility?.name || "-",
      Enrollment_Facility_Name: row?.facility?.name || "-",
    })) || [];

  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "id",
            header: "ID",
            cell: (item) => item?.id,
            sortingField: "id",
          },
          {
            id: "network",
            header: "Network",
            cell: (item) => item?.payerGroup?.name,
            sortingField: "payerGroup.name",
            sortingComparator: (a, b) =>
              (a?.payerGroup?.name ?? "").localeCompare(
                b?.payerGroup?.name ?? ""
              ),
          },
          {
            id: "payer",
            header: "Payer Name",
            cell: (item) => (
              <NavLink
                to={`/payer/${item?.payer?.id}/payerenrollment/${item?.id}/edit `}
              >
                {item?.payer?.name}
              </NavLink>
            ),
            sortingField: "payer.name",
            isRowHeader: true,
          },
          {
            id: "status",
            header: "Status",
            cell: (item) => <span>{item?.status?.name ?? "-"}</span>,
            sortingField: "status.name",
            sortingComparator: (a, b) =>
              (a?.status?.name ?? "").localeCompare(b?.status?.name ?? ""),
          },
          {
            id: "payerProviderId",
            header: "Payer Provider ID",
            cell: (item) => <div>{item?.payerProviderId}</div>,
            sortingField: "payerProviderId",
            sortingComparator: (a, b) =>
              (a?.payerProviderId ?? "").localeCompare(
                b?.payerProviderId ?? ""
              ),
          },
          {
            id: "provider",
            header: "Provider",
            cell: (item) => (
              <span>
                {(item?.provider &&
                  item?.provider?.firstName + " " + item?.provider?.lastName) ??
                  "-"}
              </span>
            ),
            sortingField: "provider.firstName",
            sortingComparator: (a, b) =>
              (a?.provider?.firstName ?? "").localeCompare(
                b?.provider?.firstName ?? ""
              ),
          },
          {
            id: "facility",
            header: "Facility",
            cell: (item) => <span>{item?.facility?.name ?? "-"}</span>,
            sortingField: "facility.name",
            sortingComparator: (a, b) =>
              (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
          },
          {
            id: "facilityGroup",
            header: "Facility Group",
            cell: (item) => <span>{item?.facilityGroup?.name ?? "-"}</span>,
            sortingField: "facilityGroup.name",
            sortingComparator: (a, b) =>
              (a?.facilityGroup?.name ?? "").localeCompare(
                b?.facilityGroup?.name ?? ""
              ),
          },
          {
            id: "submittedDate",
            header: "Submitted Date",
            cell: (item) => (
              <span>{IncredableDateFormat(item?.submittedDate) ?? "-"}</span>
            ),
          },
          {
            id: "approvedDate",
            header: "Approved Date",
            cell: (item) => (
              <span>{IncredableDateFormat(item?.approvedDate) ?? "-"}</span>
            ),
          },
          {
            id: "effectiveDate",
            header: "Effective Date",
            cell: (item) => (
              <span>{IncredableDateFormat(item?.effectiveDate) ?? "-"}</span>
            ),
          },
          {
            id: "recredentialingDate",
            header: "Recredentialing Date",
            cell: (item) => (
              <span>
                {IncredableDateFormat(item?.recredentialingDate) ?? "-"}
              </span>
            ),
          },
          {
            id: "followupDate",
            header: "Followup Date",
            cell: (item) => (
              <span>
                {item?.alertDays > 0 ? `${item?.alertDays} days` : "-"}
              </span>
            ),
          },
          {
            id: "workflow",
            header: "Workflow",
            cell: (item) => <span>{item?.workflow?.name ?? "-"}</span>,
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => <span>{item?.description ?? "-"}</span>,
          },
        ]}
        items={
          !!payerEnrollmentReportsData
            ? payerEnrollmentReportsData?.payerEnrollments
            : []
        }
        pagination={
          <Pagination
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!pageSize && pageSize !== 0
                ? Math.ceil(
                  !!payerEnrollmentReportsData
                    ? payerEnrollmentReportsData?.totalRecords / pageSize
                    : 0
                )
                : 1
            }
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Space size="middle">
                    <Button type="primary" icon={<DownloadOutlined />}>
                      Export CSV
                    </Button>
                  </Space>
                </ConfigProvider>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Payer Enrollment Reports{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${payerEnrollmentReportsData?.totalRecords})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
      />
    </div>
  );
}
