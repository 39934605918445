import { Col, Divider, Flex, Form, Row, Space, theme, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useAddFacilityMutation,
  useGetAllFacilityTypesQuery,
} from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { useNavigate } from "react-router-dom";
import { FacilityRequest } from "../../redux/api/facility/types";
import { useContext } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import IncredableContent from "../../components/incredable-content";
import { Countries, USStatesList } from "../../common/constants";
import { useGetAllFacilityGroupQuery } from "../../redux/api/facilitygroup/facilityGroup";
import { faC } from "@fortawesome/free-solid-svg-icons";

type FormInputType = FacilityRequest;

export default function AddFacility() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const { data: facilityType } = useGetAllFacilityTypesQuery();
  const { data: facilityGroup } = useGetAllFacilityGroupQuery();
  const [addFacility] = useAddFacilityMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          {
            title: <a href="/managefacilities">Facility</a>,
          },
          {
            title: "Add Facility",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addfacility"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            await addFacility({
              name: data?.name,
              npiNumber: data?.npiNumber,
              description: data?.description,
              facilityTypeId: data?.facilityTypeId,
              facilityGroupId: data?.facilityGroupId,
              taxId: data?.taxId,
              cliaId: data?.cliaId,
              phoneNumber: data?.phoneNumber,
              email: data?.email,
              notes: data?.notes,
              immunizationId: data?.immunizationId,
              taxonomyCode: data?.taxonomyCode,
              facilityAddress: {
                addressLane1: data?.facilityAddress?.addressLane1,
                addressLane2: data?.facilityAddress?.addressLane2,
                city: data?.facilityAddress?.city,
                state: data?.facilityAddress?.state,
                zipcode: data?.facilityAddress?.zipcode,
                country: data?.facilityAddress?.country,
              },
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Facility saved successfully`,
              placement: "topRight",
            });
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Facility
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="facilities" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "NPI Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `npiNumber`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility Type" }}
                            controllerProps={{
                              name: `facilityTypeId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilityType?.map((type) => {
                                return {
                                  label: type?.name,
                                  value: type?.id,
                                };
                              }),
                              onModifyClick: () => navigate("/facilitytype"),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility Group" }}
                            controllerProps={{
                              name: `facilityGroupId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: facilityGroup?.map((type) => {
                                return {
                                  label: type?.name,
                                  value: type?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Tax ID",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `taxId`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "CLIA ID",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `cliaId`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Immunization ID",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `immunizationId`,
                            }}
                          />
                        </Col>

                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Taxonomy Code",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `taxonomyCode`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFPhoneNumber
                            formItemProps={{
                              label: "Phone Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `phoneNumber`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Mailing Address",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `email`,
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider orientation="left">Facility Address</Divider>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 1",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.addressLane1`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Address Line 2",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.addressLane2`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "City",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.city`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "State" }}
                            controllerProps={{
                              name: `facilityAddress.state`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select state",
                              options: USStatesList?.map((state) => {
                                return {
                                  value: state?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Country" }}
                            controllerProps={{
                              name: `facilityAddress.country`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select country",
                              options: Countries?.map((country) => {
                                return {
                                  value: country?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Zip",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `facilityAddress.zipcode`,
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider></Divider>
                      <Col span={24}>
                        <RHFTextArea
                          formItemProps={{
                            label: "Notes",
                          }}
                          controllerProps={{
                            name: `notes`,
                          }}
                        />
                      </Col>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
