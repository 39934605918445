import queryString from "query-string";
import { api } from "..";
import { PaginationAffiliation, ProviderAffiliation } from "./types";

const affiliationApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllAffiliation: build.query<
      PaginationAffiliation,
      {
        pageNumber: number;
        pageSize: number;
        providerId?: string;
        facilityId?: string;
        affiliationTypeId?: string;
        affiliationStatusId?: string;
        sortBy?: string;
        direction?: string;
      }
    >({
      query: ({
        pageNumber,
        pageSize,
        providerId,
        facilityId,
        affiliationTypeId,
        affiliationStatusId,
        sortBy,
        direction,
      }) => {
        const requestParams = queryString.stringify(
          {
            pageNumber,
            pageSize,
            providerId,
            facilityId,
            affiliationTypeId,
            affiliationStatusId,
            sortBy,
            direction,
          },
          { skipEmptyString: true }
        );
        return `affiliation/all?${requestParams}`;
      },
      providesTags: ["Affiliation"],
    }),
    findAffiliationById: build.query<
      ProviderAffiliation,
      { affiliationId: string }
    >({
      query: ({ affiliationId }) => `/affiliation/${affiliationId}`,
      providesTags: ["Affiliation"],
    }),
    findAllAffiliationByProvider: build.query<
      ProviderAffiliation[],
      { providerId: string }
    >({
      query: ({ providerId }) => `/affiliation/${providerId}/all`,
      providesTags: ["Affiliation"],
    }),
  }),
});

export const {
  useLazyGetAllAffiliationQuery,
  useGetAllAffiliationQuery,
  useFindAffiliationByIdQuery,
  useFindAllAffiliationByProviderQuery,
} = affiliationApi;
