import { Button, ConfigProvider, Space, Typography } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useLazyGetProviderAffiliationReportQuery } from "../redux/api/reporting/reporting";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { ProviderAffiliationWithProvider } from "../redux/api/provider/types";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import IncredableDateFormat from "../common/incredable-date-format";

export default function ProviderAffiliationsReport() {
  const [selectedRows, setSelectedRows] =
    useState<ProviderAffiliationWithProvider[]>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const navigate = useNavigate();
  const [
    trigger,
    { data: providerAffiliationReportsData, isLoading, isFetching },
  ] = useLazyGetProviderAffiliationReportQuery({});

  // Debounced trigger function
  const debouncedTrigger = useCallback(
    debounce((filters) => {
      trigger(filters);
    }, 1200),
    [trigger]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTrigger.cancel();
    };
  }, [debouncedTrigger]);

  useEffect(() => {
    debouncedTrigger({
      pageNumber,
      pageSize,
      sortBy: sortingDetails?.sortingField,
      direction: !!sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [pageNumber, pageSize, sortingDetails, debouncedTrigger]);
  return (
    <div style={{ padding: "32px 32px 0 32px" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        sortingColumn={{ sortingField: sortingDetails?.sortingField }}
        sortingDescending={sortingDetails?.isDescending}
        onSortingChange={({ detail }) => {
          setSortingDetails({
            sortingField: detail?.sortingColumn?.sortingField,
            isDescending: detail?.isDescending,
          });
        }}
        columnDefinitions={[
          {
            id: "facility",
            header: "Facility",
            cell: (item) => <span>{item?.facility?.name}</span>,
            sortingField: "facility",
            isRowHeader: true,
          },
          {
            id: "affiliationStatus",
            header: "Affiliation Status",
            cell: (item) => item?.affiliationStatus?.name,
            sortingField: "affiliationStatus",
          },
          {
            id: "affiliationType",
            header: "Affiliation Type",
            cell: (item) => item?.affiliationType?.name,
            sortingField: "affiliationType",
          },
          {
            id: "name",
            header: "Name",
            cell: (item) => item?.name,
            sortingField: "name",
          },
          {
            id: "email",
            header: "Email address",
            cell: (item) => item?.email,
            sortingField: "email",
          },
          {
            id: "fax",
            header: "Fax",
            cell: (item) => item?.fax,
            sortingField: "fax",
          },
          {
            id: "phone",
            header: "Phone Number",
            cell: (item) => item?.phone,
            sortingField: "phone",
          },
          {
            id: "affiliationType",
            header: "Affiliation Type",
            cell: (item) => item?.affiliationType?.name,
          },
          {
            id: "provider",
            header: "Provider",
            cell: (item) =>
              `${item?.provider?.lastName} ${item?.provider?.firstName}`,
            sortingField: "provider",
          },
          {
            id: "alertDays",
            header: "Expiration Remainder",
            cell: (item) => `${item?.alertDays} days`,
          },
          {
            id: "startDate",
            header: "Start Date",
            cell: (item) => IncredableDateFormat(item?.startDate),
          },
          {
            id: "endDate",
            header: "End Date",
            cell: (item) => IncredableDateFormat(item?.endDate),
          },
          {
            id: "documentName",
            header: "Document Name",
            cell: (item) => item?.document?.name,
          },
          {
            id: "notes",
            header: "Notes",
            cell: (item) => item?.notes,
          },
        ]}
        items={
          !!providerAffiliationReportsData
            ? providerAffiliationReportsData?.providerAffiliations
            : []
        }
        pagination={
          <Pagination
            currentPageIndex={pageNumber + 1}
            onChange={({ detail }) => {
              setPageNumber(detail.currentPageIndex - 1);
            }}
            pagesCount={
              !!pageSize && pageSize !== 0
                ? Math.ceil(
                  !!providerAffiliationReportsData
                    ? providerAffiliationReportsData?.totalRecords / pageSize
                    : 0
                )
                : 1
            }
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <ConfigProvider
                  theme={{
                    token: {
                      colorPrimary: "#2B6BE6",
                    },
                  }}
                >
                  <Space size="middle">
                    <Button
                      onClick={() => navigate("")}
                      type="primary"
                      icon={<DownloadOutlined />}
                    >
                      Export CSV
                    </Button>
                  </Space>
                </ConfigProvider>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Provider Affiliation Reports{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${providerAffiliationReportsData?.totalRecords})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
      />
    </div>
  );
}
