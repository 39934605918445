import {
  useFormContext,
  Controller,
  UseControllerProps,
  RegisterOptions,
  FieldValues,
  FieldError,
} from "react-hook-form";
import { DatePicker, Form, FormItemProps, DatePickerProps, Typography } from "antd";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
dayjs.extend(dayjsPluginUTC);

// ----------------------------------------------------------------------

type Props = {
  formItemProps: FormItemProps;
  datePickerProps?: DatePickerProps;
  controllerProps: UseControllerProps;
  rules?:
  | Omit<
    RegisterOptions<FieldValues, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >
  | undefined;
};

export default function RHFDatePicker({
  formItemProps,
  datePickerProps,
  controllerProps,
  rules,
}: Props) {
  const { control } = useFormContext();
  const { Text } = Typography;
  return (
    <Controller
      name={controllerProps.name}
      control={control}
      defaultValue={controllerProps.defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Form.Item
            {...formItemProps}
            label={
              <span style={{ fontWeight: "600" }}>{formItemProps?.label}{" "}{!!rules?.required ? <Text>*</Text> : <></>}</span>
            }
            // required={!!rules?.required}
            validateStatus={!!fieldState.error ? "error" : ""}
            help={!!fieldState.error && getErrorMessage(fieldState.error)}
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder={datePickerProps?.placeholder}
              disabled={datePickerProps?.disabled}
              defaultValue={null}
              onChange={(date, dateString) => {
                if (!date) {
                  field.onChange();
                } else field.onChange(date.format("YYYY-MM-DD") + "T00:00:00");
              }}
              value={
                !!field.value && dayjs(field.value).isValid()
                  ? dayjs.utc(field.value)
                  : null
              }
              showNow={false}
              status={!!fieldState.error?.message ? "error" : ""}
              allowClear={true}
              format={"MM-DD-YYYY"}
            />
          </Form.Item>
        );
      }}
      rules={rules}
    />
  );
}

const getErrorMessage = (error?: FieldError | undefined) => {
  if (!error) return;
  if (error.message && error.message.length > 0) return error.message;
  if (error.type === "maxLength") return "Value is too long";
  return "Invalid value";
};
