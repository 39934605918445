import { api } from "..";
import {ApproveDocumentRequest, DocumentRequest, DocumentRequestResponse, ShareDocumentMutation } from "./types";

const DocumentRequestAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDocumentRequests: build.query<DocumentRequestResponse[], void>({
      query: () => "/documentsharerequest/all",
      providesTags: ["DocumentRequest"],
    }),
    addDocumentShareRequest: build.mutation<void, ShareDocumentMutation>({
      query: (body) => {
        return {
          url: "documentsharerequest",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["DocumentRequest"],
    }),
    updateDocumentShareRequest: build.mutation<void, { id: string } & Partial<ApproveDocumentRequest>>({
      query: ({ id, ...patch }) => {
        return {
          url: `documentsharerequest/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: ["DocumentRequest"],
    }),
    updateDocumentShareAndMarkAsDenied: build.mutation<void, { id: string }>({
      query: ({ id }) => {
        return {
          url: `documentsharerequest/status/deny/${id}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["DocumentRequest"],
    }),
    updateDocumentShareAndMarkAsApprove: build.mutation<void, { id: string } & Partial<ApproveDocumentRequest>>({
      query: ({ id, ...patch }) => {
        return {
          url: `documentsharerequest/status/approve/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: ["DocumentRequest"],
    }),
    resendDocumentShareREquest: build.mutation<void, { id: string } & Partial<DocumentRequest>>({
      query: ({ id, ...patch }) => {
        return {
          url: `documentsharerequest/resend/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: ["DocumentRequest"],
    }),
  }),
});

export const {
  useGetAllDocumentRequestsQuery,
  useAddDocumentShareRequestMutation,
  useUpdateDocumentShareRequestMutation,
  useUpdateDocumentShareAndMarkAsDeniedMutation,
  useUpdateDocumentShareAndMarkAsApproveMutation,
  useResendDocumentShareREquestMutation,

} = DocumentRequestAPI;
