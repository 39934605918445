import { useCollection } from "@cloudscape-design/collection-hooks";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    Pagination,
    Table,
    TableProps,
} from "@cloudscape-design/components";
import { PayerGroup } from "../../../redux/api/payergroup/types";
import { useDeleteAssociatedNetworkMutation } from "../../../redux/api/payers/payers";
import usePayerGroupFilter from "../../../table-filters/payergroup-filters";
import AssociatedNetworksModals from "./associated-network-modal";
import { Button, Space } from "antd";


export default function AssoicatedNetworksList({
    associatedNetworks,
    setAssociatedNetworks,
}: {
    associatedNetworks: PayerGroup[];
    setAssociatedNetworks: Dispatch<SetStateAction<PayerGroup[]>>;
}) {
    const [isAssociatedNetworksModalOpen, setAssociatedNetworksModalOpen] =
        useState<boolean>(false);
    const { TableFilters, filteredPayerGroup, UseQueryHookResult } =
        usePayerGroupFilter(associatedNetworks);


    const { payerId } = useParams();

    const [deleteAssociatedNetwork] = useDeleteAssociatedNetworkMutation();

    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences<any>
    >({
        pageSize: 10,
        wrapLines: true,
    });

    const { items, collectionProps, paginationProps } = useCollection(
        filteredPayerGroup,
        {
            pagination: {},
        }
    );
    const [selectedPayerGroup, setSelectedPayerGroup] = useState<PayerGroup[]>(
        [],
    );

    const tableProps: TableProps<PayerGroup> = useMemo(() => {
        return {
            header: (
                <Header
                    counter={`(${filteredPayerGroup?.length})`}
                    actions={
                        <Space direction="horizontal">
                            <Button
                                disabled={!selectedPayerGroup?.at(0)?.id}
                                onClick={async () => {
                                    try {
                                        if (!payerId) return;
                                        const payerToRemove =
                                            selectedPayerGroup?.map((group) => group?.id + "") || [];

                                        await deleteAssociatedNetwork({
                                            payerId: payerId,
                                            networks: payerToRemove,
                                        });

                                        const temp = associatedNetworks.filter(
                                            (network) => !payerToRemove.includes(network?.id + ""),
                                        );
                                        setAssociatedNetworks(temp);
                                        setSelectedPayerGroup([]);
                                    } catch (error) {
                                        console.error("Error deleting payer:", error);
                                    }
                                }}
                            >
                                Remove
                            </Button>

                            <Button
                                onClick={() => {
                                    setAssociatedNetworksModalOpen(true);
                                }}
                                type="primary"
                            >
                                Associate Network
                            </Button>
                        </Space>
                    }
                >
                    Associated Networks
                </Header>
            ),
            items,
            ...collectionProps,
            pagination: <Pagination {...paginationProps} />,
            preferences: (
                <CollectionPreferences
                    onConfirm={({ detail }) => {
                        if (!!detail) setPreferences(detail);
                    }}
                    preferences={preferences}
                    pageSizePreference={{
                        options: [
                            { value: 10, label: "10 items" },
                            { value: 30, label: "30 items" },
                            { value: 50, label: "50 items" },
                        ],
                    }}
                />
            ),
            filter: TableFilters,
            variant: "container",
            loading: UseQueryHookResult?.isLoading || UseQueryHookResult?.isFetching,
            loadingText: "Loading...",
            selectionType: "multi",
            resizableColumns: true,
            stickyHeader: true,
            onSelectionChange: (selectionChangeDetail) => {
                setSelectedPayerGroup(selectionChangeDetail.detail.selectedItems);
            },
            onRowClick: ({ detail }) => {
                const temp = selectedPayerGroup;
                if (!detail.item) return;
                if (temp?.includes(detail.item))
                    setSelectedPayerGroup(temp.filter((item) => item !== detail.item));
                else setSelectedPayerGroup([detail.item, ...temp]);
            },
            selectedItems: selectedPayerGroup,
            trackBy: (item) => "" + item.id,
            columnDisplay: [
                { id: "name", visible: true },
                { id: "groupCode", visible: true },
                { id: "description", visible: true },
            ],
            columnDefinitions: [
                {
                    id: "id",
                    header: <div>ID</div>,
                    cell: (item) => <span>{item.id}</span>,
                    visibility: false,
                },
                {
                    id: "name",
                    header: <div>Network Name</div>,
                    cell: (item) => (
                        <span>
                            {item?.name ?? "-"}
                        </span>
                    ),
                    sortingField: "name",
                },
                {
                    id: "groupCode",
                    header: "Network ID",
                    cell: (item) => <span>{item.groupCode || "-"}</span>,
                    sortingField: "groupCode",
                    isRowHeader: true,
                },
                {
                    id: "description",
                    header: <span>Notes</span>,
                    cell: (item) => <div>{item?.description ?? "-"}</div>,
                },
            ],
        };
    }, [items, selectedPayerGroup]);

    return (
        <div>
            <Table {...tableProps} />
            <AssociatedNetworksModals
                associatedNetworks={associatedNetworks}
                isAssociatedNetworksModalOpen={isAssociatedNetworksModalOpen}
                setAssociatedNetworks={setAssociatedNetworks}
                setAssociatedNetworksModalOpen={setAssociatedNetworksModalOpen}
            />
        </div>
    );
}
