import React, { SetStateAction } from "react";
import { Typography, Button, Card, Col, Row, Space, Divider } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { Images } from "../../common/images";
import IncredableDateFormat from "../../common/incredable-date-format";
import { WorkflowRequest } from "../../redux/api/workflow/types";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import {
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
} from "../../redux/api/workflow/workflow";
import { Table } from "@cloudscape-design/components";
import IncredableButton from "../../components/button";
import { Container, Header, SpaceBetween } from "@cloudscape-design/components";

interface StepFourProps {
  formData: WorkflowRequest | undefined;
  setCurrent: (value: SetStateAction<number>) => void;
}

const StepFour: React.FC<StepFourProps> = ({ formData, setCurrent }) => {
  const { data: workflowStatus = [] } = useGetAllWorkflowStatusQuery();
  const { data: workflowTemplates = [] } = useGetAllWorkflowTemplateQuery();
  const { data: members = [] } = useGetAllMembersQuery();
  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();
  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();

  const handleEdit = (stepIndex: number) => {
    setCurrent(stepIndex);
  };

  const assignedToValue = () => {
    const adminId = formData?.adminId;
    if (adminId) {
      const member = members?.find(
        (member: { id: { toString: () => string } }) =>
          member?.id?.toString() === adminId?.toString()
      );
      if (member) {
        return `${member?.firstName} ${member?.lastName}`;
      }
    }
    return "--";
  };

  const providerValue = () => {
    const providerId = formData?.providerId;
    if (providerId) {
      const provider = providers?.find(
        (provider: { id: { toString: () => string } }) =>
          provider?.id.toString() === providerId.toString()
      );
      if (provider) {
        return `${provider.firstName} ${provider.lastName}`;
      }
    }
    return "--";
  };

  const facilityValue = () => {
    const facilityId = formData?.facilityId;
    if (facilityId) {
      const facility = facilities?.find(
        (facility: { id: { toString: () => string } }) =>
          facility?.id.toString() === facilityId.toString()
      );
      return facility?.name || "--";
    }
    return "--";
  };

  const workflowTemplateValue = () => {
    const templateId = formData?.workflowTemplateId;
    if (templateId) {
      const workflowTemplate = workflowTemplates?.find(
        (template: { id: { toString: () => string } }) =>
          template?.id.toString() === templateId.toString()
      );
      return workflowTemplate?.name || "--";
    }
    return "--";
  };

  const workFlowStatusValue = () => {
    const statusId = formData?.workflowStatusId;
    if (statusId) {
      const workStatus = workflowStatus?.find(
        (status: { id: { toString: () => string } }) =>
          status?.id.toString() === statusId.toString()
      );
      return workStatus?.name || "--";
    }
    return "--";
  };

  const details = [
    { label: "Workflow Name", value: formData?.name || "--" },
    {
      label: "Workflow Status",
      value: workFlowStatusValue(),
    },
    { label: "Workflow Template", value: workflowTemplateValue() },
    { label: "Assigned To", value: assignedToValue() },
    { label: "Provider", value: providerValue() },
    { label: "Facility", value: facilityValue() },
    { label: "Notes", value: formData?.notes || "--" },
  ];

  const startEndDate = [
    {
      label: "Start Date",
      value: (
        <Space>
          <CalendarOutlined />
          {IncredableDateFormat(formData?.startDate) || "--"}
        </Space>
      ),
    },
    {
      label: "Due Date",
      value: (
        <Space>
          <CalendarOutlined />
          {IncredableDateFormat(formData?.dueDate) || "--"}
        </Space>
      ),
    },
  ];

  return (
    <SpaceBetween size={"l"}>
      <Container
        header={
          <div>
            <Header
              variant="h3"
              actions={
                <IncredableButton secondary onClick={() => handleEdit(0)}>
                  Edit
                </IncredableButton>
              }
            >
              Details
            </Header>
            <Divider style={{ marginTop: "12px" }} />
          </div>
        }
      >
        <Row gutter={[16, 16]}>
          {details.map((detail, index) => (
            <Col span={8} key={index}>
              <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
                {detail.label}
              </div>
              <div>{detail.value}</div>
            </Col>
          ))}
        </Row>
      </Container>

      <Container
        header={
          <div>
            <Header
              variant="h3"
              actions={
                <IncredableButton secondary onClick={() => handleEdit(1)}>
                  Edit
                </IncredableButton>
              }
            >
              Start And Due Date
            </Header>
            <Divider style={{ marginTop: "12px" }} />
          </div>
        }
      >
        <Row gutter={[16, 16]}>
          {startEndDate.map((work, index) => (
            <Col span={12} key={index}>
              <div style={{ fontWeight: "bold", marginBottom: "4px" }}>
                {work.label}
              </div>
              <div>{work.value}</div>
            </Col>
          ))}
        </Row>
      </Container>

      <Table
        items={!!formData ? formData?.tasks : []}
        columnDefinitions={[
          {
            id: "taskType",
            header: <Typography.Text>Task Type</Typography.Text>,
            cell: (item) => {
              const taskTypeName =
                taskTypes?.find(
                  (taskType: { id: { toString: () => string } }) =>
                    taskType.id?.toString() === item.taskTypeId?.toString()
                )?.name || "--";

              return <Typography.Text>{taskTypeName}</Typography.Text>;
            },
          },
          {
            id: "name",
            header: <Typography.Text>Name</Typography.Text>,
            cell: (item) => (
              <Typography.Text>{item.name || "-"}</Typography.Text>
            ),
          },
          {
            id: "status",
            header: <Typography.Text>Task Status</Typography.Text>,
            cell: (item) => {
              const taskStatus =
                taskstatuses.find(
                  (status: { id: { toString: () => string } }) =>
                    status.id?.toString() === item?.taskStatusId?.toString()
                )?.name || "--";
              return taskStatus;
            },
          },
          {
            id: "assignTo",
            header: <Typography.Text>Assign To</Typography.Text>,
            cell: (item) => {
              const admin = members.find(
                (member: { id: { toString: () => string } }) =>
                  member?.id?.toString() === item?.adminId?.toString()
              );

              const adminName = admin
                ? `${admin?.firstName} ${admin?.lastName}`
                : "--";
              return <Typography.Text>{adminName}</Typography.Text>;
            },
          },
          {
            id: "provider",
            header: <Typography.Text>Provider</Typography.Text>,
            cell: (item) => {
              const provider = providers.find(
                (provider: { id: { toString: () => string } }) =>
                  provider?.id?.toString() === item?.providerId?.toString()
              );

              const providerName = provider
                ? `${provider?.firstName} ${provider?.lastName}`
                : "--";
              return <Typography.Text>{providerName}</Typography.Text>;
            },
          },
          {
            id: "startDate",
            header: <Typography.Text>Start Date</Typography.Text>,
            cell: (item) => (
              <Typography.Text>
                <CalendarOutlined style={{ color: "#7C4095" }} />{" "}
                {IncredableDateFormat(item?.startDate)}
              </Typography.Text>
            ),
          },
          {
            id: "dueDate",
            header: <Typography.Text>Due Date</Typography.Text>,
            cell: (item) => (
              <Typography.Text>
                <CalendarOutlined style={{ color: "#598BEB" }} />{" "}
                {IncredableDateFormat(item?.dueDate)}
              </Typography.Text>
            ),
          },
        ]}
        header={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span style={{ fontWeight: "600", fontSize: "18px" }}>Tasks</span>
            <IncredableButton secondary onClick={() => handleEdit(2)}>
              Edit
            </IncredableButton>
          </div>
        }
      />
    </SpaceBetween>
  );
};

export default StepFour;
