import {
  DownOutlined,
  ReloadOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, DropdownProps, Space, Typography } from "antd";
import IncredableButton from "../button";

export type TableTitleProps = {
  filter?: React.ReactNode;
  buttons?: React.ReactNode;
  heading?: React.ReactNode;
  actionDropDownProps?: DropdownProps;
  onSettingsClick?: React.MouseEventHandler<HTMLElement>;
  onReloadClick?: React.MouseEventHandler<HTMLElement>;
};

export default function TableTitle({
  filter,
  buttons,
  heading,
  actionDropDownProps,
  onSettingsClick,
  onReloadClick,
}: TableTitleProps) {
  return (
    <Space
      style={{
        display: "flex",
        justifyContent: "space-between",
        padding: "10px",
      }}
    >
      {filter ? (
        filter
      ) : (
        <>
          <Typography.Title level={3} style={{ margin: 0 }}>
            {heading}
          </Typography.Title>
        </>
      )}
      <Space>
        <Button
          style={{
            margin: "0px",
            padding: "4px 4px",
            height: "32px",
            width: "32px",
            borderColor: "rgb(124, 64, 149)",
          }}
          onClick={onReloadClick}
        >
          <ReloadOutlined
            style={{
              color: "rgb(124, 64, 149)",
              fontSize: "16px",
            }}
          />
        </Button>

        {!!actionDropDownProps && (
          <Dropdown {...actionDropDownProps}>
            <IncredableButton secondary={!actionDropDownProps?.disabled}>
              Actions
              <DownOutlined />
            </IncredableButton>
          </Dropdown>
        )}
        <Button
          onClick={onSettingsClick}
          style={{
            border: "1.5px solid #7C4095",
            color: "#7C4095",
          }}
          icon={<SettingOutlined />}
        />
        {!!buttons && buttons}
      </Space>
    </Space>
  );
}
