import { WidgetConfig } from "../types";
import { BarChart } from "@cloudscape-design/components";
import {
  useGetAllDashboardQuery,
  useGetDashboardPayerEnrollmentStatsQuery,
} from "../../../redux/api/dashboards/dashboard";
import { useMemo } from "react";

export const PayerenrollmentStatus: WidgetConfig = {
  title: "Payer enrollments by status",
  description: "",
  header: PayerenrollmentHeader,
  content: PayerenrollmentContent,
};

export const UsageByFacilityStatus: WidgetConfig = {
  title: "Provider Breakdown by Facility",
  description: "An overview of the provider breakdown by facility.",
  header: UsageByFacilityHeader,
  content: UsageByFacilityContent,
};

function PayerenrollmentHeader() {
  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "0px 10px 0 18px",
      }}
    >
      <h3>{PayerenrollmentStatus.title}</h3>
    </div>
  );
}

function UsageByFacilityHeader() {
  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "0px 10px 0 18px",
      }}
    >
      <h3>{UsageByFacilityStatus.title}</h3>
      <p>{UsageByFacilityStatus.description}</p>
    </div>
  );
}

function PayerenrollmentContent() {
  const { data } = useGetDashboardPayerEnrollmentStatsQuery();

  return (
    <div style={{ padding: "24px" }}>
      <BarChart
        series={[
          {
            title: "Payers count",
            type: "bar",
            data:
              Object.entries(data ?? []).map((k, index) => {
                return {
                  x: k?.at(0) as string,
                  y: parseInt((k?.at(1) as string) ?? 0) ?? 0,
                };
              }) ?? [],
            color: "#2B6BE6",
          },
        ]}
        ariaLabel="Single data series line chart"
        height={280}
        xTitle="Enrollment Status"
        yTitle="Enrollments count"
        hideFilter
        hideLegend
      />
    </div>
  );
}

function UsageByFacilityContent() {
  const { data } = useGetAllDashboardQuery();

  const transformedData = useMemo(() => {
    if (!data?.servicesBreakdownByFacility) return [];
    const allStats = data.servicesBreakdownByFacility.flatMap((service) =>
      service.facilityStats.map((facility) => ({
        facilityName: facility.facilityName,
        count: facility.count,
      }))
    );

    const aggregatedStats = allStats.reduce((acc, { facilityName, count }) => {
      acc[facilityName] = (acc[facilityName] || 0) + count;
      return acc;
    }, {} as Record<string, number>);

    return Object.entries(aggregatedStats).map(([facilityName, count]) => ({
      x: facilityName,
      y: count,
    }));
  }, [data]);

  return (
    <div style={{ padding: "24px" }}>
      <BarChart
        series={[
          {
            title: "Facility Counts",
            type: "bar",
            data: transformedData,
            color: "#2B6BE6",
          },
        ]}
        ariaLabel="Bar chart showing facility counts"
        height={280}
        xTitle="Facility Name"
        yTitle="Count"
        hideFilter
        hideLegend
      />
    </div>
  );
}
