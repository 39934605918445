import { useContext, useState } from "react";
import { Button, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import {
  DeleteOutlined,
  DownOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useLocalStorage } from "../common/localStorage";
import {
  MANAGEADMININVITED_CONTENT_DISPLAY_OPTIONS,
  MANAGEADMININVITED_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./invited-table-config";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";
import { useSelector } from "react-redux";
import { ApplicationState } from "../redux/store";
import DeleteAlertModal from "../common/Modal/delete-alert-modal";
import { InvitationType } from "../redux/api/manageadmin/types";
import useInvitationsFilter from "../table-filters/invitations-filters";
import { useDeleteInviteMutation } from "../redux/api/manageadmin/invitation";

export default function InvitedList() {
  const [selectedRows, setSelectedRows] = useState<InvitationType[]>();

  const [deleteInvitations] = useDeleteInviteMutation();

  const {
    TableFilters,
    filteredInvitations = [],
    UseQueryHookResult: { refetch, isFetching, isLoading },
  } = useInvitationsFilter();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };

  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("member.delete") ?? false)
  );

  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Invitated-Table-Preferences",
    MANAGEADMININVITED_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    filteredInvitations,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <div style={{ padding: "0" }}>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          const selectedItem = detail?.selectedItems[0];
          setSelectedRows(selectedItem ? [selectedItem] : []);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows?.at(0)?.id === selectedItem.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "id",
            header: <div>Invitation ID</div>,
            cell: (item) => <span>{item.id}</span>,
          },
          {
            id: "inviterName",
            header: <div>Inviter Name</div>,
            cell: (item) => <span>{`${item?.inviterName}`}</span>,
          },
          {
            id: "status",
            header: <div>Status</div>,
            cell: (item) => <span>{`${item?.status}`}</span>,
          },
          {
            id: "email",
            header: <div>Email</div>,
            cell: (item) => <span>{`${item?.email}`}</span>,
          },
          {
            id: "role",
            header: <div>Role</div>,
            cell: (item) => <span>{`${item?.role?.name}`}</span>,
          },
          {
            id: "inviterEmail",
            header: <div>Inviter Email</div>,
            cell: (item) => <span>{`${item?.inviterEmail}`}</span>,
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={MANAGEADMININVITED_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        filter={TableFilters}
        header={
          <div style={{ marginTop: "8px", marginBottom: "12px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => {
                            handleOpenDeleteModal();
                          },
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </Space>
              }
            >
              <Typography.Title level={3} style={{ marginTop: "0" }}>
                Invitations{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${filteredInvitations.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteInvitations({
              invitedId: String(selectedRows?.at(0)?.id),
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `invitation deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Invitation"
        content={
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text>Email</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRows?.at(0)?.email}
              </Typography.Text>
            </div>
          </>
        }
        description="Are you sure you want to delete this invitation?"
      />
    </div>
  );
}
