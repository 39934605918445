import { ReactNode } from "react";
import { useSelector } from "react-redux";
import PermissionDenied from "../components/results/permission-denied";
import { ApplicationState } from "../redux/store";

type PermissionGaurdProps = {
  permissionKey: string;
  children: ReactNode;
};
export default function PermissionGaurd({
  permissionKey,
  children,
}: PermissionGaurdProps) {
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes(permissionKey) ?? false)
  );
  if (!hasPermission) return <PermissionDenied />;
  return <>{children}</>;
}
