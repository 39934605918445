import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllPayerQuery } from "../redux/api/payers/payers";
import { Payers } from "../redux/api/payers/types";
import { USStatesList } from "../common/constants";

export default function usePayerFilter(items?: Payers[]) {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
    const [payerNameFiltervalue, setPayerNameFiltervalue] = useState<
        string | undefined
    >(undefined);
    const [payerIdFiltervalue, setPayerIdFiltervalue] = useState<
        string | undefined
    >(undefined);
    const [stateFiltervalue, setStateFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: payers = [],
        refetch,
        isLoading: payersLoading,
        isFetching,
    } = useGetAllPayerQuery();

    const [sortingDetails, setSortingDetails] = useState<{
        sortingField?: string;
        isDescending?: boolean;
    }>({ sortingField: "id", isDescending: false });

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);

    const [filteredPayers, setFilteredPayers] = useState<Payers[]>(items ?? payers)
    useEffect(() => {
        setFilteredPayers(items ?? payers);
    }, [payers, items]);

    useEffect(() => {
        let temp_filtered_payers = !!items ? [...items] : [...payers];
        if (!!payerNameFiltervalue && selectedFilterkey === "name") {
            temp_filtered_payers = temp_filtered_payers?.filter(
                (payer) =>
                    payer?.name
                        ?.toLowerCase()
                        .includes(payerNameFiltervalue?.toLowerCase())
            );
        }
        else if (!!payerIdFiltervalue && selectedFilterkey === "payerId")
            temp_filtered_payers = temp_filtered_payers?.filter(
                (payer) =>
                    payer?.payerId
                        ?.toLowerCase()
                        .includes(payerIdFiltervalue?.toLowerCase())
            );
        else if (!!stateFiltervalue && selectedFilterkey === "state")
            temp_filtered_payers = temp_filtered_payers?.filter(
                (payer) =>
                    payer?.payerAddress?.state
                        ?.toLowerCase()
                        .includes(stateFiltervalue?.toLowerCase())
            );
        setFilteredPayers(temp_filtered_payers);
    }, [payerNameFiltervalue, payerIdFiltervalue, stateFiltervalue, payers, items]);

    useEffect(() => {
        setPayerIdFiltervalue(undefined);
        setPayerNameFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space direction="vertical">
            <Space size="small">
                <Select
                    value={selectedFilterkey}
                    onChange={(value) => {
                        setSelectedFilterKey(value);
                    }}
                    style={{ width: "140px" }}
                    options={[
                        { value: "name", label: "Payer Name" },
                        { value: "payerId", label: "Payer ID" },
                        { value: "state", label: "State" },
                    ]}
                />
                {selectedFilterkey === "name" && (
                    <Input
                        onChange={(event) => {
                            setPayerNameFiltervalue(event?.target?.value);
                        }}
                        value={payerNameFiltervalue}
                        style={{
                            width: "320px",
                        }}
                        placeholder="Find Name"
                        prefix={<SearchOutlined />}
                    />
                )}
                {selectedFilterkey === "payerId" && (
                    <Input
                        onChange={(event) => {
                            setPayerIdFiltervalue(event?.target?.value);
                        }}
                        value={payerIdFiltervalue}
                        style={{
                            width: "320px",
                        }}
                        placeholder="Find Payer ID"
                        prefix={<SearchOutlined />}
                    />
                )}
                {selectedFilterkey === "state" && (
                    <Select
                        allowClear={true}
                        showSearch={true}
                        optionFilterProp="label"
                        value={stateFiltervalue}
                        placeholder="Select State"
                        onChange={(value) => {
                            setStateFiltervalue(value);
                        }}
                        style={{ width: "320px" }}
                        options={USStatesList?.map((state) => ({
                            label: state?.value,
                            searchValue: state?.value,
                            value: state?.value + "",
                        }))}
                    />
                )}
            </Space>
            <div>
                {!!stateFiltervalue && (
                    <Tag
                        closeIcon
                        onClose={() => {
                            setStateFiltervalue(undefined);
                        }}
                    >
                        <span>
                            State ={" "}
                            {
                                USStatesList?.find(
                                    (state) => state?.value + "" === stateFiltervalue + ""
                                )?.value
                            }
                        </span>
                    </Tag>
                )}
            </div>
        </Space>
    );

    return {
        TableFilters,
        filteredPayers: filteredPayers,
        UseQueryHookResult: {
            refetch,
            isFetching,
            isLoading: payersLoading,
            pageNumber,
            setPageNumber,
            pageSize,
            setPageSize,
            sortingDetails,
            setSortingDetails,
        },
    };
}
