import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import {
  useGetAllContractsQuery,
  useGetAllContractTypesQuery,
} from "../redux/api/contracts/contract";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";

export default function useContractFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("name");

  const [contractTypeFilterValue, setContractTypeFilterValue] = useState<
    string | undefined
  >(undefined);

  const [facilityFilterValue, setFacilityFilterValue] = useState<
    string | undefined
  >(undefined);

  const [nameFilterValue, setNameFilterValue] = useState<string | undefined>(
    undefined
  );

  const { data: contractTypes = [], isLoading: contractTypesLoading } =
    useGetAllContractTypesQuery();

  const { data: facilities = [], isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();

  const {
    data: contracts = [],
    refetch,
    isLoading: contractsLoading,
    isFetching,
  } = useGetAllContractsQuery();

  const filteredContracts = useMemo(() => {
    if (contractsLoading || contractTypesLoading || facilitiesLoading)
      return [];
    return contracts.filter((contract) => {
      let matches = true;
      if (facilityFilterValue) {
        matches = matches && contract?.facility?.id === facilityFilterValue;
      }
      if (contractTypeFilterValue) {
        matches =
          matches && contract.contractType.id === contractTypeFilterValue;
      }
      if (nameFilterValue) {
        const nameWords = nameFilterValue.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) =>
            contract.title.toLowerCase().includes(word)
          );
      }
      return matches;
    });
  }, [
    contracts,
    facilityFilterValue,
    contractTypeFilterValue,
    nameFilterValue,
    contractsLoading,
    contractTypesLoading,
    facilitiesLoading,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "name", label: "Name" },
            { value: "facility", label: "Facility" },
            { value: "contracttype", label: "Contract Type" },
          ]}
        />
        {selectedFilterKey === "name" && (
          <Input
            onChange={(event) => setNameFilterValue(event.target.value)}
            value={nameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "facility" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={facilityFilterValue}
            placeholder="Select facility"
            onChange={(value) => setFacilityFilterValue(value)}
            style={{ width: "320px" }}
            options={facilities.map((facility) => ({
              label: facility.name,
              value: facility.id,
            }))}
          />
        )}
        {selectedFilterKey === "contracttype" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={contractTypeFilterValue}
            placeholder="Select Contract Type"
            onChange={(value) => setContractTypeFilterValue(value)}
            style={{ width: "320px" }}
            options={contractTypes.map((type) => ({
              label: type.type,
              value: type.id,
            }))}
          />
        )}
      </Space>
      <div>
        {facilityFilterValue && (
          <Tag closable onClose={() => setFacilityFilterValue(undefined)}>
            Facility:{" "}
            {
              facilities.find((facility) => facility.id === facilityFilterValue)
                ?.name
            }
          </Tag>
        )}
        {contractTypeFilterValue && (
          <Tag closable onClose={() => setContractTypeFilterValue(undefined)}>
            Contract Type:{" "}
            {
              contractTypes.find((type) => type.id === contractTypeFilterValue)
                ?.type
            }
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredContracts,
    UseQueryHookResult: {
      refetch,
      isLoading: contractsLoading,
      isFetching,
    },
  };
}
