import {
  Col,
  Divider,
  Flex,
  Form,
  Row,
  Typography,
  Space,
  Breadcrumb,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import IncredableButton from "../../components/button";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import {
  useGetAllContractTypesQuery,
  useGetContractQuery,
  useUpdateContractMutation,
} from "../../redux/api/contracts/contract";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { alertReminderOptions } from "../../common/constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import RHFPhoneNumber from "../../components/RHF/RHFPhonenumber";

type FormInputType = {
  title: string;
  executionDate: string;
  expirationDate: string;
  lastRenewalDate: string;
  description: string;
  primaryContact: string;
  attachment: string;
  facility: string;
  contractType: string;
  entityName: string;
  entityPhone: string;
  entityEmail: string;
  alertDays: string;
};

export default function EditContract() {
  const methods = useForm<FormInputType>();
  const { data: facilities } = useGetFacilitiesQuery();
  const { data: member } = useGetAllMembersQuery();
  const { data: contractType } = useGetAllContractTypesQuery();
  const { contractId } = useParams();
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("contract.edit") ?? false)
  );
  const {
    data: contract,
    isLoading,
    isFetching,
  } = useGetContractQuery(
    !!contractId
      ? {
        contractId: contractId,
      }
      : skipToken
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (contract) {
      methods.setValue("title", contract.title);
      methods.setValue("expirationDate", contract.expirationDate);
      methods.setValue("executionDate", contract.executionDate);
      methods.setValue("lastRenewalDate", contract.lastRenewalDate);
      methods.setValue("primaryContact", contract?.primaryContact?.id);
      methods.setValue("facility", contract?.facility?.id);
      methods.setValue("contractType", contract?.contractType?.id);
      methods.setValue("alertDays", contract.alertDays);
      methods.setValue("entityName", contract.entityName);
      methods.setValue("entityEmail", contract.entityEmail);
      methods.setValue("entityPhone", contract.entityPhone);
      methods.setValue("description", contract?.description);
    }
  }, [methods, contract]);

  const [updateContract] = useUpdateContractMutation();

  return (
    <IncredableContent
      spinning={isLoading || isFetching}
      breadcrumbProps={{
        items: [
          {
            title: <NavLink to="/contract">Contracts</NavLink>,
          },
          {
            title: contract?.title,
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addprovider"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (!!contractId) {
              spinnerContext?.showSpinner();
              await updateContract({
                id: contractId,
                title: data?.title,
                executionDate: data?.executionDate,
                expirationDate: data?.expirationDate,
                lastRenewalDate: data?.lastRenewalDate,
                description: data.description,
                primaryContact: { id: data.primaryContact },
                facility: { id: data.facility },
                contractType: {
                  id: data.contractType,
                  type: data.contractType,
                },
                entityName: data.entityName,
                entityPhone: data.entityPhone,
                entityEmail: data.entityEmail,
                alertDays: data.alertDays,
              });
              spinnerContext?.hidespinner();
              toastContext?.openInfoNotification({
                message: `Contract saved successfully`,
                placement: "topRight",
              });
              navigate(-1);
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Edit Contract
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton disabled={!hasPermission} type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Title",
                }}
                inputProps={{}}
                controllerProps={{
                  name: "title",
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Contract Type" }}
                controllerProps={{
                  name: `contractType`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: contractType?.map((type) => {
                    return {
                      label: type?.type,
                      value: type?.id,
                    };
                  }),
                  onModifyClick: () => {
                    navigate("/contracttype");
                  },
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Facility" }}
                controllerProps={{
                  name: `facility`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: facilities?.map((name) => {
                    return {
                      label: name?.name,
                      value: name?.id,
                    };
                  }),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Execution Date",
                }}
                controllerProps={{
                  name: `executionDate`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Expiration Date",
                }}
                controllerProps={{
                  name: `expirationDate`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Last Renewal Date",
                }}
                controllerProps={{
                  name: `lastRenewalDate`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Entity Name",
                }}
                inputProps={{}}
                controllerProps={{
                  name: `entityName`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFPhoneNumber
                formItemProps={{
                  label: "Entity Phone",
                }}
                inputProps={{}}
                controllerProps={{
                  name: `entityPhone`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Entity Email",
                }}
                inputProps={{}}
                controllerProps={{
                  name: `entityEmail`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Primary Contact" }}
                controllerProps={{
                  name: `primaryContact`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Select contact",
                  options: member?.map((name) => {
                    return {
                      label: `${name?.lastName} ${name?.firstName}`,
                      value: name?.id,
                    };
                  }),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Follow Up Date" }}
                controllerProps={{
                  name: `alertDays`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Select days",
                  options: alertReminderOptions?.map((alert) => {
                    return {
                      label: alert?.label,
                      value: alert?.value,
                    };
                  }),
                }}
              />
            </Col>
          </Row>
          <Col span={24}>
            <RHFTextArea
              formItemProps={{ label: "Notes" }}
              controllerProps={{ name: "description" }}
            />
          </Col>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
