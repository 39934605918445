import {
  Col,
  Divider,
  Flex,
  Form,
  Layout,
  Row,
  Space,
  theme,
  Typography,
} from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import IncredableButton from "../../components/button";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { ToastContext } from "../../context/toast/incredable-toast";
import { SpinnerContext } from "../../context/spinner/spinner";
import IncredableContent from "../../components/incredable-content";
import {
  useGetAllAffiliationTypeQuery,
  useUpdateAffiliationTypeMutation,
} from "../../redux/api/affiliationType/affiliation";
import { AffiliationTypeRequest } from "../../redux/api/affiliationType/types";

const { useToken } = theme;

type FormInputType = AffiliationTypeRequest;
export default function EditAffiliationType() {
  const { affiliationtypeId } = useParams();
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: affiliationType } = useGetAllAffiliationTypeQuery();
  const type = affiliationType?.find((name) => name?.id == affiliationtypeId);
  const [updateAffiliationType] = useUpdateAffiliationTypeMutation();

  useEffect(() => {
    if (type) {
      methods.setValue("name", type?.name ?? "");
      methods.setValue("description", type?.description ?? "");
    }
  }, [type]);

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          {
            title: <NavLink to="/affiliationtype">Affiliation Type</NavLink>,
          },
          {
            title: "Edit",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="editaffiliationtype"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            if (!!affiliationtypeId) {
              spinnerContext?.showSpinner();
              await updateAffiliationType({
                ...data,
                id: affiliationtypeId,
              });
            }
            toastContext?.openInfoNotification({
              message: `Affiliation Type saved successfully`,
              placement: "topRight",
            });
            spinnerContext?.hidespinner();
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Affiliation Type
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="affiliationtype" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={12}>
                          <RHFTextField
                            formItemProps={{
                              label: "Description",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `description`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
