import { ConfigProvider, Spin } from "antd";
import { createContext, useState } from "react";
export type SpinnerContextType = {
  spinning: boolean;
  showSpinner: () => void;
  hidespinner: () => void;
};

const SpinnerContext = createContext<SpinnerContextType | null>(null);

interface IProps {
  children: React.ReactNode;
}
export const IncredableSpin = ({ spinning }: { spinning: boolean }) => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: "#521262",
        fontFamily: "Open Sans", //Raleway
        colorInfo: "#521262",
        fontSize: 14,
      },
      components: {
        Spin: {
          colorWhite: "#521262",
        },
      },
    }}
  >
    <Spin spinning={spinning} fullscreen></Spin>
  </ConfigProvider>
);

export default function IncredableSpinner({ children }: IProps) {
  const [spinning, setSpinning] = useState(false);

  const showSpinner = () => {
    setSpinning(true);
    setInterval(() => {
      setSpinning(false);
    }, 2900);
  };

  const hidespinner = () => {
    setSpinning(false);
  };

  return (
    <SpinnerContext.Provider
      value={{
        spinning,
        showSpinner,
        hidespinner,
      }}
    >
      <IncredableSpin spinning={spinning} />
      {children}
    </SpinnerContext.Provider>
  );
}

export { SpinnerContext };
