import {
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    Pagination,
    Table,
    TableProps,
} from "@cloudscape-design/components";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { PayerGroup } from "../../../redux/api/payergroup/types";
import { useUpdateAssociatedNetworkMutation } from "../../../redux/api/payers/payers";
import usePayerGroupFilter from "../../../table-filters/payergroup-filters";
import { Button, Modal, Space } from "antd";

export default function AssociatedNetworksModals({
    isAssociatedNetworksModalOpen,
    setAssociatedNetworksModalOpen,
    associatedNetworks,
    setAssociatedNetworks,
}: {
    isAssociatedNetworksModalOpen: boolean;
    setAssociatedNetworksModalOpen: Dispatch<SetStateAction<boolean>>;
    associatedNetworks: PayerGroup[];
    setAssociatedNetworks: Dispatch<SetStateAction<PayerGroup[]>>;
}) {
    const { payerId } = useParams();
    const [updateAssociatedNetwork] = useUpdateAssociatedNetworkMutation();

    const { TableFilters, filteredPayerGroup, UseQueryHookResult } =
        usePayerGroupFilter();

    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences<any>
    >({
        pageSize: 10,
        wrapLines: true,
    });

    const { items, collectionProps, paginationProps } = useCollection(
        filteredPayerGroup,
        {
            pagination: {},
        },
    );
    const [selectedPayerGroups, setSelectedPayerGroups] = useState<PayerGroup[]>(
        [],
    );

    const tableProps: TableProps<PayerGroup> = useMemo(() => {
        return {
            items,
            ...collectionProps,
            pagination: <Pagination {...paginationProps} />,
            preferences: (
                <CollectionPreferences
                    onConfirm={({ detail }) => {
                        if (!!detail) setPreferences(detail);
                    }}
                    preferences={preferences}
                    pageSizePreference={{
                        options: [
                            { value: 10, label: "10 items" },
                            { value: 30, label: "30 items" },
                            { value: 50, label: "50 items" },
                        ],
                    }}
                />
            ),
            filter: TableFilters,
            variant: "borderless",
            loading: UseQueryHookResult?.isLoading || UseQueryHookResult?.isFetching,
            loadingText: "Loading...",
            selectionType: "multi",
            resizableColumns: false,
            stickyHeader: true,
            onSelectionChange: (selectionChangeDetail) => {
                setSelectedPayerGroups(selectionChangeDetail.detail.selectedItems);
            },
            onRowClick: ({ detail }) => {
                const temp = selectedPayerGroups;
                if (
                    !detail.item ||
                    !!associatedNetworks
                        ?.map((associatedNetwork) => associatedNetwork?.id + "")
                        ?.includes(detail.item.id + "")
                )
                    return;
                if (temp?.includes(detail.item))
                    setSelectedPayerGroups(temp.filter((item) => item !== detail.item));
                else setSelectedPayerGroups([detail.item, ...temp]);
            },
            selectedItems: selectedPayerGroups,
            trackBy: (item) => "" + item.id,
            columnDisplay: [
                { id: "name", visible: true },
                { id: "groupCode", visible: true },
                { id: "description", visible: true },
            ],
            isItemDisabled: (item) => {
                return associatedNetworks
                    ?.map((associatedNetwork) => associatedNetwork?.id + "")
                    ?.includes(item?.id + "");
            },
            columnDefinitions: [
                {
                    id: "id",
                    header: <div>ID</div>,
                    cell: (item) => <span>{item.id}</span>,
                    visibility: false,
                },
                {
                    id: "name",
                    header: <div>Network Name</div>,
                    cell: (item) => (<span>{item?.name ?? "-"}</span>),
                    sortingField: "name",
                },
                {
                    id: "groupCode",
                    header: "Network ID",
                    cell: (item) => <span>{item.groupCode || "-"}</span>,
                    sortingField: "groupCode",
                    isRowHeader: true,
                },
                {
                    id: "description",
                    header: <span>Notes</span>,
                    cell: (item) => <div>{item?.description ?? "-"}</div>,
                },
            ],
        };
    }, [items, selectedPayerGroups]);

    return (
        <Modal
            visible={isAssociatedNetworksModalOpen}
            title={
                <Header counter={`(${filteredPayerGroup.length})`}>Networks</Header>
            }
            onCancel={() => {
                setAssociatedNetworksModalOpen(false);
            }}
            footer={
                <Space direction="horizontal">
                    <Button
                        onClick={() => {
                            setAssociatedNetworks([]);
                            setAssociatedNetworksModalOpen(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        onClick={async () => {
                            try {
                                if (!!selectedPayerGroups && !!payerId) {
                                    const networksToUpdate =
                                        selectedPayerGroups?.map(
                                            (selectedPayerGroup) => selectedPayerGroup?.id + "",
                                        ) || [];

                                    await updateAssociatedNetwork({
                                        payerId: payerId,
                                        networks: networksToUpdate,
                                    });

                                    setAssociatedNetworks([
                                        ...associatedNetworks,
                                        ...selectedPayerGroups,
                                    ]);
                                    setSelectedPayerGroups([]);
                                    setAssociatedNetworksModalOpen(false);
                                }
                            } catch (error) {
                                console.error("Error updating admin payerGroups:", error);
                            }
                        }}
                    >
                        Submit
                    </Button>
                </Space>
            }
            width={800}
            style={{ maxHeight: "90vh" }}
            styles={{
                body: { overflowY: "auto", maxHeight: "80vh" },
            }}
        >
            <div>
                <Table {...tableProps} />
            </div>
        </Modal>
    );
}

