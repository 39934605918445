import { Attachment } from "../redux/api/document/types";
import { Card, UploadProps } from "antd";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import IncredableButton from "./button";
import PDFTronViewer from "./pdftron-viewer/pdftron-viewer";

type AttachmentViewerProps = {
  attachment?: Attachment;
  file?: File;
  hideHeader?: boolean;
  maxHeight?: boolean;
  onFileChange?: (file?: File) => void;
};

function AttachmentViewer({
  attachment,
  file,
  onFileChange,
}: AttachmentViewerProps) {
  const handleChange: UploadProps["onChange"] = (info) => {
    !!onFileChange && onFileChange(info.file?.originFileObj as File);
  };

  if (!!file)
    return <PDFTronViewer attachmentFile={file} replaceFile={onFileChange} />;
  else if (!!attachment && !!attachment.key) {
    return (
      <div>
        <PDFTronViewer
          attachmentKey={attachment.key}
          replaceFile={onFileChange}
        />
      </div>
    );
  } else
    return (
      <Card
        title="Supporting Documents"
        style={{
          borderRadius: "16px",
          boxShadow: "0px 10px 25px 0px #5911920D",
        }}
      >
        <Dragger
          multiple={false}
          onChange={handleChange}
          showUploadList={false}
          style={{
            border: "1px dashed #598BEB",
            background: "#F6FAFF",
            height: "200px",
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined style={{ fontSize: "32px", color: "#7C4095" }} />
          </p>
          <p className="ant-upload-text" style={{ fontWeight: "600" }}>
            Drag and Drop your file(s) to start uploading
          </p>
          <p className="ant-upload-hint">OR</p>
          <IncredableButton
            style={{
              background: "#598BEB",
              color: "#FFF",
              borderColor: "#598BEB",
            }}
          >
            Select a File
          </IncredableButton>
        </Dragger>
      </Card>
    );
}

export default AttachmentViewer;
