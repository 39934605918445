import { useNavigate, useParams } from "react-router-dom";
import {
  useDeleteCredentialTemplateMutation,
  useGetCredentialTemplateQuery,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/query";
import { Card, List, Space, Typography } from "antd";
import IncredableButton from "../../../components/button";
import { useContext, useState } from "react";
import DeleteAlertModal from "../../../common/Modal/delete-alert-modal";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import EditCredentialTemplateModal from "../modals/editcredentialtemplate-modal";
import IncredableContent from "../../../components/incredable-content";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../redux/store";
import CategoriesListV2 from "./categories-list-v2";
import FormsListV2 from "./forms-list-v2";

export default function EditCredentialTemplateDetails() {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const { data: credentialTemplate } = useGetCredentialTemplateQuery(
    templateId ? { credentialTemplateId: templateId } : skipToken
  );
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const [editTemplateModalVisible, setEditTemplateModalVisible] =
    useState<boolean>(false);
  const handleOpenDeleteModal = () => {
    setDeleteModalOpen(true);
  };
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("credentialpackage.delete") ?? false)
  );
  const [deleteCredentialTemplate] = useDeleteCredentialTemplateMutation();
  const [activeTabKey, setActiveTabKey] = useState<string>("forms");
  const onTabChange = (key: string) => {
    setActiveTabKey(key);
  };

  const templateData = [
    {
      title: "Name",
      description: credentialTemplate?.name,
    },
    {
      title: "Categories Count",
      description: credentialTemplate?.categories?.length,
    },
    {
      title: "License Type",
      description: credentialTemplate?.licenseType?.name,
    },
  ];
  const tabList = [
    {
      key: "forms",
      label: <span style={{ color: "rgb(88 82 82)" }}>Forms</span>,
    },
    {
      key: "categories",
      label: <span style={{ color: "rgb(88 82 82)" }}>Categories</span>,
    },
  ];
  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          {
            title: <a href="/credentialtemplates">Credential Templates</a>,
          },
          {
            title: "Edit",
          },
        ],
      }}
    >
      <Space direction="vertical" size="large" style={{ display: "flex" }}>
        <Card
          title={
            <Typography.Title
              style={{ paddingTop: "0", marginTop: "0" }}
              level={4}
            >
              {`Credential Template Details `}
            </Typography.Title>
          }
          extra={
            <Space>
              <IncredableButton
                disabled={!hasDeletePermission}
                secondary
                onClick={() => {
                  handleOpenDeleteModal();
                }}
              >
                Delete
              </IncredableButton>
              <IncredableButton
                type="primary"
                disabled={!hasPermission}
                onClick={() => setEditTemplateModalVisible(true)}
              >
                Edit
              </IncredableButton>
            </Space>
          }
        >
          <List
            itemLayout="vertical"
            grid={{ column: 3 }}
            dataSource={templateData}
            renderItem={(item) => (
              <List.Item>
                <Typography.Title level={5}>{item.title}</Typography.Title>
                <Typography.Text>{item.description}</Typography.Text>
              </List.Item>
            )}
          />
        </Card>
        <Card
          style={{ width: "100%" }}
          tabList={tabList}
          activeTabKey={activeTabKey}
          onTabChange={onTabChange}
          tabProps={{
            size: "middle",
          }}
        >
          {activeTabKey === "forms" ? <FormsListV2 /> : <CategoriesListV2 />}
        </Card>
      </Space>
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (templateId) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteCredentialTemplate({
              credentialTemplateId: templateId,
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Credential Template deleted successfully`,
              placement: "topRight",
            });
          }
          navigate(-1);
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Credential Template"
        content={
          <Typography.Text>
            Credential Template Name:{" "}
            <Typography.Text strong>{credentialTemplate?.name}</Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this credential template?"
      />
      <EditCredentialTemplateModal
        closeModal={() => {
          setEditTemplateModalVisible(false);
        }}
        visible={editTemplateModalVisible}
      />
    </IncredableContent>
  );
}
