import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllCategoryQuery } from "../redux/api/category/category";

export default function useCategoryFilter() {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
  const [selectedFiltervalue, setSelectedFiltervalue] = useState<
    string | undefined
  >(undefined);

  const {
    data: category = [],
    refetch,
    isLoading: categoryLoading,
    isFetching,
  } = useGetAllCategoryQuery();

  const filteredCategory = useMemo(() => {
    if (!!selectedFiltervalue) {
      if (selectedFilterkey === "name")
        return category?.filter((category) =>
          category?.name
            ?.toLowerCase()
            .includes(selectedFiltervalue?.toLowerCase())
        );
    }
    return category;
  }, [selectedFiltervalue, category]);

  useEffect(() => {
    setSelectedFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space size="small">
      <Select
        value={selectedFilterkey}
        onChange={(value) => {
          setSelectedFilterKey(value);
        }}
        style={{ width: "140px" }}
        options={[{ value: "name", label: "Name" }]}
      />
      {selectedFilterkey === "name" && (
        <Input
          onChange={(event) => {
            setSelectedFiltervalue(event?.target?.value);
          }}
          value={selectedFiltervalue}
          style={{
            width: "320px",
          }}
          placeholder="Find Name"
          prefix={<SearchOutlined />}
        />
      )}
    </Space>
  );

  return {
    TableFilters,
    filteredCategory,
    UseQueryHookResult: {
      refetch,
      isLoading: categoryLoading,
      isFetching,
    },
  };
}
