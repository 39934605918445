import dashboard from "../images/dashboard.svg";
import manage from "../images/manage.svg";
import contracts from "../images/contracts.svg";
import facilities from "../images/facilities.svg";
import privileges from "../images/privileges.svg";
import credentialPackages from "../images/credential-packages.svg";
import providers from "../images/providers.svg";
import reports from "../images/reports.svg";
import workFlows from "../images/workFlows.svg";
import safe from "../images/safe.svg";
import payerEnrollment from "../images/payerEnrollment.svg";
import eSignature from "../images/E-signature.svg";
import readyForm from "../images/readyForm.svg";
import tasks from "../images/task.svg";
import logo from "../images/logo.svg";
import responsiveLogo from "../images/responsiveLogo.svg";
import navButtonLeft from "../images/Button - left.svg";
import navButtonRight from "../images/Button - right.svg";
import navBackground from "../images/nav-background.svg";
import profilePic from "../images/profilePic.svg";
import tick from "../images/tick.svg";
import downArrow from "../images/downArrow.svg";
import comment from "../images/comments.svg";
import commentColorIcon from "../images/comments_p.svg";
import details from "../images/Details.svg";
import detailColorIcon from "../images/details_p.svg";
import version from "../images/version.svg";
import versionColorIcon from "../images/version_p.svg";
import audit from "../images/audit.svg";
import auditColorIcon from "../images/audit_p.svg";
import calendar from "../images/Calendar.svg";
import dot from "../images/blue_dot.svg";
import userLogo from "../images/user-logo.png";
import trash from "../images/trash.svg";
import edit from "../images/Edit.svg";
import replaceIcon from "../images/replace.svg";
import downloadIcon from "../images/download.svg";
import vector from "../images/Vector.svg";
import overview from "../images/overview.svg";
import personalInfo from "../images/personalInfo.svg";
import professionalInfo from "../images/professionalInfo.svg";
import facility from "../images/facility.svg";
import affiliation from "../images/affiliation.svg";
import dea from "../images/DEA.svg";
import education from "../images/education.svg";
import liability from "../images/liability.svg";
import license from "../images/license.svg";
import peerRef from "../images/peerRef.svg";
import specialityInfo from "../images/specialityInfo.svg";
import thirdParty from "../images/thirdParty.svg";
import workHistory from "../images/workHistory.svg";
import enrollPayers from "../images/enrolled.svg";
import edit2 from "../images/edit2.svg";
import userWithKey from "../images/userWithKey.svg";
import support from "../images/Support.svg";
import setting from "../images/Settings copy.svg";
import userIcon from "../images/User_02.svg";
import userGroup from "../images/Users_Group.svg";
import dangerDot from "../images/_Dot.svg";
import successDot from "../images/success_Dot.svg";
import warningDot from "../images/danger_Dot.svg";
import activeProvider from "../images/active_provider_barchart.svg";
import documents from "../images/document_dot_barchart.svg";
import documentExpiring from "../images/document_expiring_barchart.svg";
import documentExpired from "../images/document_expired_barchart.svg";
import safeExpired from "../images/expired.svg";
import safeExpiring from "../images/expiring.svg";
import safeTotleDoc from "../images/total doc.svg";
import pdfIcon from "../images/PDf Icon.svg";
import jpgIcon from "../images/JPG icon.svg";
import pngIcon from "../images/PNG icon.svg";
import resendSign from "../images/resendSign.svg";
import voidSign from "../images/voidSign.svg";

export const Images = {
  dashboard,
  manage,
  contracts,
  facilities,
  privileges,
  credentialPackages,
  providers,
  reports,
  workFlows,
  safe,
  payerEnrollment,
  eSignature,
  readyForm,
  tasks,
  logo,
  responsiveLogo,
  navButtonRight,
  navButtonLeft,
  navBackground,
  profilePic,
  tick,
  downArrow,
  comment,
  commentColorIcon,
  details,
  detailColorIcon,
  version,
  versionColorIcon,
  audit,
  auditColorIcon,
  calendar,
  dot,
  userLogo,
  trash,
  edit,
  replaceIcon,
  downloadIcon,
  vector,
  overview,
  personalInfo,
  professionalInfo,
  facility,
  affiliation,
  dea,
  education,
  liability,
  license,
  peerRef,
  specialityInfo,
  thirdParty,
  workHistory,
  enrollPayers,
  edit2,
  userWithKey,
  support,
  setting,
  userIcon,
  userGroup,
  dangerDot,
  successDot,
  warningDot,
  activeProvider,
  documents,
  documentExpiring,
  documentExpired,
  safeExpired,
  safeExpiring,
  safeTotleDoc,
  pdfIcon,
  jpgIcon,
  pngIcon,
  resendSign,
  voidSign,
};
