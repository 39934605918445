import { useParams } from "react-router-dom";
import {
  useGetCredentialTemplateQuery,
  useUpdateCredentialTemplateMutation,
} from "../../../redux/api/credentialpackages/credentialpackages";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  Col,
  Divider,
  Flex,
  Form,
  Layout,
  Modal,
  Row,
  Space,
  Typography,
} from "antd";
import IncredableButton from "../../../components/button";
import { CredentialTemplateRequest } from "../../../redux/api/credentialpackages/types";
import { FormProvider, useForm } from "react-hook-form";
import { useEffect } from "react";
import { useGetAllLicenseTypeQuery } from "../../../redux/api/licensetype/licensetype";
import RHFTextField from "../../../components/RHF/RHFTextField";
import RHFSelect from "../../../components/RHF/RHFSelect";

type FormInputType = CredentialTemplateRequest;
export default function EditCredentialTemplateModal({
  visible,
  closeModal,
}: {
  visible: boolean;
  closeModal: VoidFunction;
}) {
  const { templateId } = useParams();
  const methods = useForm<FormInputType>();
  const { data: template } = useGetCredentialTemplateQuery(
    templateId ? { credentialTemplateId: templateId } : skipToken
  );
  const [updateCredentialTemplate] = useUpdateCredentialTemplateMutation();

  const { data: licenseType } = useGetAllLicenseTypeQuery();
  useEffect(() => {
    if (template) {
      methods.setValue("name", template?.name ?? "");
      methods.setValue("licenseTypeId", template?.licenseType?.id ?? "");
    }
  }, [methods, template]);

  return (
    <Modal
      onCancel={closeModal}
      open={visible}
      footer={false}
      centered
      width={600}
    >
      <Layout.Content>
        <FormProvider {...methods}>
          <Form
            name="editcredentialtemplate"
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: FormInputType) => {
              if (!!templateId)
                await updateCredentialTemplate({
                  id: templateId,
                  name: data?.name,
                  licenseTypeId: data?.licenseTypeId,
                });
              closeModal();
            })}
            autoComplete="off"
          >
            <Flex gap="small" wrap justify="space-between">
              <Typography.Text
                strong
                style={{
                  fontSize: "22px",
                  color: "#1D1A1F",
                  lineHeight: "25px",
                  fontWeight: "700",
                }}
              >
                Edit Credential Template
              </Typography.Text>
              <Space>
                <IncredableButton
                  secondary
                  onClick={() => {
                    closeModal();
                  }}
                >
                  Cancel
                </IncredableButton>
                <IncredableButton type="primary" htmlType="submit">
                  Edit
                </IncredableButton>
              </Space>
            </Flex>
            <Divider />
            <Form.List name="credentialtemplate" initialValue={[{}]}>
              {(fields, { add }) => {
                return (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div key={key}>
                        {index > 0 && <Divider />}
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                          <Col span={16}>
                            <RHFTextField
                              formItemProps={{
                                label: "Name",
                              }}
                              inputProps={{}}
                              controllerProps={{
                                name: `name`,
                              }}
                              rules={{ required: "This field is required" }}
                            />
                          </Col>
                          <Col span={16}>
                            <RHFSelect
                              formItemProps={{ label: "License Type" }}
                              controllerProps={{
                                name: `licenseTypeId`,
                              }}
                              selectProps={{
                                allowClear: true,
                                showSearch: true,
                                style: { width: "100%" },
                                placeholder: "Search to Select",
                                options: licenseType?.map((type) => {
                                  return {
                                    label: type?.name,
                                    value: type?.id,
                                  };
                                }),
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </>
                );
              }}
            </Form.List>
          </Form>
        </FormProvider>
      </Layout.Content>
    </Modal>
  );
}
