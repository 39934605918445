import { api } from "..";
import {
  DigitalForm,
  DigitalFormType,
  DigitalFormRequest,
  GetDigitalFormResponse,
  DigitalFormTypeRequest,
} from "./types";

const digitalformsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllDigitalForms: build.query<DigitalForm[], void>({
      query: (arg) => "digitalform/all",
      providesTags: ["DigitalForm"],
    }),

    getDigitalForm: build.query<GetDigitalFormResponse, string>({
      query: (id) => `digitalform/${id}`,
      providesTags: (result, error, id) => [{ type: "DigitalForm", id: id }],
    }),

    createDigitalForm: build.mutation<
      GetDigitalFormResponse,
      DigitalFormRequest
    >({
      query: (body) => {
        return {
          url: `digitalform`,
          method: "POST",
          body: {
            ...body,
            attachment:
              !!body.file && body.file?.size > 0
                ? {
                  name: body.file?.name,
                  size: body.file?.size,
                  contentType: body.file?.type,
                }
                : null,
          },
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    updateDigitalForm: build.mutation<
      GetDigitalFormResponse,
      Pick<DigitalForm, "id"> & Partial<DigitalFormRequest>
    >({
      query: ({ id, ...body }) => {
        return {
          url: `digitalform/${id}`,
          method: "PUT",
          body: {
            ...body,
            attachment:
              !!body.file && body.file?.size > 0
                ? {
                  name: body.file?.name,
                  size: body.file?.size,
                  contentType: body.file?.type,
                }
                : null,
          },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalForm", id: arg.id },
        { type: "DigitalForm" },
      ],
    }),

    disableDigitalForm: build.mutation<void, Pick<DigitalForm, "id">>({
      query: ({ id }) => {
        return {
          url: `digitalform/${id}`,
          method: "PUT",
          body: { disabled: true },
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    enableDigitalForm: build.mutation<void, Pick<DigitalForm, "id">>({
      query: ({ id }) => {
        return {
          url: `digitalform/${id}`,
          method: "PUT",
          body: { disabled: false },
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    deleteDigitalForm: build.mutation<void, Pick<DigitalForm, "id">>({
      query: ({ id }) => {
        return {
          url: `digitalform/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),

    updateDigitalFormAnnotation: build.mutation<
      void,
      Pick<DigitalForm, "id"> & { xfdfString: string }
    >({
      query: ({ id, xfdfString }) => ({
        url: `digitalform/${id}/annotation`,
        method: "POST",
        body: xfdfString,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalForm", id: arg.id },
      ],
    }),

    digitalformSaveToSafe: build.mutation<
      void,
      Pick<DigitalForm, "id"> & {
        xfdfString: string;
        providerId: string;
      }
    >({
      query: ({ id, ...body }) => ({
        url: `signature/digitalform/${id}/save`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: "SignatureRequest", id: arg.id },
      ],
    }),

    uploadDigitalFormTemplate: build.mutation<
      void,
      Pick<DigitalForm, "id"> & { file: File }
    >({
      query({ id, file }) {
        // upload with multipart/form-data
        const formData = new FormData();
        formData.append("file", file);
        return {
          url: `digitalform/${id}/template/upload`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalForm", id: arg.id },
        { type: "DigitalForm" },
      ],
    }),
    getAllDigitalFormTypes: build.query<DigitalFormType[], void>({
      query: () => `digitalform/type/all`,
      providesTags: ["DigitalForm", "DigitalFormType"],
    }),
    saveAllDigitalFormTypes: build.mutation<void, DigitalFormType[]>({
      query: (digitalFormTypes) => {
        return {
          url: "digitalform/type/all",
          method: "POST",
          body: digitalFormTypes,
        };
      },
      invalidatesTags: ["DigitalForm"],
    }),
    saveDigitalFormType: build.mutation<void, DigitalFormTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "digitalform/type",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["DigitalForm", "DigitalFormType"],
    }),
    updateDigitalFormType: build.mutation<
      void,
      { id: string } & Partial<DigitalFormTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `digitalform/type/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "DigitalFormType", id: arg.id },
        { type: "DigitalFormType" },
      ],
    }),
    deleteDigitalFormType: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `digitalform/type/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["DigitalFormType"],
    }),
  }),
});

export const {
  useGetAllDigitalFormsQuery,
  useGetDigitalFormQuery,
  useDigitalformSaveToSafeMutation,
  useUpdateDigitalFormMutation,
  useDisableDigitalFormMutation,
  useEnableDigitalFormMutation,
  useDeleteDigitalFormMutation,
  useCreateDigitalFormMutation,
  useUploadDigitalFormTemplateMutation,
  useUpdateDigitalFormAnnotationMutation,
  useGetAllDigitalFormTypesQuery,
  useSaveAllDigitalFormTypesMutation,
  useUpdateDigitalFormTypeMutation,
  useSaveDigitalFormTypeMutation,
  useDeleteDigitalFormTypeMutation
} = digitalformsApi;
