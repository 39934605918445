import {
  Badge,
  Box,
  BreadcrumbGroup,
  ColumnLayout,
  Container,
  Grid,
  Header,
  Link,
  SpaceBetween,
  Modal,
  StatusIndicator,
} from "@cloudscape-design/components";
import { useGetSignatureRequestQuery } from "../../redux/api/signaturerequest/signaturerequest";
import { useParams } from "react-router-dom";
import {
  RecipientResponse,
  eSignatureResponse,
} from "../../redux/api/signaturerequest/types";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import { PageLoading } from "@ant-design/pro-components";
import { Space } from "antd";
import IncredableDateFormat from "../../common/incredable-date-format";
import PDFTronViewer from "../../components/pdftron-viewer/pdftron-viewer";
import { skipToken } from "@reduxjs/toolkit/query";

export default function SignatureDetails() {
  const { signatureId } = useParams();
  const { data, isLoading } = useGetSignatureRequestQuery(
    signatureId ? { id: signatureId } : skipToken
  );

  if (!data) return <PageLoading />;
  else
    return (
      <div style={{ margin: "12px 24px 12px 24px" }}>
        <SpaceBetween size="l">
          <BreadcrumbGroup
            items={[
              { text: "e-Signatures", href: "/requests" },
              { text: data.subject, href: "#" },
            ]}
          />
          <SpaceBetween size="l">
            <Container header={<Header>Details</Header>}>
              <Details eSignatureResponse={data} />
            </Container>
            <Container header={<Header>Recipients</Header>}>
              {!!data?.recipientGroupByStatus?.completed &&
                data?.recipientGroupByStatus?.completed?.length > 0 && (
                  <>
                    <Divider>Completed</Divider>
                    <SpaceBetween size="xs">
                      {data?.recipientGroupByStatus?.completed?.map(
                        (recipient, index) => {
                          return (
                            <RecipientDetails
                              recipient={recipient}
                              hasDivider={index + 1 !== data.recipients.length}
                            />
                          );
                        }
                      )}
                    </SpaceBetween>
                  </>
                )}
              {!!data?.recipientGroupByStatus?.current && (
                <>
                  <Divider>Current</Divider>
                  <RecipientDetails
                    recipient={data?.recipientGroupByStatus?.current}
                    hasDivider={true}
                  />
                </>
              )}
              {!!data?.recipientGroupByStatus?.pending &&
                data?.recipientGroupByStatus?.pending?.length > 0 && (
                  <>
                    <Divider>Waiting</Divider>
                    <SpaceBetween size="xs">
                      {data?.recipientGroupByStatus?.pending?.map(
                        (recipient, index) => {
                          return (
                            <RecipientDetails
                              recipient={recipient}
                              hasDivider={index + 1 !== data.recipients.length}
                            />
                          );
                        }
                      )}
                    </SpaceBetween>
                  </>
                )}
            </Container>
            <Container header={<Header>Message</Header>}>
              {!!data.message ? data.message : "-"}
            </Container>
          </SpaceBetween>
        </SpaceBetween>
      </div>
    );
}

const Details = ({
  eSignatureResponse,
}: {
  eSignatureResponse: eSignatureResponse;
}) => {
  const [isFilePreviewOpen, OpenFilePreviewModal] = useState<boolean>(false);

  return (
    <ColumnLayout columns={3} variant="text-grid">
      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Status</Box>
          <div>
            {eSignatureResponse?.status === "pending" && (
              <StatusIndicator type="pending">Pending</StatusIndicator>
            )}
            {eSignatureResponse?.status === "completed" && (
              <StatusIndicator>Completed</StatusIndicator>
            )}
            {eSignatureResponse?.status === "voided" && (
              <StatusIndicator type="stopped">Voided</StatusIndicator>
            )}
          </div>
        </div>
        <div>
          <Box variant="awsui-key-label">File Name</Box>
          <Link
            variant="secondary"
            href="#"
            onFollow={() => {
              OpenFilePreviewModal(true);
            }}
          >
            {eSignatureResponse?.attachment?.name || "-"}
          </Link>
        </div>
        <div>
          <Box variant="awsui-key-label">Category</Box>
          <div>{eSignatureResponse.category?.name ?? "-"}</div>
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Subject</Box>
          <div>{eSignatureResponse.subject}</div>
        </div>
        <div>
          <Box variant="awsui-key-label">Sender</Box>
          <div>
            {eSignatureResponse?.admin?.firstName ?? "-"}{" "}
            {eSignatureResponse?.admin?.lastName}{" "}
          </div>
        </div>
        <div>
          <Box variant="awsui-key-label">Created Date</Box>
          <div>{IncredableDateFormat(eSignatureResponse.createdDate)}</div>
        </div>

        <div>
          <Box variant="awsui-key-label">Primary Recipient</Box>
          <RenderRecipient
            recipeint={eSignatureResponse.recipients?.find(
              (r) => r.primary === true
            )}
          />
        </div>
      </SpaceBetween>

      <SpaceBetween size="l">
        <div>
          <Box variant="awsui-key-label">Signed Document</Box>
          {!!eSignatureResponse.document &&
          !!eSignatureResponse.recipients?.find((r) => r.primary === true)
            ?.provider ? (
            <Link
              external
              href={`/safe/${
                eSignatureResponse.recipients?.find((r) => r.primary === true)
                  ?.provider?.id
              }/documents/${eSignatureResponse?.document?.id}`}
            >
              {eSignatureResponse?.document?.name}
            </Link>
          ) : (
            "-"
          )}
        </div>
        <div>
          <Box variant="awsui-key-label">Signed Date</Box>
          <div>
            {IncredableDateFormat(eSignatureResponse.completedDate) ?? "-"}
          </div>
        </div>
        <div>
          <Box variant="awsui-key-label">Associated With</Box>
          {!!eSignatureResponse.digitalForm && (
            <Link
              external
              href={`/digitalforms/${eSignatureResponse.digitalForm?.id}`}
            >
              {eSignatureResponse.digitalForm?.name}
            </Link>
          )}
          {!!eSignatureResponse.credentialPackage && (
            <Link
              external
              href={`/credentialpackages/${eSignatureResponse.credentialPackage?.id}`}
            >
              {eSignatureResponse.credentialPackage?.name}
            </Link>
          )}
        </div>
      </SpaceBetween>
      {!!isFilePreviewOpen && (
        <Modal
          onDismiss={() => OpenFilePreviewModal(false)}
          visible={isFilePreviewOpen}
          header="e-Signature File Preview"
          size="max"
        >
          <div>
            <PDFTronViewer
              attachmentKey={eSignatureResponse?.attachment?.key}
              replaceFile={undefined}
            />
          </div>
        </Modal>
      )}
    </ColumnLayout>
  );
};

export const RenderRecipient = ({
  recipeint,
}: {
  recipeint?: RecipientResponse;
}) => {
  if (!!recipeint)
    if (!!recipeint.provider)
      return (
        <div>
          <SpaceBetween size="xs" direction="horizontal">
            <Box variant="div">
              {recipeint.provider.firstName} {recipeint.provider.lastName}
            </Box>
          </SpaceBetween>
        </div>
      );
    else if (!!recipeint.admin)
      return (
        <div>
          <SpaceBetween size="xs" direction="horizontal">
            <Box variant="div">
              {recipeint.admin.firstName} {recipeint.admin.lastName}
            </Box>
          </SpaceBetween>
        </div>
      );
    else if (!!recipeint.emailAddress)
      return (
        <div>
          <SpaceBetween size="xs" direction="horizontal">
            <Box variant="div">{recipeint.emailAddress}</Box>
          </SpaceBetween>
        </div>
      );
    else return <div>-</div>;
  else return <div>-</div>;
};

function GridItem({
  label,
  value,
}: {
  label: string;
  value: string | React.ReactNode;
}) {
  return (
    <div>
      <Grid gridDefinition={[{ colspan: 1 }, { colspan: 11 }]} disableGutters>
        <Box variant="awsui-key-label">{label}:</Box>
        <span>{value}</span>
      </Grid>
    </div>
  );
}

const RecipientDetails = ({
  recipient,
  hasDivider,
}: {
  recipient: RecipientResponse;
  hasDivider: boolean;
}) => {
  return (
    <SpaceBetween size="xxs">
      <SpaceBetween size="xxs">
        <GridItem
          label={"Type"}
          value={
            <SpaceBetween size="s" direction="horizontal">
              {!!recipient.provider
                ? "Provider"
                : !!recipient.admin
                ? "Member"
                : "Email"}
              {recipient.primary && (
                <Badge color="grey">Primary recipient</Badge>
              )}
            </SpaceBetween>
          }
        />
        <GridItem
          label={"Email"}
          value={
            recipient?.emailAddress ??
            recipient.provider?.email ??
            recipient.admin?.email ??
            "-"
          }
        />
        <GridItem
          label={"Name"}
          value={
            !!recipient.provider
              ? recipient.provider.firstName + " " + recipient.provider.lastName
              : !!recipient.admin
              ? recipient.admin.firstName + " " + recipient.admin.lastName
              : "-"
          }
        />
      </SpaceBetween>
      <GridItem
        label={"Signed Date"}
        value={IncredableDateFormat(recipient.completedDate) ?? "-"}
      />
      {hasDivider && <Divider />}
    </SpaceBetween>
  );
};
