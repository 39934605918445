// awsui

//hook-form
import { FormProvider, useForm, useFormState } from "react-hook-form";
// pdftron
import { Core } from "@pdftron/webviewer";
// lodash

//components

import { useEffect } from "react";
import { FontType } from "./digitalform-tags-list";
import { Divider, Flex, Form, Layout, Space, Typography } from "antd";
import IncredableButton from "../button";
import RHFSelect from "../RHF/RHFSelect";
import RHFTextField from "../RHF/RHFTextField";
import RHFCheckBox from "../RHF/RHFCheckbox";
import { FontFamily } from "./constants";
import { range } from "lodash";

interface TemplateDesignerAnnotationFormatterProps {
  selectedAnnots: Core.Annotations.FreeTextAnnotation[];
  onFontFamilyChanged: (fontFamily: string) => void;
  onFontSizeChanged: (fontSize: string) => void;
  onRequiredChanged: (isRequired: boolean) => void;
  onIndexChanged: (index: string) => void;
  onNameChanged: (name: string) => void;
  deselectAllAnnotations: VoidFunction;
  defaultFontValue: FontType;
  options?: { label: string; value: string }[];
  selectedRecipient?: { label: string; value: string } | null;
  onSelectedRecipientChanged?: (option: {
    label: string;
    value: string;
  }) => void;
}

export type FormInputType = {
  name: string;
  serailNo: string;
  isRequired: boolean;
  fontFamily: string;
  fontSize: string;
  recipient?: { label: string; value: string };
};

export default function TemplateDesignerAnnotationFormatter({
  selectedAnnots,
  defaultFontValue,
  onNameChanged,
  options,
  selectedRecipient,
  onIndexChanged,
  onFontSizeChanged,
  onRequiredChanged,
  onFontFamilyChanged,
  deselectAllAnnotations,
  onSelectedRecipientChanged,
}: TemplateDesignerAnnotationFormatterProps) {
  const methods = useForm<FormInputType>({
    // this is not the right way to set field values,see recipient field for the correct way,
    // defaultValue is used only when field value is undefined.
    defaultValues: {
      name: selectedAnnots?.at(0)?.getCustomData("name") ?? "",
      serailNo: selectedAnnots?.at(0)?.getCustomData("index") ?? "",
      isRequired: selectedAnnots?.at(0)?.getCustomData("isRequired") === "true",
      fontFamily:
        selectedAnnots.length === 1
          ? selectedAnnots.at(0)?.Font
          : defaultFontValue.fontFamily,
      fontSize:
        selectedAnnots.length === 1
          ? selectedAnnots.at(0)?.FontSize.match(/(\d+)/)?.at(0) ??
            defaultFontValue.fontSize
          : defaultFontValue.fontSize,
    },
  });
  const { dirtyFields } = useFormState({
    control: methods.control,
  });

  useEffect(() => {
    methods.setValue("recipient", selectedRecipient ?? undefined);
  }, [methods, selectedRecipient]);

  return (
    <Layout
      style={{
        width: "100%",
        boxShadow: "0px 10px 25px 0px #5911920D",
        borderRadius: "16px",
        border: "1px solid #E5E7EB",
        backgroundColor: "white",
        padding: "32px",
      }}
    >
      <FormProvider {...methods}>
        <Form
          onFinish={methods.handleSubmit((data) => {
            if (dirtyFields.fontFamily) onFontFamilyChanged(data.fontFamily);
            if (dirtyFields.fontSize) onFontSizeChanged(data.fontSize);
            if (dirtyFields.isRequired) onRequiredChanged(data.isRequired);
            if (dirtyFields.name) onNameChanged(data.name);
            if (dirtyFields.serailNo) onIndexChanged(data.serailNo);
            if (dirtyFields.recipient) {
              const selectedOption = options?.find(
                (option) => option.value === data.recipient?.value
              );

              !!selectedOption &&
                !!onSelectedRecipientChanged &&
                onSelectedRecipientChanged(selectedOption);
            }
            deselectAllAnnotations();
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                padding: "0",
                fontWeight: "700",
              }}
            >
              {selectedAnnots.length === 1
                ? `${selectedAnnots.length} Field selected`
                : `${selectedAnnots.length} Fields selected`}
            </Typography.Text>
            <Divider
              style={{
                padding: "0",
                marginTop: "4px",
                marginBottom: "16px",
              }}
            />
            <Space direction="vertical">
              {!!options && (
                <RHFSelect
                  controllerProps={{
                    name: "recipient",
                  }}
                  formItemProps={{
                    label: (
                      <Typography.Title level={5}>Recipient</Typography.Title>
                    ),
                  }}
                  selectProps={{
                    allowClear: true,
                    showSearch: true,
                    style: { width: "100%" },
                    placeholder: "Search to category",
                    options: options,
                  }}
                />
              )}

              {selectedAnnots.length === 1 && (
                <RHFTextField
                  formItemProps={{
                    label: "Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "name",
                  }}
                />
              )}
              {selectedAnnots.length === 1 &&
                selectedAnnots?.at(0)?.getCustomData("isList") === "true" && (
                  <RHFTextField
                    controllerProps={{ name: "serailNo" }}
                    inputProps={{}}
                    formItemProps={{ label: "Serial No" }}
                  />
                )}
              <RHFCheckBox
                controllerProps={{ name: "isRequired" }}
                formItemProps={{ label: "Required" }}
                checkboxProps={{ label: "", stringfy: true }}
              />
              <RHFSelect
                controllerProps={{
                  name: "fontFamily",
                }}
                formItemProps={{
                  label: "Font Family",
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to category",
                  options: FontFamily.map((fontFamily) => ({
                    value: fontFamily,
                  })),
                }}
              />
              <RHFSelect
                controllerProps={{
                  name: "fontSize",
                }}
                formItemProps={{
                  label: "Font Size",
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to category",
                  options: range(8, 42).map((fontSize) => ({
                    value: `${fontSize}`,
                  })),
                }}
              />
            </Space>
            <Divider
              style={{
                padding: "0",
                marginTop: "4px",
                marginBottom: "16px",
              }}
            />
          </Flex>
          <Flex gap="small" wrap justify="flex-end">
            <IncredableButton
              secondary
              formAction="none"
              onClick={deselectAllAnnotations}
            >
              Cancel
            </IncredableButton>
            <IncredableButton type="primary" htmlType="submit">
              Submit
            </IncredableButton>
          </Flex>
        </Form>
      </FormProvider>
    </Layout>
  );
}
