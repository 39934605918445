import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Space, Typography, Divider, Card, Spin } from "antd";
import TextArea from "antd/es/input/TextArea";
import IncredableButton from "../../components/button";
import { ChangeEvent, useEffect, useState } from "react";
import { upperCase } from "lodash";
import Comment from "../../redux/api/comments/types";
import IncredableDateFormat from "../../common/incredable-date-format";
import { useGetProfileDetailsQuery } from "../../redux/api/profile/profile";
import {
  useAddCommentToDocument_2Mutation,
  useDeleteCommentFromDocument_2Mutation,
  useGetDocumentComments_2Query,
  useUpdateCommentInDocument_2Mutation,
} from "../../redux/api/comments/comment";
import { skipToken } from "@reduxjs/toolkit/query";
import { useParams } from "react-router-dom";
import IncredableContent from "../../components/incredable-content";

const { Paragraph } = Typography;

export default function DocumentComment() {
  const [message, setMessage] = useState<string>("");
  const [commentIdToEdit, setCommentIdToEdit] = useState<string>();
  const [loading, setLoading] = useState(false);

  const [enableButtons, setEnableButtons] = useState(false);
  const [commentsList, setCommentsList] = useState<Comment[]>([]);
  const { documentId } = useParams();

  const { data: comments, isLoading } = useGetDocumentComments_2Query(
    documentId ? { documentId } : skipToken
  );

  useEffect(() => {
    if (comments) {
      setCommentsList(comments);
    }
  }, [comments]);

  const [addCommentMutation] = useAddCommentToDocument_2Mutation();
  const [deleteCommentMutation] = useDeleteCommentFromDocument_2Mutation();
  const [updateCommentMutation] = useUpdateCommentInDocument_2Mutation();

  const postComment = async (message: string) => {
    try {
      if (!!documentId) {
        await addCommentMutation({
          documentId: documentId,
          message: message,
        });
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };
  const editComment = async (commentId: string, message: string) => {
    try {
      if (!!documentId) {
        await updateCommentMutation({
          documentId: documentId,
          commentId: commentId,
          message: message,
        });
      }
    } catch (error) {
      console.error("Error editing comment:", error);
    }
  };

  const deleteComment = async (commentId: string) => {
    try {
      if (!!documentId) {
        await deleteCommentMutation({
          documentId: documentId,
          commentId: commentId,
        });
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);

  const { data: user } = useGetProfileDetailsQuery();

  const saveComments = async () => {
    setLoading(true);
    if (!!commentIdToEdit) {
      await editComment(commentIdToEdit, message);
    } else {
      await postComment(message);
    }

    setMessage("");
    setEnableButtons(false);
    setLoading(false);
    setCommentIdToEdit(undefined);
  };

  const handleEditClick = (item: Comment) => {
    setMessage(item?.message);
    setCommentIdToEdit(item?.id);
    setEnableButtons(true);
  };

  const handleDeleteClick = async (commentId: string) => {
    setLoading(true);
    if (!!commentId) {
      await deleteComment(commentId);
    }

    setLoading(false);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const paginatedComments = commentsList.slice(
    startIndex,
    startIndex + pageSize
  );

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  return (
    <IncredableContent
      style={{ height: `calc(100vh - 210px)`, overflowY: "auto" }}
    >
      <div>
        <Space
          size="middle"
          align="center"
          style={{ paddingLeft: "10px", display: "flex", flexWrap: "wrap" }}
        >
          <h3>Comments</h3>
        </Space>
        <div style={{ padding: "16px" }}>
          <Col
            flex="none"
            style={{
              width: "100%",
              boxShadow: " 0px 0px 12px 1px #52126214",
              border: "1px solid var(--Branding-Colors-Primary, #7C4095)",
              borderRadius: "12px",
              padding: "16px 14px",
            }}
          >
            <Space>
              <Typography.Link>
                {upperCase(user?.firstName?.at(0) + "" + user?.lastName?.at(0))}
              </Typography.Link>
            </Space>
            <Divider
              style={{
                width: "90%",
                border: "1px solid #E5E7EB",
                margin: "5px 0px",
              }}
            />
            <TextArea
              value={message}
              onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
                setMessage(event.target.value);
              }}
              onFocus={() => {
                setEnableButtons(true);
              }}
              placeholder="Add a comment..."
              style={{ border: "none", boxShadow: "none" }}
            />
          </Col>
        </div>
        {!!enableButtons && (
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
              marginTop: "10px",
            }}
            onClick={() => {
              setEnableButtons(false);
              setMessage("");
            }}
          >
            <IncredableButton secondary>Cancel</IncredableButton>
            <IncredableButton type="primary" onClick={saveComments}>
              Save
            </IncredableButton>
          </Space>
        )}
      </div>
      {!!paginatedComments ? (
        paginatedComments.map((comment) => (
          <Card
            key={comment?.id}
            bordered={true}
            style={{
              marginTop: "20px",
              borderRadius: 8,
            }}
          >
            <Col style={{ width: "100%" }}>
              <Space direction="vertical" size={0} style={{ width: "100%" }}>
                <Space>
                  <Typography.Link>
                    {comment?.user?.firstName} {comment?.user?.lastName}
                  </Typography.Link>
                  <small>{IncredableDateFormat(comment?.createdDate)}</small>
                </Space>
                <Paragraph>{comment.message}</Paragraph>
                <Divider
                  style={{
                    width: "90%",
                    border: "1px solid #E5E7EB",
                    margin: "5px 0px",
                  }}
                />
                <Space
                  style={{
                    width: "100%",
                    justifyContent: "end",
                    marginTop: "10px",
                  }}
                >
                  <IncredableButton
                    secondary
                    icon={<DeleteOutlined />}
                    onClick={() => handleDeleteClick(comment.id)}
                  >
                    Delete
                  </IncredableButton>
                  <IncredableButton
                    icon={<EditOutlined />}
                    type="primary"
                    onClick={() => handleEditClick(comment)}
                  >
                    Edit
                  </IncredableButton>
                </Space>
              </Space>
            </Col>
          </Card>
        ))
      ) : (
        <div>No Comments Found</div>
      )}
    </IncredableContent>
  );
}
