import { Divider, Input, Layout, Menu, Space, Typography } from "antd";
import { ListItemButton, styled } from "@mui/material";
import { SearchOutlined } from "@ant-design/icons";
import useReadyFormTagsFilter from "./useReadyFormTagsFilter";

interface TemplateDesignerLeftPanelProps {
  onDragStart: React.DragEventHandler<HTMLDivElement>;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>, field: FieldType) => void;
}

export type DIGITALFORM_FIELDS_Type = {
  label: string;
  value: string;
  type: string;
  icon?: JSX.Element;
  properties: {
    label: string;
    value: string;
    key?: string;
    svg?: React.ReactNode;
    type: "string" | "date" | "bool" | "signature";
  }[];
};

export type FontType = {
  fontFamily: string;
  fontSize: string;
};
export type FieldType = {
  label: string;
  key: string;
  index?: number;
  type: "string" | "date" | "bool" | "signature";
  isList: boolean;
};

export const ListItemStyle = styled(ListItemButton)(({ theme }) => ({
  ...theme.typography.body2,
  position: "relative",
  textTransform: "capitalize",
  color: theme.palette.text.secondary,
  "&:hover": {
    backgroundColor: "#b5d6f4", //`${alpha(awsui.colorBackgroundButtonPrimaryActive, 0.5)}`,
  },
}));

export default function TemplateDesignerTagsList({
  onDragStart,
  onDragEnd,
}: TemplateDesignerLeftPanelProps) {
  const { filteredFields, filter, setFilter } = useReadyFormTagsFilter();
  return (
    <Layout
      style={{
        boxShadow: "0px 10px 25px 0px #5911920D",
        borderRadius: "16px",
        border: "1px solid #E5E7EB",
        backgroundColor: "white",
      }}
    >
      <Space direction="vertical" style={{ padding: "0px 0 0 16px" }}>
        <Typography.Title level={4}>Fields</Typography.Title>
        <Input
          onChange={(event) => {
            setFilter({ ...filter, filterText: event.target.value });
          }}
          value={filter?.filterText}
          style={{
            width: "75%",
          }}
          placeholder="Find Ready Form by name"
          prefix={<SearchOutlined />}
        />
      </Space>
      <Divider />
      <Menu
        style={{
          width: "100%",
          borderRight: 0,
          borderRadius: "8px",
          height: `calc(100vh - 275px)`,
          overflow: "scroll",
        }}
        defaultSelectedKeys={["personal_info"]}
        theme="light"
        mode="inline"
        items={filteredFields.map((digitalFormField) => {
          return {
            key: digitalFormField?.value,
            icon: digitalFormField?.icon,
            label: digitalFormField?.label,
            children: digitalFormField?.properties?.map((property) => {
              const annot_field: FieldType = {
                label: [digitalFormField?.label, property.label].join("_"),
                type: property.type,
                key:
                  property.key ??
                  [digitalFormField?.value, property.value].join("."),
                isList: digitalFormField?.type === "list",
              };
              return {
                label: (
                  <ListItemStyle
                    key={[digitalFormField.label, property.label].join("_")}
                    draggable
                    onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                      onDragStart(e)
                    }
                    onDragEnd={(e: React.DragEvent<HTMLDivElement>) =>
                      onDragEnd(e, annot_field)
                    }
                    onClick={() => {}}
                  >
                    <Typography.Text>
                      {[digitalFormField.label, property.label].join("_")}
                    </Typography.Text>
                  </ListItemStyle>
                ),
                key:
                  property.key ||
                  [digitalFormField.value, property.value].join("."),
              };
            }),
          };
        })}
      />
    </Layout>
  );
}
