import { Checkbox, CheckboxProps, Form, FormItemProps } from "antd";
import {
  useFormContext,
  Controller,
  UseControllerProps,
  RegisterOptions,
  FieldValues,
  FieldError,
} from "react-hook-form";
// ----------------------------------------------------------------------
type Props = {
  formItemProps: FormItemProps;
  checkboxProps: CheckboxProps & { label: string; stringfy?: boolean };
  controllerProps: UseControllerProps;
  rules?:
    | Omit<
        RegisterOptions<FieldValues, string>,
        "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
      >
    | undefined;
};

export default function RHFCheckBox({
  formItemProps,
  checkboxProps,
  controllerProps,
  rules,
}: Props) {
  const { control } = useFormContext();
  return (
    <Controller
      name={controllerProps.name}
      control={control}
      defaultValue={controllerProps.defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Form.Item
            {...formItemProps}
            label={
              <span style={{ fontWeight: "600" }}>{formItemProps?.label}</span>
            }
            required={!!rules?.required}
            validateStatus={!!fieldState.error ? "error" : ""}
            help={!!fieldState.error && getErrorMessage(fieldState.error)}
          >
            <Checkbox
              checked={field.value === "true" || field.value === true}
              onChange={(changeDetail) => {
                if (!!checkboxProps?.stringfy)
                  field.onChange(changeDetail.target.checked?.toString());
                else field.onChange(changeDetail.target.checked);
              }}
            >
              {checkboxProps?.label}
            </Checkbox>
          </Form.Item>
        );
      }}
      rules={rules}
    />
  );
}

const getErrorMessage = (error?: FieldError | undefined) => {
  if (!error) return;
  if (error.message && error.message.length > 0) return error.message;
  if (error.type === "maxLength") return "Value is too long";
  return "Invalid value";
};
