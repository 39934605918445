import { WidgetConfig } from "../types";

import PieChart from "@cloudscape-design/components/pie-chart";
import { useGetAllDashboardQuery } from "../../../redux/api/dashboards/dashboard";

export const eSignatureStatus: WidgetConfig = {
  title: "e-Signatures Status",
  description: "Overview of e-Signatures",
  header: eSignatureStatusHeader,
  content: ESignatureStatusWidget,
};

function eSignatureStatusHeader() {
  return (
    <div
      style={{
        borderRadius: "4px",
        padding: "0px 10px 0 18px",
        marginBottom: "16px",
      }}
    >
      <h3>{eSignatureStatus.title}</h3>
    </div>
  );
}

function ESignatureStatusWidget() {
  const { data } = useGetAllDashboardQuery();

  return (
    <div style={{ height: "420px", padding: "12px 0px 12px 12px" }}>
      <PieChart
        data={[
          {
            title: "Completed",
            value: data?.esignatureStatus?.completed ?? 0,
            color: "#2B6BE6",
          },
          {
            title: "Pending",
            value: data?.esignatureStatus?.pending ?? 0,
            color: "#b4b4bb",
          },
          /*  {
            title: "Voided",
            value: data?.esignatureStatus?.voided ?? 0,
            color: "#2b95e6",
          }, */
          {
            title: "Expired",
            value: data?.esignatureStatus?.expired || 0,
            color: "#cf1322",
          },
        ]}
        detailPopoverContent={(datum, sum) => [
          { key: "Count", value: datum.value },
          {
            key: "Percentage",
            value: `${((datum.value / sum) * 100).toFixed(0)}%`,
          },
        ]}
        hideFilter
        fitHeight
      />
    </div>
  );
}
