import { Col, Divider, Flex, Form, Row, theme, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import {
  useAddProviderSpecialtyInfoMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { ProviderSpecialtyInfoRequestType } from "../../redux/api/provider/types";
import RHFCheckBox from "../../components/RHF/RHFCheckbox";
import { useGetAllSpecialtyQuery } from "../../redux/api/specialty/specialty";
import { useGetAllBoardNameQuery } from "../../redux/api/boardName/board";

const { useToken } = theme;

export default function EditProviderSpecialtyInfo() {
  const { providerId, specialtyinfoId } = useParams();
  const methods = useForm<ProviderSpecialtyInfoRequestType>();
  const { data: specialty } = useGetAllSpecialtyQuery();
  const { data: boardname } = useGetAllBoardNameQuery();
  const {
    data: provider,
    isLoading,
    isFetching,
  } = useGetProviderQuery(!!providerId ? { providerId } : skipToken);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { token } = useToken();

  useEffect(() => {
    if (provider && provider.providerSpecialties) {
      const specialtyInfo = provider?.providerSpecialties?.find(
        (specialty) => specialty?.id + "" === specialtyinfoId + ""
      );
      if (specialtyInfo) {
        methods.setValue("id", specialtyInfo.id);
        methods.setValue("boardName", specialtyInfo.boardName);
        methods.setValue("boardCertNumber", specialtyInfo.boardCertNumber);
        methods.setValue(
          "initialCertificationDate",
          specialtyInfo.initialCertificationDate
        );
        methods.setValue("expirationDate", specialtyInfo.expirationDate);
        methods.setValue(
          "reCertificationDate",
          specialtyInfo.reCertificationDate
        );
        methods.setValue("specialtyId", specialtyInfo.specialty.id);
      }
    }
  }, [provider, specialtyinfoId]);

  const [updateProviderSpecialty] = useAddProviderSpecialtyInfoMutation();

  return (
    <IncredableContent spinning={isLoading || isFetching}>
      <FormProvider {...methods}>
        <Form
          name="editproviderspecialtyinfo"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: ProviderSpecialtyInfoRequestType) => {
              if (!!providerId) {
                spinnerContext?.showSpinner();
                await updateProviderSpecialty({
                  ...data,
                  providerId: providerId,
                });
                spinnerContext?.hidespinner();
                toastContext?.openSuccessNotification({
                  message: `Specialty Info saved successfully`,
                  placement: "topRight",
                });
              }
              navigate(-1);
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Specialty Info
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="liabilityinsurance" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Board Name" }}
                            controllerProps={{
                              name: "boardName",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select boardname",
                              options: boardname?.map((name) => {
                                return {
                                  label: name?.name,
                                  value: name?.name,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/boardname");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Specialty" }}
                            controllerProps={{
                              name: "specialtyId",
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select specialty",
                              options: specialty?.map((specialty) => {
                                return {
                                  label: specialty?.name,
                                  value: specialty?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/specialty");
                              },
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Board Cert Number",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "boardCertNumber",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Initial Certification Date",
                            }}
                            controllerProps={{
                              name: `initialCertificationDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Expiration Date",
                            }}
                            controllerProps={{
                              name: `expirationDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "ReCertification Date",
                            }}
                            controllerProps={{
                              name: `reCertificationDate`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
