import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import AuthProvider from "./context/JWTContext";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { ConfigProvider } from "antd";
import "@cloudscape-design/global-styles/index.css";
import { I18nProvider } from "@cloudscape-design/components/i18n";
import enMessages from "@cloudscape-design/components/i18n/messages/all.en";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#521262",
          colorInfo: "#521262",
          fontFamily: "Open Sans", //"Roboto", //"Poppins", //"Open Sans", //Raleway
          fontSize: 14,
        },
        components: {
          Tabs: {
            itemSelectedColor: "#ffffff",
            colorBgContainer: "#2B6BE6",
            cardGutter: 0,
            itemHoverColor: "#2B6BE6",
            borderRadius: 0,
            borderRadiusLG: 0,
          },
          Anchor: {
            colorPrimary: "#2B6BE6",
            paddingXXS: 0,
          },
          Form: {
            labelColor: "rgb(51, 51, 51)",
            labelHeight: "18px",
          },
          Breadcrumb: {
            itemColor: "#521262",
            fontSize: 16,
            fontWeightStrong: 600,
            linkColor: "#521262",
          },
          Typography: {
            titleMarginBottom: "0",
            titleMarginTop: "0",
            colorText: "rgb(0,7,22)",
          },
          Divider: {
            colorSplit: "#A884C9",
          },
          Button: {
            borderRadius: 8,
            colorPrimary: "#2B6BE6",
          },
          Checkbox: { colorPrimary: "#2B6BE6" },
          Menu: {
            darkItemBg: "#521262",
            darkItemSelectedBg: "#2B6BE6",
          },
          Select: {
            optionSelectedBg: "#e6f4ff",
          },
          Layout: {
            siderBg: "#521262",
            lightSiderBg: "#521262",
            triggerBg: "rgba(90,20,101,1)",
          },
          Table: {
            rowSelectedBg: "#bae0ff",
            rowSelectedHoverBg: "#e6f4ff",
            headerSplitColor: "rgba(0, 0, 0, 0.28)",
          },
        },
      }}
    >
      <I18nProvider locale="en" messages={[enMessages]}>
        <Provider store={store}>
          <AuthProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </AuthProvider>
        </Provider>
      </I18nProvider>
    </ConfigProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
