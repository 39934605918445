import { Breadcrumb, BreadcrumbProps, Layout, Space } from "antd";
import { IncredableSpin } from "../context/spinner/spinner";
import { CSSProperties, ReactNode } from "react";
const IncredableContent = ({
  spinning,
  children,
  style,
  breadcrumbProps,
}: {
  spinning?: boolean;
  children: ReactNode;
  style?: CSSProperties | undefined;
  breadcrumbProps?: BreadcrumbProps;
}) => (
  <Space style={{ display: "block" }} direction="vertical">
    {!!breadcrumbProps && (
      <Breadcrumb
        style={{
          margin: "24px 0 0 48px",
        }}
        {...breadcrumbProps}
      />
    )}
    <Layout.Content
      style={{
        margin: !!breadcrumbProps ? "12px 48px 0 48px" : "12px",
        padding: "32px",
        backgroundColor: "white",
        boxShadow: "0px 10px 25px 0px #5911920D",
        borderRadius: "16px",
        border: "1px solid #E5E7EB",
        overflow: "hidden",
        ...style,
      }}
    >
      <IncredableSpin spinning={!!spinning} />
      {children}
    </Layout.Content>
  </Space>
);
export default IncredableContent;
