import { Modal, Form, Col, Space, Divider, Flex, Row } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import IncredableButton from "../../components/button";
import RHFTextField from "../../components/RHF/RHFTextField";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useContext, useEffect } from "react";
import { alertReminderOptions } from "../../common/constants";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
  useGetTaskQuery,
  useUpdateMyTaskMutation,
} from "../../redux/api/task";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import { TaskRequest } from "../../redux/api/task/types";
import { skipToken } from "@reduxjs/toolkit/query";

interface EditMyTaskModalProps {
  visible: boolean;
  handleCloseEditTaskModal: () => void;
  taskId: any;
}

const EditMyTaskModal: React.FC<EditMyTaskModalProps> = ({
  visible,
  handleCloseEditTaskModal,
  taskId,
}) => {
  const methods = useForm<TaskRequest>();
  const { data: myTask, isLoading } = useGetTaskQuery(
    !!taskId ? { id: taskId } : skipToken
  );
  const [updateTask] = useUpdateMyTaskMutation();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: taskstatuses = [] } = useGetAllTaskStatusQuery();
  const { data: taskTypes = [] } = useGetAllTaskTypeQuery();
  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: workflows = [] } = useGetAllWorkflowQuery();
  const { data: facilities = [] } = useGetFacilitiesQuery();

  useEffect(() => {
    if (!!myTask) {
      methods.setValue("name", myTask.name ?? "");
      methods.setValue("description", myTask.description ?? "");
      methods.setValue("taskStatusId", myTask.taskStatus?.id ?? "");
      methods.setValue("taskTypeId", myTask.taskType?.id ?? "");
      methods.setValue("workflowId", myTask.workflow?.id ?? "");
      methods.setValue("providerId", myTask.provider?.id ?? "");
      methods.setValue("facilityId", myTask.facility?.id ?? "");
      methods.setValue("startDate", myTask.startDate ?? "");
      methods.setValue("dueDate", myTask.dueDate ?? "");
      methods.setValue("completedDate", myTask.completedDate ?? "");
      methods.setValue("alertDays", myTask.alertDays ?? "");
    }
  }, [methods, myTask]);

  return (
    <Modal
      style={{ top: 20 }}
      width={1000}
      open={visible}
      onCancel={handleCloseEditTaskModal}
      title="Edit My Task"
      footer={null}
      loading={isLoading}
    >
      <FormProvider {...methods}>
        <Form
          name="editmytask"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: TaskRequest) => {
            if (!!taskId) {
              handleCloseEditTaskModal();
              spinnerContext?.showSpinner();
              await updateTask({
                id: taskId,
                ...data,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Task Updated Successfully`,
                placement: "topRight",
              });
            }
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="end">
            <Space>
              <IncredableButton secondary onClick={handleCloseEditTaskModal}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider style={{ margin: "5px", padding: "0" }} />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Task Type" }}
                controllerProps={{
                  name: `taskTypeId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: taskTypes?.map((taskType) => {
                    return {
                      label: taskType?.name,
                      value: taskType?.id,
                    };
                  }),
                }}
                rules={{ required: "This field is required" }}
              />
            </Col>
            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Task Name",
                }}
                inputProps={{}}
                controllerProps={{
                  name: "name",
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Task Status" }}
                controllerProps={{
                  name: `taskStatusId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: taskstatuses?.map((taskStatus) => {
                    return {
                      label: taskStatus?.name,
                      value: taskStatus?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Provider" }}
                controllerProps={{
                  name: `providerId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: providers?.map((provider) => {
                    return {
                      label: `${provider?.firstName} ${provider?.lastName}`,
                      value: provider?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Facility" }}
                controllerProps={{
                  name: `facilityId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: facilities?.map((facility) => {
                    return {
                      label: facility?.name,
                      value: facility?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Associated Workflow" }}
                controllerProps={{
                  name: `workflowId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Select",
                  options: workflows?.map((workflow) => {
                    return {
                      label: workflow?.name,
                      value: workflow?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Start Date",
                }}
                controllerProps={{
                  name: `startDate`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Completed Date",
                }}
                controllerProps={{
                  name: `completedDate`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Expiration Date",
                }}
                controllerProps={{
                  name: `endDate`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Alert Days" }}
                controllerProps={{
                  name: `alertDays`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Select Days",
                  options: alertReminderOptions?.map((alert) => {
                    return {
                      label: alert?.label,
                      value: alert?.value,
                    };
                  }),
                }}
              />
            </Col>
          </Row>
          <Divider style={{ margin: "5px", padding: "0" }} />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24}>
              <RHFTextArea
                formItemProps={{ label: "Notes" }}
                controllerProps={{ name: "description" }}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default EditMyTaskModal;
