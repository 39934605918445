import { Tabs, Space, Typography, Segmented } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import IncredableContent from "../components/incredable-content";
import RolesTable from "./manage-roles/roles-table";
import PermissionsList from "./manage-permissions/permissions-list";
import { useEffect, useState } from "react";
import { RolePermissions } from "../redux/api/role/types";
import { useGetAllRoleQuery } from "../redux/api/role/role";

export default function RolesAndPermissions() {
  const { data } = useGetAllRoleQuery();
  const [roles, setRoles] = useState<RolePermissions[]>([]);
  const [value, setValue] = useState<string>("roles");

  useEffect(() => {
    if (data)
      setRoles(
        data.map((role) => {
          return {
            id: role.id,
            name: role.name,
            default: role.default,
            permissions: role.permissions.map((permission) => permission.key),
          };
        })
      );
  }, [data]);

  return (
    <IncredableContent style={{ margin: "20px 30px 0" }}>
      <Segmented
        options={[
          {
            label: (
              <span
                style={{
                  fontWeight: value === "roles" ? "600" : "400",
                  fontSize: "18px",
                  padding: 8,
                }}
              >
                Roles
              </span>
            ),
            value: "roles",
          },
          {
            label: (
              <span
                style={{
                  fontWeight: value === "permission" ? "600" : "400",
                  fontSize: "18px",
                  padding: 8,
                }}
              >
                Permissions
              </span>
            ),
            value: "permission",
          },
        ]}
        block
        value={value}
        onChange={setValue}
      />
      {value === "roles" && <RolesTable />}
      {value === "permission" && (
        <PermissionsList
          roles={roles}
          setRoles={setRoles}
          tabName="permissions"
        />
      )}
    </IncredableContent>
  );
}
