import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  SpaceBetween,
} from "@cloudscape-design/components";
import { useState } from "react";
import { captureException } from "@sentry/react";
import {
  useDigitalformSaveToSafeMutation,
  useGetDigitalFormQuery,
} from "../../redux/api/digitalform/digitalforms";
import { useAddDigitalformSignatureRequestMutation } from "../../redux/api/signaturerequest/signaturerequest";
import { eSignatureResponse } from "../../redux/api/signaturerequest/types";
import RequestSignature from "./add-eSignature/request-signature";
import { skipToken } from "@reduxjs/toolkit/query";

export default function DigitalformRequestSignature() {
  const { digitalformId } = useParams();
  const { data: digitalform, isLoading } = useGetDigitalFormQuery(
    digitalformId ?? skipToken
  );
  const navigate = useNavigate();
  const [
    addDigitalformSignatureRequest,
    { isLoading: loadingSubmitSignatureRequest },
  ] = useAddDigitalformSignatureRequestMutation();
  const [digitalformSaveToSafe, { isLoading: isSavingToSafe }] =
    useDigitalformSaveToSafeMutation();

  const [signatureResponse, setSignatureResponse] =
    useState<eSignatureResponse>();

  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

  if (!digitalform) return <div>loading</div>;
  else
    return (
      <>
        <RequestSignature
          fileKey={digitalform.annotatedTemplate.key}
          name={digitalform.name}
          message={digitalform.description}
          isLoading={isLoading}
          savetosafe={async (xfdfString, providerId) => {
            await digitalformSaveToSafe({
              id: digitalform.id,
              providerId,
              xfdfString,
            });
            navigate(-1);
          }}
          addsignaturerequest={async (
            primaryRecipient,
            addlRecipients,
            signatureRequest
          ) => {
            await addDigitalformSignatureRequest({
              formId: digitalform.id,
              id: "",
              primaryRecipient,
              addlRecipients,
              ...signatureRequest,
            })
              .unwrap()
              .then((payload) => {
                setSignatureResponse(payload);
                setShowSuccessModal(true);
              })
              .catch((error) => {
                captureException(error);
                navigate(-1);
              });
          }}
          loadingSubmitSignatureRequest={loadingSubmitSignatureRequest}
        />
        <Modal
          onDismiss={() => {
            setShowSuccessModal(false);
            navigate(`/requests/${signatureResponse?.id}`);
          }}
          visible={!!showSuccessModal}
          footer={
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link">Cancel</Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowSuccessModal(false);
                    navigate(`/requests/${signatureResponse?.id}`);
                  }}
                >
                  Ok
                </Button>
              </SpaceBetween>
            </Box>
          }
          header="Signature Request Completed!"
        >
          Your signature request has been successfully sent
        </Modal>
      </>
    );
}
