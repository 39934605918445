import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import {
  useGetAllCredentialTemplatesQuery,
  useGetAllCredentialPackagesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
} from "../redux/api/credentialpackages/credentialpackages";

export default function useCredentialPackageFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("name");

  const [nameFilterValue, setNameFilterValue] = useState<string | undefined>(
    undefined
  );

  const [providerFilterValue, setProviderFilterValue] = useState<
    string | undefined
  >(undefined);

  const [packageTypeFilterValue, setPackageTypeFilterValue] = useState<
    string | undefined
  >(undefined);

  const [packageStatusFilterValue, setPackageStatusFilterValue] = useState<
    string | undefined
  >(undefined);

  const [credentialTemplateFilterValue, setCredentialTemplateFilterValue] =
    useState<string | undefined>(undefined);

  const { data: packageTypes = [], isLoading: packageTypeLoading } =
    useGetAllPackageTypesQuery();
  const { data: packageStatus = [], isLoading: packageStatusLoading } =
    useGetAllPackageStatusQuery();
  const {
    data: credentialtemplates = [],
    isLoading: credentialtemplateLoding,
  } = useGetAllCredentialTemplatesQuery();

  const { data: providers = [], isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();

  const {
    data: credentialpackages = [],
    refetch,
    isLoading: credentialPackagesLoading,
    isFetching,
  } = useGetAllCredentialPackagesQuery();

  const filteredCredentialPackage = useMemo(() => {
    if (
      credentialPackagesLoading ||
      packageTypeLoading ||
      packageStatusLoading ||
      credentialtemplateLoding ||
      providersLoading
    )
      return [];
    return credentialpackages.filter((credentialpackage) => {
      let matches = true;
      if (nameFilterValue) {
        const nameWords = nameFilterValue?.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) =>
            credentialpackage?.name?.toLowerCase().includes(word)
          );
      }
      if (packageTypeFilterValue) {
        matches =
          matches &&
          credentialpackage?.packageType?.id === packageTypeFilterValue;
      }
      if (packageStatusFilterValue) {
        matches =
          matches &&
          credentialpackage?.packageStatus?.id === packageStatusFilterValue;
      }
      if (credentialTemplateFilterValue) {
        matches =
          matches &&
          credentialpackage?.credentialTemplate?.id ===
            credentialTemplateFilterValue;
      }
      if (providerFilterValue) {
        matches =
          matches && credentialpackage?.provider?.id === providerFilterValue;
      }
      return matches;
    });
  }, [
    credentialpackages,
    nameFilterValue,
    packageTypeFilterValue,
    packageStatusFilterValue,
    credentialTemplateFilterValue,
    providerFilterValue,
    credentialPackagesLoading,
    packageTypeLoading,
    packageStatusLoading,
    credentialtemplateLoding,
    providersLoading,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "name", label: "Name" },
            { value: "packageType", label: "Package Type" },
            { value: "packageStatus", label: "Package Status" },
            { value: "credentialTemplate", label: " Credential Template" },
            { value: "provider", label: "Provider" },
          ]}
        />
        {selectedFilterKey === "name" && (
          <Input
            onChange={(event) => setNameFilterValue(event.target.value)}
            value={nameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "packageType" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={packageTypeFilterValue}
            placeholder="Select Package Type"
            onChange={(value) => setPackageTypeFilterValue(value)}
            style={{ width: "320px" }}
            options={packageTypes.map((type) => ({
              label: type.name,
              value: type.id,
            }))}
          />
        )}
        {selectedFilterKey === "packageStatus" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={packageStatusFilterValue}
            placeholder="Select Package Status"
            onChange={(value) => setPackageStatusFilterValue(value)}
            style={{ width: "320px" }}
            options={packageStatus.map((status) => ({
              label: status.name,
              value: status.id,
            }))}
          />
        )}
        {selectedFilterKey === "credentialTemplate" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={credentialTemplateFilterValue}
            placeholder="Select Credential Template"
            onChange={(value) => setCredentialTemplateFilterValue(value)}
            style={{ width: "320px" }}
            options={credentialtemplates.map((template) => ({
              label: template.name,
              value: template.id,
            }))}
          />
        )}
        {selectedFilterKey === "provider" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={providerFilterValue}
            placeholder="Select Provider"
            onChange={(value) => setProviderFilterValue(value)}
            style={{ width: "320px" }}
            options={providers.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              value: provider.id,
            }))}
          />
        )}
      </Space>
      <div>
        {packageTypeFilterValue && (
          <Tag closable onClose={() => setPackageTypeFilterValue(undefined)}>
            Package Type:{" "}
            {
              packageTypes.find((type) => type.id === packageTypeFilterValue)
                ?.name
            }
          </Tag>
        )}
        {packageStatusFilterValue && (
          <Tag closable onClose={() => setPackageStatusFilterValue(undefined)}>
            Package Status:{" "}
            {
              packageStatus.find(
                (status) => status.id === packageStatusFilterValue
              )?.name
            }
          </Tag>
        )}
        {credentialTemplateFilterValue && (
          <Tag
            closable
            onClose={() => setCredentialTemplateFilterValue(undefined)}
          >
            Credential Template:{" "}
            {
              credentialtemplates.find(
                (template) => template.id === credentialTemplateFilterValue
              )?.name
            }
          </Tag>
        )}
        {providerFilterValue && (
          <Tag closable onClose={() => setProviderFilterValue(undefined)}>
            Provider:{" "}
            {
              providers.find((provider) => provider.id === providerFilterValue)
                ?.firstName
            }
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredCredentialPackage,
    UseQueryHookResult: {
      refetch,
      isLoading: credentialPackagesLoading,
      isFetching,
    },
  };
}
