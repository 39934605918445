import { Col, Divider, Flex, Form, Row, Typography, Space } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import IncredableButton from "../../components/button";
import { NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import IncredableContent from "../../components/incredable-content";
import { useGetAllWorkflowQuery } from "../../redux/api/workflow/workflow";
import {
  useCreateCredentialPackageMutation,
  useGetAllCredentialTemplatesQuery,
  useGetAllPackageStatusQuery,
  useGetAllPackageTypesQuery,
} from "../../redux/api/credentialpackages/credentialpackages";
import { CredentialPackageRequest } from "../../redux/api/credentialpackages/types";
import { alertReminderOptions } from "../../common/constants";
import RHFTextArea from "../../components/RHF/RHFTextArea";

export default function AddCredentialPackage() {
  const methods = useForm<CredentialPackageRequest>();

  const [addCredentialPackage] = useCreateCredentialPackageMutation();

  const { data: packageStatus = [] } = useGetAllPackageStatusQuery();
  const { data: packageTypes = [] } = useGetAllPackageTypesQuery();

  const { data: templates = [] } = useGetAllCredentialTemplatesQuery();
  const { data: providers = [] } = useGetBasicAllProvidersQuery();
  const { data: workflows = [] } = useGetAllWorkflowQuery();

  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          {
            title: <NavLink to="/credentialpackages">Credential Packages</NavLink>,
          },
          {
            title: "Add Package",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="editprovideraffiliation"
          layout="vertical"
          onFinish={methods.handleSubmit(
            async (data: CredentialPackageRequest) => {
              spinnerContext?.showSpinner();
              await addCredentialPackage({
                ...data,
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `credential package saved successfully`,
                placement: "topRight",
              });
              navigate(-1);
            }
          )}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Create Package
            </Typography.Text>
            <Space>
              <IncredableButton secondary onClick={() => navigate(-1)}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="affiliations" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Provider" }}
                            controllerProps={{
                              name: `providerId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: providers?.map((provider) => {
                                return {
                                  label: `${provider?.firstName} ${provider?.lastName}`,
                                  value: provider?.id,
                                };
                              }),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Credential Template" }}
                            controllerProps={{
                              name: `templateId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: templates?.map((template) => {
                                return {
                                  label: template?.name,
                                  value: template?.id,
                                };
                              }),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Credential Package Type" }}
                            controllerProps={{
                              name: `packageTypeId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: packageTypes?.map((packageType) => {
                                return {
                                  label: packageType?.name,
                                  value: packageType?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/packagetype");
                              },
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: "name",
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Package Status" }}
                            controllerProps={{
                              name: `packageStatusId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: packageStatus?.map((packageStatu) => {
                                return {
                                  label: packageStatu?.name,
                                  value: packageStatu?.id,
                                };
                              }),
                              onModifyClick: () => {
                                navigate("/packagestatus");
                              },
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Submitted Date",
                            }}
                            controllerProps={{
                              name: `submittedDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFDatePicker
                            formItemProps={{
                              label: "Re-Credentialing Date",
                            }}
                            controllerProps={{
                              name: `recredentialingDate`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Follow Up Date" }}
                            controllerProps={{
                              name: `alertDays`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: alertReminderOptions?.map((alert) => {
                                return {
                                  label: alert?.label,
                                  value: alert?.value,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={24}>
                          <RHFTextArea
                            formItemProps={{ label: "Notes" }}
                            controllerProps={{ name: "description" }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
