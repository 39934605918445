import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];

export const TASKS_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Task ID" },
  { id: "taskType", label: "Task Type", alwaysVisible: true },
  { id: "name", label: "Name" },
  { id: "admin", label: "Assigned To" },
  { id: "provider", label: "Provider" },
  { id: "facility", label: "Facility" },
  { id: "taskStatusId", label: "Task Status" },
  { id: "workflow", label: "Workflow" },
  { id: "startDate", label: "Start Date" },
  { id: "dueDate", label: "Due Date" },
  { id: "completedDate", label: "Completed Date" },
  { id: "description", label: "Description" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "alertDays", label: "Alert Days" },
];

export const TASKS_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "taskType", visible: true },
      { id: "name", visible: true },
      { id: "admin", visible: true },
      { id: "provider", visible: true },
      { id: "facility", visible: true },
      { id: "taskStatusId", visible: true },
      { id: "workflow", visible: true },
      { id: "startDate", visible: true },
      { id: "dueDate", visible: true },
      { id: "completedDate", visible: true },
      { id: "description", visible: true },
      { id: "expirationDate", visible: true },
      { id: "alertDays", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const TASKSTATUS_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID", alwaysVisible: false },
  { id: "status", label: "Status" },
];

export const TASKSTATUS_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "status", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const TASKTYPES_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID", alwaysVisible: false },
  { id: "type", label: "Type" },
];

export const TASKTYPES_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "type", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const TablePreferences = ({
  title,
  disabled,
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions,
}: {
  title?: string;
  disabled?: boolean;
  preferences: CollectionPreferencesProps.Preferences<any> | undefined;
  setPreferences: (
    preferences: CollectionPreferencesProps.Preferences<any>
  ) => void;
  pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}) => (
  <CollectionPreferences
    disabled={disabled || !contentDisplayOptions.length}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    title={title}
    pageSizePreference={{ options: pageSizeOptions }}
    wrapLinesPreference={{}}
    stripedRowsPreference={{}}
    contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
