import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllPayerGroupQuery } from "../redux/api/payergroup/payergroup";
import { PayerGroup } from "../redux/api/payergroup/types";

export default function usePayerGroupFilter(items?: PayerGroup[]) {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
    const [payerGroupNameFilterValue, setPayerGroupNameFiltervalue] = useState<
        string | undefined
    >(undefined);
    const [groupCodeFilterValue, setGroupCodeFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: payergroup = [],
        refetch,
        isLoading: payergroupLoading,
        isFetching,
    } = useGetAllPayerGroupQuery();
    const [filteredPayerGroup, setFilteredPayerGroup] = useState<PayerGroup[]>(items ?? payergroup)
    useEffect(() => {
        setFilteredPayerGroup(items ?? payergroup);
    }, [payergroup, items]);

    const [sortingDetails, setSortingDetails] = useState<{
        sortingField?: string;
        isDescending?: boolean;
    }>({ sortingField: "id", isDescending: false });

    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);


    useEffect(() => {
        let temp_filtered_payergroup = !!items ? [...items] : [...payergroup];
        if (!!payerGroupNameFilterValue && selectedFilterkey === "name") {
            temp_filtered_payergroup = temp_filtered_payergroup?.filter(
                (group) =>
                    group?.name
                        ?.toLowerCase()
                        .includes(payerGroupNameFilterValue?.toLowerCase())
            );
        }
        else if (!!groupCodeFilterValue && selectedFilterkey === "groupCode")
            temp_filtered_payergroup = temp_filtered_payergroup?.filter(
                (group) =>
                    group?.groupCode
                        ?.toLowerCase()
                        .includes(groupCodeFilterValue?.toLowerCase())
            );
        setFilteredPayerGroup(temp_filtered_payergroup)
    }, [payerGroupNameFilterValue, groupCodeFilterValue, payergroup, items]);

    useEffect(() => {
        setPayerGroupNameFiltervalue(undefined);
        setGroupCodeFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space size="small">
            <Select
                value={selectedFilterkey}
                onChange={(value) => {
                    setSelectedFilterKey(value);
                }}
                style={{ width: "140px" }}
                options={[
                    { value: "name", label: "Name" },
                    { value: "groupCode", label: "Group Code" },
                ]}
            />
            {selectedFilterkey === "name" && (
                <Input
                    onChange={(event) => {
                        setPayerGroupNameFiltervalue(event?.target?.value);
                    }}
                    value={payerGroupNameFilterValue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Name"
                    prefix={<SearchOutlined />}
                />
            )}
            {selectedFilterkey === "groupCode" && (
                <Input
                    onChange={(event) => {
                        setGroupCodeFiltervalue(event?.target?.value);
                    }}
                    value={groupCodeFilterValue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Group Code"
                    prefix={<SearchOutlined />}
                />
            )}

        </Space>
    );

    return {
        TableFilters,
        filteredPayerGroup,
        UseQueryHookResult: {
            refetch,
            isLoading: payergroupLoading,
            isFetching,
            pageNumber,
            setPageNumber,
            pageSize,
            setPageSize,
            sortingDetails,
            setSortingDetails,
        },
    };
}
