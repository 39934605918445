import { useCollection } from "@cloudscape-design/collection-hooks";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  CollectionPreferences,
  CollectionPreferencesProps,
  Header,
  SpaceBetween,
  Table,
  TableProps,
} from "@cloudscape-design/components";
import { Button, ConfigProvider } from "antd";
import { Facility } from "../../../redux/api/facility/types";
import useFacilityFilter from "../../../table-filters/facility-filters";
import AssociatedFacilityModals from "./associated-location-modal";

export default function AssoicatedLocationList({
  associatedFacility,
  setAssociatedFacility,
}: {
  associatedFacility: Facility[];
  setAssociatedFacility: Dispatch<SetStateAction<Facility[]>>;
}) {
  const [selectedFacility, setSelectedFacility] = useState<Facility[]>([]);
  const [isAssociatedFacilityModalOpen, setAssociatedFacilityModalOpen] =
    useState<boolean>(false);

  const { TableFilters, filteredFacilities, UseQueryHookResult } =
    useFacilityFilter(associatedFacility);
  const [preferences, setPreferences] = useState<
    CollectionPreferencesProps.Preferences<any>
  >({
    pageSize: 10,
    wrapLines: true,
  });

  const { items, collectionProps, paginationProps } = useCollection(
    filteredFacilities,
    {
      pagination: {},
    }
  );

  const tableProps: TableProps<Facility> = useMemo(() => {
    return {
      header: (
        <Header
          counter={`(${filteredFacilities?.length})`}
          actions={
            <SpaceBetween size="l" direction="horizontal">
              <Button disabled={!selectedFacility?.at(0)?.id}>Remove</Button>

              <ConfigProvider
                theme={{
                  token: {
                    colorPrimary: "#2B6BE6",
                  },
                }}
              >
                <Button
                  type="primary"
                  onClick={() => {
                    setAssociatedFacilityModalOpen(true);
                  }}
                >
                  Associate Facility
                </Button>
              </ConfigProvider>
            </SpaceBetween>
          }
        >
          Associated Facility
        </Header>
      ),
      items,
      ...collectionProps,
      preferences: (
        <CollectionPreferences
          onConfirm={({ detail }) => {
            if (!!detail) setPreferences(detail);
          }}
          preferences={preferences}
          pageSizePreference={{
            options: [
              { value: 10, label: "10 items" },
              { value: 30, label: "30 items" },
              { value: 50, label: "50 items" },
            ],
          }}
        />
      ),
      filter: TableFilters,
      variant: "container",
      loading: UseQueryHookResult.isLoading || UseQueryHookResult.isFetching,
      loadingText: "Loading...",
      selectionType: "multi",
      resizableColumns: true,
      stickyHeader: true,
      onSelectionChange: (selectionChangeDetail) => {
        setSelectedFacility(selectionChangeDetail.detail.selectedItems);
      },
      onRowClick: ({ detail }) => {
        const temp = selectedFacility;
        if (!detail.item) return;
        if (temp?.includes(detail.item))
          setSelectedFacility(temp.filter((item) => item !== detail.item));
        else setSelectedFacility([detail.item, ...temp]);
      },
      selectedItems: selectedFacility,
      trackBy: (item) => "" + item.id,
      columnDisplay: [
        { id: "name", visible: true },
        { id: "notes", visible: true },
        { id: "state", visible: true },
      ],
      columnDefinitions: [
        {
          id: "id",
          header: <div>ID</div>,
          cell: (item) => <span>{item.id}</span>,
          visibility: false,
        },
        {
          id: "name",
          header: <div>Name</div>,
          cell: (item) => <span>{item?.name}</span>,
          sortingField: "name",
        },
        {
          id: "notes",
          header: <div>Notes</div>,
          cell: (item) => <span>{item?.notes}</span>,
          sortingField: "notes",
        },
      ],
    };
  }, [items, selectedFacility]);

  return (
    <div>
      <Table {...tableProps} />
      <AssociatedFacilityModals
        associatedFacility={associatedFacility}
        isAssociatedFaciityModalOpen={isAssociatedFacilityModalOpen}
        setAssociatedFaclity={setAssociatedFacility}
        setAssociatedFaclityModalOpen={setAssociatedFacilityModalOpen}
      />
    </div>
  );
}
