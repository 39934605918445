import { useContext, useState, useMemo } from "react";
import { Flex, Layout, Space, Spin, Table, Typography } from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { TablePreferenceModal } from "../../common/TablePreferenceModal";
import IncredableButton from "../../components/button";
import TableTitle from "../../components/incredable-table/table-title";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import {
  useDeleteRoleMutation,
  useGetAllRoleQuery,
} from "../../redux/api/role/role";
import { RoleMetaData } from "../../redux/api/role/types";
import AddRoleModal from "../modals/add-role-modal";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

const columns = [
  {
    title: () => <Typography.Text>Roles</Typography.Text>,
    dataIndex: "name",
    key: "name",
    width: "50%",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
  {
    title: () => <Typography.Text>Description</Typography.Text>,
    dataIndex: "description",
    key: "description",
    ellipsis: true,
    render: (text: string) => <Typography.Text>{text || "-"}</Typography.Text>,
  },
];

export default function RolesTable() {
  const [selectedRow, setSelectedRow] = useState<RoleMetaData | undefined>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.edit") ?? false),
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("roles.delete") ?? false),
  );
  const [addRoleModal, setAddRoleModal] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    columns.map((col) => col.key as string)
  );
  const [visibleColumnOrder, setVisibleColumnOrder] = useState(
    columns.map((col) => col.key as string)
  );
  const {
    data: RoleMetaData,
    isLoading,
    refetch,
    isFetching,
  } = useGetAllRoleQuery();
  const [deleteRole] = useDeleteRoleMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();

  const visibleColumns = useMemo(() => {
    return visibleColumnOrder
      .filter((colKey) => selectedColumns.includes(colKey))
      .map((colKey) => columns.find((col) => col.key === colKey)!);
  }, [selectedColumns, visibleColumnOrder]);

  const handleModal =
    (setter: (value: boolean) => void, state?: boolean) => () => {
      setter(state ?? false);
    };

  const handleSavePreferences = (
    selectedColumns: string[],
    columnOrder: string[]
  ) => {
    setSelectedColumns(selectedColumns);
    setVisibleColumnOrder(columnOrder);
    setIsModalVisible(false);
  };

  const deleteSelectedRole = async () => {
    try {
      if (selectedRow?.id) {
        setDeleteModalOpen(false);
        spinnerContext?.showSpinner();
        await deleteRole({ roleId: String(selectedRow.id) });
        toastContext?.openSuccessNotification({
          message: `Role deleted successfully`,
          placement: "topRight",
        });
      }
    } catch (error) {
      toastContext?.openErrorNotification({
        message: `Failed to delete role`,
        placement: "topRight",
      });
    } finally {
      spinnerContext?.hidespinner();
    }
  };

  const tableTitle = (
    <Space direction="vertical" style={{ display: "flex" }}>
      <Flex
        align="start"
        justify="space-between"
        style={{ margin: "16px 10px 0 10px" }}
      >
        <Space size="middle">
          <Typography.Title
            style={{ paddingTop: "0", marginTop: "0" }}
            level={4}
          >
            {`Roles (${RoleMetaData?.length ?? ""})`}
          </Typography.Title>
        </Space>
        <Space size="middle">
          <IncredableButton
            onClick={handleModal(setAddRoleModal, true)}
            type="primary"
            icon={<PlusOutlined />}
            disabled={!hasPermission}
            size="large"
          >
            Add Role
          </IncredableButton>
        </Space>
      </Flex>
      <TableTitle
        onReloadClick={refetch}
        actionDropDownProps={{
          disabled: !selectedRow,
          menu: {
            items: [
              { label: "Details", key: "details", icon: <ProfileOutlined /> },
              {
                label: "Delete",
                key: "delete",
                icon: <DeleteOutlined />,
                disabled: !hasDeletePermission,
                danger: true,
              },
            ],
            onClick: (e) => {
              if (e.key === "details") {
                navigate(`/settings/roles/${selectedRow?.id}`);
              } else if (e.key === "delete") {
                setDeleteModalOpen(true);
              }
            },
          },
        }}
        onSettingsClick={handleModal(setIsModalVisible, true)}
      />
    </Space>
  );

  return (
    <Layout>
      <Space size="large" direction="vertical">
        <Table
          size="small"
          columns={visibleColumns}
          loading={isLoading || isFetching}
          rowKey="id"
          dataSource={RoleMetaData}
          onRow={(record) => ({
            onClick: () =>
              setSelectedRow((prev) =>
                prev?.id === record.id ? undefined : record
              ),
          })}
          rowSelection={{
            type: "radio",
            selectedRowKeys: selectedRow ? [selectedRow.id] : [],
            onChange: (_, selectedRows) => setSelectedRow(selectedRows[0]),
            columnWidth: 60,
          }}
          pagination={{
            position: ["bottomRight"],
            style: { backgroundColor: "white", padding: "10px", margin: "0" },
            pageSizeOptions: [10, 25, 50, 100, 300],
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          title={() => tableTitle}
        />
        <TablePreferenceModal
          visible={isModalVisible}
          onCancel={handleModal(setIsModalVisible)}
          onSave={handleSavePreferences}
          availableColumns={columns.map((col) => col.key as string)}
          selectedColumns={selectedColumns}
          columnOrderState={visibleColumnOrder}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={deleteSelectedRole}
          closeModal={handleModal(setDeleteModalOpen)}
          header="Delete Role"
          content={
            <>
              <Typography.Text>Role</Typography.Text>
              <Typography.Text strong style={{ marginTop: "8px" }}>
                {selectedRow?.name}
              </Typography.Text>
            </>
          }
          description="Are you sure you want to delete this Role?"
        />
      </Space>
      <AddRoleModal
        visible={addRoleModal}
        onClose={handleModal(setAddRoleModal)}
      />
    </Layout>
  );
}
