import { api } from "..";
import {
  AlterPreferenceType,
  ProfileTypes,
  UpdateProfileDetailsType,
} from "./types";

const ProfileApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProfileDetails: build.query<ProfileTypes, void>({
      query: () => "user",
      providesTags: ["UserProfile"],
    }),

    updateProfile: build.mutation<
      void,
      Pick<ProfileTypes, "id"> & Partial<UpdateProfileDetailsType>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `user`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "UserProfile", id: arg.id },
        { type: "UserProfile" },
      ],
    }),

    updateAlertPrefernces: build.mutation<void, AlterPreferenceType>({
      query: (body) => {
        return {
          url: `user/preferences/alerts`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, arg) => [{ type: "UserProfile" }],
    }),
  }),
});

export const {
  useGetProfileDetailsQuery,
  useUpdateProfileMutation,
  useUpdateAlertPreferncesMutation,
} = ProfileApi;
