import { api } from "..";
import {
  WorkFlowStatus,
  WorkFlowStatusRequest,
  WorkFlowTaskRequest,
  WorkFlowTemplate,
  WorkFlowTemplateRequest,
  Workflow,
  WorkflowMetadata,
  WorkflowRequest,
} from "./types";

const WorkflowAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllWorkflow: build.query<WorkflowMetadata[], void>({
      query: () => "/workflow/all",
      providesTags: ["Workflow"],
    }),
    getWorkflow: build.query<Workflow, { id: string }>({
      query: ({ id }) => `/workflow/${id}`,
      providesTags: ["Workflow", "Task"],
    }),
    addWorkflow: build.mutation<void, WorkflowRequest>({
      query: ({ ...body }) => {
        return {
          url: "workflow",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Workflow"],
    }),
    updateWorkflow: build.mutation<
      void,
      { id: string } & Partial<WorkflowRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `workflow/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Workflow", id: arg.id },
        { type: "Workflow" },
      ],
    }),
    patchWorkflow: build.mutation<
      void,
      { id: string } & { request: Record<string, string> }
    >({
      query: ({ id, request }) => {
        return {
          url: `workflow/${id}/patch`,
          method: "PUT",
          body: { ...request },
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Workflow", id: arg.id },
        { type: "Workflow" },
      ],
    }),
    deleteWorkflow: build.mutation<
      void,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `workflow/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Workflow"],
    }),

    getAllWorkflowStatus: build.query<WorkFlowStatus[], void>({
      query: () => `workflow/status/all`,
      providesTags: ["Workflow"],
    }),
    saveAllWorkflowStatus: build.mutation<void, WorkFlowStatusRequest[]>({
      query: (statusTypes) => {
        return {
          url: "workflow/status/all",
          method: "POST",
          body: statusTypes,
        };
      },
      invalidatesTags: ["Workflow"],
    }),
    getAllWorkflowTemplate: build.query<WorkFlowTemplate[], void>({
      query: () => "/workflowtemplate/all",
      providesTags: ["Workflow"],
    }),
    getWorkflowTemplate: build.query<WorkFlowTemplate, { id: string }>({
      query: ({ id }) => `/workflowtemplate/${id}`,
      providesTags: ["Workflow"],
    }),
    addWorkflowTemplate: build.mutation<void, WorkFlowTemplateRequest>({
      query: ({ ...body }) => {
        return {
          url: "workflowtemplate",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Workflow"],
    }),
    updateWorkflowTemplate: build.mutation<
      void,
      { id: string } & Partial<WorkFlowTemplateRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `workflowtemplate/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Workflow", id: arg.id },
        { type: "Workflow" },
      ],
    }),
    deleteWorkflowTemplate: build.mutation<
      void,
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `workflowtemplate/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Workflow"],
    }),

    addWorkflowTask: build.mutation<
      void,
      { workflowId: string } & WorkFlowTaskRequest
    >({
      query: ({ workflowId, ...body }) => {
        return {
          url: `/workflow/${workflowId}/task`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Workflow"],
    }),
    updateWorkflowTask: build.mutation<
      void,
      { workflowId: string } & { taskId: string } & WorkFlowTaskRequest
    >({
      query: ({ workflowId, taskId, ...body }) => {
        return {
          url: `/workflow/${workflowId}/task/${taskId}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: ["Workflow"],
    }),
    addWorkflowStatus: build.mutation<void, WorkFlowStatusRequest>({
      query: ({ ...body }) => {
        return {
          url: "workflow/status",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Workflow"],
    }),
    editWorkflowStatus: build.mutation<
      void,
      { id: string } & Partial<WorkFlowStatusRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `workflow/status/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Workflow", id: arg.id },
        { type: "Workflow" },
      ],
    }),
    deleteWorkflowStatus: build.mutation<
      void,
      {
        workflowStatusId: string;
      }
    >({
      query: ({ workflowStatusId }) => ({
        url: `workflow/status/${workflowStatusId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Workflow"],
    }),
  }),
});

export const {
  usePatchWorkflowMutation,
  useGetAllWorkflowQuery,
  useGetWorkflowQuery,
  useAddWorkflowMutation,
  useUpdateWorkflowMutation,
  useDeleteWorkflowMutation,
  useGetAllWorkflowStatusQuery,
  useSaveAllWorkflowStatusMutation,
  useGetAllWorkflowTemplateQuery,
  useGetWorkflowTemplateQuery,
  useAddWorkflowTemplateMutation,
  useUpdateWorkflowTemplateMutation,
  useDeleteWorkflowTemplateMutation,
  useAddWorkflowTaskMutation,
  useUpdateWorkflowTaskMutation,
  useAddWorkflowStatusMutation,
  useEditWorkflowStatusMutation,
  useDeleteWorkflowStatusMutation,
} = WorkflowAPI;
