import { api } from "..";
import { BoardName, BoardNameRequest } from "./types";

const boardApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllBoardName: build.query<BoardName[], void>({
      query: () => `boardname/all`,
      providesTags: ["BoardName"],
    }),
    saveAllBoardName: build.mutation<void, BoardName[]>({
      query: (types) => {
        return {
          url: `boardname`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["BoardName"],
    }),
    saveBoardName: build.mutation<void, BoardNameRequest>({
      query: ({ ...body }) => {
        return {
          url: "boardname",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["BoardName"],
    }),
    updateBoardName: build.mutation<
      void,
      { id: string } & Partial<BoardNameRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `boardname/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "BoardName", id: arg.id },
        { type: "BoardName" },
      ],
    }),
    deleteBoardName: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `boardname/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BoardName"],
    }),
  }),
});

export const {
  useGetAllBoardNameQuery,
  useSaveAllBoardNameMutation,
  useSaveBoardNameMutation,
  useUpdateBoardNameMutation,
  useDeleteBoardNameMutation,
} = boardApi;
