import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { api } from "./api";
import { uiReducer } from "./UI/reducer";
import { UIState } from "./UI/types";
import { AuthState } from "../context/types";
import { jwtReducer } from "../context/JWTContext";

const rootReducer = combineReducers({
  ui: uiReducer,
  auth: jwtReducer,
  [api.reducerPath]: api.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  // adding the api middleware enables caching, invalidation, polling and other features of `rtk-query`
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

// The top-level state object
export interface ApplicationState {
  auth: AuthState;
  ui: UIState;
}
