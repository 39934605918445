import React from "react";
import {
  Dropdown,
  Avatar,
  Button,
  Typography,
  Row,
  Col,
  Space,
  Flex,
} from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import useAuth from "../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../routes/path";
import { Images } from "../../common/images";
import { Content } from "antd/es/layout/layout";
import type { MenuProps } from "antd";
import { TopHeaderBarProps } from "./topbar.interface";
import {
  headerProfileImage,
  headerProfilePic,
  menuTitle,
  profileRole,
  profileSection,
  subHeadingStyle,
} from "./topbar.styles";
import { useGetOrganizationDetailsQuery } from "../../redux/api/organization/organization";
import { useGetProfileDetailsQuery } from "../../redux/api/profile/profile";

const { Text } = Typography;

const TopHeaderBar: React.FC<TopHeaderBarProps> = ({
  showDrawer,
  activeMenu,
}) => {
  const { logout } = useAuth();
  const { data: organization } = useGetOrganizationDetailsQuery();
  const { data: user } = useGetProfileDetailsQuery();
  const headingStyle = {
    color: "#7C4095",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "14px",
  };

  const items: MenuProps["items"] = [
    {
      key: "email",
      label: (
        <Space style={profileSection}>
          <Row justify="center" align="middle" style={headerProfilePic}>
            <Col>
              <Avatar src={Images.profilePic} style={headerProfileImage} />
            </Col>
          </Row>

          <Flex vertical={true}>
            <Text
              style={{ fontSize: "16px", color: "#fff", fontWeight: "600" }}
            >
              {organization?.name}
            </Text>
            <Text style={subHeadingStyle}>{user?.email}</Text>
          </Flex>
        </Space>
      ),
    },
    {
      type: "divider",
      style: { height: "1px", background: "#7C4095" },
    },
    {
      key: "organization",
      label: (
        <a href="/org" style={headingStyle}>
          Organization
        </a>
      ),
    },
    {
      key: "general",
      label: (
        <span style={{ color: "#fff" }}>
          <a href="/org" style={subHeadingStyle}>
            <img src={Images.setting} />
            General
          </a>
        </span>
      ),
    },
    {
      key: "members",
      label: (
        <span style={{ color: "#fff" }}>
          <a href="/manageadmin" style={subHeadingStyle}>
            <img src={Images.userGroup} />
            Members
          </a>
        </span>
      ),
    },
    {
      key: "rolesandpermissions",
      label: (
        <span style={{ color: "#fff" }}>
          <a href={PATH_DASHBOARD.roles.list} style={subHeadingStyle}>
            <img src={Images.userWithKey} /> Roles and Permissions
          </a>
        </span>
      ),
    },
    {
      type: "divider",
      style: { height: "1px", background: "#7C4095" },
    },
    {
      key: "account",
      label: <span style={headingStyle}>Account</span>,
    },
    {
      key: "profile",
      label: (
        <span style={{ color: "#fff" }}>
          <a href="/settings/profile" style={subHeadingStyle}>
            <img src={Images.userIcon} />
            Profile
          </a>
        </span>
      ),
    },
    /*   {
      key: "support",
      label: (
        <span style={{ color: "#fff" }}>
          <a href="/support" style={subHeadingStyle}>
            <img src={Images.support} /> Support
          </a>
        </span>
      ),
    }, */
    {
      type: "divider",
      style: { height: "1px", background: "#7C4095" },
    },
    {
      key: "signout",
      label: (
        <span
          style={{
            color: "#598BEB",
            fontWeight: "700",
            fontSize: "16px",
            lineHeight: "14px",
          }}
          onClick={logout}
        >
          <LogoutOutlined style={{ marginRight: "8px", marginLeft: "2px" }} />{" "}
          Sign Out
        </span>
      ),
    },
  ];

  const organizationMenu: MenuProps = {
    items,
    style: {
      backgroundColor: "#521262",
    },
  };

  return (
    <Flex style={{ width: "100%" }} justify="space-between" align="center">
      <img src={Images.logo} alt="Logo" className="logo" />
      <Space direction="horizontal">
        <Button type="link" onClick={showDrawer}>
          <img src={Images.tick} alt="" />
          <Text>My Tasks</Text>
        </Button>
        <Dropdown menu={organizationMenu}>
          <Space style={profileSection}>
            <Row justify="center" align="middle" style={headerProfilePic}>
              <Col>
                <Avatar src={Images.profilePic} style={headerProfileImage} />
              </Col>
            </Row>
            <Space style={profileRole}>
              <Text
                style={{
                  margin: "5px 0",
                  color: "rgba(81, 81, 91, 1)",
                }}
              >
                {organization?.name}
              </Text>
              <Text
                style={{
                  color: "rgba(138, 146, 166, 1)",
                  fontSize: "13px",
                }}
              >
                {user?.role} <img src={Images.downArrow} />
              </Text>
            </Space>
          </Space>
        </Dropdown>
      </Space>
    </Flex>
  );
};

export default TopHeaderBar;
