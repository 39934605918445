import { notification } from "antd";
import { ArgsProps } from "antd/es/notification";
import { createContext } from "react";

export type ToastContextType = {
  openInfoNotification: (args: ArgsProps) => void;
  openSuccessNotification: (args: ArgsProps) => void;
  openErrorNotification: (args: ArgsProps) => void;
  openWarningNotification: (args: ArgsProps) => void;
};

const ToastContext = createContext<ToastContextType | null>(null);

interface IProps {
  children: React.ReactNode;
}

export default function IncredableToast({ children }: IProps) {
  const [api, contextHolder] = notification.useNotification();
  const openSuccessNotification = (args: ArgsProps) => {
    api.success(args);
  };
  const openErrorNotification = (args: ArgsProps) => {
    api.error(args);
  };
  const openInfoNotification = (args: ArgsProps) => {
    api.info(args);
  };
  const openWarningNotification = (args: ArgsProps) => {
    api.warning(args);
  };

  return (
    <ToastContext.Provider
      value={{
        openSuccessNotification,
        openErrorNotification,
        openInfoNotification,
        openWarningNotification,
      }}
    >
      {contextHolder}
      {children}
    </ToastContext.Provider>
  );
}

export { ToastContext };
