import { api } from "..";
import { PayerGroup, PayerGroupRequest } from "./types";
const payerGroupAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getAllPayerGroup: build.query<PayerGroup[], void>({
      query: () => `payer/payergroup/all`,
      providesTags: ["Payergroup"],
    }),
    getPayerGroup: build.query<PayerGroup, { id: string }>({
      query: ({ id }) => `payer/payergroup/${id}`,
      providesTags: (result, error, { id }) => [{ type: "Payergroup", id: id }],
    }),
    createPayerGroup: build.mutation<PayerGroup, PayerGroupRequest>({
      query: ({ ...body }) => {
        return {
          url: `payer/payergroup`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Payergroup"],
    }),
    updatePayerGroup: build.mutation<
      PayerGroup,
      Pick<PayerGroup, "id"> & Partial<PayerGroupRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `payer/payergroup/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Payergroup", id: arg.id },
        { type: "Payergroup" },
      ],
    }),
    deletePayerGroup: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `payer/payergroup/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Payergroup"],
    }),
    updateAssociatedPayer: build.mutation<
      void,
      { payerGroupID: string; payersToUpdate: string[] }
    >({
      query: ({ payerGroupID, payersToUpdate }) => ({
        url: `payer/payergroup/${payerGroupID}/payer`,
        method: "POST",
        body: payersToUpdate, // Wrap faciltiesToRemove in an object
      }),
      invalidatesTags: ["Payergroup"],
    }),
    deleteAssociatedPayer: build.mutation<
      void,
      { payerGroupID: string; deleteassociatedPayer: string[] }
    >({
      query: ({ payerGroupID, deleteassociatedPayer }) => ({
        url: `payer/payergroup/${payerGroupID}/payer`,
        method: "DELETE",
        body: deleteassociatedPayer, // Wrap deleteassociatedPayer in an object
      }),
      invalidatesTags: ["Payergroup"],
    }),
  }),
});

export const {
  useGetAllPayerGroupQuery,
  useGetPayerGroupQuery,
  useCreatePayerGroupMutation,
  useUpdatePayerGroupMutation,
  useDeletePayerGroupMutation,
  useUpdateAssociatedPayerMutation,
  useDeleteAssociatedPayerMutation,
} = payerGroupAPI;
