import {
  Card,
  Col,
  Row,
  Space,
  Typography,
  Divider,
  Flex,
  Form,
  Radio,
} from "antd";
import IncredableContent from "../components/incredable-content";
import { FormProvider, useForm } from "react-hook-form";
import IncredableButton from "../components/button";
import RHFTextField from "../components/RHF/RHFTextField";
import { useState, useEffect, useContext } from "react";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";
import {
  useGetProfileDetailsQuery,
  useUpdateAlertPreferncesMutation,
  useUpdateProfileMutation,
} from "../redux/api/profile/profile";
import { AlterPreferenceType } from "../redux/api/profile/types";
import { SpinnerContext } from "../context/spinner/spinner";
import { ToastContext } from "../context/toast/incredable-toast";

const { Text } = Typography;

type IFormInput = {
  firstName: string;
  lastName: string;
  preferredName: string;
};

export default function Profile() {
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const methods = useForm<IFormInput>();

  const { data: user, isFetching, isLoading } = useGetProfileDetailsQuery();
  const { data: organization } = useGetOrganizationDetailsQuery();

  const [alertPreference, setAlertPreference] = useState<AlterPreferenceType>();

  const [updateAlertPrefernces] = useUpdateAlertPreferncesMutation();

  const [updateProfile] = useUpdateProfileMutation();

  useEffect(() => {
    if (user) {
      methods.setValue("firstName", user?.firstName);
      methods.setValue("lastName", user?.lastName);
      methods.setValue("preferredName", user?.preferredName);
      setAlertPreference({
        enableDocumentAlerts: user?.enableDocumentAlerts,
        enablePrivilegeAlerts: user?.enablePrivilegeAlerts,
        enablePackageAlerts: user?.enablePackageAlerts,
        enableEnrollmentAlerts: user?.enableEnrollmentAlerts,
        enableContractAlerts: user?.enableContractAlerts,
        enableAffiliationAlerts: user?.enableAffiliationAlerts,
        enableSignatureAlerts: user?.enableSignatureAlerts,
      });
    }
  }, [user]);

  return (
    <>
      <IncredableContent
        spinning={isLoading || isFetching}
        style={{ margin: "30px 20px 0" }}
      >
        <FormProvider {...methods}>
          <Form
            name="profile"
            style={{ padding: "0", margin: "0" }}
            layout="vertical"
            onFinish={methods.handleSubmit(async (data: IFormInput) => {
              if (user?.id) {
                spinnerContext?.showSpinner();
                await updateProfile({
                  id: user?.id,
                  firstName: data.firstName,
                  lastName: data.lastName,
                  preferredName: data.preferredName,
                });
                spinnerContext?.hidespinner();
                toastContext?.openInfoNotification({
                  message: `Profile updated successfully`,
                  placement: "topRight",
                });
              }
            })}
            autoComplete="off"
          >
            <Flex justify="space-between">
              <Typography.Title style={{ padding: "0", margin: "0" }} level={4}>
                Profile
              </Typography.Title>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Flex>
            <Divider />
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "First Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "firstName",
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Last Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "lastName",
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
              <Col span={8}>
                <RHFTextField
                  formItemProps={{
                    label: "Preferred Name",
                  }}
                  inputProps={{}}
                  controllerProps={{
                    name: "preferredName",
                  }}
                  rules={{ required: "This field is required" }}
                />
              </Col>
            </Row>
          </Form>
        </FormProvider>
      </IncredableContent>
      <IncredableContent style={{ margin: "30px 20px 0" }}>
        <Row>
          <Col span={12}>
            <Space direction="vertical" size="large">
              <Space direction="vertical">
                <Text strong>Email</Text>
                <Text>{user?.email ?? "--"}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Organization</Text>
                <Text>{user?.org ?? "--"}</Text>
              </Space>
            </Space>
          </Col>
          <Col span={12} style={{ marginTop: "10px" }}>
            <Space direction="vertical" size="large">
              <Space direction="vertical">
                <Text strong>Role</Text>
                <Text>{user?.role ?? "--"}</Text>
              </Space>
              <Space direction="vertical">
                <Text strong>Last Login</Text>
                <Text>
                  {user?.lastLogin
                    ? new Date(user?.lastLogin)?.toLocaleString()
                    : "-"}
                </Text>
              </Space>
            </Space>
          </Col>
        </Row>
      </IncredableContent>
      <IncredableContent style={{ margin: "30px 20px 0", padding: "0" }}>
        <Card
          bordered={false}
          title="Expiration Alert Preferences"
          extra={
            <IncredableButton
              type="primary"
              onClick={async () => {
                if (!!alertPreference) {
                  spinnerContext?.showSpinner();
                  await updateAlertPrefernces(alertPreference);
                  spinnerContext?.hidespinner();
                  toastContext?.openInfoNotification({
                    message: `Alert Preference Updated Successfully`,
                    placement: "topRight",
                  });
                }
              }}
            >
              Save
            </IncredableButton>
          }
        >
          <Form layout="vertical">
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <Row gutter={16} align="middle">
                <Col span={12}>
                  <Text strong style={{ margin: 0 }}>
                    Document Expiration Alerts
                  </Text>
                </Col>
                <Col span={6}>
                  <Radio.Group
                    value={alertPreference?.enableDocumentAlerts}
                    onChange={(e) => {
                      setAlertPreference({
                        ...alertPreference,
                        enableDocumentAlerts: e.target.value,
                      });
                    }}
                  >
                    <Radio value={true}>Enable</Radio>
                    <Radio value={false}>Disable</Radio>
                  </Radio.Group>
                </Col>
              </Row>

              {!!organization?.organizationServiceLimits?.privileges && (
                <Row gutter={16} align="middle">
                  <Col span={12}>
                    <Text strong style={{ margin: 0 }}>
                      Affiliation  Expiration Alerts
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Radio.Group
                      value={alertPreference?.enableAffiliationAlerts}
                      onChange={(e) => {
                        setAlertPreference({
                          ...alertPreference,
                          enableAffiliationAlerts: e.target.value,
                        });
                      }}
                    >
                      <Radio value={true}>Enable</Radio>
                      <Radio value={false}>Disable</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              )}

              {!!organization?.organizationServiceLimits?.payerEnrollments && (
                <Row gutter={16} align="middle">
                  <Col span={12}>
                    <Text strong style={{ margin: 0 }}>
                      Payer Enrollment Expiration Alerts
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Radio.Group
                      value={alertPreference?.enableEnrollmentAlerts}
                      onChange={(e) => {
                        setAlertPreference({
                          ...alertPreference,
                          enableEnrollmentAlerts: e.target.value,
                        });
                      }}
                    >
                      <Radio value={true}>Enable</Radio>
                      <Radio value={false}>Disable</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              )}

              {!!organization?.organizationServiceLimits?.eSignatures && (
                <Row gutter={16} align="middle">
                  <Col span={12}>
                    <Text strong style={{ margin: 0 }}>
                      e-Signature Expiration Alerts
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Radio.Group
                      value={alertPreference?.enableSignatureAlerts}
                      onChange={(e) => {
                        setAlertPreference({
                          ...alertPreference,
                          enableSignatureAlerts: e.target.value,
                        });
                      }}
                    >
                      <Radio value={true}>Enable</Radio>
                      <Radio value={false}>Disable</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              )}

              {!!organization?.organizationServiceLimits?.contracts && (
                <Row gutter={16} align="middle">
                  <Col span={12}>
                    <Text strong style={{ margin: 0 }}>
                      Contract Expiration Alerts
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Radio.Group
                      value={alertPreference?.enableContractAlerts}
                      onChange={(e) => {
                        setAlertPreference({
                          ...alertPreference,
                          enableContractAlerts: e.target.value,
                        });
                      }}
                    >
                      <Radio value={true}>Enable</Radio>
                      <Radio value={false}>Disable</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              )}

              {!!organization?.organizationServiceLimits?.tasks && (
                <Row gutter={16} align="middle">
                  <Col span={12}>
                    <Text strong style={{ margin: 0 }}>
                      Tasks Expiration Alerts
                    </Text>
                  </Col>
                  <Col span={6}>
                    <Radio.Group
                      value={alertPreference?.enableTaskAlerts}
                      onChange={(e) => {
                        setAlertPreference({
                          ...alertPreference,
                          enableTaskAlerts: e.target.value,
                        });
                      }}
                    >
                      <Radio value={true}>Enable</Radio>
                      <Radio value={false}>Disable</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              )}
            </Space>
          </Form>
        </Card>
      </IncredableContent>
    </>
  );
}
