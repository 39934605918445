import { Button, ConfigProvider, Dropdown, Space, Spin, theme, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    PlusOutlined,
    ReloadOutlined,
} from "@ant-design/icons";
import { useCollection } from "@cloudscape-design/collection-hooks";
import IncredableButton from "../../components/button";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import IncredableContent from "../../components/incredable-content";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { BoardName } from "../../redux/api/boardName/types";
import { useDeleteBoardNameMutation, useGetAllBoardNameQuery } from "../../redux/api/boardName/board";
import { useLocalStorage } from "../../common/localStorage";
import { BOARDNAME_CONTENT_DISPLAY_OPTIONS, BOARDNAME_TABLE_DEFAULT_PREFERENCES, TablePreferences } from "../table-config";
import { Header, Pagination, Table } from "@cloudscape-design/components";

const { getDesignToken } = theme;
const globalToken = getDesignToken();

export default function BoardNameListV2() {
    const navigate = useNavigate();
    const [selectedRows, setSelectedRows] = useState<BoardName[]>([]);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
    const spinnerContext = useContext(SpinnerContext);
    const toastContext = useContext(ToastContext);
    const [deleteBoardName] = useDeleteBoardNameMutation();

    const {
        data: boardname,
        isLoading,
        isFetching,
        error,
        refetch,
    } = useGetAllBoardNameQuery();

    const [tablePreferences, setTablePreferences] = useLocalStorage(
        "RD-BoardName-Table-Preferences",
        BOARDNAME_TABLE_DEFAULT_PREFERENCES
    );

    const { items, collectionProps, paginationProps } = useCollection(
        boardname ?? [],
        {
            pagination: { pageSize: tablePreferences?.pageSize },
        }
    );

    if (isLoading) {
        return <Spin />;
    }

    if (error) {
        return <div>Error loading board name</div>;
    }
    return (
        <>
            <IncredableButton style={{
                margin: "12px 0 0 48px",
                padding: "12px",
            }}
                secondary
                onClick={() => navigate(-1)}
            >
                Go back
            </IncredableButton>
            <IncredableContent style={{ margin: "12px 0 0 48px" }} spinning={isLoading || isFetching}>
                <Table
                    loading={isFetching || isLoading}
                    onSelectionChange={({ detail }) => {
                        setSelectedRows([...detail.selectedItems]);
                    }}
                    onRowClick={(onRowClickDetails) => {
                        const selectedItem = onRowClickDetails?.detail?.item;
                        if (selectedItem) {
                            const temp = selectedRows ? [...selectedRows] : [];
                            const selectedItemIndex = temp.findIndex(
                                (record) => record?.id + "" === "" + selectedItem?.id
                            );
                            if (selectedItemIndex !== -1) {
                                temp?.splice(selectedItemIndex, 1);
                                setSelectedRows(temp);
                            } else {
                                setSelectedRows([...temp, selectedItem]);
                            }
                        }
                    }}
                    selectedItems={selectedRows || []}
                    columnDefinitions={[
                        {
                            id: "name",
                            header: (
                                <div>Name</div>
                            ),
                            cell: (item) =>
                                <NavLink to={`${item.id}/edit`}>
                                    {item?.name}
                                </NavLink>,
                        },
                    ]}
                    items={items}
                    pagination={
                        < Pagination
                            {...paginationProps}
                        />
                    }
                    preferences={
                        < TablePreferences
                            preferences={tablePreferences}
                            setPreferences={(preferences) => {
                                setTablePreferences(preferences);
                            }}
                            contentDisplayOptions={BOARDNAME_CONTENT_DISPLAY_OPTIONS}
                        />
                    }
                    loadingText="Loading resources"
                    selectionType="single"
                    trackBy="id"
                    header={
                        < div style={{ marginTop: "8px", marginBottom: "12px" }}>
                            <Header
                                actions={
                                    <Space size="middle">
                                        <Button
                                            onClick={() => {
                                                !!refetch && refetch();
                                            }}
                                        >
                                            <ReloadOutlined />
                                        </Button>
                                        <Dropdown
                                            menu={{
                                                disabled: selectedRows?.length !== 1,
                                                items: [
                                                    {
                                                        label: "Edit",
                                                        key: "edit",
                                                        icon: <EditOutlined />,
                                                        onClick: () => { navigate(`${selectedRows?.at(0)?.id}/edit`); },
                                                    },
                                                    {
                                                        label: "Delete",
                                                        key: "delete",
                                                        onClick: () => setDeleteModalOpen(true),
                                                        icon: <DeleteOutlined />,
                                                        danger: true,
                                                    },
                                                ],
                                            }}
                                        >
                                            <Button>
                                                Actions
                                                <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                        <ConfigProvider
                                            theme={{
                                                token: {
                                                    colorPrimary: "#2B6BE6",
                                                },
                                            }}
                                        >
                                            <Button
                                                onClick={() => navigate(`add`)}
                                                type="primary"
                                                icon={<PlusOutlined />}
                                            >
                                                Add Board Name
                                            </Button>
                                        </ConfigProvider>
                                    </Space>
                                }
                            >
                                <Typography.Title level={3} style={{ marginTop: "0" }}>
                                    Board Name{" "}
                                    <Typography.Text
                                        type="secondary"
                                        style={{ fontSize: "16px" }}
                                    >{`(${boardname?.length})`}</Typography.Text>
                                </Typography.Title>
                            </Header>
                        </div >
                    }
                    {...collectionProps}
                    columnDisplay={tablePreferences?.contentDisplay}
                    wrapLines={tablePreferences?.wrapLines}
                    stripedRows={tablePreferences?.stripedRows}
                    contentDensity={tablePreferences?.contentDensity}
                    stickyColumns={tablePreferences?.stickyColumns}
                />
                <DeleteAlertModal
                    visible={isDeleteModalOpen}
                    action={async () => {
                        if (selectedRows?.at(0)?.id) {
                            setDeleteModalOpen(false);
                            spinnerContext?.showSpinner();
                            await deleteBoardName({
                                id: String(selectedRows?.at(0)?.id),
                            });
                            spinnerContext?.hidespinner();
                            toastContext?.openSuccessNotification({
                                message: `Board Name deleted successfully`,
                                placement: "topRight",
                            });
                        }
                    }}
                    closeModal={() => setDeleteModalOpen(false)}
                    header="Delete Board Name"
                    content={
                        <Typography.Text>
                            Board Name:{" "}
                            <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
                        </Typography.Text>
                    }
                    description="Are you sure you want to delete this board name?"
                />
            </IncredableContent>
        </>
    );
}
