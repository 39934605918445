import { api } from "..";
import { Facility, FacilityRequest, FacilityType, FacilityTypeRequest } from "./types";

const FacilityAPI = api.injectEndpoints({
  endpoints: (build) => ({
    getFacilities: build.query<Facility[], void>({
      query: () => "/facility/all",
      providesTags: ["Facility"],
    }),
    getFacility: build.query<Facility, { id: string }>({
      query: ({ id }) => `/facility/${id}`,
      providesTags: ["Facility"],
    }),
    postFacilityRecord: build.mutation<
      void,
      { facilityId: string; recordId?: string; data: any }
    >({
      query({ facilityId, ...body }) {
        return {
          url: `facility/${facilityId}/profile`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Facility" }],
    }),

    deleteRecord: build.mutation<
      void,
      { facilityId: string; recordId: string }
    >({
      query({ facilityId, recordId }) {
        return {
          url: `facility/${facilityId}/profile/${recordId}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, args) => [{ type: "Facility" }],
    }),

    addFacility: build.mutation<void, FacilityRequest>({
      query: ({ ...body }) => {
        return {
          url: "facility",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Facility"],
    }),
    updateFacility: build.mutation<
      void,
      Pick<Facility, "id"> & Partial<FacilityRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `facility/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Facility", id: arg.id },
        { type: "Facility" },
      ],
    }),
    deleteFacility: build.mutation<
      void,
      {
        facilityId: string;
      }
    >({
      query: ({ facilityId }) => ({
        url: `facility/${facilityId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Facility"],
    }),

    getAllFacilityTypes: build.query<FacilityType[], void>({
      query: () => `facility/type/all`,
      providesTags: ["Facility"],
    }),
    
    saveAllFacilityTypes: build.mutation<void, FacilityType[]>({
      query: (facilityTypes) => {
        return {
          url: "facility/type/all",
          method: "POST",
          body: facilityTypes,
        };
      },
      invalidatesTags: ["Facility"],
    }),
    saveFacilityType: build.mutation<void, FacilityTypeRequest>({
      query: ({ ...body }) => {
        return {
          url: "facility/type",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Facility"],
    }),
    updateFacilityType: build.mutation<
      void,
      { id: string } & Partial<FacilityTypeRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `facility/type/${id}`,
          method: "POST",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Facility", id: arg.id },
        { type: "Facility" },
      ],
    }),
    deleteFacilityType: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `facility/type/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Facility"],
    }),
  }),
});

export const {
  useGetFacilitiesQuery,
  useGetFacilityQuery,
  useAddFacilityMutation,
  useUpdateFacilityMutation,
  useDeleteFacilityMutation,
  useDeleteRecordMutation,
  usePostFacilityRecordMutation,
  useGetAllFacilityTypesQuery,
  useSaveAllFacilityTypesMutation,
  useSaveFacilityTypeMutation,
  useUpdateFacilityTypeMutation,
  useDeleteFacilityTypeMutation
} = FacilityAPI;
