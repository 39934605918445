import { useCollection } from "@cloudscape-design/collection-hooks";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
    CollectionPreferences,
    CollectionPreferencesProps,
    Header,
    Link,
    SpaceBetween,
    Table,
    TableProps,
} from "@cloudscape-design/components";
import { Payers } from "../../../redux/api/payers/types";
import usePayerFilter from "../../../table-filters/payer-filters";
import { useDeleteAssociatedPayerMutation } from "../../../redux/api/payergroup/payergroup";
import { Button } from "antd";
import AssociatedPayersModals from "./associated-payer-modal";

export default function AssoicatedPayersList({
    associatedPayers,
    setAssociatedPayers,
}: {
    associatedPayers: Payers[];
    setAssociatedPayers: Dispatch<SetStateAction<Payers[]>>;
}) {
    const [selectedPayers, setSelectedPayers] = useState<Payers[]>([]);
    const [isAssociatedPayersModalOpen, setAssociatedPayersModalOpen] =
        useState<boolean>(false);
    const { payergroupId } = useParams();

    const { TableFilters, filteredPayers, UseQueryHookResult } = usePayerFilter(associatedPayers);
    const [deleteAssociatedPayer] = useDeleteAssociatedPayerMutation();
    const [preferences, setPreferences] = useState<
        CollectionPreferencesProps.Preferences<any>
    >({
        pageSize: 10,
        wrapLines: true,
    });

    const { items, collectionProps, paginationProps } = useCollection(
        filteredPayers,
        {
            pagination: {},
        }
    );

    const tableProps: TableProps<Payers> = useMemo(() => {
        return {
            header: (
                <Header
                    counter={`(${filteredPayers?.length})`}
                    actions={
                        <SpaceBetween size="l" direction="horizontal">
                            <Button
                                disabled={!selectedPayers?.at(0)?.id}
                                onClick={async () => {
                                    try {
                                        if (!payergroupId) return;
                                        const payerToRemove =
                                            selectedPayers?.map(
                                                (selectedPayer) => selectedPayer?.id + "",
                                            ) || [];

                                        await deleteAssociatedPayer({
                                            payerGroupID: payergroupId,
                                            deleteassociatedPayer: payerToRemove,
                                        });

                                        const temp = associatedPayers.filter(
                                            (payer) => !payerToRemove.includes(payer?.id + ""),
                                        );
                                        setAssociatedPayers(temp);
                                        setSelectedPayers([]);
                                    } catch (error) {
                                        console.error("Error deleting payer:", error);
                                        // Handle error (e.g., show a message to the user)
                                    }
                                }}
                            >
                                Remove
                            </Button>

                            <Button
                                type="primary"
                                onClick={() => {
                                    setAssociatedPayersModalOpen(true);
                                }}
                            >
                                Associate Payer
                            </Button>
                        </SpaceBetween>
                    }
                >
                    Associated Payers
                </Header>
            ),
            items,
            ...collectionProps,
            preferences: (
                <CollectionPreferences
                    onConfirm={({ detail }) => {
                        if (!!detail) setPreferences(detail);
                    }}
                    preferences={preferences}
                    pageSizePreference={{
                        options: [
                            { value: 10, label: "10 items" },
                            { value: 30, label: "30 items" },
                            { value: 50, label: "50 items" },
                        ],
                    }}
                />
            ),
            filter: TableFilters,
            variant: "container",
            loading: UseQueryHookResult.isLoading || UseQueryHookResult.isFetching,
            loadingText: "Loading...",
            selectionType: "multi",
            resizableColumns: true,
            stickyHeader: true,
            onSelectionChange: (selectionChangeDetail) => {
                setSelectedPayers(selectionChangeDetail.detail.selectedItems);
            },
            onRowClick: ({ detail }) => {
                const temp = selectedPayers;
                if (!detail.item) return;
                if (temp?.includes(detail.item))
                    setSelectedPayers(temp.filter((item) => item !== detail.item));
                else setSelectedPayers([detail.item, ...temp]);
            },
            selectedItems: selectedPayers,
            trackBy: (item) => "" + item.id,
            columnDisplay: [
                { id: "name", visible: true },
                { id: "payerId", visible: true },
                { id: "website", visible: true },
                { id: "state", visible: true },
            ],
            columnDefinitions: [
                {
                    id: "id",
                    header: <div>ID</div>,
                    cell: (item) => <span>{item.id}</span>,
                    visibility: false,
                },
                {
                    id: "name",
                    header: <div>Name</div>,
                    cell: (item) => <span>{item?.name}</span>,
                    sortingField: "name",
                },

                {
                    id: "payerId",
                    header: <div>Payer ID</div>,
                    cell: (item) => <div>{item?.payerId ?? "-"}</div>,
                },
                {
                    id: "website",
                    header: "Website",
                    cell: (item) => <div>{item.website || "-"}</div>,
                    sortingField: "website",
                    isRowHeader: true,
                },
                {
                    id: "state",
                    header: <div>State</div>,
                    cell: (item) => <div> {item?.payerAddress?.state ?? "-"} </div>,
                },
            ],
        };
    }, [items, selectedPayers]);

    return (
        <div>
            <Table {...tableProps} />
            <AssociatedPayersModals
                associatedPayers={associatedPayers}
                isAssociatedPayersModalOpen={isAssociatedPayersModalOpen}
                setAssociatedPayers={setAssociatedPayers}
                setAssociatedPayersModalOpen={setAssociatedPayersModalOpen}
            />
        </div>
    );
}
