import {
  CollectionPreferences,
  CollectionPreferencesProps,
} from "@cloudscape-design/components";

export const PAGE_SIZE_OPTIONS = [
  { value: 10, label: "10 items" },
  { value: 30, label: "30 items" },
  { value: 50, label: "50 items" },
];

export const DocumentRequest_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID", alwaysVisible: false },
  { id: "title", label: "Title", alwaysVisible: true },
  { id: "provider", label: "Provider" },
  { id: "status", label: "Status" },
  { id: "attachment", label: "Attachment" },
  { id: "category", label: "Category" },
  { id: "description", label: "Description" },
];

export const DocumentRequest_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "title", visible: true },
      { id: "provider", visible: true },
      { id: "status", visible: true },
      { id: "attachment", visible: true },
      { id: "category", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PROVIDER_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Provider ID", alwaysVisible: false },
  { id: "fullname", label: "Full Name", alwaysVisible: true },
  { id: "firstName", label: "First Name" },
  { id: "lastName", label: "Last Name" },
  { id: "npiNumber", label: "NPI Number" },
  { id: "email", label: "Email" },
  { id: "facilityGroup", label: "Facility Group" },
  { id: "facility", label: "Facility" },
  { id: "licenseType", label: "License Type" },
  { id: "status", label: "Status" },
];

export const PROVIDER_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "fullname", visible: true },
      { id: "firstName", visible: true },
      { id: "lastName", visible: true },
      { id: "npiNumber", visible: true },
      { id: "email", visible: true },
      { id: "facilityGroup", visible: true },
      { id: "facility", visible: true },
      { id: "licenseType", visible: true },
      { id: "status", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const AFFILIATION_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Affiliation ID", alwaysVisible: false },
  { id: "affiliationStatus", label: "Affiliation Status" },
  { id: "affiliationType", label: "Affiliation Type" },
  { id: "facility", label: "Facility" },
  { id: "provider", label: "Provider" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "alertDays", label: "Expiration Reminder" },
  { id: "name", label: "Name" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "documentName", label: "Document Name" },
  { id: "notes", label: "Notes" },
];
export const AFFILIATION_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "affiliationStatus", visible: true },
      { id: "affiliationType", visible: true },
      { id: "facility", visible: true },
      { id: "provider", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "alertDays", visible: true },
      { id: "name", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: true },
      { id: "documentName", visible: true },
      { id: "notes", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const DEA_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "DEA ID", alwaysVisible: false },
  { id: "deaNumber", label: "Dea Number", alwaysVisible: true },
  { id: "type", label: "Type" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "status", label: "Status" },
  { id: "state", label: "State" },
  { id: "documentName", label: "Document Name" },
  { id: "notes", label: "Notes" },
];
export const DEA_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "deaNumber", visible: true },
      { id: "type", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "status", visible: true },
      { id: "state", visible: true },
      { id: "documentName", visible: true },
      { id: "notes", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };
export const EDUCATION_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Education ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "type", label: "Type" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "degree", label: "Degree" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
  { id: "residencyDirector", label: "Residency Director" },
  { id: "fellowshipDirector", label: "Fellowship Director" },
  { id: "documentName", label: "Document Name" },
];
export const EDUCATION_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "type", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "degree", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zip", visible: true },
      { id: "country", visible: true },
      { id: "residencyDirector", visible: true },
      { id: "fellowshipDirector", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const LIABILITYINSURANCE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Liability Insurance ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "isCurrent", label: "isCurrent" },
  { id: "policyNumber", label: "Policy Number" },
  { id: "selfInsured", label: "Self Insured" },
  { id: "effectiveDate", label: "Effective Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "typeOfCoverage", label: "Type Of Coverage" },
  {
    id: "timeWithCurrentCarrierValue",
    label: "Time With Current Carrier Value",
    alwaysVisible: false,
  },
  {
    id: "timeWithCurrentCarrierUnit",
    label: "Time With Current Carrier Unit",
    alwaysVisible: false,
  },
  {
    id: "coveragePerOccurrence",
    label: "Coverage Per Occurrence",
    alwaysVisible: false,
  },
  { id: "aggregateCoverage", label: "Aggregate Coverage" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
  { id: "documentName", label: "Document Name" },
];
export const LIABILITYINSURANCE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "isCurrent", visible: false },
      { id: "policyNumber", visible: true },
      { id: "selfInsured", visible: false },
      { id: "effectiveDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "typeOfCoverage", visible: true },
      { id: "timeWithCurrentCarrierValue", visible: false },
      { id: "timeWithCurrentCarrierUnit", visible: false },
      { id: "coveragePerOccurrence", visible: false },
      { id: "aggregateCoverage", visible: false },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: false },
      { id: "addressLane1", visible: false },
      { id: "addressLane2", visible: false },
      { id: "city", visible: false },
      { id: "state", visible: false },
      { id: "zip", visible: false },
      { id: "country", visible: false },
      { id: "documentName", visible: false },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const LICENSE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "License ID", alwaysVisible: false },
  { id: "licenseType", label: "License Type", alwaysVisible: true },
  { id: "licenseNumber", label: "License Number" },
  { id: "state", label: "State" },
  { id: "isCurrent", label: "is Current" },
  { id: "startDate", label: "Start Date" },
  { id: "notes", label: "Notes" },
  { id: "documentName", label: "Document Name" },
  { id: "expirationDate", label: "Expiration Date" },
];
export const LICENSE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "licenseType", visible: true },
      { id: "licenseNumber", visible: true },
      { id: "state", visible: true },
      { id: "isCurrent", visible: true },
      { id: "startDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "documentName", visible: true },
      { id: "notes", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const PEERREFERENCE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Peer Reference ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "degree", label: "Degree" },
  { id: "specialty", label: "Specialty" },
  { id: "timeWithReferenceValue", label: "Time With Reference Value" },
  { id: "timeWithReferenceUnit", label: "Time With Reference Unit" },
  { id: "email", label: "Email" },
  { id: "phone", label: "Phone" },
  { id: "fax", label: "Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
];

export const PEERREFERENCE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "degree", visible: true },
      { id: "specialty", visible: true },
      { id: "timeWithReferenceValue", visible: true },
      { id: "timeWithReferenceUnit", visible: true },
      { id: "email", visible: true },
      { id: "phone", visible: true },
      { id: "fax", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zip", visible: true },
      { id: "country", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const SPECIALTYINFO_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Specialty ID", alwaysVisible: false },
  { id: "boardName", label: "Board Name", alwaysVisible: true },
  { id: "specialty", label: "Specialty" },
  { id: "psv", label: "PSV" },
  { id: "initialCertificationDate", label: "Initial Certification Date" },
  { id: "expirationDate", label: "Expiration Date" },
  { id: "reCertificationDate", label: "Recertification Date" },
  { id: "documentName", label: "Document Name" },
];
export const SPECIALTYINFO_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "boardName", visible: true },
      { id: "specialty", visible: true },
      { id: "psv", visible: true },
      { id: "initialCertificationDate", visible: true },
      { id: "expirationDate", visible: true },
      { id: "reCertificationDate", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const THIRDPARTYLOGIN_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Third Party Login ID", alwaysVisible: false },
  { id: "name", label: "Name" },
  { id: "accountNo", label: "Account No", alwaysVisible: true },
  { id: "websiteUrl", label: "Website Url" },
  { id: "username", label: "Username" },
  { id: "password", label: "Password" },
];
export const THIRDPARTYLOGIN_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "accountNo", visible: true },
      { id: "websiteUrl", visible: true },
      { id: "username", visible: true },
      { id: "password", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const WORKHISTORY_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Work History ID", alwaysVisible: false },
  { id: "name", label: "Name", alwaysVisible: true },
  { id: "type", label: "Type" },
  { id: "startDate", label: "Start Date" },
  { id: "endDate", label: "End Date" },
  { id: "positionHeld", label: "Position Held" },
  { id: "gapExplanation", label: "Gap Explanation" },
  { id: "referenceName", label: "Reference Name" },
  { id: "referenceEmail", label: "Reference Email" },
  { id: "referencePhone", label: "Reference Phone" },
  { id: "referernceFax", label: "Reference Fax" },
  { id: "addressLane1", label: "Address Line 1" },
  { id: "addressLane2", label: "Address Line 2" },
  { id: "city", label: "City" },
  { id: "state", label: "State" },
  { id: "zip", label: "Zip" },
  { id: "country", label: "Country" },
  { id: "documentName", label: "Document Name" },
];
export const WORKHISTORY_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: false },
      { id: "name", visible: true },
      { id: "type", visible: true },
      { id: "startDate", visible: true },
      { id: "endDate", visible: true },
      { id: "positionHeld", visible: true },
      { id: "gapExplanation", visible: true },
      { id: "referenceName", visible: true },
      { id: "referencePhone", visible: true },
      { id: "referenceFax", visible: true },
      { id: "addressLane1", visible: true },
      { id: "addressLane2", visible: true },
      { id: "city", visible: true },
      { id: "state", visible: true },
      { id: "zip", visible: true },
      { id: "country", visible: true },
      { id: "documentName", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const ENROLLEDPAYER_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Enrolled Payer ID" },
  { id: "payer", label: "Payer" },
  { id: "network", label: "Network" },
  { id: "provider", label: "Provider" },
  { id: "payerProviderId", label: "Provider ID" },
  { id: "facility", label: "Facility" },
  { id: "facilityGroup", label: "Facility Group" },
  { id: "status", label: "Status" },
  { id: "submittedDate", label: "Submitted Date" },
  { id: "approvedDate", label: "Approved Date" },
  { id: "effectiveDate", label: "Effective Date" },
  { id: "recredentialingDate", label: "Re-Enrollment Date" },
  { id: "followupDate", label: "Follow Up Date" },
  { id: "description", label: "Notes" },
];
export const ENROLLEDPAYER_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "payer", visible: true },
      { id: "network", visible: true },
      { id: "provider", visible: true },
      { id: "payerProviderId", visible: true },
      { id: "facility", visible: true },
      { id: "facilityGroup", visible: true },
      { id: "status", visible: true },
      { id: "submittedDate", visible: true },
      { id: "approvedDate", visible: true },
      { id: "effectiveDate", visible: true },
      { id: "recredentialingDate", visible: true },
      { id: "followupDate", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const AFFILIATIONSTATUS_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Affiliation Status ID" },
  { id: "name", label: "Name" },
];
export const AFFILIATIONSTATUS_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const AFFILIATIONTYPE_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "Affiliation Type ID" },
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
];
export const AFFILIATIONTYPE_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const BOARDNAME_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
];
export const BOARDNAME_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const SPECIALTY_CONTENT_DISPLAY_OPTIONS = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
  { id: "description", label: "Description" },
];
export const SPECIALTY_TABLE_DEFAULT_PREFERENCES: CollectionPreferencesProps.Preferences<any> =
  {
    pageSize: 10,
    contentDisplay: [
      { id: "id", visible: true },
      { id: "name", visible: true },
      { id: "description", visible: true },
    ],
    wrapLines: false,
    stripedRows: true,
    contentDensity: "comfortable",
    stickyColumns: { first: 0, last: 0 },
  };

export const TablePreferences = ({
  title,
  disabled,
  preferences,
  setPreferences,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions,
}: {
  title?: string;
  disabled?: boolean;
  preferences: CollectionPreferencesProps.Preferences<any> | undefined;
  setPreferences: (
    preferences: CollectionPreferencesProps.Preferences<any>
  ) => void;
  pageSizeOptions?: CollectionPreferencesProps.PageSizeOption[];
  contentDisplayOptions: CollectionPreferencesProps.ContentDisplayOption[];
}) => (
  <CollectionPreferences
    disabled={disabled || !contentDisplayOptions.length}
    preferences={preferences}
    onConfirm={({ detail }) => {
      setPreferences(detail);
    }}
    title={title}
    pageSizePreference={{ options: pageSizeOptions }}
    wrapLinesPreference={{}}
    stripedRowsPreference={{}}
    contentDensityPreference={{}}
    contentDisplayPreference={{ options: contentDisplayOptions }}
    stickyColumnsPreference={{
      firstColumns: {
        title: "Stick first column(s)",
        description:
          "Keep the first column(s) visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "First column", value: 1 },
          { label: "First two columns", value: 2 },
        ],
      },
      lastColumns: {
        title: "Stick last column",
        description:
          "Keep the last column visible while horizontally scrolling the table content.",
        options: [
          { label: "None", value: 0 },
          { label: "Last column", value: 1 },
        ],
      },
    }}
  />
);
