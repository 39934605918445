import { Modal, Row, Col, Space } from "antd";
import IncredableButton from "../../components/button";

type DeleteModalProps = {
  visible: boolean;
  action: VoidFunction;
  closeModal: VoidFunction;
  header: React.ReactNode | string;
  content: React.ReactNode | string;
  description: React.ReactNode | string;
};

export default function DeleteAlertModal({
  visible,
  action,
  header,
  closeModal,
  content,
  description,
}: DeleteModalProps) {
  return (
    <Modal
      title={header}
      visible={visible}
      onCancel={closeModal}
      footer={
        <Row justify="end" gutter={16}>
          <Col>
            <IncredableButton secondary onClick={closeModal}>
              Cancel
            </IncredableButton>
          </Col>
          <Col>
            <IncredableButton type="primary" onClick={action}>
              Delete
            </IncredableButton>
          </Col>
        </Row>
      }
    >
      <Space direction="vertical">
        {content}
        {description}
      </Space>
    </Modal>
  );
}
