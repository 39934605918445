import { Col, Divider, Form, Row, Space, theme, Typography } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { RecipientType } from "../redux/api/signaturerequest/types";
import { useContext, useState } from "react";
import { SpinnerContext } from "../context/spinner/spinner";
import { useNavigate } from "react-router-dom";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";
import IncredableButton from "../components/button";
import RHFSelect from "../components/RHF/RHFSelect";
import { DownOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";

const { useToken } = theme;

type FormInputType = {
  addlRecipient: RecipientType[];
};

export default function AddlRecipient() {
  const methods = useForm<FormInputType>();
  const spinnerContext = useContext(SpinnerContext);
  const navigate = useNavigate();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: members } = useGetAllMembersQuery();
  const { token } = useToken();
  const [expand, setExpand] = useState(false);

  return (
    <FormProvider {...methods}>
      <Form
        name="addprovider"
        layout="vertical"
        onFinish={methods.handleSubmit(async (data: FormInputType) => {
          spinnerContext?.showSpinner();
          console.log({ data });
          spinnerContext?.hidespinner();
          navigate(-1);
        })}
        autoComplete="off"
      >
        <Space
          direction="horizontal"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          <Typography.Text
            style={{
              padding: "0",
            }}
          >
            Additional Recipient
          </Typography.Text>
          {!!expand ? <DownOutlined /> : <RightOutlined />}
        </Space>
        {!!expand && (
          <Divider
            style={{
              padding: "0",
              margin: "12px 0 12px 0",
            }}
          />
        )}
        {!!expand && (
          <Form.List name="providers" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Type" }}
                            controllerProps={{
                              name: `addlRecipient[${name}].type`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Select Recipient type",
                              options: [
                                { label: "Provider", value: "provider" },
                                { label: "Member", value: "member" },
                                { label: "Email", value: "email" },
                              ]?.map((type) => {
                                return {
                                  label: type?.label,
                                  value: type?.value,
                                };
                              }),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "Facility" }}
                            controllerProps={{
                              name: `providers[${name}].facility`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: providers?.map((provider) => {
                                return {
                                  label:
                                    provider?.firstName +
                                    " " +
                                    provider?.lastName,
                                  value: provider?.id,
                                };
                              }),
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "License Type" }}
                            controllerProps={{
                              name: `providers[${name}].licenseType`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: members?.map((member) => {
                                return {
                                  label:
                                    member?.firstName + " " + member?.lastName,
                                  value: member?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                  <Divider />
                  <Form.Item>
                    <IncredableButton
                      onClick={() => add()}
                      style={{
                        color: token.colorPrimary,
                        borderColor: token.colorPrimary,
                      }}
                      type="dashed"
                      block
                      icon={<PlusOutlined />}
                    >
                      Add More
                    </IncredableButton>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        )}
      </Form>
    </FormProvider>
  );
}
