import { CSSProperties } from "react";

export const sidebarStyles: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

export const menuItemStyle: { [key: string]: CSSProperties } = {
  item: {
    display: "flex",
    alignItems: "center",
  },
  label: {
    display: "flex",
    alignItems: "center",
  },
};

export const iconStyles: CSSProperties = {
  fontSize: "24px",
  width: "24px",
  height: "24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const logoStyles: CSSProperties = {
  display: "flex",
  backgroundColor: "#521262",
  padding: "10px 0",
  justifyContent: "center",
};

export const menuStyles: CSSProperties = {
  color: "white",
  marginTop: "21px",
  marginLeft: "10px",
};

export const menuItemStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const collapsedMenuItemStyles: CSSProperties = {
  flexDirection: "column",
};

export const collapsedMenuTitleStyles: CSSProperties = {
  display: "none",
};

export const submenuTitleStyles: CSSProperties = {
  display: "flex",
  alignItems: "center",
};
