import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useMemo, useState } from "react";
import { useGetAllMembersQuery } from "../redux/api/manageadmin/manageadmin";

export default function useActiveMembersFilter() {
  const [selectedFilterKey, setSelectedFilterKey] =
    useState<string>("firstName");

  const [firstNameFilterValue, setFirstNameFilterValue] = useState<
    string | undefined
  >(undefined);

  const [lastNameFilterValue, setLastNameFilterValue] = useState<
    string | undefined
  >(undefined);

  const [emailFilterValue, setEmailFilterValue] = useState<string | undefined>(
    undefined
  );

  const {
    data: members = [],
    refetch,
    isLoading: membersLoading,
    isFetching,
  } = useGetAllMembersQuery();

  const filteredMember = useMemo(() => {
    if (membersLoading) return [];
    return members.filter((member) => {
      let matches = true;
      if (firstNameFilterValue) {
        const nameWords = firstNameFilterValue?.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) =>
            member?.firstName?.toLowerCase().includes(word)
          );
      }
      if (lastNameFilterValue) {
        const nameWords = lastNameFilterValue?.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) =>
            member?.lastName?.toLowerCase().includes(word)
          );
      }
      if (emailFilterValue) {
        const nameWords = emailFilterValue?.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) =>
            member?.email?.toLowerCase().includes(word)
          );
      }
      return matches;
    });
  }, [
    members,
    firstNameFilterValue,
    lastNameFilterValue,
    emailFilterValue,
    membersLoading,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "firstName", label: "First Name" },
            {
              value: "lastName",
              label: "Last Name",
            },
            { value: "email", label: "Email" },
          ]}
        />
        {selectedFilterKey === "firstName" && (
          <Input
            onChange={(event) => setFirstNameFilterValue(event.target.value)}
            value={firstNameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find First Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "lastName" && (
          <Input
            onChange={(event) => setLastNameFilterValue(event.target.value)}
            value={lastNameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Last Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "email" && (
          <Input
            onChange={(event) => setEmailFilterValue(event.target.value)}
            value={emailFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Email"
            prefix={<SearchOutlined />}
          />
        )}
      </Space>
      <div></div>
    </Space>
  );

  return {
    TableFilters,
    filteredMember,
    UseQueryHookResult: {
      refetch,
      isLoading: membersLoading,
      isFetching,
    },
  };
}
