import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import {
  useGetAllWorkflowQuery,
  useGetAllWorkflowStatusQuery,
  useGetAllWorkflowTemplateQuery,
} from "../redux/api/workflow/workflow";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";

export default function useWorkflowFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("name");

  const [nameFilterValue, setNameFilterValue] = useState<string | undefined>(
    undefined
  );

  const [workflowStatusFilterValue, setWorkflowStatusFilterValue] = useState<
    string | undefined
  >(undefined);

  const [workflowTemplateFilterValue, setWorkflowTemplateFilterValue] =
    useState<string | undefined>(undefined);

  const [providerFilterValue, setProviderFilterValue] = useState<
    string | undefined
  >(undefined);

  const { data: workflowStatus = [], isLoading: workflowStatusLoading } =
    useGetAllWorkflowStatusQuery();

  const { data: workflowTemplate = [], isLoading: workflowTemplateLoading } =
    useGetAllWorkflowTemplateQuery();

  const { data: providers = [], isLoading: providersLoading } =
    useGetBasicAllProvidersQuery();

  const {
    data: workflows = [],
    refetch,
    isLoading: workflowLoading,
    isFetching,
  } = useGetAllWorkflowQuery();

  const filteredWorkflow = useMemo(() => {
    if (
      workflowLoading ||
      workflowStatusLoading ||
      providersLoading ||
      workflowTemplateLoading
    )
      return [];
    return workflows.filter((task) => {
      let matches = true;
      if (nameFilterValue) {
        const nameWords = nameFilterValue.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) => task?.name.toLowerCase().includes(word));
      }
      if (providerFilterValue) {
        matches = matches && task?.provider?.id === providerFilterValue;
      }
      if (workflowStatusFilterValue) {
        matches =
          matches && task?.workFlowStatus?.id === workflowStatusFilterValue;
      }
      if (workflowTemplateFilterValue) {
        matches =
          matches && task?.workflowTemplate?.id === workflowTemplateFilterValue;
      }
      return matches;
    });
  }, [
    workflows,
    nameFilterValue,
    workflowStatusFilterValue,
    providerFilterValue,
    workflowTemplateFilterValue,
    workflowLoading,
    workflowStatusLoading,
    providersLoading,
    workflowTemplateLoading,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "name", label: "Name" },
            { value: "workflowStatus", label: "Workflow Status" },
            { value: "workflowTemplate", label: "Workflow Template" },
            { value: "provider", label: "Provider" },
          ]}
        />
        {selectedFilterKey === "name" && (
          <Input
            onChange={(event) => setNameFilterValue(event.target.value)}
            value={nameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "workflowStatus" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={workflowStatusFilterValue}
            placeholder="Select Workflow Status"
            onChange={(value) => setWorkflowStatusFilterValue(value)}
            style={{ width: "320px" }}
            options={workflowStatus.map((status) => ({
              label: status.name,
              value: status.id,
            }))}
          />
        )}
        {selectedFilterKey === "provider" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={providerFilterValue}
            placeholder="Select Provider"
            onChange={(value) => setProviderFilterValue(value)}
            style={{ width: "320px" }}
            options={providers.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              value: provider.id,
            }))}
          />
        )}
        {selectedFilterKey === "workflowTemplate" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={workflowTemplateFilterValue}
            placeholder="Select Workflow Template"
            onChange={(value) => setWorkflowTemplateFilterValue(value)}
            style={{ width: "320px" }}
            options={workflowTemplate.map((template) => ({
              label: template.name,
              value: template.id,
            }))}
          />
        )}
      </Space>
      <div>
        {workflowStatusFilterValue && (
          <Tag closable onClose={() => setWorkflowStatusFilterValue(undefined)}>
            Workflow Status:{" "}
            {
              workflowStatus.find(
                (status) => status.id === workflowStatusFilterValue
              )?.name
            }
          </Tag>
        )}
        {providerFilterValue && (
          <Tag closable onClose={() => setProviderFilterValue(undefined)}>
            Provider:{" "}
            {
              providers.find((provider) => provider.id === providerFilterValue)
                ?.firstName
            }
          </Tag>
        )}
        {workflowTemplateFilterValue && (
          <Tag
            closable
            onClose={() => setWorkflowTemplateFilterValue(undefined)}
          >
            Workflow Template:{" "}
            {
              workflowTemplate.find(
                (status) => status.id === workflowTemplateFilterValue
              )?.name
            }
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredWorkflow,
    UseQueryHookResult: {
      refetch,
      isLoading: workflowLoading,
      isFetching,
    },
  };
}
