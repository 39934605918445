import { useContext, useState } from "react";
import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import {
  TASKTYPES_CONTENT_DISPLAY_OPTIONS,
  TASKTYPES_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";

import { useLocalStorage } from "../../common/localStorage";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useCollection } from "@cloudscape-design/collection-hooks";
import IncredableButton from "../../components/button";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import IncredableContent from "../../components/incredable-content";
import { TaskType } from "../../redux/api/task/types";
import {
  useDeleteTaskTypeMutation,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";

export default function TasksTypeList() {
  const [selectedRows, setSelectedRows] = useState<TaskType[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-TaskType-Table-Preferences",
    TASKTYPES_TABLE_DEFAULT_PREFERENCES
  );

  const {
    data: tasksTypes = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetAllTaskTypeQuery();
  const [deleteTaskType] = useDeleteTaskTypeMutation();
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const { items, collectionProps, paginationProps } = useCollection(
    tasksTypes,
    {
      pagination: { pageSize: tablePreferences.pageSize },
    }
  );

  return (
    <>
      <IncredableButton
        style={{
          margin: "12px 0 0 32px",
          padding: "12px",
        }}
        secondary
        onClick={() => navigate(-1)}
      >
        Go back
      </IncredableButton>
      <IncredableContent style={{ margin: "12px 32px 0 32px", padding: "0px" }}>
        <Table
          loading={isFetching || isLoading}
          onSelectionChange={({ detail }) => {
            const selectedItem = detail?.selectedItems[0];
            setSelectedRows(selectedItem ? [selectedItem] : []);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          columnDefinitions={[
            {
              id: "id",
              header: "Id",
              cell: (item) => <span>{item?.id}</span>,
              sortingField: "id",
            },
            {
              id: "type",
              header: "Type",
              cell: (item) => <span>{item?.name}</span>,
              sortingField: "name",
              sortingComparator: (a, b) =>
                (a?.name ?? "").localeCompare(b?.name ?? ""),
            },
          ]}
          items={items}
          pagination={<Pagination {...paginationProps} />}
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              contentDisplayOptions={TASKTYPES_CONTENT_DISPLAY_OPTIONS}
              setPreferences={(preferences) => {
                setTablePreferences(preferences);
              }}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button onClick={() => refetch()}>
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRows.length !== 1,
                        items: [
                          {
                            label: "Edit",
                            key: "edit",
                            icon: <EditOutlined />,
                            onClick: () =>
                              navigate(`${selectedRows?.at(0)?.id}/edit`),
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            icon: <DeleteOutlined />,
                            danger: true,
                            onClick: () => setIsDeleteModalOpen(true),
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        onClick={() => navigate(`add`)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add Task Type
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: 0 }}>
                  Task Type{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >
                    ({tasksTypes?.length})
                  </Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences.contentDisplay}
          wrapLines={tablePreferences.wrapLines}
          stripedRows={tablePreferences.stripedRows}
          contentDensity={tablePreferences.contentDensity}
          stickyColumns={tablePreferences.stickyColumns}
        />

        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRows?.at(0)?.id) {
              setIsDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteTaskType({
                taskTypeId: String(selectedRows?.at(0)?.id),
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Task Type Deleted Successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setIsDeleteModalOpen(false)}
          header="Delete Task Type"
          content={
            <Typography.Text>
              Type: {""}
              <Typography.Text strong>
                {selectedRows?.at(0)?.name}
              </Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this task type?"
        />
      </IncredableContent>
    </>
  );
}
