import {
  Container,
  FormField,
  Header,
  Select,
  SpaceBetween,
} from "@cloudscape-design/components";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import Divider from "@mui/material/Divider";
import { RecipientType } from "../../../redux/api/signaturerequest/types";
import { convertRecipentsToOptionDefinitions } from "./signature-request-field-formatter";
import {
  FieldType,
  ListItemStyle,
} from "../../../components/digitalforms/digitalform-tags-list";
import {
  PROVIDER_DIGITALFORM_FIELDS,
  STANDARD_DIGITALFORM_FIELDS,
} from "../../../components/digitalforms/constants";
import { Menu, Typography } from "antd";

export function SignatureRequestTagsList({
  primaryRecipient,
  addlRecipients,
  onDragEnd,
  onDragStart,
  selectedRecipient,
  setSelectedRecipient,
}: {
  selectedRecipient: OptionDefinition | null;
  setSelectedRecipient: React.Dispatch<
    React.SetStateAction<OptionDefinition | null>
  >;
  backgroundColor?: string;
  primaryRecipient: RecipientType;
  addlRecipients: RecipientType[];
  onDragStart: React.DragEventHandler<HTMLDivElement>;
  onDragEnd: (e: React.DragEvent<HTMLDivElement>, field: FieldType) => void;
}) {
  return (
    <Container fitHeight>
      <SpaceBetween size={"m"}>
        <FormField label={<Header variant="h3">Recipient</Header>}>
          <Select
            data-id="selectedRecipient"
            triggerVariant="option"
            selectedOption={selectedRecipient}
            onChange={({ detail }) =>
              setSelectedRecipient(detail.selectedOption)
            }
            options={convertRecipentsToOptionDefinitions(
              addlRecipients,
              primaryRecipient
            )}
          />
        </FormField>
        <Divider />
        <FormField label={<Header variant="h3">Signature Fields</Header>}>
          <Menu
            style={{
              width: "100%",
              borderRight: 0,
              borderRadius: "8px",
              height: `calc(100vh - 275px)`,
              overflow: "scroll",
            }}
            defaultSelectedKeys={["personal_info"]}
            theme="light"
            mode="inline"
            items={[
              ...STANDARD_DIGITALFORM_FIELDS,
              ...PROVIDER_DIGITALFORM_FIELDS,
            ].map((digitalFormField) => {
              return {
                key: digitalFormField?.value,
                icon: digitalFormField?.icon,
                label: digitalFormField?.label,
                children: digitalFormField?.properties?.map((property) => {
                  const annot_field: FieldType = {
                    label: [digitalFormField?.label, property.label].join("_"),
                    type: property.type,
                    key:
                      property.key ??
                      [digitalFormField?.value, property.value].join("."),
                    isList: digitalFormField?.type === "list",
                  };
                  return {
                    label: (
                      <ListItemStyle
                        key={[digitalFormField.label, property.label].join("_")}
                        draggable
                        onDragStart={(e: React.DragEvent<HTMLDivElement>) =>
                          onDragStart(e)
                        }
                        onDragEnd={(e: React.DragEvent<HTMLDivElement>) =>
                          onDragEnd(e, annot_field)
                        }
                        onClick={() => {}}
                      >
                        <Typography.Text>
                          {[digitalFormField.label, property.label].join("_")}
                        </Typography.Text>
                      </ListItemStyle>
                    ),
                    key:
                      !!property.key ??
                      [digitalFormField.value, property.value].join("."),
                  };
                }),
              };
            })}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
}
