import { Row, Col } from "antd";
import { serviceOverview } from "./wdigets/service-overview";
import { BaseStaticWidget } from "./wdigets/basic-static-widget";
import {
  PayerenrollmentStatus,
  UsageByFacilityStatus,
} from "./wdigets/payer-enrollment-status";
import { eSignatureStatus } from "./wdigets/eSignatures-status/";
import { taskStatus } from "./wdigets/workflow-tasks-widget/tasks-status-widget";
import { workflowStatus } from "./wdigets/workflow-tasks-widget/workflows-status-widget";
import { documentsSnapShot } from "./wdigets/document-snapshot";
import { useGetOrganizationDetailsQuery } from "../redux/api/organization/organization";

export default function Dashboard() {
  const { data: organization } = useGetOrganizationDetailsQuery();

  return (
    <Row gutter={[16, 16]} style={{ margin: "0px 25px 24px 24px" }}>
      <Col span={24}>
        <BaseStaticWidget config={serviceOverview} style={{ padding: "0" }} />
      </Col>
      {!!organization?.organizationServiceLimits?.payerEnrollments ? (
        <Col span={16}>
          <BaseStaticWidget config={PayerenrollmentStatus} />
        </Col>
      ) : (
        <Col span={16}>
          <BaseStaticWidget config={UsageByFacilityStatus} />
        </Col>
      )}
      {!!organization?.organizationServiceLimits?.eSignatures && (
        <Col span={8}>
          <BaseStaticWidget config={eSignatureStatus} />
        </Col>
      )}
      <Col span={8}>
        <BaseStaticWidget config={documentsSnapShot} />
      </Col>
      <Col span={8}>
        <BaseStaticWidget config={taskStatus} />
      </Col>
      {!!organization?.organizationServiceLimits?.workflows && (
        <Col span={8}>
          <BaseStaticWidget config={workflowStatus} />
        </Col>
      )}
    </Row>
  );
}
