import { useContext, useEffect, useState } from "react";
import {
  Button,
  ConfigProvider,
  Dropdown,
  Layout,
  Space,
  theme,
  Typography,
} from "antd";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useLocalStorage } from "../../common/localStorage";
import {
  LOCATION_CONTENT_DISPLAY_OPTIONS,
  LOCATION_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "./table-config";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import useLocationFilter from "../../table-filters/location-filters";
import { Location } from "../../redux/api/location/types";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useDeleteLocationMutation } from "../../redux/api/location/location";
const { useToken } = theme;

export default function LocationList() {
  const [selectedRows, setSelectedRows] = useState<Location[]>([]);
  const {
    TableFilters,
    filteredLocation = [],
    UseQueryHookResult: {
      refetch,
      setPageNumber,
      setPageSize,
      pageNumber,
      pageSize,
      isFetching,
      sortingDetails,
      setSortingDetails,
    },
  } = useLocationFilter();
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Location-Table-Preferences",
    LOCATION_TABLE_DEFAULT_PREFERENCES
  );

  useEffect(() => {
    if (tablePreferences?.pageSize) setPageSize(tablePreferences.pageSize);
  }, [tablePreferences, setPageSize]);

  const { items, collectionProps, paginationProps } = useCollection(
    filteredLocation,
    {
      pagination: { pageSize },
    }
  );
  const navigate = useNavigate();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const [deleteLocation] = useDeleteLocationMutation();

  return (
    <Layout style={{ margin: "20px 30px 0 30px" }}>
      <Space size="large" direction="vertical">
        <Table
          loading={isFetching}
          onSelectionChange={({ detail }) => {
            setSelectedRows([detail.selectedItems[0]]);
          }}
          onRowClick={(onRowClickDetails) => {
            const selectedItem = onRowClickDetails?.detail?.item;
            if (selectedItem) {
              if (selectedRows?.at(0)?.id === selectedItem?.id) {
                setSelectedRows([]);
              } else {
                setSelectedRows([selectedItem]);
              }
            }
          }}
          selectedItems={selectedRows || []}
          sortingColumn={{ sortingField: sortingDetails?.sortingField }}
          sortingDescending={sortingDetails?.isDescending}
          onSortingChange={({ detail }) => {
            setSortingDetails({
              sortingField: detail?.sortingColumn?.sortingField,
              isDescending: detail?.isDescending,
            });
          }}
          columnDefinitions={[
            {
              id: "id",
              header: <div>ID</div>,
              cell: (item) => <span>{item.id}</span>,
            },
            {
              id: "name",
              header: <div>Name</div>,
              cell: (item) => (
                <NavLink to={`/location/${item.id}/edit`}>{item?.name}</NavLink>
              ),
            },
            {
              id: "description",
              header: <div>Description</div>,
              cell: (item) => <span>{item?.description}</span>,
            },
            {
              id: "addressLine1",
              header: <div>Address Line1</div>,
              cell: (item) => <span>{item?.addressLine1}</span>,
            },
            {
              id: "addressLine2",
              header: <div>Address Line2</div>,
              cell: (item) => <span>{item?.addressLine2}</span>,
            },
            {
              id: "city",
              header: <div>City</div>,
              cell: (item) => <span>{item?.city}</span>,
            },
            {
              id: "state",
              header: <div>State</div>,
              cell: (item) => <span>{item?.state}</span>,
            },
            {
              id: "counrty",
              header: <div>Counrty</div>,
              cell: (item) => <span>{item?.country}</span>,
            },
            {
              id: "zip",
              header: <div>Zip Code</div>,
              cell: (item) => <span>{item?.zip}</span>,
            },
            {
              id: "notes",
              header: <div>Notes</div>,
              cell: (item) => <span>{item?.notes}</span>,
            },
          ]}
          items={items}
          pagination={
            <Pagination
              {...paginationProps}
              currentPageIndex={pageNumber + 1}
              onChange={({ detail }) => {
                setPageNumber(detail.currentPageIndex - 1);
              }}
              pagesCount={
                !!tablePreferences?.pageSize && tablePreferences?.pageSize !== 0
                  ? Math.ceil(
                      filteredLocation?.length / tablePreferences?.pageSize
                    )
                  : 1
              }
            />
          }
          preferences={
            <TablePreferences
              preferences={tablePreferences}
              setPreferences={(preferences) => {
                if (preferences.pageSize !== tablePreferences.pageSize) {
                  preferences.pageSize && setPageSize(preferences.pageSize);
                }
                setTablePreferences(preferences);
              }}
              contentDisplayOptions={LOCATION_CONTENT_DISPLAY_OPTIONS}
            />
          }
          loadingText="Loading resources"
          selectionType="single"
          trackBy="id"
          filter={TableFilters}
          header={
            <div style={{ marginTop: "8px", marginBottom: "12px" }}>
              <Header
                actions={
                  <Space size="middle">
                    <Button
                      onClick={() => {
                        !!refetch && refetch();
                      }}
                    >
                      <ReloadOutlined />
                    </Button>
                    <Dropdown
                      menu={{
                        disabled: selectedRows?.length !== 1,
                        items: [
                          {
                            // label: hasPermission ? "Edit" : "View",
                            label: "Edit",
                            key: "edit",
                            icon: <EditOutlined />,
                            onClick: () => {
                              navigate(`${selectedRows?.at(0)?.id}/edit`);
                            },
                          },
                          {
                            label: "Delete",
                            key: "delete",
                            onClick: () => setDeleteModalOpen(true),
                            // disabled: !hasDeletePermission,
                            icon: <DeleteOutlined />,
                            danger: true,
                          },
                        ],
                      }}
                    >
                      <Button>
                        Actions
                        <DownOutlined />
                      </Button>
                    </Dropdown>
                    <ConfigProvider
                      theme={{
                        token: {
                          colorPrimary: "#2B6BE6",
                        },
                      }}
                    >
                      <Button
                        // disabled={!hasPermission}
                        onClick={() => navigate(`add`)}
                        type="primary"
                        icon={<PlusOutlined />}
                      >
                        Add Location
                      </Button>
                    </ConfigProvider>
                  </Space>
                }
              >
                <Typography.Title level={3} style={{ marginTop: "0" }}>
                  Locations{" "}
                  <Typography.Text
                    type="secondary"
                    style={{ fontSize: "16px" }}
                  >{`(${filteredLocation?.length})`}</Typography.Text>
                </Typography.Title>
              </Header>
            </div>
          }
          {...collectionProps}
          columnDisplay={tablePreferences?.contentDisplay}
          wrapLines={tablePreferences?.wrapLines}
          stripedRows={tablePreferences?.stripedRows}
          contentDensity={tablePreferences?.contentDensity}
          stickyColumns={tablePreferences?.stickyColumns}
        />
        <DeleteAlertModal
          visible={isDeleteModalOpen}
          action={async () => {
            if (selectedRows?.at(0)?.id) {
              setDeleteModalOpen(false);
              spinnerContext?.showSpinner();
              await deleteLocation({
                id: String(selectedRows?.at(0)?.id),
              });
              spinnerContext?.hidespinner();
              toastContext?.openSuccessNotification({
                message: `Location deleted successfully`,
                placement: "topRight",
              });
            }
          }}
          closeModal={() => setDeleteModalOpen(false)}
          header="Delete Location"
          content={
            <Typography.Text>
              Location Name:{" "}
              <Typography.Text>{selectedRows?.at(0)?.name}</Typography.Text>
            </Typography.Text>
          }
          description="Are you sure you want to delete this location?"
        />
      </Space>
    </Layout>
  );
}
