import {
  Col,
  Divider,
  Flex,
  Form,
  Layout,
  Row,
  Space,
  theme,
  Typography,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { LicenseTypeRequest } from "../../../redux/api/licensetype/types";
import { useAddLicenseTypeMutation } from "../../../redux/api/licensetype/licensetype";
import IncredableContent from "../../../components/incredable-content";

const { useToken } = theme;
type FormInputType = LicenseTypeRequest;

export default function AddLicenseType() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const [addLicenseType] = useAddLicenseTypeMutation();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          {
            title: <a href="/licensetypes">License Types</a>,
          },
          {
            title: "Add",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addlicensetype"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            await addLicenseType({
              name: data?.name,
              description: data?.description,
              code: data?.code,
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `License Type saved successfully`,
              placement: "topRight",
            });
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              License Type
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Save
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="licensetype" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Description",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `description`,
                            }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Code",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `code`,
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
