import { Button } from "antd";
import { ButtonProps } from "antd/es/button";

export type IncredableButtonProps = ButtonProps & {
  secondary?: boolean;
};
const IncredableButton: React.FC<IncredableButtonProps> = ({ secondary, style, ...rest }) => {
  return (
    <Button
      {...rest}
      style={{
        boxShadow: "0px 1px 2px 0px #1018280A",
        ...(secondary && {
          border: "1px solid #521262",
          color: "#521262",
          fontWeight: "500",
        }),
        ...style,
      }}
    />
  );
};

export default IncredableButton;
