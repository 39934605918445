import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useMemo, useState } from "react";
import {
  useGetAllDigitalFormsQuery,
  useGetAllDigitalFormTypesQuery,
} from "../redux/api/digitalform/digitalforms";

export default function useDigitalFormFilter() {
  const [selectedFilterKey, setSelectedFilterKey] = useState<string>("name");

  const [digitalFormTypeFilterValue, setDigitalFormTypeFilterValue] = useState<
    string | undefined
  >(undefined);

  const [nameFilterValue, setNameFilterValue] = useState<string | undefined>(
    undefined
  );

  const { data: digitalFormTypes = [], isLoading: isFormTypesLoading } =
    useGetAllDigitalFormTypesQuery();

  const {
    data: digitalforms = [],
    refetch,
    isLoading: digitalformsLoading,
    isFetching,
  } = useGetAllDigitalFormsQuery();

  const filteredReadyForms = useMemo(() => {
    if (digitalformsLoading || isFormTypesLoading) return [];
    return digitalforms.filter((form) => {
      let matches = true;
      if (digitalFormTypeFilterValue) {
        matches =
          matches && form?.digitalFormType?.id === digitalFormTypeFilterValue;
      }
      if (nameFilterValue) {
        const nameWords = nameFilterValue.toLowerCase().split(" ");
        matches =
          matches &&
          nameWords.every((word) => form?.name.toLowerCase().includes(word));
      }
      return matches;
    });
  }, [
    digitalforms,
    digitalFormTypeFilterValue,
    nameFilterValue,
    digitalformsLoading,
    isFormTypesLoading,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterKey}
          onChange={(value) => setSelectedFilterKey(value)}
          style={{ width: "140px" }}
          options={[
            { value: "name", label: "Name" },
            { value: "type", label: "Type" },
          ]}
        />
        {selectedFilterKey === "name" && (
          <Input
            onChange={(event) => setNameFilterValue(event.target.value)}
            value={nameFilterValue}
            style={{ width: "320px" }}
            placeholder="Find Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterKey === "type" && (
          <Select
            allowClear
            showSearch
            optionFilterProp="label"
            value={digitalFormTypeFilterValue}
            placeholder="Select Ready Form by type"
            onChange={(value) => setDigitalFormTypeFilterValue(value)}
            style={{ width: "320px" }}
            options={digitalFormTypes.map((type) => ({
              label: type.type,
              value: type.id,
            }))}
          />
        )}
      </Space>
      <div>
        {digitalFormTypeFilterValue && (
          <Tag
            closable
            onClose={() => setDigitalFormTypeFilterValue(undefined)}
          >
            Digital Form Type:{" "}
            {
              digitalFormTypes.find(
                (type) => type.id === digitalFormTypeFilterValue
              )?.type
            }
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredReadyForms,
    UseQueryHookResult: {
      refetch,
      isLoading: digitalformsLoading,
      isFetching,
    },
  };
}
