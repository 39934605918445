import {
  Col,
  Row,
  Image,
  Layout,
  Form,
  Typography,
  Flex,
  Checkbox,
} from "antd";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import RHFTextField from "../../components/RHF/RHFTextField";
import {
  loginButton,
  forgotPassButton,
  loginContent,
} from "../login/login.styles";
import IncredableButton from "../../components/button";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContext } from "../../context/toast/incredable-toast";

type FormInputType = {
  password: string;
  confirmPassword: string;
};

export default function PasswordResetForm() {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const toastContext = useContext(ToastContext);
  const methods = useForm<FormInputType>();
  const navigate = useNavigate();
  return (
    <>
      <Row>
        <Col xs={0} md={12} lg={14}>
          <Image
            height={"100vh"}
            width={"100%"}
            preview={false}
            src="/assets/Login_Bg_image.png"
          />
        </Col>
        <Col xs={24} md={12} lg={10}>
          <Layout
            style={{
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={loginContent}>
              <FormProvider {...methods}>
                <Form
                  layout="vertical"
                  name="resetpassword"
                  onFinish={methods.handleSubmit(
                    async (data: FormInputType) => {
                      toastContext?.openSuccessNotification({
                        message: "Password Updated ",
                        placement: "top",
                      });
                    }
                  )}
                >
                  <Typography.Title
                    level={3}
                    style={{ margin: "0px 0px 20px 0px", fontWeight: "500" }}
                  >
                    Reset Your Password?
                  </Typography.Title>
                  <RHFTextField
                    formItemProps={{ label: "Password" }}
                    controllerProps={{ name: "password" }}
                    inputProps={{
                      placeholder: "Enter Password",
                      type: showPassword ? "text" : "password",
                    }}
                    rules={{ required: "This field is required" }}
                  />

                  <RHFTextField
                    formItemProps={{ label: "Confirm Password" }}
                    controllerProps={{ name: "Confirm Password" }}
                    inputProps={{
                      placeholder: "confirmPassword",
                      type: showPassword ? "text" : "password",
                    }}
                    rules={{
                      required: "This field is required",
                      validate: (value: any, formValues: FieldValues) => {
                        return (
                          value === (formValues.password ?? "") ||
                          "Password not match"
                        );
                      },
                    }}
                  />
                  <Form.Item>
                    <Flex justify="space-between" align="center">
                      <Checkbox
                        style={{ marginBottom: "24px" }}
                        onChange={() => setShowPassword(!showPassword)}
                        checked={showPassword}
                      >
                        Show Password
                      </Checkbox>

                      <IncredableButton
                        type="link"
                        style={forgotPassButton}
                        onClick={() => {
                          navigate("/auth/login");
                        }}
                      >
                        Back to Login
                      </IncredableButton>
                    </Flex>
                    <IncredableButton
                      block
                      type="primary"
                      htmlType="submit"
                      style={loginButton}
                    >
                      Reset Password
                    </IncredableButton>
                  </Form.Item>
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </Col>
      </Row>
    </>
  );
}
