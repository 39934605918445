import { Button, ConfigProvider, Dropdown, Space, Typography } from "antd";
import { useContext, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
  CalendarOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  useDeleteProviderAffiliationMutation,
  useGetProviderQuery,
} from "../../redux/api/provider/provider";
import { ProviderAffiliationType } from "../../redux/api/provider/types";
import IncredableDateFormat from "../../common/incredable-date-format";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import DeleteAlertModal from "../../common/Modal/delete-alert-modal";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";
import {
  AFFILIATION_CONTENT_DISPLAY_OPTIONS,
  AFFILIATION_TABLE_DEFAULT_PREFERENCES,
  TablePreferences,
} from "../table-config";
import { useLocalStorage } from "../../common/localStorage";
import { useCollection } from "@cloudscape-design/collection-hooks";
import { Header, Pagination, Table } from "@cloudscape-design/components";
import { AsYouType } from "libphonenumber-js";

export default function AffiliationsV2() {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const [selectedRows, setSelectedRows] = useState<ProviderAffiliationType[]>(
    []
  );
  const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  const [deleteProviderAffiliation] = useDeleteProviderAffiliationMutation();
  const {
    data: provider,
    isLoading,
    isFetching,
    refetch,
  } = useGetProviderQuery(
    !!providerId
      ? {
          providerId: providerId,
        }
      : skipToken
  );

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.edit") ?? false)
  );
  const hasDeletePermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("provider.delete") ?? false)
  );
  const [tablePreferences, setTablePreferences] = useLocalStorage(
    "RD-Affiliation-Table-Preferences",
    AFFILIATION_TABLE_DEFAULT_PREFERENCES
  );

  const { items, collectionProps, paginationProps } = useCollection(
    provider?.providerAffiliations ?? [],
    {
      pagination: { pageSize: tablePreferences?.pageSize },
      sorting: { defaultState: { isDescending: true, sortingColumn: {} } },
    }
  );

  return (
    <>
      <Table
        loading={isFetching || isLoading}
        onSelectionChange={({ detail }) => {
          setSelectedRows([detail.selectedItems[0]]);
        }}
        onRowClick={(onRowClickDetails) => {
          const selectedItem = onRowClickDetails?.detail?.item;
          if (selectedItem) {
            if (selectedRows.at(0)?.id === selectedItem?.id) {
              setSelectedRows([]);
            } else {
              setSelectedRows([selectedItem]);
            }
          }
        }}
        selectedItems={selectedRows || []}
        columnDefinitions={[
          {
            id: "facility",
            header: <div>Facility </div>,
            cell: (item) => (
              <NavLink
                to={
                  hasPermission
                    ? `/manageprovider/${providerId}/affiliation/${item.id}/edit`
                    : `#`
                }
              >
                {item?.facility?.name}
              </NavLink>
            ),
            sortingField: "facility.name",
            sortingComparator: (a, b) =>
              (a?.facility?.name ?? "").localeCompare(b?.facility?.name ?? ""),
          },
          {
            id: "name",
            header: <div>Name</div>,
            cell: (item) => <div>{item?.name ?? "-"}</div>,
          },
          {
            id: "affiliationStatus",
            header: <div>Affiliation Status</div>,
            cell: (item) => <div>{item?.affiliationStatus?.name ?? "-"}</div>,
            sortingField: "affiliationStatus.name",
            sortingComparator: (a, b) =>
              (a?.affiliationStatus?.name ?? "").localeCompare(
                b?.affiliationStatus?.name ?? ""
              ),
          },
          {
            id: "affiliationType",
            header: <div>Affiliation Type</div>,
            cell: (item) => <div>{item?.affiliationType?.name ?? "-"}</div>,
            sortingField: "affiliationType.name",
            sortingComparator: (a, b) =>
              (a?.affiliationType?.name ?? "").localeCompare(
                b?.affiliationType?.name ?? ""
              ),
          },
          {
            id: "startDate",
            header: <div>Start Date</div>,
            cell: (item) => (
              <div>
                {" "}
                {item.startDate ? (
                  <span>
                    <CalendarOutlined /> {IncredableDateFormat(item.startDate)}
                  </span>
                ) : (
                  "-"
                )}{" "}
              </div>
            ),
            sortingField: "startDate",
          },
          {
            id: "endDate",
            header: <div>End Date</div>,
            cell: (item) => (
              <div>
                {" "}
                {item.endDate ? (
                  <span>
                    <CalendarOutlined /> {IncredableDateFormat(item.endDate)}
                  </span>
                ) : (
                  "-"
                )}{" "}
              </div>
            ),
            sortingField: "endDate",
          },
          {
            id: "email",
            header: <div>Email</div>,
            cell: (item) => <div> {item.email ?? "-"} </div>,
          },
          {
            id: "phone",
            header: <div>Phone</div>,
            cell: (item) => (
              <div> {new AsYouType("US").input(item?.phone ?? "") ?? "-"} </div>
            ),
          },
          {
            id: "fax",
            header: <div>Fax</div>,
            cell: (item) => <div> {item.fax ?? "-"} </div>,
          },
          {
            id: "alertDays",
            header: <div>Expiration Reminder</div>,
            cell: (item) => (
              <span>{item.alertDays > 0 ? `${item.alertDays} days` : ""}</span>
            ),
          },
          {
            id: "notes",
            header: <div>Notes</div>,
            cell: (item) => <span>{item.notes || ""}</span>,
          },
        ]}
        items={items}
        pagination={<Pagination {...paginationProps} />}
        preferences={
          <TablePreferences
            preferences={tablePreferences}
            setPreferences={(preferences) => {
              setTablePreferences(preferences);
            }}
            contentDisplayOptions={AFFILIATION_CONTENT_DISPLAY_OPTIONS}
          />
        }
        loadingText="Loading resources"
        selectionType="single"
        trackBy="id"
        header={
          <div style={{ marginTop: "8px" }}>
            <Header
              actions={
                <Space size="middle">
                  <Button
                    onClick={() => {
                      !!refetch && refetch();
                    }}
                  >
                    <ReloadOutlined />
                  </Button>
                  <Dropdown
                    menu={{
                      disabled: selectedRows?.length !== 1,
                      items: [
                        {
                          label: hasPermission ? "Edit" : "View",
                          key: "edit",
                          icon: <EditOutlined />,
                          onClick: () => {
                            navigate(
                              `affiliation/${selectedRows?.at(0)?.id}/edit`
                            );
                          },
                        },
                        {
                          label: "Delete",
                          key: "delete",
                          onClick: () => setDeleteModalOpen(true),
                          disabled: !hasDeletePermission,
                          icon: <DeleteOutlined />,
                          danger: true,
                        },
                      ],
                    }}
                  >
                    <Button>
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                  <ConfigProvider
                    theme={{
                      token: {
                        colorPrimary: "#2B6BE6",
                      },
                    }}
                  >
                    <Button
                      disabled={!hasPermission}
                      onClick={() => navigate(`affiliation/add`)}
                      type="primary"
                      icon={<PlusOutlined />}
                    >
                      Add
                    </Button>
                  </ConfigProvider>
                </Space>
              }
            >
              <Typography.Title
                level={3}
                style={{ marginTop: "0", fontSize: "22px" }}
              >
                Affiliations{" "}
                <Typography.Text
                  type="secondary"
                  style={{ fontSize: "16px" }}
                >{`(${provider?.providerAffiliations?.length})`}</Typography.Text>
              </Typography.Title>
            </Header>
          </div>
        }
        {...collectionProps}
        columnDisplay={tablePreferences?.contentDisplay}
        wrapLines={tablePreferences?.wrapLines}
        stripedRows={tablePreferences?.stripedRows}
        contentDensity={tablePreferences?.contentDensity}
        stickyColumns={tablePreferences?.stickyColumns}
      />
      <DeleteAlertModal
        visible={isDeleteModalOpen}
        action={async () => {
          if (!!providerId && !!selectedRows?.at(0)?.id) {
            setDeleteModalOpen(false);
            spinnerContext?.showSpinner();
            await deleteProviderAffiliation({
              providerId: providerId,
              affiliationId: selectedRows?.at(0)?.id + " ",
            });
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Affiliation deleted successfully`,
              placement: "topRight",
            });
          }
        }}
        closeModal={() => setDeleteModalOpen(false)}
        header="Delete Affiliation"
        content={
          <Typography.Text>
            Affiliation Name:{" "}
            <Typography.Text strong>
              {selectedRows?.at(0)?.name}
            </Typography.Text>
          </Typography.Text>
        }
        description="Are you sure you want to delete this affiliation?"
      />
    </>
  );
}
