import { Col, Divider, Form, Row, Space, Typography } from "antd";
import RHFTextField from "../../components/RHF/RHFTextField";
import { FormProvider, useForm } from "react-hook-form";
import RHFSelect from "../../components/RHF/RHFSelect";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useContext, useEffect, useState } from "react";
import IncredableContent from "../../components/incredable-content";
import RHFTextArea from "../../components/RHF/RHFTextArea";
import { WorkFlowTaskRequest } from "../../redux/api/workflow/types";
import {
  useGetWorkflowQuery,
  useUpdateWorkflowTaskMutation,
} from "../../redux/api/workflow/workflow";
import { SpinnerContext } from "../../context/spinner/spinner";
import { ToastContext } from "../../context/toast/incredable-toast";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import IncredableButton from "../../components/button";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store";

export default function EditWorkflowTasks() {
  const methods = useForm<WorkFlowTaskRequest>();
  const [showTextArea, setShowTextArea] = useState(false);
  const { workflowId, taskId } = useParams();
  const { data: workflow, isLoading } = useGetWorkflowQuery(
    !!workflowId ? { id: workflowId } : skipToken
  );
  const [updateTask] = useUpdateWorkflowTaskMutation();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const navigate = useNavigate();
  const { data: taskStatuses } = useGetAllTaskStatusQuery();
  const { data: taskTypes } = useGetAllTaskTypeQuery();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: members } = useGetAllMembersQuery();
  const { data: facilities } = useGetFacilitiesQuery();

  const hasPermission = useSelector(
    (state: ApplicationState) =>
      state.ui.is_owner ||
      (state.ui.permissions?.includes("workflow.edit") ?? false)
  );
  const handleLabelClick = () => {
    setShowTextArea((prev) => !prev);
  };

  const onSubmit = async (data: WorkFlowTaskRequest) => {
    if (!!workflowId && !!taskId) {
      try {
        spinnerContext?.showSpinner();
        await updateTask({ taskId: taskId, ...data });
        toastContext?.openSuccessNotification({
          message: `Workflow task updated successfully`,
          placement: "topRight",
          onClose: () => {
            navigate(-1);
          },
        });
      } catch (error) {
        toastContext?.openErrorNotification({
          message: "Failed to update workflow task.",
          placement: "topRight",
        });
      } finally {
        spinnerContext?.hidespinner();
      }
    }
  };

  useEffect(() => {
    if (workflow && workflow?.tasks) {
      const workflowtask = workflow?.tasks?.find(
        (task) => task?.id + "" === taskId + ""
      );
      if (!workflowtask) return;
      methods.setValue("name", workflowtask?.name ?? "");
      methods.setValue("description", workflowtask?.description ?? "");
      methods.setValue("taskStatusId", workflowtask?.taskStatus?.id ?? "");
      methods.setValue("taskTypeId", workflowtask?.taskType?.id ?? "");
      methods.setValue("adminId", workflowtask?.admin?.id ?? "");
      methods.setValue("providerId", workflowtask?.provider?.id ?? "");
      methods.setValue("facilityId", workflowtask?.facility?.id ?? "");
      methods.setValue("workflowId", workflowId ?? "");
      methods.setValue("startDate", workflowtask?.startDate ?? "");
      methods.setValue("dueDate", workflowtask?.dueDate ?? "");
      methods.setValue("completedDate", workflowtask?.completedDate ?? "");
    }
  }, [methods, workflow]);

  return (
    <IncredableContent
      spinning={isLoading}
      style={{ margin: "20px 30px 0px" }}
      breadcrumbProps={{
        items: [
          {
            title: <NavLink to="/workflows">Workflows</NavLink>,
          },
          {
            title: <NavLink to={`/workflows/${workflow?.id}`}>{workflow?.name}</NavLink>,
          },
          {
            title: "Edit Task",
          },
        ],
      }}
    >
      <Space style={{ paddingBottom: "20px" }}>
        <Typography.Text
          strong
          style={{
            fontSize: "18px",
            color: "#1D1A1F",
            lineHeight: "25px",
            fontWeight: "500",
          }}
        >
          Edit Workflow Task
        </Typography.Text>
      </Space>
      <FormProvider {...methods}>
        <Form layout="vertical">
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Task Type",
                }}
                controllerProps={{
                  name: "taskTypeId",
                  rules: { required: "Task Type is required" },
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Task Type",
                  options: taskTypes?.map((type) => ({
                    label: type.name,
                    value: type.id,
                  })),
                  onModifyClick: () => {
                    navigate("/taskstype");
                  },
                }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Provider",
                }}
                controllerProps={{
                  name: `providerId`,
                }}
                selectProps={{
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Select Provider",
                  options: providers?.map((provider) => ({
                    label: `${provider.firstName} ${provider.lastName}`,
                    value: provider.id,
                  })),
                }}
              />
            </Col>

            <Col span={8}>
              <RHFTextField
                formItemProps={{
                  label: "Task Name",
                }}
                inputProps={{
                  placeholder: "Enter Task Name",
                }}
                controllerProps={{
                  name: `name`,
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Assigned To",
                }}
                controllerProps={{
                  name: `adminId`,
                  rules: { required: "Task Type is required" },
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Member",
                  options: members?.map((member) => ({
                    label: `${member.firstName} ${member.lastName}`,
                    value: member.id,
                  })),
                }}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Task Status",
                }}
                controllerProps={{
                  name: `taskStatusId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Task Status",
                  options: taskStatuses?.map((status) => ({
                    label: status.name,
                    value: status.id,
                  })),
                  onModifyClick: () => {
                    navigate("/taskstatus");
                  },
                }}
              />
            </Col>

            <Col span={8}>
              <RHFSelect
                formItemProps={{
                  label: "Facility",
                }}
                controllerProps={{
                  name: `facilityId`,
                  rules: { required: false },
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  placeholder: "Select Facility",
                  options: facilities?.map((facility) => ({
                    label: facility.name,
                    value: facility.id,
                  })),
                }}
              />
            </Col>

            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Start Date",
                }}
                controllerProps={{
                  name: `startDate`,
                }}
                datePickerProps={{
                  placeholder: "Select Start Date",
                }}
              />
            </Col>

            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Due Date",
                }}
                controllerProps={{
                  name: `dueDate`,
                }}
                datePickerProps={{
                  placeholder: "Select Due Date",
                }}
              />
            </Col>
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{
                  label: "Completed Date",
                }}
                controllerProps={{
                  name: "completedDate",
                }}
                datePickerProps={{
                  placeholder: "Select Completed Date",
                }}
              />
            </Col>
            <Col span={24}>
              <label
                onClick={handleLabelClick}
                style={{
                  cursor: "pointer",
                  fontWeight: "bold",
                  fontSize: "16px",
                  marginLeft: "5px",
                }}
              >
                {showTextArea ? <RightOutlined /> : <DownOutlined />} Notes{" "}
              </label>
              <Divider style={{ margin: "5px", padding: "0" }} />
              {showTextArea && (
                <RHFTextArea
                  formItemProps={{ label: "Notes" }}
                  controllerProps={{ name: "description" }}
                />
              )}
            </Col>
          </Row>
          <Space
            style={{
              width: "100%",
              justifyContent: "end",
            }}
          >
            <IncredableButton
              secondary
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </IncredableButton>
            <IncredableButton
              disabled={!hasPermission}
              type="primary"
              onClick={methods.handleSubmit(onSubmit)}
            >
              Save
            </IncredableButton>
          </Space>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
