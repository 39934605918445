import React, { useEffect, useMemo } from "react";
import { Col, Row, Divider, Alert, Spin, Button } from "antd";
import { useFieldArray } from "react-hook-form";
import { PlusOutlined } from "@ant-design/icons";
import RHFDatePicker from "../../components/RHF/RHFDatePicker";
import RHFSelect from "../../components/RHF/RHFSelect";
import RHFTextField from "../../components/RHF/RHFTextField";
import { useGetFacilitiesQuery } from "../../redux/api/facility/facility";
import { useGetAllMembersQuery } from "../../redux/api/manageadmin/manageadmin";
import { useGetBasicAllProvidersQuery } from "../../redux/api/provider/provider";
import {
  useGetAllTaskStatusQuery,
  useGetAllTaskTypeQuery,
} from "../../redux/api/task";
import { WorkFlowTaskRequest } from "../../redux/api/workflow/types";
import { useGetWorkflowTemplateQuery } from "../../redux/api/workflow/workflow";
import { skipToken } from "@reduxjs/toolkit/query";
import IncredableButton from "../../components/button";

const StepThree = ({
  workflowTemplateId,
  methods,
}: {
  workflowTemplateId: string | undefined;
  methods: any;
}) => {
  const { data: workflowtemplate } = useGetWorkflowTemplateQuery(
    !!workflowTemplateId ? { id: workflowTemplateId } : skipToken
  );

  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "tasks",
  });

  useEffect(() => {
    if (!!workflowtemplate) {
      const transformedTasks = workflowtemplate.tasks.map((task) => ({
        id: task.id,
        name: task.name,
        description: task.description,
        taskTypeId: task.taskType?.id || "",
        taskStatusId: task.taskStatus?.id || "",
      }));

      methods.setValue("tasks", transformedTasks);
    }
  }, [methods, workflowtemplate]);

  const {
    data: taskStatuses,
    isLoading: isLoadingTaskStatuses,
    isError: isErrorTaskStatuses,
  } = useGetAllTaskStatusQuery();

  const {
    data: taskTypes,
    isLoading: isLoadingTaskTypes,
    isError: isErrorTaskTypes,
  } = useGetAllTaskTypeQuery();

  const {
    data: providers,
    isLoading: isLoadingProviders,
    isError: isErrorProviders,
  } = useGetBasicAllProvidersQuery();

  const {
    data: members,
    isLoading: isLoadingMembers,
    isError: isErrorMembers,
  } = useGetAllMembersQuery();

  const {
    data: facilities,
    isLoading: isLoadingFacilities,
    isError: isErrorFacilities,
  } = useGetFacilitiesQuery();

  const taskStatusOptions = useMemo(
    () =>
      taskStatuses?.map((status) => ({
        label: status.name,
        value: status.id,
      })) || [],
    [taskStatuses]
  );

  const taskTypeOptions = useMemo(
    () =>
      taskTypes?.map((type) => ({
        label: type.name,
        value: type.id,
      })) || [],
    [taskTypes]
  );

  const providerOptions = useMemo(
    () =>
      providers?.map((provider) => ({
        label: `${provider.firstName} ${provider.lastName}`,
        value: provider.id,
      })) || [],
    [providers]
  );

  const memberOptions = useMemo(
    () =>
      members?.map((member) => ({
        label: `${member.firstName} ${member.lastName}`,
        value: member.id,
      })) || [],
    [members]
  );

  const facilityOptions = useMemo(
    () =>
      facilities?.map((facility) => ({
        label: facility.name,
        value: facility.id,
      })) || [],
    [facilities]
  );

  if (
    isLoadingTaskStatuses ||
    isLoadingTaskTypes ||
    isLoadingProviders ||
    isLoadingMembers ||
    isLoadingFacilities
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "20vh",
        }}
      >
        <Spin tip="Loading..." />
      </div>
    );
  }

  if (
    isErrorTaskStatuses ||
    isErrorTaskTypes ||
    isErrorProviders ||
    isErrorMembers ||
    isErrorFacilities
  ) {
    return <Alert message="Error fetching data" type="error" />;
  }
  const commonButtonStyle: React.CSSProperties = {
    width: "140px",
    height: "40px",
    border: "1.5px solid #598BEB",
    color: "#598BEB",
    borderRadius: "8px",
    padding: "10px 18px",
    fontWeight: 600,
    fontSize: "16px",
  };

  return (
    <>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        {fields.map((field, index) => (
          <Col span={24} key={field.id}>
            <div style={{ marginTop: "20px" }}>
              {index > 0 && <Divider />}
              <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col span={8}>
                  <RHFSelect
                    formItemProps={{
                      label: "Task Type",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.taskTypeId`,
                      rules: { required: "Task Type is required" },
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Select Task Type",
                      options: taskTypeOptions,
                    }}
                  />
                </Col>

                <Col span={8}>
                  <RHFSelect
                    formItemProps={{
                      label: "Provider",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.providerId`,
                      rules: { required: false },
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Select Provider",
                      options: providerOptions,
                    }}
                  />
                </Col>

                <Col span={8}>
                  <RHFTextField
                    formItemProps={{
                      label: "Task Name",
                    }}
                    inputProps={{
                      placeholder: "Enter Task Name",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.name`,
                      rules: { required: "Task Name is required" },
                    }}
                  />
                </Col>

                <Col span={8}>
                  <RHFSelect
                    formItemProps={{
                      label: "Task Status",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.taskStatusId`,
                      rules: { required: "Task Status is required" },
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Select Task Status",
                      options: taskStatusOptions,
                    }}
                  />
                </Col>

                <Col span={8}>
                  <RHFSelect
                    formItemProps={{
                      label: "Assigned To",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.adminId`,
                      rules: { required: false },
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Select Member",
                      options: memberOptions,
                    }}
                  />
                </Col>

                <Col span={8}>
                  <RHFSelect
                    formItemProps={{
                      label: "Facility",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.facilityId`,
                      rules: { required: false },
                    }}
                    selectProps={{
                      allowClear: true,
                      showSearch: true,
                      style: { width: "100%" },
                      placeholder: "Select Facility",
                      options: facilityOptions,
                    }}
                  />
                </Col>

                <Col span={8}>
                  <RHFDatePicker
                    formItemProps={{
                      label: "Start Date",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.startDate`,
                      rules: { required: "Start Date is required" },
                    }}
                    datePickerProps={{
                      style: { width: "100%" },
                      placeholder: "Select Start Date",
                    }}
                  />
                </Col>

                <Col span={8}>
                  <RHFDatePicker
                    formItemProps={{
                      label: "Due Date",
                    }}
                    controllerProps={{
                      name: `tasks.${index}.dueDate`,
                    }}
                    datePickerProps={{
                      style: { width: "100%" },
                      placeholder: "Select Due Date",
                    }}
                  />
                </Col>
                <Col span={8} style={{ textAlign: "right", marginTop: "25px" }}>
                  {fields.length > 1 && (
                    <IncredableButton secondary onClick={() => remove(index)}>
                      Remove
                    </IncredableButton>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        ))}

        <Col span={24}>
          <IncredableButton
            onClick={() => append({} as WorkFlowTaskRequest)}
            secondary
            icon={<PlusOutlined />}
            style={{ marginTop: "20px" }}
          >
            Add New Task
          </IncredableButton>
        </Col>
      </Row>
    </>
  );
};

export default StepThree;
