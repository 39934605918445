import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import { ToastContext } from "../context/toast/incredable-toast";
import { SpinnerContext } from "../context/spinner/spinner";
import { useContext } from "react";
import { Layout, Form, Flex, Typography, Space, Divider, Row, Col } from "antd";
import IncredableButton from "../components/button";
import RHFSelect from "../components/RHF/RHFSelect";
import RHFDatePicker from "../components/RHF/RHFDatePicker";
import AddlRecipient from "./addl-recipient";
import { alertReminderOptions } from "../components/digitalforms/constants";

type FormInputType = {
  providerId: string;
  categoryId: string;
};
export default function AddSignatureRequest() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: categories } = useGetAllCategoryQuery();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);

  return (
    <Layout.Content
      style={{
        margin: "48px",
        padding: "32px",
        backgroundColor: "white",
        borderRadius: "16px",
        boxShadow: "0px 10px 25px 0px #5911920D",
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addcontract"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            spinnerContext?.showSpinner();

            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Signature request saved successfully`,
              placement: "topRight",
            });
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Signature Request
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Add Signature Request
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={16}>
              <RHFSelect
                formItemProps={{ label: "Primary recipeint" }}
                controllerProps={{
                  name: `providerId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Select provider",
                  options: providers?.map((provider) => {
                    return {
                      label: provider?.firstName + " " + provider?.lastName,
                      value: provider?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Divider
              style={{
                padding: "0",
                margin: "0 0 8px 0",
              }}
            />
            <Col span={24}>
              <AddlRecipient />
            </Col>
            <Divider
              style={{
                padding: "0",
                margin: "8px 0 12px 0",
              }}
            />
            <Col span={16}>
              <RHFSelect
                formItemProps={{
                  label: "Catgeory",
                  help: "Choose the document category where the signed signature request will be stored",
                }}
                controllerProps={{
                  name: `categoryId`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Choose category",
                  options: categories?.map((category) => {
                    return {
                      label: category?.name,
                      value: category?.id,
                    };
                  }),
                }}
              />
            </Col>
            <Divider
              style={{
                padding: "0",
                margin: "0px 0 12px 0",
              }}
            />
            <Col span={8}>
              <RHFDatePicker
                formItemProps={{ label: "Expiration date" }}
                controllerProps={{ name: "expirationDate" }}
                datePickerProps={{}}
              />
            </Col>
            <Col span={8}>
              <RHFSelect
                formItemProps={{ label: "Expiration Alert Reminder" }}
                controllerProps={{
                  name: `alertDays`,
                }}
                selectProps={{
                  allowClear: true,
                  showSearch: true,
                  style: { width: "100%" },
                  placeholder: "Search to Expiration Alert Reminder",
                  options: alertReminderOptions?.map((alertdays) => {
                    return {
                      label: alertdays?.label,
                      value: alertdays?.value,
                    };
                  }),
                }}
              />
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Layout.Content>
  );
}
