import {
  Col,
  Divider,
  Flex,
  Form,
  Layout,
  Row,
  Space,
  theme,
  Typography,
} from "antd";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SpinnerContext } from "../../../context/spinner/spinner";
import { ToastContext } from "../../../context/toast/incredable-toast";
import IncredableButton from "../../../components/button";
import RHFTextField from "../../../components/RHF/RHFTextField";
import { CredentialTemplateRequest } from "../../../redux/api/credentialpackages/types";
import { useCreateCredentialTemplateMutation } from "../../../redux/api/credentialpackages/credentialpackages";
import { useGetAllLicenseTypeQuery } from "../../../redux/api/licensetype/licensetype";
import RHFSelect from "../../../components/RHF/RHFSelect";
import IncredableContent from "../../../components/incredable-content";

const { useToken } = theme;
type FormInputType = CredentialTemplateRequest;

export default function AddCredentialTemplate() {
  const navigate = useNavigate();
  const methods = useForm<FormInputType>();
  const [addCredentialTemplate] = useCreateCredentialTemplateMutation();
  const { token } = useToken();
  const spinnerContext = useContext(SpinnerContext);
  const toastContext = useContext(ToastContext);
  const { data: licenseType } = useGetAllLicenseTypeQuery();

  return (
    <IncredableContent
      breadcrumbProps={{
        items: [
          {
            title: <a href="/credentialtemplates">Credential Templates</a>,
          },
          {
            title: "Add",
          },
        ],
      }}
    >
      <FormProvider {...methods}>
        <Form
          name="addcredentialtemplate"
          layout="vertical"
          onFinish={methods.handleSubmit(async (data: FormInputType) => {
            await addCredentialTemplate(data);
            spinnerContext?.hidespinner();
            toastContext?.openSuccessNotification({
              message: `Credential Template saved successfully`,
              placement: "topRight",
            });
            navigate(-1);
          })}
          autoComplete="off"
        >
          <Flex gap="small" wrap justify="space-between">
            <Typography.Text
              strong
              style={{
                fontSize: "22px",
                color: "#1D1A1F",
                lineHeight: "25px",
                fontWeight: "700",
              }}
            >
              Credential Template
            </Typography.Text>
            <Space>
              <IncredableButton
                secondary
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" htmlType="submit">
                Add Credential Template
              </IncredableButton>
            </Space>
          </Flex>
          <Divider />
          <Form.List name="credentialtemplate" initialValue={[{}]}>
            {(fields, { add }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <div key={key}>
                      {index > 0 && <Divider />}
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <RHFTextField
                            formItemProps={{
                              label: "Name",
                            }}
                            inputProps={{}}
                            controllerProps={{
                              name: `name`,
                            }}
                            rules={{ required: "This field is required" }}
                          />
                        </Col>
                        <Col span={8}>
                          <RHFSelect
                            formItemProps={{ label: "License Type" }}
                            controllerProps={{
                              name: `licenseTypeId`,
                            }}
                            selectProps={{
                              allowClear: true,
                              showSearch: true,
                              style: { width: "100%" },
                              placeholder: "Search to Select",
                              options: licenseType?.map((type) => {
                                return {
                                  label: type?.name,
                                  value: type?.id,
                                };
                              }),
                            }}
                          />
                        </Col>
                      </Row>
                    </div>
                  ))}
                </>
              );
            }}
          </Form.List>
        </Form>
      </FormProvider>
    </IncredableContent>
  );
}
