import { CSSProperties } from "react";

export const profileSection: CSSProperties = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
};

export const profileRole: CSSProperties = {
  display: "flex",
  lineHeight: "0",
  flexDirection: "column",
  justifyContent: "center",
  rowGap: "0px",
  alignItems: "start",
};

export const headerProfilePic: CSSProperties = {
  display: "flex",
  alignItems: "center",
};

export const headerProfileImage: CSSProperties = {
  width: "48px",
  height: "48px",
};

export const menuTitle: CSSProperties = {
  fontWeight: 700,
  fontSize: "24px",
  color: "#521262",
};

export const subHeadingStyle: CSSProperties = {
  color: "#fff",
  fontSize: "14px",
  lineHeight: "20px",
  fontWeight: "500",
  display: "flex",
  alignItems: "center",
  gap: "10px",
};
