  // import React, { useEffect, useState, FC, useRef } from "react";
  // import {
  //   Modal,
  //   Form,
  //   Switch,
  //   Row,
  //   Col,
  //   Divider,
  //   Space,
  //   Typography,
  // } from "antd";
  // import IncredableButton from "../../components/button";
  // import { HolderOutlined } from "@ant-design/icons";
  // import { DndProvider, useDrag, useDrop } from "react-dnd";
  // import { HTML5Backend } from "react-dnd-html5-backend";
  // interface TablePreferenceModalProps {
  //   visible: boolean;
  //   onCancel: () => void;
  //   onSave: (selectedColumns: string[], columnOrder: string[]) => void;
  //   availableColumns: string[];
  //   selectedColumns: string[];
  //   columnOrderState: string[];
  // }
  // interface DragItem {
  //   index: number;
  //   type: string;
  // }
  // const DraggableColumn: FC<{
  //   col: string;
  //   index: number;
  //   moveColumn: (dragIndex: number, hoverIndex: number) => void;
  //   localSelectedColumns: string[];
  //   handleColumnChange: (col: string, checked: boolean) => void;
  // }> = ({ col, index, moveColumn, localSelectedColumns, handleColumnChange }) => {
  //   const ref = useRef<HTMLDivElement>(null);
  //   const [, drag] = useDrag({
  //     type: "column",
  //     item: { index },
  //   });
  //   const [, drop] = useDrop({
  //     accept: "column",
  //     hover: (draggedItem: DragItem, monitor) => {
  //       if (!ref.current) {
  //         return;
  //       }
  //       const hoverIndex = index;
  //       const dragIndex = draggedItem.index;
  //       if (dragIndex === hoverIndex) {
  //         return;
  //       }
  //       const hoverBoundingRect = ref.current.getBoundingClientRect();
  //       const hoverMiddleY =
  //         (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
  //       const clientOffset = monitor.getClientOffset();
  //       if (!clientOffset) {
  //         return;
  //       }
  //       const hoverClientY = clientOffset.y - hoverBoundingRect.top;
  //       if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
  //         return;
  //       }
  //       if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
  //         return;
  //       }
  //       moveColumn(dragIndex, hoverIndex);
  //       draggedItem.index = hoverIndex;
  //     },
  //   });
  //   drag(drop(ref));
  //   return (
  //     <div style={{ alignItems: "center" }}>
  //       <Form.Item
  //         style={{ marginBottom: "5px" }}
  //         label={
  //           <span style={{ display: "flex", alignItems: "center" }}>
  //             <HolderOutlined
  //               ref={ref}
  //               style={{ marginRight: "73px", cursor: "move" }}
  //             />
  //             <span style={{ textTransform: "capitalize" }}>{col.replace(/([a-z])([A-Z])/g, '$1 $2')}</span>
  //           </span>
  //         }
  //         colon={false}
  //       >
  //         <Switch
  //           style={{ float: "right" }}
  //           checked={localSelectedColumns.includes(col)}
  //           onChange={(checked) => handleColumnChange(col, checked)}
  //         />
  //       </Form.Item>
  //     </div>
  //   );
  // };
  // const TablePreferenceModal: FC<TablePreferenceModalProps> = ({
  //   visible,
  //   onCancel,
  //   onSave,
  //   selectedColumns,
  //   columnOrderState,
  // }) => {
  //   const [localSelectedColumns, setLocalSelectedColumns] =
  //     useState<string[]>(selectedColumns);
  //   const [columnOrder, setColumnOrder] = useState<string[]>(columnOrderState);
  //   useEffect(() => {
  //     setLocalSelectedColumns(selectedColumns);
  //     setColumnOrder(columnOrderState);
  //   }, [selectedColumns, columnOrderState]);
  //   const moveColumn = (dragIndex: number, hoverIndex: number) => {
  //     const newColumns = [...columnOrder];
  //     const [draggedCol] = newColumns.splice(dragIndex, 1);
  //     newColumns.splice(hoverIndex, 0, draggedCol);
  //     setColumnOrder(newColumns);
  //   };
  //   const handleColumnChange = (col: string, checked: boolean) => {
  //     setLocalSelectedColumns((prev) =>
  //       checked ? [...prev, col] : prev.filter((c) => c !== col)
  //     );
  //   };
  //   const handleConfirm = () => {
  //     onSave(localSelectedColumns, columnOrder);
  //     onCancel();
  //   };
  //   return (
  //     <DndProvider backend={HTML5Backend}>
  //       <Modal
  //         title={
  //           <Space direction="vertical" size={0}>
  //             <Typography.Title level={5} style={{ marginBottom: 0 }}>
  //               Column Preferences
  //             </Typography.Title>
  //             <Typography.Text type="secondary">
  //               Customize the columns visibility and order.
  //             </Typography.Text>
  //           </Space>
  //         }
  //         open={visible}
  //         onCancel={onCancel}
  //         onOk={handleConfirm}
  //         footer={null}
  //       >
  //         <Form layout="horizontal">
  //           <Row
  //             gutter={16}
  //             style={{
  //               borderBottom: "1px solid rgb(245 231 234)",
  //               marginBottom: 10,
  //             }}
  //           >
  //             <Col span={24}>
  //               <div style={{ width: "100%", marginTop: 15 }}>
  //                 <Typography.Text strong style={{ marginLeft: 87 }}>
  //                   Name
  //                 </Typography.Text>
  //                 {columnOrder.map((col, index) => (
  //                   <React.Fragment key={col}>
  //                     <Divider
  //                       style={{
  //                         height: 1,
  //                         margin: "5px 0",
  //                         background: "#FFFEFE",
  //                       }}
  //                     />
  //                     <DraggableColumn
  //                       col={col}
  //                       index={index}
  //                       moveColumn={moveColumn}
  //                       localSelectedColumns={localSelectedColumns}
  //                       handleColumnChange={handleColumnChange}
  //                     />
  //                   </React.Fragment>
  //                 ))}
  //               </div>
  //             </Col>
  //           </Row>
  //           <Row justify="end" style={{ marginTop: 20 }}>
  //             <Space>
  //               <IncredableButton secondary onClick={onCancel}>
  //                 Cancel
  //               </IncredableButton>
  //               <IncredableButton type="primary" onClick={handleConfirm}>
  //                 Confirm
  //               </IncredableButton>
  //             </Space>
  //           </Row>
  //         </Form>
  //       </Modal>
  //     </DndProvider>
  //   );
  // };
  // export default TablePreferenceModal;


  import React, { useEffect, useState, FC, useRef, useCallback } from "react";
import {
  Modal,
  Form,
  Switch,
  Row,
  Col,
  Divider,
  Space,
  Typography,
} from "antd";
import IncredableButton from "../../components/button";
import { HolderOutlined } from "@ant-design/icons";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

interface TablePreferenceModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedColumns: string[], columnOrder: string[]) => void;
  availableColumns: string[];
  selectedColumns: string[];
  columnOrderState: string[];
}

interface DragItem {
  index: number;
  type: string;
}

const DraggableColumn: FC<{
  col: string;
  index: number;
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  localSelectedColumns: string[];
  handleColumnChange: (col: string, checked: boolean) => void;
}> = ({ col, index, moveColumn, localSelectedColumns, handleColumnChange }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag({
    type: "column",
    item: { index },
  });

  const [, drop] = useDrop({
    accept: "column",
    hover: (draggedItem: DragItem, monitor) => {
      if (!ref.current) {
        return;
      }
      const hoverIndex = index;
      const dragIndex = draggedItem.index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      if (!clientOffset) {
        return;
      }
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveColumn(dragIndex, hoverIndex);
      draggedItem.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <div style={{ alignItems: "center" }} ref={ref}>
      <Form.Item
        style={{ marginBottom: "5px" }}
        label={
          <span style={{ display: "flex", alignItems: "center" }}>
            <HolderOutlined style={{ marginRight: "73px", cursor: "move" }} />
            <span style={{ textTransform: "capitalize" }}>
              {col.replace(/([a-z])([A-Z])/g, '$1 $2')}
            </span>
          </span>
        }
        colon={false}
      >
        <Switch
          style={{ float: "right" }}
          checked={localSelectedColumns.includes(col)}
          onChange={(checked) => handleColumnChange(col, checked)}
        />
      </Form.Item>
    </div>
  );
};

const TablePreferenceModal: FC<TablePreferenceModalProps> = ({
  visible,
  onCancel,
  onSave,
  selectedColumns,
  columnOrderState,
}) => {
  const [localSelectedColumns, setLocalSelectedColumns] = useState<string[]>(selectedColumns);
  const [columnOrder, setColumnOrder] = useState<string[]>(columnOrderState);

  useEffect(() => {
    setLocalSelectedColumns(selectedColumns);
    setColumnOrder(columnOrderState);
  }, [selectedColumns, columnOrderState]);

  const moveColumn = useCallback((dragIndex: number, hoverIndex: number) => {
    const newColumns = [...columnOrder];
    const [draggedCol] = newColumns.splice(dragIndex, 1);
    newColumns.splice(hoverIndex, 0, draggedCol);
    setColumnOrder(newColumns);
  }, [columnOrder]);

  const handleColumnChange = useCallback((col: string, checked: boolean) => {
    setLocalSelectedColumns((prev) =>
      checked ? [...prev, col] : prev.filter((c) => c !== col)
    );
  }, []);

  const handleConfirm = () => {
    console.log("Selected Columns: ", localSelectedColumns);
    console.log("Column Order: ", columnOrder);
    onSave(localSelectedColumns, columnOrder);
    onCancel();
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        title={
          <Space direction="vertical" size={0}>
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              Column Preferences
            </Typography.Title>
            <Typography.Text type="secondary">
              Customize the columns visibility and order.
            </Typography.Text>
          </Space>
        }
        open={visible}
        onCancel={onCancel}
        onOk={handleConfirm}
        footer={null}
      >
        <Form layout="horizontal">
          <Row
            gutter={16}
            style={{
              borderBottom: "1px solid rgb(245 231 234)",
              marginBottom: 10,
            }}
          >
            <Col span={24}>
              <div style={{ width: "100%", marginTop: 15 }}>
                <Typography.Text strong style={{ marginLeft: 87 }}>
                  Name
                </Typography.Text>
                {columnOrder.map((col, index) => (
                  <React.Fragment key={col}>
                    <Divider
                      style={{
                        height: 1,
                        margin: "5px 0",
                        background: "#FFFEFE",
                      }}
                    />
                    <DraggableColumn
                      col={col}
                      index={index}
                      moveColumn={moveColumn}
                      localSelectedColumns={localSelectedColumns}
                      handleColumnChange={handleColumnChange}
                    />
                  </React.Fragment>
                ))}
              </div>
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: 20 }}>
            <Space>
              <IncredableButton secondary onClick={onCancel}>
                Cancel
              </IncredableButton>
              <IncredableButton type="primary" onClick={handleConfirm}>
                Confirm
              </IncredableButton>
            </Space>
          </Row>
        </Form>
      </Modal>
    </DndProvider>
  );
};

export default TablePreferenceModal;
