import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space, Tag } from "antd";
import { useEffect, useState } from "react";
import {
  useGetAllFacilityTypesQuery,
  useGetFacilitiesQuery,
} from "../redux/api/facility/facility";
import { USStatesList } from "../common/constants";
import { Facility } from "../redux/api/facility/types";

export default function useFacilityFilter(items?: Facility[]) {
  const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");

  const [facilityNameFiltervalue, setFacilityNameFiltervalue] = useState<
    string | undefined
  >(undefined);
  const [npiNumberFiltervalue, setNpiNumberFiltervalue] = useState<
    string | undefined
  >(undefined);
  const [facilityTypeFiltervalue, setFacilityTypeFiltervalue] = useState<
    string | undefined
  >(undefined);
  const [stateFiltervalue, setStateFiltervalue] = useState<string | undefined>(
    undefined
  );
  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "id", isDescending: false });

  const [pageSize, setPageSize] = useState<number>(10);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data: facilityTypes = [], isLoading: facilityTypesLoading } =
    useGetAllFacilityTypesQuery();
  const {
    data: facilities = [],
    refetch,
    isLoading: facilitiesLoading,
    isFetching,
  } = useGetFacilitiesQuery();

  const [filteredFacilities, setFilteredFacilities] = useState<Facility[]>(
    items ?? facilities
  );
  useEffect(() => {
    setFilteredFacilities(items ?? facilities);
  }, [facilities, items]);

  useEffect(() => {
    let temp_filtered_facilities = !!items ? [...items] : [...facilities];

    if (!!facilityNameFiltervalue && selectedFilterkey === "name")
      temp_filtered_facilities = temp_filtered_facilities?.filter((name) =>
        name?.name
          ?.toLowerCase()
          .includes(facilityNameFiltervalue?.toLowerCase())
      );
    if (!!npiNumberFiltervalue && selectedFilterkey === "npiNumber")
      temp_filtered_facilities = temp_filtered_facilities?.filter((npi) =>
        npi?.npiNumber
          ?.toLowerCase()
          .includes(npiNumberFiltervalue?.toLowerCase())
      );

    if (!!facilityTypeFiltervalue && selectedFilterkey === "facilitytype") {
      temp_filtered_facilities = temp_filtered_facilities?.filter(
        (type) => type?.facilityType?.id + "" === facilityTypeFiltervalue + ""
      );
    }
    if (!!stateFiltervalue && selectedFilterkey === "state") {
      temp_filtered_facilities = temp_filtered_facilities?.filter(
        (state) => state?.facilityAddress?.state + "" === stateFiltervalue + ""
      );
    }
    setFilteredFacilities(temp_filtered_facilities);
  }, [
    facilityNameFiltervalue,
    facilityTypeFiltervalue,
    npiNumberFiltervalue,
    stateFiltervalue,
    facilities,
    items,
  ]);
  useEffect(() => {
    setFacilityNameFiltervalue(undefined);
    setNpiNumberFiltervalue(undefined);
  }, [selectedFilterkey]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterkey}
          onChange={(value) => {
            setSelectedFilterKey(value);
          }}
          style={{ width: "140px" }}
          options={[
            { value: "name", label: "Name" },
            { value: "npiNumber", label: "NPI Number" },
            {
              value: "facilitytype",
              label: "Facility Type",
            },
            { value: "state", label: "State" },
          ]}
        />
        {selectedFilterkey === "name" && (
          <Input
            onChange={(event) => {
              setFacilityNameFiltervalue(event?.target?.value);
            }}
            value={facilityNameFiltervalue}
            style={{
              width: "320px",
            }}
            placeholder="Find Name"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterkey === "npiNumber" && (
          <Input
            onChange={(event) => {
              setNpiNumberFiltervalue(event?.target?.value);
            }}
            value={npiNumberFiltervalue}
            style={{
              width: "320px",
            }}
            placeholder="Find NPI Number"
            prefix={<SearchOutlined />}
          />
        )}
        {selectedFilterkey === "facilitytype" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={facilityTypeFiltervalue}
            placeholder="Select Facility Type"
            onChange={(value) => {
              setFacilityTypeFiltervalue(value);
            }}
            style={{ width: "320px" }}
            options={facilityTypes?.map((facilityType) => ({
              label: facilityType?.name,
              searchValue: facilityType?.name,
              value: facilityType?.id + "",
            }))}
          />
        )}
        {selectedFilterkey === "state" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            value={stateFiltervalue}
            placeholder="Select State"
            onChange={(value) => {
              setStateFiltervalue(value);
            }}
            style={{ width: "320px" }}
            options={USStatesList?.map((state) => ({
              label: state?.value,
              searchValue: state?.value,
              value: state?.value + "",
            }))}
          />
        )}
      </Space>
      <div>
        {!!facilityTypeFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setFacilityTypeFiltervalue(undefined);
            }}
          >
            <span>
              FacilityType ={" "}
              {
                facilityTypes?.find(
                  (type) => type?.id + "" === facilityTypeFiltervalue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!stateFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setStateFiltervalue(undefined);
            }}
          >
            <span>
              State ={" "}
              {
                USStatesList?.find(
                  (state) => state?.value + "" === stateFiltervalue + ""
                )?.value
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredFacilities: filteredFacilities,
    UseQueryHookResult: {
      refetch,
      isFetching,
      isLoading: facilitiesLoading,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}
