import { api } from "..";
import { Specialty, SpecialtyInfo, SpecialtyRequest } from "./types";

const specialtyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllSpecialty: build.query<Specialty[], void>({
      query: () => "specialty/all",
      providesTags: ["Specialty"],
    }),
    saveAllSpecialty: build.mutation<void, SpecialtyInfo[]>({
      query: (types) => {
        return {
          url: `specialty`,
          method: "POST",
          body: types,
        };
      },
      invalidatesTags: ["Specialty"],
    }),
    saveSpecialty: build.mutation<void, SpecialtyRequest>({
      query: ({ ...body }) => {
        return {
          url: "specialty",
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: ["Specialty"],
    }),
    updateSpecialty: build.mutation<
      void,
      { id: string } & Partial<SpecialtyRequest>
    >({
      query: ({ id, ...patch }) => {
        return {
          url: `specialty/${id}`,
          method: "PUT",
          body: patch,
        };
      },
      invalidatesTags: (result, error, arg) => [
        { type: "Specialty", id: arg.id },
        { type: "Specialty" },
      ],
    }),
    deleteSpecialty: build.mutation<void, { id: string }>({
      query: ({ id }) => ({
        url: `specialty/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Specialty"],
    }),
  }),
});

export const {
  useGetAllSpecialtyQuery,
  useSaveAllSpecialtyMutation,
  useSaveSpecialtyMutation,
  useUpdateSpecialtyMutation,
  useDeleteSpecialtyMutation,
} = specialtyApi;
