import { useEffect, useState } from "react";
import {
  Button,
  Upload,
  Card,
  Typography,
  Table,
  Space,
  Input,
  Modal,
  Form,
  Steps,
  Select,
} from "antd";
import { DeleteOutlined, EditOutlined, InboxOutlined } from "@ant-design/icons";
import { FormProvider, useForm } from "react-hook-form";
import IncredableButton from "../components/button";
import { useGetAllCategoryQuery } from "../redux/api/category/category";
import Flex from "antd/lib/flex";
import RHFDatePicker from "../components/RHF/RHFDatePicker";
import RHFSelect from "../components/RHF/RHFSelect";
import RHFTextArea from "../components/RHF/RHFTextArea";
import RHFTextField from "../components/RHF/RHFTextField";
import { DocumentRequest } from "../redux/api/document/types";
import { RcFile, UploadFile } from "antd/lib/upload";
import { v4 as uuidv4 } from "uuid";
import IncredableDateFormat from "../common/incredable-date-format";
import { useSaveDocumentMutation } from "../redux/api/document/document";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import IncredableContent from "../components/incredable-content";
import { useNavigate } from "react-router-dom";
import { alertReminderOptions } from "../common/constants";

const { Dragger } = Upload;
const { Search } = Input;

type DocumentRequestWithUid = DocumentRequest & { key: string };
const items = [
  {
    title: "Select provider",
  },
  {
    title: "Upload Documents",
  },
  {
    title: "Review and save",
  },
];
export default function UploadMultipleDocuments() {
  const methods = useForm<DocumentRequestWithUid>();
  const { data: categories } = useGetAllCategoryQuery();
  const [fileList, setFileList] = useState<DocumentRequestWithUid[]>([]);
  const [selectedFile, setSelectedFile] = useState<DocumentRequestWithUid>();
  const [saveDocument] = useSaveDocumentMutation();
  const [providerId, setProviderId] = useState();
  const { data: providers } = useGetBasicAllProvidersQuery();

  const naivgate = useNavigate();
  const uploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: (file: RcFile) => {
      const newFile = {
        key: uuidv4(),
        name: file.name,
        expirationDate: "",
        alertReminders: "",
        notes: "",
        categoryId: "",
        file: file,
      };
      setFileList((prev) => [...prev, newFile]);
      return false;
    },
    onRemove: (file: UploadFile) => {
      setFileList((prev) => prev.filter((item) => item.name !== file.name));
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const columns = [
    {
      title: <Typography.Text>Name</Typography.Text>,
      dataIndex: "name",
      key: "name",
      render: (text: string) => text,
    },
    {
      title: <Typography.Text>Category Name</Typography.Text>,
      dataIndex: "categoryId",
      key: "categoryId",
      render: (categoryId: string) => (
        <span>
          {categories?.find((category) => category?.id + "" === categoryId + "")
            ?.name ?? "-"}
        </span>
      ),
    },
    {
      title: <Typography.Text>Expiration Date</Typography.Text>,
      dataIndex: "expirationDate",
      key: "expirationDate",
      render: (expirationDate: string) => (
        <span>
          {!!expirationDate ? IncredableDateFormat(expirationDate) : "-"}
        </span>
      ),
    },
    {
      title: <Typography.Text>Expiration Alert Reminders</Typography.Text>,
      dataIndex: "alertDays",
      key: "alertDays",
      render: (alertDays: string) => <span>{alertDays ?? "-"}</span>,
    },
    {
      title: <Typography.Text>Notes</Typography.Text>,
      dataIndex: "notes",
      render: (notes: string) => <span>{notes ?? "-"}</span>,
    },
    {
      title: <Typography.Text>Action</Typography.Text>,
      key: "actions",
      render: (_: any, record: any) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => {
              setSelectedFile(record);
              showModal();
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.name)}
          />
        </Space>
      ),
    },
  ];

  const handleDelete = (key: string) => {
    const newData = fileList.filter((item) => item.name !== key);
    setFileList(newData);
  };

  useEffect(() => {
    if (!selectedFile) return;
    methods.reset({
      key: selectedFile?.key,
      name: selectedFile?.name,
      expirationDate: selectedFile?.expirationDate,
      alertDays: selectedFile?.alertDays,
      notes: selectedFile?.notes,
      categoryId: selectedFile?.categoryId,
      file: selectedFile?.file,
    });
  }, [selectedFile, methods]);

  const [current, setCurrent] = useState(0);

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  return (
    <IncredableContent>
      <Steps current={current} items={items} />
      <div style={{ marginTop: 24 }}>
        {current === 0 && (
          <Card
            title="Select provider"
            extra={
              <Button type="primary" onClick={() => next()}>
                Next
              </Button>
            }
          >
            <Select
              style={{ width: "300px" }}
              showSearch
              placeholder="Select a provider"
              optionFilterProp="label"
              onChange={(value, option) => {
                setProviderId(value);
              }}
              options={providers?.map((provider) => {
                return {
                  label: `${provider?.firstName} ${provider?.lastName}`,
                  value: provider?.id,
                };
              })}
            />
          </Card>
        )}
        {current === 1 && (
          <Card
            extra={
              <Space>
                <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                  Previous
                </Button>
                <Button type="primary" onClick={() => next()}>
                  Next
                </Button>
              </Space>
            }
            title={
              <div style={{ marginTop: "10px" }}>
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Upload Multiple Documents
                </Typography.Title>
                <Typography.Text type="secondary">
                  Add the file you want to upload
                </Typography.Text>
              </div>
            }
            style={{
              borderRadius: "16px",
              boxShadow: "0px 10px 25px 0px #5911920D",
            }}
          >
            <Dragger
              {...uploadProps}
              style={{
                border: "1px dashed #598BEB",
                background: "#F6FAFF",
                height: "200px",
              }}
            >
              <Space direction="vertical">
                <Space>
                  <InboxOutlined
                    style={{ fontSize: "32px", color: "#7C4095" }}
                  />
                  <span>Drag and Drop your file(s) to start uploading</span>
                </Space>
                OR
                <IncredableButton
                  style={{
                    background: "#598BEB",
                    color: "#FFF",
                    borderColor: "#598BEB",
                  }}
                >
                  Select a File
                </IncredableButton>
              </Space>
            </Dragger>
          </Card>
        )}
        {current === 2 && (
          <Table
            style={{
              boxShadow: "0px 10px 25px 0px #5911920D",
              borderRadius: "16px",
              border: "1px solid #E5E7EB",
              overflow: "hidden",
            }}
            columns={columns}
            dataSource={fileList}
            pagination={false}
            title={() => (
              <>
                <Typography.Title level={5} style={{ margin: 0 }}>
                  Files ({fileList.length})
                </Typography.Title>
                <Typography.Text type="secondary" style={{ fontSize: "14px" }}>
                  All the files in this table will be uploaded.
                </Typography.Text>
                <Flex justify="space-between" style={{ marginTop: 15 }}>
                  <Search
                    placeholder="Find by Name"
                    onSearch={(value) => console.log(value)}
                    style={{ width: 400, marginRight: 16 }}
                  />
                  <Space>
                    <Button style={{ margin: "0 8px" }} onClick={() => prev()}>
                      Previous
                    </Button>
                    <IncredableButton
                      type="primary"
                      onClick={async () => {
                        if (!providerId) return;
                        fileList?.forEach(async (item) => {
                          await saveDocument({
                            //    providerId: providerId,
                            document: {
                              name: item?.name,
                              expirationDate: item?.expirationDate,
                              alertDays: item?.alertDays,
                              notes: item?.notes,
                              categoryId: item?.categoryId,
                              file: item?.file,
                            },
                          });
                        });

                        naivgate(`/safe?providerId=${providerId}`);
                      }}
                    >
                      Save
                    </IncredableButton>
                  </Space>
                </Flex>
              </>
            )}
          />
        )}
      </div>
      <Modal
        title="Document Details"
        open={isModalOpen}
        footer={false}
        width={412}
        closeIcon={false}
        style={{
          borderRadius: "16px",
          boxShadow: "0px 10px 25px 0px #5911920D",
          top: "20px",
        }}
      >
        <FormProvider {...methods}>
          <Form name="addprovider" layout="vertical" autoComplete="off">
            <RHFTextField
              formItemProps={{ label: "Document Name" }}
              controllerProps={{ name: "name" }}
              rules={{ required: "This field is required" }}
              inputProps={{
                placeholder: "Enter Document Name",
              }}
            />
            <RHFSelect
              formItemProps={{ label: "Category" }}
              controllerProps={{
                name: "categoryId",
              }}
              selectProps={{
                allowClear: true,
                showSearch: true,
                style: { width: "100%" },
                placeholder: "Select Category",
                options: categories?.map((category) => {
                  return {
                    label: category?.name,
                    value: category?.id + "",
                  };
                }),
              }}
            />
            <RHFDatePicker
              formItemProps={{ label: "Expiration Date" }}
              controllerProps={{ name: "expirationDate" }}
              datePickerProps={{
                placeholder: "Expiration Date",
              }}
            />
            <RHFSelect
              formItemProps={{ label: "Expiration Alert Reminder" }}
              controllerProps={{
                name: `alertDays`,
              }}
              selectProps={{
                allowClear: true,
                showSearch: true,
                style: { width: "100%" },
                placeholder: "Expiration Alert Reminder",
                options: alertReminderOptions?.map((category) => {
                  return {
                    label: category?.label,
                    value: category?.value,
                  };
                }),
              }}
            />
            <RHFTextArea
              formItemProps={{ label: "Notes" }}
              controllerProps={{ name: "notes" }}
              textAreaProps={{
                placeholder: "Notes",
              }}
            />
            <Space style={{ width: "100%", justifyContent: "end" }}>
              <IncredableButton secondary onClick={handleCancel}>
                Cancel
              </IncredableButton>
              <IncredableButton
                type="primary"
                style={{ width: "90px" }}
                onClick={methods.handleSubmit(
                  async (data: DocumentRequestWithUid) => {
                    console.log({ data });
                    let temp = [...fileList];

                    let index = temp.findIndex(
                      (item) => item.key === methods.getValues("key")
                    );

                    if (index !== -1) {
                      temp[index] = { ...data };
                    }
                    setFileList(temp);
                    handleOk();
                  }
                )}
              >
                Save
              </IncredableButton>
            </Space>
          </Form>
        </FormProvider>
      </Modal>
    </IncredableContent>
  );
}
