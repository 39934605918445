import {
  useFormContext,
  Controller,
  RegisterOptions,
  FieldValues,
  FieldError,
  UseControllerProps,
} from "react-hook-form";
import { AsYouType } from "libphonenumber-js";
import { Form, FormItemProps, Input, InputProps, Typography } from "antd";

// ----------------------------------------------------------------------
type Props = {
  formItemProps: FormItemProps;
  inputProps?: InputProps;
  controllerProps: UseControllerProps;
  rules?:
  | Omit<
    RegisterOptions<FieldValues, string>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >
  | undefined;
};

export default function RHFPhoneNumber({
  formItemProps,
  inputProps,
  controllerProps,
  rules,
}: Props) {
  const { control } = useFormContext();
  const { Text } = Typography;
  return (
    <Controller
      name={controllerProps.name}
      control={control}
      defaultValue={controllerProps.defaultValue}
      render={({ field, fieldState }) => {
        return (
          <Form.Item
            {...formItemProps}
            label={
              <span style={{ fontWeight: "600" }}>{formItemProps?.label}{" "}{!!rules?.required ? <Text>*</Text> : <></>}</span>
            }
            // required={!!rules?.required}
            validateStatus={!!fieldState.error ? "error" : ""}
            help={!!fieldState.error && getErrorMessage(fieldState.error)}
          >
            <Input
              {...inputProps}
              value={new AsYouType("US").input(field.value ?? "")}
              onChange={(event) => {
                field.onChange(event.target.value);
              }}
            />
          </Form.Item>
        );
      }}
      rules={{ maxLength: 256, ...rules }}
    />
  );
}
const getErrorMessage = (error?: FieldError | undefined) => {
  if (!error) return;
  if (error.message && error.message.length > 0) return error.message;
  if (error.type === "maxLength") return "Value is too long";
  return "Invalid value";
};
const defaultFormat = (v: string) => v;
