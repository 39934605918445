import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Space } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useGetAllFacilityGroupQuery } from "../redux/api/facilitygroup/facilityGroup";
import { useGetAllLicenseTypeQuery } from "../redux/api/licensetype/licensetype";

export default function useLicenseTypeFilter() {
    const [selectedFilterkey, setSelectedFilterKey] = useState<string>("name");
    const [selectedFiltervalue, setSelectedFiltervalue] = useState<
        string | undefined
    >(undefined);

    const {
        data: licensetypes = [],
        refetch,
        isLoading: licensetypeLoading,
        isFetching,
    } = useGetAllLicenseTypeQuery();

    const [sortingDetails, setSortingDetails] = useState<{
        sortingField?: string;
        isDescending?: boolean;
    }>({ sortingField: "id", isDescending: false });
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageNumber, setPageNumber] = useState<number>(0);

    const filteredLicenseType = useMemo(() => {
        if (!!selectedFiltervalue) {
            if (selectedFilterkey === "name")
                return licensetypes?.filter(
                    (type) =>
                        type?.name
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );
            if (selectedFilterkey === "code")
                return licensetypes?.filter(
                    (type) =>
                        type?.code
                            ?.toLowerCase()
                            .includes(selectedFiltervalue?.toLowerCase())
                );

        }
        return licensetypes;
    }, [selectedFiltervalue, licensetypes]);

    useEffect(() => {
        setSelectedFiltervalue(undefined);
    }, [selectedFilterkey]);

    const TableFilters = (
        <Space size="small">
            <Select
                value={selectedFilterkey}
                onChange={(value) => {
                    setSelectedFilterKey(value);
                }}
                style={{ width: "140px" }}
                options={[
                    { value: "name", label: "Name" },
                    { value: "code", label: "Code" }
                ]}
            />
            {selectedFilterkey === "name" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Name"
                    prefix={<SearchOutlined />}
                />
            )}
            {selectedFilterkey === "code" && (
                <Input
                    onChange={(event) => {
                        setSelectedFiltervalue(event?.target?.value);
                    }}
                    value={selectedFiltervalue}
                    style={{
                        width: "320px",
                    }}
                    placeholder="Find Code"
                    prefix={<SearchOutlined />}
                />
            )}
        </Space>
    );

    return {
        TableFilters,
        filteredLicenseType,
        UseQueryHookResult: {
            refetch,
            isLoading: licensetypeLoading,
            isFetching,
            pageNumber,
            setPageNumber,
            pageSize,
            setPageSize,
            sortingDetails,
            setSortingDetails,
        },
    };
}
