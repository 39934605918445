import { Select, Space, Tag } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useGetFacilitiesQuery } from "../redux/api/facility/facility";
import { useGetBasicAllProvidersQuery } from "../redux/api/provider/provider";
import { debounce } from "lodash";
import {
  useGetAllPayerEnrollmentStatusQuery,
  useGetBasicAllPayerQuery,
  useLazyGetAllEnrollmentsQuery,
} from "../redux/api/payerenrollment/payerenrollment";
import { useGetAllPayerGroupQuery } from "../redux/api/payergroup/payergroup";
import { useGetAllFacilityGroupQuery } from "../redux/api/facilitygroup/facilityGroup";

export default function usePayerEnrollmentFilter() {
  const [selectedFilterkey, setSelectedFilterkey] = useState<string>("payer");

  const [providerNameFiltervalue, setProviderNameFiltervalue] = useState<
    string | undefined
  >(undefined);

  const [payerNameFilterValue, setPayerNameFilterValue] = useState<
    string | undefined
  >(undefined);

  const [netWorkNameFilterValue, setNetWorkNameFilterValue] = useState<
    string | undefined
  >(undefined);

  const [statusQueryFilterValue, setStatusQueryFilterValue] = useState<
    string | undefined
  >(undefined);

  const [facilityNameQueryFilterValue, setFacilityNameQueryFilterValue] =
    useState<string | undefined>(undefined);
  const [facilityGroupNameQueryFilterValue, setFacilityGroupNameQueryFilterValue] =
    useState<string | undefined>(undefined);

  const [sortingDetails, setSortingDetails] = useState<{
    sortingField?: string;
    isDescending?: boolean;
  }>({ sortingField: "payer", isDescending: false });

  const [pageSize, setPageSize] = useState<number>(10);

  const [pageNumber, setPageNumber] = useState<number>(0);

  const { data: facilities, isLoading: facilitiesLoading } =
    useGetFacilitiesQuery();

  const { data: providers } = useGetBasicAllProvidersQuery();
  const { data: status = [] } = useGetAllPayerEnrollmentStatusQuery();
  const { data: networks } = useGetAllPayerGroupQuery();
  const { data: facilityGroup } = useGetAllFacilityGroupQuery();

  const [triggerEnrollment, { data: enrollmentsData, isFetching }] =
    useLazyGetAllEnrollmentsQuery({});

  const { data: payers } = useGetBasicAllPayerQuery();

  useEffect(() => {
    setPageNumber(0);
  }, [
    providerNameFiltervalue,
    statusQueryFilterValue,
    facilityNameQueryFilterValue,
    facilityGroupNameQueryFilterValue,
    payerNameFilterValue,
    netWorkNameFilterValue,
  ]);

  // Debounced trigger function
  const debouncedTriggerEnrollment = useCallback(
    debounce((filters) => {
      triggerEnrollment(filters);
    }, 1200),
    [triggerEnrollment]
  );

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      debouncedTriggerEnrollment.cancel();
    };
  }, [debouncedTriggerEnrollment]);

  const reload = () => {
    debouncedTriggerEnrollment({
      pageNumber,
      pageSize,
      providerId: providerNameFiltervalue,
      statusId: statusQueryFilterValue,
      facilityId: facilityNameQueryFilterValue,
      facilityGroupId: facilityGroupNameQueryFilterValue,
      payerId: payerNameFilterValue,
      payerGroupId: netWorkNameFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  };

  useEffect(() => {
    debouncedTriggerEnrollment({
      pageNumber,
      pageSize,
      providerId: providerNameFiltervalue,
      statusId: statusQueryFilterValue,
      payerId: payerNameFilterValue,
      payerGroupId: netWorkNameFilterValue,
      facilityId: facilityNameQueryFilterValue,
      facilityGroupId: facilityGroupNameQueryFilterValue,
      sortBy: sortingDetails?.sortingField,
      direction: sortingDetails?.isDescending ? "DSC" : "ASC",
    });
  }, [
    pageNumber,
    pageSize,
    providerNameFiltervalue,
    statusQueryFilterValue,
    facilityNameQueryFilterValue,
    facilityGroupNameQueryFilterValue,
    payerNameFilterValue,
    netWorkNameFilterValue,
    sortingDetails,
    debouncedTriggerEnrollment,
  ]);

  const TableFilters = (
    <Space direction="vertical">
      <Space size="small">
        <Select
          value={selectedFilterkey}
          onChange={(value) => {
            setSelectedFilterkey(value);
          }}
          style={{ width: "140px" }}
          options={[
            { value: "payer", label: "Payer" },
            { value: "netwrok", label: "Network" },
            { value: "provider", label: "Provider" },
            { value: "status", label: "Status" },
            { value: "facility", label: "Facility" },
            { value: "facilityGroup", label: "Facility Group" }
          ]}
        />
        {selectedFilterkey === "payer" && (
          <Select
            key="payer-select"
            placeholder="Select Payer"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            value={payerNameFilterValue}
            onChange={(value) => {
              setPayerNameFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={payers?.map((payer) => ({
              label: `${payer.name}`,
              searchValue: payer.name,
              value: payer.id,
            }))}
          />
        )}
        {selectedFilterkey === "netwrok" && (
          <Select
            key="network-select"
            placeholder="Select Netwoek"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            value={netWorkNameFilterValue}
            onChange={(value) => {
              setNetWorkNameFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={networks?.map((network) => ({
              label: `${network.name}`,
              searchValue: network.name,
              value: network.id,
            }))}
          />
        )}
        {selectedFilterkey === "provider" && (
          <Select
            key="provider-select"
            placeholder="Select Provider"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            value={providerNameFiltervalue}
            onChange={(value) => {
              setProviderNameFiltervalue(value);
            }}
            style={{ width: "320px" }}
            options={providers?.map((provider) => ({
              label: `${provider.firstName} ${provider.lastName}`,
              searchValue: provider.firstName,
              value: provider.id,
            }))}
          />
        )}
        {selectedFilterkey === "status" && (
          <Select
            key="status-select"
            placeholder="Select status"
            allowClear
            showSearch={true}
            optionFilterProp="label"
            value={statusQueryFilterValue}
            onChange={(value) => {
              setStatusQueryFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={status.map((status) => ({
              label: status?.name,
              searchValue: status?.name,
              value: status?.id + "",
            }))}
          />
        )}
        {selectedFilterkey === "facility" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            placeholder="Select facility"
            value={facilityNameQueryFilterValue}
            onChange={(value) => {
              setFacilityNameQueryFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={facilities?.map((facility) => ({
              label: facility?.name,
              searchValue: facility?.name,
              value: facility?.id + "",
            }))}
          />
        )}
        {selectedFilterkey === "facilityGroup" && (
          <Select
            allowClear={true}
            showSearch={true}
            optionFilterProp="label"
            placeholder="Select Facility Group"
            value={facilityGroupNameQueryFilterValue}
            onChange={(value) => {
              setFacilityGroupNameQueryFilterValue(value);
            }}
            style={{ width: "320px" }}
            options={facilityGroup?.map((facility) => ({
              label: facility?.name,
              searchValue: facility?.name,
              value: facility?.id + "",
            }))}
          />
        )}
      </Space>
      <div>
        {!!payerNameFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setPayerNameFilterValue(undefined);
            }}
          >
            <span>
              Payer ={" "}
              {
                payers?.find(
                  (payer) => payer?.id + "" === payerNameFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!netWorkNameFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setNetWorkNameFilterValue(undefined);
            }}
          >
            <span>
              NetWork ={" "}
              {
                networks?.find(
                  (network) => network?.id + "" === netWorkNameFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!providerNameFiltervalue && (
          <Tag
            closeIcon
            onClose={() => {
              setProviderNameFiltervalue(undefined);
            }}
          >
            <span>
              Provider ={" "}
              {
                providers?.find(
                  (stautsOption) =>
                    stautsOption?.id + "" === providerNameFiltervalue + ""
                )?.firstName
              }
            </span>
          </Tag>
        )}
        {!!statusQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setStatusQueryFilterValue(undefined);
            }}
          >
            <span>
              Status ={" "}
              {
                status?.find(
                  (stautsOption) =>
                    stautsOption?.id + "" === statusQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!facilityNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setFacilityNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Facility ={" "}
              {
                facilities?.find(
                  (facility) =>
                    facility?.id + "" === facilityNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
        {!!facilityGroupNameQueryFilterValue && (
          <Tag
            closeIcon
            onClose={() => {
              setFacilityGroupNameQueryFilterValue(undefined);
            }}
          >
            <span>
              Facility Group={" "}
              {
                facilityGroup?.find(
                  (facility) =>
                    facility?.id + "" === facilityGroupNameQueryFilterValue + ""
                )?.name
              }
            </span>
          </Tag>
        )}
      </div>
    </Space>
  );

  return {
    TableFilters,
    filteredPayerEnrollments: enrollmentsData?.payerEnrollments || [],
    enrollmentsTotalRecords: enrollmentsData?.totalRecords ?? 0,
    UseQueryHookResult: {
      reload,
      isFetching,
      isLoading: facilitiesLoading,
      pageNumber,
      setPageNumber,
      pageSize,
      setPageSize,
      sortingDetails,
      setSortingDetails,
    },
  };
}
